import { Injectable, OnDestroy } from '@angular/core';
import { AuthService, NavigateWrapperService } from '../../../core/services';
import { OnboardingService } from '../../../onboarding/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OnboardingSummaryDialogComponent } from '../../../onboarding/components';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopNavbarService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();
  constructor(
    private dialog: MatDialog,
    private navigateService: NavigateWrapperService,
    private onboardingService: OnboardingService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateTo(route): void {
    if (!this.onboardingService.canSkipForm()) {
      this.openConfirmationModal()
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: boolean) => {
          this.navigateWithConfirmation(result, route);
        });

      return;
    }
    this.navigateService.navigate([route]);
  }

  private navigateWithConfirmation(saveChanges: boolean, route: string, callback?: () => void): void {
    if (saveChanges) {
      if (this.onboardingService.isFormValid()) {
        this.onboardingService.skipAndSave.next(true);
        this.onboardingService.setFormSkip(true);
        if (callback) {
          callback();
        }
        this.navigateService.navigate([route]);
      }
      return;
    }
    this.onboardingService.setFormSkip(true);
    this.onboardingService.skipAndSave.next(false);
    if (callback) {
      callback();
    }
    this.navigateService.navigate([route]);
  }

  openConfirmationModal(): MatDialogRef<any> {
    return this.dialog.open(OnboardingSummaryDialogComponent, {
      width: '350px',
      data: { formValid: true },
    });
  }

  logout() {
    if (!this.onboardingService.canSkipForm()) {
      this.openConfirmationModal()
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: boolean) => {
          this.navigateWithConfirmation(result, 'booking/previous-visit', () => this.authService.logout());
        });
      return;
    }
    this.authService.logout();
    this.navigateService.navigate(['booking', 'previous-visit']);
  }
}
