import { PhoneTypeEnum } from './../../shared/models/phoneType.enum';
export interface BookingDay {
  date: Date;
  availabilities: Timeslot[];
  // displayedAvailabilities: Timeslot[];
  dropdownAvailabilities: Timeslot[];
}

export interface Timeslot {
  offset: number;
  slotIndex: number;
  mainInternId?: number;
  supervisorId?: number;
  assistingInternId?: number;
  practitionerId?: number;
  localDateTime: string; // clinic location local date Timeslot
  dateObj: Date;
  timeString: string;
  date: string;
}

export interface Guest {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneType: PhoneTypeEnum;
  // cellNumber: string;
  // homeNumber: string;
  gender: string;
  otherGender: string;
  dateOfBirth: Date;
}

// only a mapper to send the appointment to server
export interface AppointmentData {
  appointmentUid: string;
  locationGuid: string;
  isNewPatient: boolean;
  serviceId: number;
  practitionerId?: number;
  mainInternId?: number;
  supervisorId?: number;
  assistingInternId?: number;
  index: number;
  date: string;
  account: Guest;
  additionalInformation: {
    reasonForVisit: string;
    receiveSms: boolean;
    referral: string;
  };
  basicInsurance: any;
  workerCompensationInsurance: any;
  autoAccidentInsurance: any;
  cardData?: any;
  cardId?: number;
}

export interface BookingAppointment {
  locationGuid: string;
  serviceId: number;
  practitionerId: number;
  isNewPatient: boolean;
  index: number;
  date: string;
}

export enum BookingConfirmationStatus {
  AutoAcceptedWithNewUser = 'AutoAcceptedWithNewUser',
  CreatedWithNewUser = 'CreatedWithNewUser',
  CreatedWithMatchingError = 'CreatedWithMatchingError',
  CreatedWithUserWithoutPP = 'CreatedWithUserWithoutPP',
  AutoAcceptedWithExistingUser = 'AutoAcceptedWithExistingUser',
  CreatedWithExistingUser = 'CreatedWithExistingUser',
}
