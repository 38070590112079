import { AccountModule } from './../account/account.module';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AppointmentsRoutingModule } from './appointments-routing.module';
import { SharedModule } from '../shared/shared.module';
import { APPOINTMENTS_COMPONENTS } from './components/index';
import { APPOINTMENTS_SERVICES } from './services/index';
import { APPOINTMENT_GUARDS } from './guards';
import { ClinicModule } from '../clinic/clinic.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    SharedModule,
    AccountModule,
    ClinicModule,
    AppointmentsRoutingModule,
    FlexLayoutModule
  ],
  declarations: [
    ...APPOINTMENTS_COMPONENTS
  ],
  providers: [
    ...APPOINTMENTS_SERVICES,
    ...APPOINTMENT_GUARDS
  ]
})
export class AppointmentsModule {

  constructor(@Optional() @SkipSelf() parentModule: AppointmentsModule) {
    if (parentModule) {
      throw new Error('AppointmentsModule already loaded; Import in root module only.');
    }
  }

}
