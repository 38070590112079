<div [ngSwitch]="error">
  <!-- 404 error -->
  <div class="page" *ngSwitchCase="'404'">
    <pp-not-found-page></pp-not-found-page>
  </div>

  <!-- 500 error -->
  <div class="page" *ngSwitchCase="'500'">
    <pp-500-page></pp-500-page>
  </div>

  <!-- the app -->
  <div *ngSwitchDefault>
    <router-outlet></router-outlet>
  </div>
</div>
