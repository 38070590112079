import { AccountModule } from './../account/account.module';
import { BOOKING_GUARDS } from './guards/index';
import { BOOKING_SERVICES } from './services/index';
import { BOOKING_COMPONENTS } from './components/index';
import { SharedModule } from './../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf } from '@angular/core';

import { BookingRoutingModule } from './booking-routing.module';
import { ClinicModule } from '../clinic/clinic.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullSteamIntegrationService } from '../core/services/full-steam-integration.service';
import { FullSteamIntegrationClient } from '../core/services/full-steam-integration.client';
import { TranslateService } from '@ngx-translate/core';
import { UpNgFullsteamModule, UpFullSteamIntegrationService } from '@unifiedpractice/up-ng-fullsteam';

@NgModule({
  imports: [
    SharedModule,
    AccountModule,
    BookingRoutingModule,
    ClinicModule,
    FlexLayoutModule,
    UpNgFullsteamModule.forRoot({
      service: {
        provide: UpFullSteamIntegrationService,
        useClass: FullSteamIntegrationService,
        deps: [FullSteamIntegrationClient, TranslateService],
      },
    }),
  ],
  declarations: [...BOOKING_COMPONENTS],
  providers: [...BOOKING_SERVICES, ...BOOKING_GUARDS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BookingModule {
  constructor(@Optional() @SkipSelf() parentModule: BookingModule) {
    if (parentModule) {
      throw new Error('BookingModule already loaded; Import in root module only.');
    }
  }
}
