import { Component, forwardRef, OnDestroy } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CatalogsService } from '../../../core/services/catalogs.service';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-country-state',
  templateUrl: './country-state.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => CountryStateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => CountryStateComponent),
      multi: true,
    },
  ],
})
export class CountryStateComponent extends BaseComponent implements OnDestroy {
  countries: any;
  states: any;
  form: FormGroup;

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  constructor(private catalogsService: CatalogsService, private formBuilder: FormBuilder) {
    super();
    catalogsService
      .getCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // add US on top of the list for a quicker find
        this.countries = data;
      });
    catalogsService
      .getStates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.states = data;
      });

    this.form = this.formBuilder.group({
      country: new FormControl(undefined),
      state: new FormControl(undefined),
      otherState: new FormControl(''),
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.change();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  writeValue(obj: any) {
    if (obj) {
      if (obj.country) {
        this.form.setControl('country', new FormControl(obj.country, [Validators.required]));
      }
      if (obj.state) {
        this.form.setControl('state', new FormControl(obj.state, [Validators.required]));
      }
      if (obj.otherState) {
        this.form.setControl('otherState', new FormControl(obj.otherState));
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // validates the form, returns null when valid else the validation object
  validate(c: FormControl) {
    if (this.form.get('country').value && this.form.get('country').value === 'US' && !this.form.get('state').value) {
      return {
        stateError: {
          valid: false,
        },
      };
    }
    return null;
  }

  change() {
    if (!this.form.get('country').value) {
      this.propagateChange(null);
    } else {
      this.propagateChange({
        country: this.form.value.country,
        state: this.form.value.country === 'US' ? this.form.value.state : this.form.value.otherState,
      });
    }
  }

  private propagateChange = (_: any) => {};
}
