import { BookingConfirmationStatus, Timeslot } from './../models/booking-day';
import { InsuranceType } from './../models/insurance-type';
import { BookingActions } from '../actions/index';

export interface State {
  appointmentUid: string;
  appointmentId: number;
  isExistingPatient: any;
  location: any;
  queryLocationGuid: any;
  service: any;
  practitioner: any;
  preferredPractitionerOption?: string;
  practitioners: any;
  dateDay: any;
  timeslot: Timeslot;
  account: any;
  clinicGuid: any;
  locationQueryGuid: any;
  currentStep: any;
  canChangeLocation: any;
  canChangePractitioner: any;
  insuranceType: InsuranceType;
  basicInsurance: any;
  workerCompensationInsurance: any;
  autoAccidentInsurance: any;
  additionalInformation: any;

  successStepTitle: string;
  successStepText: string;
  bookingConfirmationStatus: BookingConfirmationStatus;

  locationStepMsg: string;
  practitionerStepMsg: string;
  serviceStepMsg: string;
}

export const initialState: State = {
  appointmentUid: null,
  appointmentId: null,
  isExistingPatient: null,
  location: null,
  queryLocationGuid: null,
  service: null,
  practitioner: null,
  practitioners: null,
  dateDay: null,
  timeslot: null,
  account: null,
  clinicGuid: null,
  locationQueryGuid: null,
  currentStep: null,
  canChangeLocation: true,
  canChangePractitioner: true,
  additionalInformation: null,
  insuranceType: null,
  basicInsurance: null,
  workerCompensationInsurance: null,
  autoAccidentInsurance: null,

  successStepTitle: 'booking-success-title-not-logged-in',
  successStepText: '',

  locationStepMsg: null,
  practitionerStepMsg: null,
  serviceStepMsg: null,
  bookingConfirmationStatus: null,
};

export function reducer(
  state: State = initialState,
  // could support multiple state actions via union type here
  action: BookingActions.Actions
): State {
  switch (action.type) {
    case BookingActions.SET_EXISTING_PATIENT: {
      const value = action.payload;

      return Object.assign({}, state, {
        isExistingPatient: value,
      });
    }

    case BookingActions.SET_LOCATION: {
      const value = action.payload;

      return Object.assign({}, state, {
        location: value,
      });
    }

    case BookingActions.SET_QUERY_LOCATION: {
      const value = action.payload;

      return Object.assign({}, state, {
        queryLocationGuid: value,
      });
    }

    case BookingActions.SET_LOCATION_SERVICE: {
      const value = action.payload;

      return Object.assign({}, state, {
        service: value,
      });
    }

    case BookingActions.SET_PRACTITIONER: {
      const value = action.payload;

      return Object.assign({}, state, {
        practitioner: value,
      });
    }

    case BookingActions.SET_PREFERRED_PRACTITIONER_OPTION: {
      const value = action.payload;

      return Object.assign({}, state, {
        preferredPractitionerOption: value,
      });
    }

    case BookingActions.SET_PRACTITIONERS: {
      const value = action.payload;

      return Object.assign({}, state, {
        practitioners: value,
      });
    }

    case BookingActions.CAN_CHANGE_LOCATION: {
      const value = action.payload;

      return Object.assign({}, state, {
        canChangeLocation: value,
      });
    }
    case BookingActions.CAN_CHANGE_PRACTITIONER: {
      const value = action.payload;

      return Object.assign({}, state, {
        canChangePractitioner: value,
      });
    }
    case BookingActions.SET_ACCOUNT: {
      const value = action.payload;

      return Object.assign({}, state, {
        account: value,
      });
    }
    case BookingActions.SET_ADDITIONAL_DATA: {
      const value = action.payload;

      return Object.assign({}, state, {
        additionalInformation: value,
      });
    }

    case BookingActions.SET_INSURANCE_TYPE: {
      const value = action.payload;

      return Object.assign({}, state, {
        insuranceType: value,
      });
    }

    case BookingActions.SET_INSURANCE: {
      const value = action.payload;

      return Object.assign({}, state, {
        basicInsurance: value,
      });
    }
    case BookingActions.SET_WORK_INSURANCE: {
      const value = action.payload;

      return Object.assign({}, state, {
        workerCompensationInsurance: value,
      });
    }
    case BookingActions.SET_AUTO_INSURANCE: {
      const value = action.payload;

      return Object.assign({}, state, {
        autoAccidentInsurance: value,
      });
    }
    case BookingActions.SET_APPOINTMENT_UID: {
      const value = action.payload;

      return Object.assign({}, state, {
        appointmentUid: value,
      });
    }

    case BookingActions.SET_TIMESLOT: {
      const value = action.payload;

      if (value == null) {
        return Object.assign({}, state, {
          timeslot: null,
        });
      }

      return Object.assign({}, state, {
        timeslot: value,
      });
    }
    case BookingActions.RESET_ALL: {
      let { successStepTitle, ...x } = initialState;
      let { successStepText, ...y } = x;
      let { isExistingPatient, ...newState } = y;

      return Object.assign({}, state, newState);
    }
    case BookingActions.SET_MESSAGE_ON_SUCCEESS_STEP: {
      const value = action.payload;

      return Object.assign({}, state, {
        successStepTitle: value.title,
        successStepText: value.text,
      });
    }
    case BookingActions.SET_BOOKING_CONFIRMATION_STATUS: {
      const value = action.payload;

      return Object.assign({}, state, {
        bookingConfirmationStatus: value,
      });
    }
    case BookingActions.SET_MESSAGE_ON_Practitioner_STEP: {
      const value = action.payload;
      return Object.assign({}, state, {
        practitionerStepMsg: value,
      });
    }
    case BookingActions.SET_MESSAGE_ON_Location_STEP: {
      const value = action.payload;
      return Object.assign({}, state, {
        locationStepMsg: value,
      });
    }
    case BookingActions.SET_MESSAGE_ON_Service_STEP: {
      const value = action.payload;
      return Object.assign({}, state, {
        serviceStepMsg: value,
      });
    }
    default:
      return state;
  }
}

/**
 * Selectors
 */

export const getExistingPatient = (state: State) => state.isExistingPatient;

export const getLocation = (state: State) => state.location;

export const getQueryLocationGuid = (state: State) => state.queryLocationGuid;

export const getService = (state: State) => state.service;

export const getPractitioner = (state: State) => state.practitioner;
export const getPractitioners = (state: State) => state.practitioners;

export const getTimeslot = (state: State) => state.timeslot;

export const getClinicGuid = (state: State) => state.clinicGuid;

export const getLocationQueryGuid = (state: State) => state.locationQueryGuid;

export const getCurrentStep = (state: State) => state.currentStep;

export const canChangeLocation = (state: State) => state.canChangeLocation;
export const canChangePractitioner = (state: State) => state.canChangePractitioner;
export const getAccount = (state: State) => state.account;
export const getAdditionalInformation = (state: State) => state.additionalInformation;
export const getInsuranceType = (state: State) => state.insuranceType;

export const getRescheduledAppointmentUid = (state: State) => state.appointmentUid;
export const getPreferredPractitionerOption = (state: State) => state.preferredPractitionerOption;

export const getLocationStepMessage = (state: State) => state.locationStepMsg;
export const getPractitionerStepMessage = (state: State) => state.practitionerStepMsg;
export const getServiceStepMessage = (state: State) => state.serviceStepMsg;

export const getSuccessStepMessages = (state: State) => {
  return {
    title: state.successStepTitle,
    text: state.successStepText,
  };
};
export const getBookingConfirmationStatus = (state: State) => {
  return state.bookingConfirmationStatus;
};

export const getLocationAndExisting = (state: State) => {
  return {
    isNewPatient: state.isExistingPatient,
    locationGuid: state.location.guid,
  };
};
export const getLocationAndService = (state: State) => {
  return {
    location: state.location,
    service: state.service,
  };
};

export const getAppointment = (state: State) => {
  const apt = {
    appointmentUid: state.appointmentUid ? state.appointmentUid : null,
    locationGuid: state.location ? state.location.guid : null,
    serviceId: state.service ? state.service.id : null,
    isNewPatient: !state.isExistingPatient,
    practitionerId: state.practitioner ? state.practitioner.id : null,
    index: state.timeslot ? state.timeslot.slotIndex : null,
    date: state.timeslot ? state.timeslot.date : null,
    account: state.account,
    additionalInformation: state.additionalInformation,
    workerCompensationInsurance: state.workerCompensationInsurance,
    autoAccidentInsurance: state.autoAccidentInsurance,
    basicInsurance: state.basicInsurance,
  };

  return apt;
};
