import { ProfileModel } from '../../account/models/profile-model';
import { AuthTokenModel } from '../../account/models/auth-tokens-model';
import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AuthStateModel } from '../../account/models/auth-state-model';

@Injectable()
export class AuthHelperService implements OnDestroy {
  refreshSubscription$: Subscription;

  private state: BehaviorSubject<AuthStateModel> = new BehaviorSubject<AuthStateModel>({ profile: null, tokens: null, authReady: false });
  state$: Observable<AuthStateModel> = this.state.asObservable();
  tokens$: Observable<AuthTokenModel>;
  profile$: Observable<ProfileModel>;
  loggedIn$: Observable<boolean>;
  token: AuthTokenModel;

  constructor() {
    this.tokens$ = this.state.pipe(filter((state) => state.authReady)).pipe(map((state) => state.tokens));
    this.profile$ = this.state.pipe(filter((state) => state.authReady)).pipe(map((state) => state.profile));
    this.loggedIn$ = this.tokens$.pipe(map((tokens) => !!tokens));
  }

  ngOnDestroy(): void {
    this.state.complete();
  }

  public storeToken(tokens: AuthTokenModel): void {
    const previousTokens = this.retrieveTokens();
    if (previousTokens != null && tokens.refresh_token == null) {
      tokens.refresh_token = previousTokens.refresh_token;
    }
    this.token = tokens;
    localStorage.setItem('auth-tokens', JSON.stringify(tokens));
  }

  public retrieveTokens(): AuthTokenModel {
    const tokensString = localStorage.getItem('auth-tokens');
    const tokensModel: AuthTokenModel = tokensString == null ? null : JSON.parse(tokensString);
    this.token = tokensModel;
    return tokensModel;
  }

  public removeToken(): void {
    localStorage.removeItem('auth-tokens');
    this.token = undefined;
  }

  public updateState(newState: AuthStateModel): void {
    const previousState = this.state.getValue();
    this.state.next(Object.assign({}, previousState, newState));
  }
}
