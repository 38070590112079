<input type="file" #fileInput style="display: none"
    (change)="onFilesAdded($event)" [accept]="formatsAllowed" />

<button class="btn btn-primary btn-small" (click)="addFiles()"
    *ngIf="!file">
    <span *ngIf="!url;else changeFileTemplate">
        {{ 'upload-file' | translate}}
    </span>
    <ng-template #changeFileTemplate>
        {{ 'replace-file' | translate}}
    </ng-template>
</button>

<button class="btn btn-primary btn-small" (click)="removeFile(file)"
    *ngIf="file">
    <!-- <mat-icon>settings_backup_restore</mat-icon> -->
    {{'undo' | translate}}
</button>

<div class="row mt-2" *ngIf="file">
    <div class="col-12">
        <small> {{'upload-info-message' | translate}}
        </small>
    </div>
    <div class="col-12 file-upload-preview">
        <div class="pdf-file" *ngIf="file.type.indexOf('pdf') > -1;else renderImageTemplate">
            <mat-icon aria-label="picture_as_pdf">picture_as_pdf</mat-icon>
            <span class="cut-text-250"> {{file.name}}
            </span>
        </div>

        <ng-template #renderImageTemplate>
            <img class="preview-img" *ngIf="localPath"
                [src]="localPath" alt="your image" />
        </ng-template>
    </div>

</div>

<div *ngIf="url && !file" class="previous-upload row mt-2">
    <div class="col-12">
        <small> {{'previous-upload' | translate}}
        </small>
    </div>
    <div class="col-12">
        <img class="insurance-image" *ngIf="!isPdf(url)"
            [src]="url" />
        <a mat-icon-button target="_blank" *ngIf="isPdf(url)"
            [href]="url">
            <mat-icon aria-label="picture_as_pdf">picture_as_pdf</mat-icon>
            {{'open' | translate}}
        </a>
    </div>
</div>
