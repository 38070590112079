import { WorkInsuranceStepPageComponent } from './components/work-insurance-step-page/work-insurance-step-page.component';
import { AutoInsuranceStepPageComponent } from './components/auto-insurance-step-page/auto-insurance-step-page.component';
import { CanActivateIfTimeslotGuard } from './guards';
import { LoginStepPageComponent } from './components/login-step-page/login-step-page.component';
import { GuestStepPageComponent } from './components/guest-step-page/guest-step-page.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import {
  BookingContainerPageComponent,
  PreviusVisitStepPageComponent,
  LocationStepPageComponent,
  ServiceStepPageComponent,
  TimeslotStepPageComponent,
  InsuranceTypeStepPageComponent,
  PrimaryInsuranceStepPageComponent,
  ReviewStepPageComponent,
  SuccessfullyConfirmationStepPageComponent,
  PractitionerStepPageComponent,
} from './components/index';

// Guards
import {
  CanActivateServiceStepGuard,
  CanActivateLocationStepGuard,
  CanActivatePractitionerStepGuard,
  CanActivateTimeslotStepGuard,
  CanActivatePreviousVisitStepGuard,
} from './guards/index';

// Resolvers
import { ClinicStepResolver } from './guards/clinic-step.resolver';
import { StatesResolver } from '../onboarding/services/states.resolver';

export const bookingRoutes: Routes = [
  {
    path: 'booking',
    component: BookingContainerPageComponent,
    children: [
      { path: '', redirectTo: 'previous-visit', pathMatch: 'full' },
      {
        path: 'previous-visit',
        component: PreviusVisitStepPageComponent,
        canActivate: [CanActivatePreviousVisitStepGuard],
      },
      { path: 'previous-visit/:locationGuid', component: PreviusVisitStepPageComponent },
      {
        path: 'location',
        component: LocationStepPageComponent,
        resolve: { locations: ClinicStepResolver },
        canActivate: [CanActivateLocationStepGuard],
      },
      {
        path: 'service',
        component: ServiceStepPageComponent,
        canActivate: [CanActivateServiceStepGuard],
      },
      {
        path: 'practitioner',
        component: PractitionerStepPageComponent,
        canActivate: [CanActivatePractitionerStepGuard],
      },
      {
        path: 'timeslot',
        component: TimeslotStepPageComponent,
        canActivate: [CanActivateTimeslotStepGuard],
      },
      {
        path: 'auth',
        component: LoginStepPageComponent,
        canActivate: [CanActivateIfTimeslotGuard],
      },
      {
        path: 'register',
        component: GuestStepPageComponent,
        canActivate: [CanActivateIfTimeslotGuard],
      },
      {
        path: 'insurance-type',
        component: InsuranceTypeStepPageComponent,
        canActivate: [CanActivateIfTimeslotGuard],
      },
      {
        path: 'primary-insurance',
        component: PrimaryInsuranceStepPageComponent,
        resolve: { states: StatesResolver },
        canActivate: [CanActivateIfTimeslotGuard],
      },
      {
        path: 'auto-insurance',
        component: AutoInsuranceStepPageComponent,
        canActivate: [CanActivateIfTimeslotGuard],
      },
      {
        path: 'work-insurance',
        component: WorkInsuranceStepPageComponent,
        canActivate: [CanActivateIfTimeslotGuard],
      },
      {
        path: 'review',
        component: ReviewStepPageComponent,
        canActivate: [CanActivateIfTimeslotGuard],
      },
      {
        path: 'success',
        component: SuccessfullyConfirmationStepPageComponent,
        canActivate: [CanActivateIfTimeslotGuard],
      },
    ],
  },
];

@NgModule({
  exports: [RouterModule],
  providers: [ClinicStepResolver, StatesResolver],
})
export class BookingRoutingModule {}
