<div class="row">
  <div
    [class]="
      item.settings.options.length === 1
        ? 'col-sm-12 d-none d-sm-block'
        : item.settings.options.length === 2
        ? 'col-sm-6 d-none d-sm-block'
        : 'col-sm-4 d-none d-sm-block'
    "
    *ngFor="let option of item.settings.options; let i = index"
  >
    <div *ngFor="let ctrl of option; let j = index">
      <mat-radio-button
        *ngIf="singleChoice"
        [name]="item.key"
        [value]="ctrl.value"
        [checked]="values.indexOf(ctrl.value) > -1"
        (change)="change(ctrl.value)"
      >
        {{ ctrl.label }}
      </mat-radio-button>

      <mat-checkbox
        *ngIf="!singleChoice"
        [name]="item.key"
        [value]="ctrl.value"
        [checked]="values.indexOf(ctrl.value) > -1"
        (change)="change(ctrl.value)"
      >
        {{ ctrl.label }}
      </mat-checkbox>
    </div>
  </div>
</div>

<div class="d-block d-sm-none">
  <mat-select class="form-control form-control-select" *ngIf="singleChoice" placeholder="{{ 'select' | translate }}" [value]="values">
    <mat-option (click)="change(option.value)" *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>

  <mat-select
    class="form-control form-control-select"
    *ngIf="!singleChoice"
    multiple
    placeholder="{{ 'select' | translate }}"
    [value]="values"
  >
    <mat-option (click)="change(option.value)" *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</div>
