import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { OnboardingService } from '../../services';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { parseISO } from 'date-fns';

@Component({
  selector: 'pp-medical-info-page',
  templateUrl: 'medical-info-page.component.html',
})
export class MedicalInfoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  loaderRows = [0, 1, 2, 3, 4];

  form: FormGroup;
  loggedIn = false;
  showLoader = true;
  saving = false;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      showMedication: new FormControl(false),
      showVitamins: new FormControl(false),
      showAllergies: new FormControl(false),
      showMedicalDevices: new FormControl(false),
      medications: new FormArray([]),
      vitaminsSuplements: new FormArray([]),
      allergies: new FormArray([]),
      medicalDevices: new FormArray([]),
    });

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.onboardingService
      .getMedicalInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.medications && res.medications.length > 0) {
          res.medications.forEach((medication) => {
            this.addMedication(medication);
            this.form.controls['showMedication'].setValue(true);
          });
        } else {
          this.addMedication({});
        }

        if (res.vitaminsSuplements && res.vitaminsSuplements.length > 0) {
          res.vitaminsSuplements.forEach((vitamins) => {
            this.addVitamins(vitamins);
            this.form.controls['showVitamins'].setValue(true);
          });
        } else {
          this.addVitamins({});
        }

        if (res.allergies && res.allergies.length > 0) {
          res.allergies.forEach((allergy) => {
            this.addAllergies(allergy);
            this.form.controls['showAllergies'].setValue(true);
          });
        } else {
          this.addAllergies({});
        }

        if (res.medicalDevices && res.medicalDevices.length > 0) {
          res.medicalDevices.forEach((device) => {
            this.addMedicalDevices(device);
            this.form.controls['showMedicalDevices'].setValue(true);
          });
        } else {
          this.addMedicalDevices({});
        }

        if (
          !(
            this.form.controls['showMedication'].value ||
            this.form.controls['showVitamins'].value ||
            this.form.controls['showAllergies'].value ||
            this.form.controls['showMedicalDevices'].value
          )
        ) {
          this.form.controls['showMedication'].setValue(true);
        }

        this.showLoader = false;

        const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
        if (contentContainer) {
          contentContainer.scrollIntoView();
        }
      });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const isFormChangedAndFormNotCompleted = this.form.pristine;
      this.onboardingService.setFormSkip(isFormChangedAndFormNotCompleted);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  addMedication(data) {
    const control = <FormArray>this.form.controls['medications'];
    control.push(
      new FormGroup({
        name: new FormControl(data.name || ''),
        dosage: new FormControl(data.dosage || ''),
        duration: new FormControl(data.duration || ''),
        startDate: new FormControl(data.startDate ? parseISO(data.startDate) : null),
        discontinuedDate: new FormControl(data.discontinuedDate ? parseISO(data.discontinuedDate) : null),
      })
    );
  }

  addVitamins(data) {
    const control = <FormArray>this.form.controls['vitaminsSuplements'];
    control.push(
      new FormGroup({
        name: new FormControl(data.name || ''),
        dosage: new FormControl(data.dosage || ''),
        duration: new FormControl(data.duration || ''),
        startDate: new FormControl(data.startDate ? parseISO(data.startDate) : null),
        discontinuedDate: new FormControl(data.discontinuedDate ? parseISO(data.discontinuedDate) : null),
      })
    );
  }

  addAllergies(data) {
    const control = <FormArray>this.form.controls['allergies'];
    control.push(
      new FormGroup({
        name: new FormControl(data.name || ''),
        reaction: new FormControl(data.reaction || ''),
        startDate: new FormControl(data.startDate ? parseISO(data.startDate) : null),
        discontinuedDate: new FormControl(data.discontinuedDate ? parseISO(data.discontinuedDate) : null),
      })
    );
  }

  addMedicalDevices(data) {
    const control = <FormArray>this.form.controls['medicalDevices'];
    control.push(
      new FormGroup({
        name: new FormControl(data.name || ''),
        startDate: new FormControl(data.startDate ? parseISO(data.startDate) : null),
        discontinuedDate: new FormControl(data.discontinuedDate ? parseISO(data.discontinuedDate) : null),
      })
    );
  }

  // remove item from the list
  removeItem(i: number, arrayControl) {
    const control = <FormArray>this.form.controls[arrayControl];
    control.removeAt(i);
  }

  getControls(frmGrp: FormGroup, key: string) {
    return (<FormArray>frmGrp.controls[key]).controls;
  }

  private cleanUpData(formValues) {
    const medicalInfo = formValues;

    delete medicalInfo.showMedication;
    delete medicalInfo.showVitamins;
    delete medicalInfo.showAllergies;
    delete medicalInfo.showMedicalDevices;

    medicalInfo.medications = medicalInfo.medications.filter((m) => m.name !== '');
    medicalInfo.vitaminsSuplements = medicalInfo.vitaminsSuplements.filter((m) => m.name !== '');
    medicalInfo.allergies = medicalInfo.allergies.filter((m) => m.name !== '');
    medicalInfo.medicalDevices = medicalInfo.medicalDevices.filter((m) => m.name !== '');

    medicalInfo.medications.forEach((medication) => {
      medication.startDate = medication.startDate
        ? `${medication.startDate.getFullYear()}-${medication.startDate.getMonth() + 1}-${medication.startDate.getDate()}`
        : null;
      // tslint:disable-next-line:max-line-length
      medication.discontinuedDate = medication.discontinuedDate
        ? `${medication.discontinuedDate.getFullYear()}-${
            medication.discontinuedDate.getMonth() + 1
          }-${medication.discontinuedDate.getDate()}`
        : null;
    });

    medicalInfo.vitaminsSuplements.forEach((vitamin) => {
      vitamin.startDate = vitamin.startDate
        ? `${vitamin.startDate.getFullYear()}-${vitamin.startDate.getMonth() + 1}-${vitamin.startDate.getDate()}`
        : null;
      // tslint:disable-next-line:max-line-length
      vitamin.discontinuedDate = vitamin.discontinuedDate
        ? `${vitamin.discontinuedDate.getFullYear()}-${vitamin.discontinuedDate.getMonth() + 1}-${vitamin.discontinuedDate.getDate()}`
        : null;
    });

    medicalInfo.allergies.forEach((allergy) => {
      allergy.startDate = allergy.startDate
        ? `${allergy.startDate.getFullYear()}-${allergy.startDate.getMonth() + 1}-${allergy.startDate.getDate()}`
        : null;
      // tslint:disable-next-line:max-line-length
      allergy.discontinuedDate = allergy.discontinuedDate
        ? `${allergy.discontinuedDate.getFullYear()}-${allergy.discontinuedDate.getMonth() + 1}-${allergy.discontinuedDate.getDate()}`
        : null;
    });

    medicalInfo.medicalDevices.forEach((device) => {
      device.startDate = device.startDate
        ? `${device.startDate.getFullYear()}-${device.startDate.getMonth() + 1}-${device.startDate.getDate()}`
        : null;
      // tslint:disable-next-line:max-line-length
      device.discontinuedDate = device.discontinuedDate
        ? `${device.discontinuedDate.getFullYear()}-${device.discontinuedDate.getMonth() + 1}-${device.discontinuedDate.getDate()}`
        : null;
    });

    return medicalInfo;
  }

  onSubmit(redirect: boolean = true) {
    this.saving = true;
    const medicalInfo = this.cleanUpData(this.form.value);

    this.onboardingService.saveMedicalInfo(medicalInfo).subscribe((res) => {
      this.saving = false;
      this.onboardingService.updateSummary(Common.OnboardingSummaryForms.Medical);
      this.onboardingService.setFormSkip(true);
      if (redirect) {
        const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
        if (uncompleted) {
          this.navigateService.navigate([uncompleted.route], null, true);
        } else {
          this.navigateService.navigate(['onboarding/success'], null, true);
        }
      }
    });
  }
}
