import { ClinicService } from './../../../clinic/services';
import { AccountService } from './../../../account/services/account.service';
import { AppointmentsService } from './../../services/appointments.service';
import { CancelModalComponent } from './../cancel-modal/cancel-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Appointment } from '../../models/appointment';
import { select, Store } from '@ngrx/store';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../../booking/actions/booking.actions';
import { StartupService, NavigateWrapperService } from '../../../core/services';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { OrganizationTypeEnum } from '../../../shared/models/organization.enum';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'pp-appointment-item',
  templateUrl: 'appointment-item.component.html',
  styleUrls: ['appointment-item.component.scss'],
})
export class AppointmentItemComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() appointment: Appointment;
  @Input() canRescheduleAppointments: boolean;
  @Input() minHoursCancelOrReschedule: number;
  canCancel = false;
  status = '';
  canReschedule = false;
  canBookSimilar = false;
  organization$ = this.store.pipe(select(fromRoot.getOrganizationState));
  organization;
  mediaBaseUrl = environment.resourceUrl;

  constructor(
    private dialog: MatDialog,
    private store: Store<fromRoot.State>,
    private navigateService: NavigateWrapperService,
    public startupService: StartupService,
    private accountService: AccountService,
    private clinicService: ClinicService,
    public appointmentsService: AppointmentsService
  ) {
    super();
  }

  ngOnInit() {
    this.organization$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.organization = response;
    });
    if (this.appointment) {
      this.appointment.localDateTimeDateObj = parseISO(this.appointment.localDateTime);
      this.setAppointmentStatus();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get hasOrganizationCCPE() {
    return this.organization?.useCancellationPolicy;
  }

  isUniversity(): boolean {
    return this.organization?.organizationType === OrganizationTypeEnum.univeristy;
  }

  onOpenCancelModalClick() {
    const dialogRef = this.dialog.open(CancelModalComponent, {
      data: this.appointment,
      panelClass: 'cancel-app-modal-content',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.appointment.acceptedState = result.acceptedState;
          this.appointment.appointmentState = result.appointmentState;
          this.setAppointmentStatus();
        }
      });
  }

  onRescheduleClick() {
    this.store.dispatch(new BookingActions.SetAppointmentUid(this.appointment.appointmentUid));
    this.setBookingStoreDataAndRedirect();
  }

  onBookSimilarClick() {
    this.setBookingStoreDataAndRedirect();
  }

  getStatusIcon(status: string): string {
    switch (status) {
      case 'pending':
        return 'appointment_pending';
      case 'completed':
        return 'completed';
      case 'missed':
        return 'appointment_rejected';
      case 'accepted':
        return 'appointment_accepted';
      case 'cancelled':
        return 'appointment_cancelled';
      default:
        return '';
    }
  }

  cancelationPolicyInfoForService(cancellationFee: number): string {
    return `Cancellation Policy: $${cancellationFee} if cancelled less than ${this.organization?.cancellationPeriod} hours before the appointment.`;
  }

  setAppointmentStatus() {
    this.status = this.appointmentsService.getStatus(this.appointment);
    this.canReschedule = this.appointmentsService.canCancelOrReschedule(
      this.status,
      this.appointment.utcDateTime,
      this.canRescheduleAppointments,
      this.minHoursCancelOrReschedule
    );
    this.canCancel = this.appointmentsService.canCancelOrReschedule(
      this.status,
      this.appointment.utcDateTime,
      this.canRescheduleAppointments,
      this.minHoursCancelOrReschedule
    );
    this.canBookSimilar = this.appointmentsService.canBookSimilar();
  }

  private setBookingStoreDataAndRedirect() {
    this.appointment.service.cancellationFee = this.appointment.cancellationFee;
    if (!this.appointment.location.isActiveForOnlineBooking) {
      this.store.dispatch(new BookingActions.SetMessageOnLocationStep('location-not-available'));
      return this.navigateService.navigate(['booking', 'location']);
    } else {
      this.store.dispatch(new BookingActions.SetLocationAction(this.appointment.location));
    }

    if (this.clinicService.serviceNotAvailableForAuthenticated(this.appointment.service)) {
      this.store.dispatch(new BookingActions.SetMessageOnServiceStep('service-not-available'));
      return this.navigateService.navigate(['booking', 'service']);
    } else {
      this.store.dispatch(new BookingActions.SetServiceAction(this.appointment.service));
    }

    if (!this.appointment.practitioner.isActiveForOnlineBooking) {
      this.store.dispatch(new BookingActions.SetMessageOnPractitionerStep('practitioner-not-available'));
      return this.navigateService.navigate(['booking', 'practitioner']);
    } else {
      this.store.dispatch(new BookingActions.SetPractitionerAction(this.appointment.practitioner));
    }
    if (this.isUniversity() && this.appointment.intern) {
      this.store.dispatch(new BookingActions.SetPreferredPractitionerOption('Intern'));
    }
    return this.navigateService.navigate(['booking', 'timeslot']);
  }
}
