<div class="content-inner-deprecated pp-container" *ngIf="bookingConfirmationStatus === null">
  <div class="top-title">
    {{ 'book-appointment' | translate }}
  </div>
  <div class="box">
    <div class="text-center wrap-success-icon flex place-content-center">
      <img src="assets/img/succes-appointment.png" />
    </div>
    <h1 class="title title-top-success">{{ title | translate }}</h1>
    <p class="text-center">{{ text | translate }}</p>
    <div class="wrap-buttons-appointment">
      <div class="wrap-btn-appointment">
        <button style="margin: 20px" class="btn btn-primary btn-appointment" (click)="navigateTo('booking/previous-visit')">
          {{ 'book-another-app' | translate }}
        </button>
        <button style="margin: 20px" *ngIf="loggedIn" class="btn btn-secondary btn-appointment" (click)="navigateTo('appointments')">
          {{ 'dashboard' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="content-inner-deprecated pp-container mt-4" *ngIf="bookingConfirmationStatus !== null">
  <div class="box">
    <div class="booking-container">
      <pp-booking-steps *ngIf="canShowStepper()" step="step-2"></pp-booking-steps>
      <div *ngIf="!canShowStepper()">
        <img [src]="'assets/icon/' + getStatusIcon()" />
      </div>
      <div class="top-title text-center">{{ bookingConfirmationTitle | translate }}</div>
      <div>{{ bookingConfirmationText | translate }}</div>
      <div class="my-4">
        <button class="btn btn-primary btn-appointment" (click)="navigateTo('booking/previous-visit')">
          {{ buttonLabel | translate }}
        </button>
      </div>
      <div class="font-italic" *ngIf="canShowStepper()">{{ 'BookingConfirmation.CheckJunk' | translate }}</div>
    </div>
  </div>
</div>
