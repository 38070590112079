import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LogoutWarningModalComponent } from '../../shared/components/logout-warning-modal/logout-warning-modal.component';
import { AuthHelperService } from './auth-helper.service';
import { AuthService } from './auth.service';
import { StartupService } from './startup.service';



/**
 * Service used:
 * https://hackedbychinese.github.io/ng2-idle/
 */

@Injectable()
export class PingService implements OnDestroy {
  dialogRef;
  warningPeriod = environment.defaultWarningTime * 60; // in seconds
  private destroy$: Subject<void> = new Subject();

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private http: HttpClient,
    private startupService: StartupService,
    private dialog: MatDialog,
    private authService: AuthService,
    private authHelperService: AuthHelperService,
    private route: ActivatedRoute,

    private router: Router
  ) {
    /**
     * substract 1 minute from SessionTime,
     */
    const secondsUntilWarningDisplayed = (environment.defaultSessionTime - environment.defaultWarningTime) * 60;
    this.init(secondsUntilWarningDisplayed);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  init(idleInterval) {
    // interval until logout warning pop-up appears
    this.idle.setIdle(idleInterval);

    // sets a timeout period. after {warningPeriod} seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.warningPeriod);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialog.closeAll();
    });

    // logout
    // redirect user to logout if there was no interaction
    this.idle.onTimeout.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialog.closeAll();
      this.logout();
    });

    this.idle.onIdleStart.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.openWarningModal();
    });

    // triggered every second when idle start
    this.idle.onTimeoutWarning.pipe(takeUntil(this.destroy$)).subscribe(() => { });

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      if (loggedIn) {
        this.idle.watch();
      } else {
        this.idle.stop();
      }
    });
  }

  openWarningModal() {
    if (this.dialog.openDialogs.length === 0) {
      this.dialogRef = this.dialog.open(LogoutWarningModalComponent, {
        data: this.warningPeriod,
      });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login'], { relativeTo: this.route.root.firstChild });
  }
}
