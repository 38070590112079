import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UpChatApiIntegrationService, FeaturePlan, StreamUserDto, FeaturePlanType, ApplicationType } from '@unifiedpractice/up-chat-lib';
import { environment } from '../../environments/environment';
import { StartupService } from '../core/services';
import { AccountService } from '../account/services';

@Injectable({
  providedIn: 'root',
})
export class ChatApiIntegrationService extends UpChatApiIntegrationService {
  constructor(private httpClient: HttpClient, private startupService: StartupService, private accountService: AccountService) {
    super();
  }

  // @ts-ignore
  getFeaturePlans(): Observable<FeaturePlan[]> {
    return of([
      {
        type: FeaturePlanType.Starter,
        options: [
          {
            description: 'files-exchange',
            available: true,
          },
          {
            description: 'hipaa',
            available: true,
          },
          {
            description: 'aca-compliance',
            available: true,
          },
          {
            description: 'live-messages',
            available: false,
          },
          {
            description: 'live-team-chat',
            available: false,
          },
          {
            description: 'live-widget',
            available: false,
          },
        ],
      },
      {
        type: FeaturePlanType.Enhanced,
        options: [
          {
            description: 'files-exchange',
            available: true,
          },
          {
            description: 'hipaa',
            available: true,
          },
          {
            description: 'aca-compliance',
            available: true,
          },
          {
            description: 'live-messages',
            available: true,
          },
          {
            description: 'live-team-chat',
            available: false,
          },
          {
            description: 'live-widget',
            available: false,
          },
        ],
      },
      {
        type: FeaturePlanType.Premium,
        options: [
          {
            description: 'files-exchange',
            available: true,
          },
          {
            description: 'hipaa',
            available: true,
          },
          {
            description: 'aca-compliance',
            available: true,
          },
          {
            description: 'live-messages',
            available: true,
          },
          {
            description: 'live-team-chat',
            available: true,
          },
          {
            description: 'website-chat-leads',
            available: true,
          },
        ],
      },
    ]);
  }

  // @ts-ignore
  getCurrentFeaturePlan(): FeaturePlanType {
    return this.startupService.getChatFeaturePlan();
  }

  // @ts-ignore
  setFeaturePlanDisplay(value: boolean): Observable<boolean> {
    return of(true);
  }

  // @ts-ignore
  getUserChannels(): Observable<StreamUserDto> {
    return this.httpClient.get(`${environment.apiUrl}/Stream/channels`) as Observable<StreamUserDto>;
  }

  // @ts-ignore
  initStreamPatient(channelType: 'Practitioner' | 'FrontDesk'): Observable<StreamUserDto> {
    return this.httpClient.post(`${environment.apiUrl}/Stream/channel`, { channelType }) as unknown as Observable<StreamUserDto>;
  }

  // @ts-ignore
  createChannelWithPatient(): Observable<StreamUserDto> {
    return this.httpClient.post(`${environment.apiUrl}/Stream/init`, {}) as unknown as Observable<StreamUserDto>;
  }

  // @ts-ignore
  createChannelWithClinicStaff(): Observable<StreamUserDto> {
    return of(null) as unknown as Observable<StreamUserDto>;
  }

  // @ts-ignore
  getChatContext(): ApplicationType {
    return ApplicationType.PP;
  }

  isLiveChatEnabled(): boolean {
    return this.startupService.getChatFeaturePlan() !== null && this.startupService.getChatFeaturePlan() !== FeaturePlanType.Starter;
  }

  isLiveChatEnabledForPatients(): boolean {
    return this.startupService.isLiveChatEnabled();
  }

  patientHasPrimaryPractitioner(): boolean {
    return !!this.accountService.user.getValue().primaryPractitionerId;
  }
}
