import { GenderEnum } from './../../models/gender.enum';
import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator } from '@angular/forms';
import { Component, OnInit, forwardRef, Input } from '@angular/core';

@Component({
    selector: 'pp-select-list',
    templateUrl: 'select-list.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => SelectListComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => SelectListComponent),
            multi: true,
        }
    ]
})
export class SelectListComponent implements ControlValueAccessor, Validator, OnInit {

    @Input() item: any;
    disabled = false;
    values = [];
    options = [];
    singleChoice: boolean;

    // Function to call when the input is touched (when a star is clicked).
    onTouched = () => { };

    constructor() { }

    ngOnInit() {
        this.singleChoice = this.item.settings.isSingle;
        this.values = this.item.value || [];

        let rowCounter = 0;
        this.item.settings.options.forEach(row => {
            let optionCounter = 0;
            row.forEach(control => {

                this.options.push({
                    label: control.label,
                    value: `${this.item.id}_${rowCounter}_${optionCounter}`
                });

                control.value = `${this.item.id}_${rowCounter}_${optionCounter}`;

                optionCounter++;
            });
            rowCounter++;
        });

        // console.log(this.options);
        // console.log(this.item);
    }

    writeValue(obj: any): void {
        if (obj) {
            this.values = obj || [];
        }
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    // validates the form, returns null when valid else the validation object
    // in this case we're checking if the date parsing has passed or failed from the onChange method
    public validate(c: FormControl) {
        return null;
    }

    change(option) {
        if (option) {
            const index: number = this.values.indexOf(option);
            if (this.singleChoice) {
                this.values = option;
            } else {
                if (index !== -1) {
                    this.values.splice(index, 1);
                } else {
                    this.values.push(option);
                }
            }

        }
      if (this.values.length === 0) {
      this.propagateChange(null);
        } else {
        this.propagateChange({
            value: this.singleChoice ? [this.values] : this.values
        });
      }
    }

    // the method set in registerOnChange to emit changes back to the form
    // tslint:disable-next-line:no-empty
    private propagateChange = (_: any) => { };

}
