import { BookingDay } from './../models/booking-day';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { format, parseISO } from 'date-fns';
import { map } from 'rxjs/operators';
import { BookingActions } from '../actions';
import { NavigateWrapperService } from '../../core/services';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../ngrx';

@Injectable()
export class BookingService {
  constructor(
    private http: HttpClient,
    private navigateService: NavigateWrapperService,

    private store: Store<fromRoot.State>
  ) {}

  createGuestBooking(appointment) {
    return this.http.post(`${environment.apiUrl}/Appointments/guest`, appointment);
  }

  createAuthBooking(appointment) {
    appointment = this.authAppoimentMapper(appointment);

    return this.http.post(`${environment.apiUrl}/Appointments`, appointment);
  }

  reschedule(appointment) {
    appointment = this.authAppoimentMapper(appointment);
    const uid = appointment.appointmentUid;
    delete appointment.appointmentUid;
    return this.http.put(`${environment.apiUrl}/Appointments/${uid}/reschedule`, appointment);
  }

  /**
   * get all the availabilites from server
   * save the availabe dates in memory for timeslots display
   * return the array with the available dates used by the calendar component to lock the days
   *
   * @param locationGuid      - selected location guid
   * @param serviceId         - selected service id
   * @param practitionerId    - selected practitioner
   * @param isForNewPatients - if the user is an existing patient
   * @param startDate         - first day of the interval
   * @param endDate           - last day of the interval
   */
  getAvailableDates(
    locationGuid: string,
    serviceId: number,
    practitionerId: number,
    isForNewPatients: boolean,
    startDate: Date,
    endDate: Date,
    rescheduledAppointmentUid?: string,
    preferredPractitionerOption?: 'FeelingLucky' | 'Practitioner' | 'Intern'
  ) {
    let params = new HttpParams()
      .set('LocationGuid', locationGuid)
      .set('ServiceId', serviceId.toString())
      .set('IsForNewPatients', isForNewPatients.toString())
      .set('StartDate', format(startDate, 'yyyy-MM-dd'))
      .set('EndDate', format(endDate, 'yyyy-MM-dd'));

    if (practitionerId) {
      params = params.set('PractitionerId', practitionerId.toString());
    }
    if (rescheduledAppointmentUid) {
      params = params.set('rescheduledAppointmentUid', rescheduledAppointmentUid);
    }
    if (preferredPractitionerOption) {
      params = params.set('PreferredPractitionerOption', preferredPractitionerOption);
    }

    return this.http.get(`${environment.apiUrl}/Availabilities`, { params }).pipe(
      map((res) => {
        const availableDays: BookingDay[] = [];

        for (const bd of <BookingDay[]>res) {
          if (bd.availabilities.length > 0) {
            bd.date = parseISO(bd.date as any);

            for (const av of bd.availabilities) {
              av.dateObj = parseISO(av.date);
              av.timeString = format(av.dateObj, 'h:mm a');
            }

            bd.dropdownAvailabilities = [];

            availableDays.push(bd);
          }
        }

        return availableDays;
      })
    );
  }

  authAppoimentMapper(appointment) {
    const apt = {
      locationGuid: appointment.locationGuid,
      serviceId: appointment.serviceId,
      practitionerId: appointment.practitionerId,
      index: appointment.index,
      date: appointment.date,
      cardData: appointment.cardData,
      cardId: appointment.cardId,
    };
    if (appointment.supervisorId) {
      apt['supervisorId'] = appointment.supervisorId;
    }
    if (appointment.mainInternId) {
      apt['mainInternId'] = appointment.mainInternId;
    }
    if (appointment.assistingInternId) {
      apt['assistingInternId'] = appointment.assistingInternId;
    }

    if (appointment.appointmentUid) {
      apt['appointmentUid'] = appointment.appointmentUid;
    }

    return apt;
  }

  redirectToStepWithError(error: string): void {
    switch (error) {
      case 'no-availabilities':
        this.navigateService.navigate(['booking', 'timeslot'], { error: 'timeslot-not-available' });
        break;
      case 'service-onlinescheduling-not-allowed':
        this.store.dispatch(new BookingActions.SetMessageOnServiceStep('service-not-available'));
        this.store.dispatch(new BookingActions.SetServiceAction(null));
        this.store.dispatch(new BookingActions.SetTimeslotAction(null));
        this.store.dispatch(new BookingActions.SetPractitionerAction(null));
        this.navigateService.navigate(['booking', 'service']);
        break;
      case 'location-onlinescheduling-not-allowed':
        this.store.dispatch(new BookingActions.SetServiceAction(null));
        this.store.dispatch(new BookingActions.SetTimeslotAction(null));
        this.store.dispatch(new BookingActions.SetPractitionerAction(null));
        this.store.dispatch(new BookingActions.SetMessageOnLocationStep('location-not-available'));
        this.navigateService.navigate(['booking', 'location']);
        break;
      case 'practitioner-onlinescheduling-not-allowed':
        this.store.dispatch(new BookingActions.SetPractitionerAction(null));
        this.store.dispatch(new BookingActions.SetTimeslotAction(null));
        this.store.dispatch(new BookingActions.SetMessageOnPractitionerStep('practitioner-not-available'));
        this.navigateService.navigate(['booking', 'practitioner']);
        break;
    }
  }
}
