import { Action } from '@ngrx/store';
import { type } from '../../ngrx/type';

export namespace OrganizationActions {
  export const ORGANIZATION = '[Organization]';
  export const SET_ORGANIZATION = type(`${ORGANIZATION} Set ORGANIZATION`);

  export class SetOrganization implements Action {
    type = SET_ORGANIZATION;
    constructor(public payload: any) { }
  }

  export type Actions = SetOrganization;
}
