export enum FormFieldsEnum {
    TextBox = 1,
    DropdownSingleSelect = 2,
    DropdownMultipleSelect = 3,
    CheckBox = 4,
    DatePicker = 5,
    Gender = 100,
    Phone = 101,

    Heading = 200,
    FreeText = 201,
    TextInput = 202,
    DateInput = 203,
    CustomCheckbox = 204,
    CheckboxList = 205,
    Slider = 206,
    File = 2017,
    Signature = 2018
}
