<div class="box select-box select-clinic-box">

  <h3 class="subtitle d-none d-sm-block">
    {{'medical-forms' | translate}} </h3>

  <pp-generic-forms-list-page
    [formType]="formTypes.MedicalForms">
  </pp-generic-forms-list-page>

</div>
