import { NameList } from '../actions/index';
import { Observable } from 'rxjs';

export interface State {
    names: Array<string>;
}

export const sampleInitialState: State = {
    names: <Array<string>>[]
};

export function reducer(
    state: State = sampleInitialState,
    // could support multiple state actions via union type here
    // ie: NameList.Actions | Other.Actions
    // the seed's example just has one set of actions: NameList.Actions
    action: NameList.Actions
): State {
    switch (action.type) {
        case NameList.INITIALIZED:
            return (<any>Object).assign({}, state, {
                names: action.payload
            });

        case NameList.NAME_ADDED:
            return (<any>Object).assign({}, state, {
                names: [...state.names, action.payload]
            });

        default:
            return state;
    }
}

// selects specific slice from sample state
// export const getNames = (state: State) => state.names;
