import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppointmentsService } from '../../services';
import { StartupService } from './../../../core/services/startup.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from './../../../ngrx';
import { State } from './../../../organization/reducers';
import { zonedTimeToUtc } from 'date-fns-tz';

@Component({
  selector: 'pp-cancel-modal',
  styleUrls: ['./cancel-modal.component.scss'],
  templateUrl: 'cancel-modal.component.html',
})
export class CancelModalComponent implements OnInit {
  cancelInProgress: boolean = false;

  reason = '';
  cancellationPeriod: number;
  cancellationPolicyTitle: string;
  organization$ = this.store.pipe(select(fromRoot.getOrganizationState));
  organization: State;

  get cancellationPolicyWarningText(): string {
    return `According to the agreed ${this.cancellationPolicyTitle}, cancelling the appointment within ${this.cancellationPeriod} hours before its scheduled start time will be charged a late cancellation fee of $${this.data.cancellationFee}.`;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appointmentsService: AppointmentsService,
    public dialogRef: MatDialogRef<CancelModalComponent>,
    private startupService: StartupService,
    private store: Store<fromRoot.State>
  ) {}

  ngOnInit() {
    this.cancellationPeriod = this.startupService.startupData.cancellationPeriod;
    this.cancellationPolicyTitle = this.startupService.startupData.cancellationPolicyTitle;
    this.organization$.pipe().subscribe((response) => (this.organization = response));
  }

  onCancelClick() {
    this.cancelInProgress = true;

    this.appointmentsService.cancel(this.data.appointmentUid, this.reason).subscribe(
      (res) => {
        this.dialogRef.close(res);
      },
      (err) => {
        if (err.status === 401) {
          this.dialogRef.close();
        }
      }
    );
  }

  public showCcpeSection(): boolean {
    let currentDateTimeUTC = zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone);
    let bookingDateTimeUTC = zonedTimeToUtc(this.data.utcDateTime, 'UTC');
    bookingDateTimeUTC.setHours(bookingDateTimeUTC.getHours() - this.cancellationPeriod);
    const appointmentIsInCcpeTimeFrame = currentDateTimeUTC > bookingDateTimeUTC;

    return (
      appointmentIsInCcpeTimeFrame &&
      this.organization.useCancellationPolicy &&
      this.data.cancellationFee > 0 &&
      !this.data.doNotChargeCancellationFee
    );
  }
}
