<div *ngIf="queryParams.type === 'extended'"
  class="content-inner-deprecated pp-container">
  <div class="top-title">
    {{ 'register' | translate }}
  </div>
  <div class="box select-box select-clinic-box">
    <h3 class="subtitle subtitle-create-account">{{
      'fill-in-personal-info' | translate }}</h3>
    <p>
      <i class="text-note">{{ 'required-fields-note' |
        translate }}</i>
    </p>
    <form class="form-full-width" [formGroup]="guest"
      data-lpignore="true" id="lpignore-search-trick"
      novalidate>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <label
            class="label-form-control required-field-label">
            {{ 'email' | translate }}</label>
          <input class="form-control inp"
            placeholder="{{ 'email-placeholder' | translate }}"
            type="email" formControlName="email"
            tabindex="0" />
          <div
            *ngIf="guest.get('email') && guest.get('email').invalid && (guest.get('email').dirty || guest.get('email').touched)">
            <p class="error-message-field"
              *ngIf="guest.get('email').errors.required">
              {{ 'form-input-error-required' | translate }}
            </p>
            <p class="error-message-field"
              *ngIf="guest.get('email').value && guest.get('email').errors.email && guest.get('email').errors.email.invalid">
              {{ 'form-input-error-email-format' | translate
              }}
            </p>
            <p class="error-message-field"
              *ngIf="guest.get('email').value && guest.get('email').errors && guest.get('email').errors.emailIsInUse">
              {{ 'email-is-in-use' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <pp-form-render [formFields]="fields"
          [form]="guest">
        </pp-form-render>

        <div class="col-lg-12 col-xs-12 form-row">
          <mat-checkbox id="1"
            formControlName="termsAgreed">
            {{ 'read-and-accept' | translate }}
            <a href="https://www.unifiedpractice.com/terms-and-conditions/"
              target="_blank">Terms of Use</a>
            and
            <a href="https://www.unifiedpractice.com/privacy-policy"
              target="_blank">Privacy Policy </a>
            <span
              class="label-form-control required-field-label"></span>
          </mat-checkbox>
          <div *ngIf="
              guest.get('termsAgreed') &&
              guest.get('termsAgreed').invalid &&
              (guest.get('termsAgreed').dirty || guest.get('termsAgreed').touched)
            ">
            <p class="error-message-field"
              *ngIf="guest.get('termsAgreed').errors.required">
              {{ 'form-input-error-terms-not-greed' |
              translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xs-12 form-row">
          <label
            class="label-form-control label-form-control-inline">{{
            'password' | translate }}</label>
          <input class="form-control" type="password"
            formControlName="password" />
          <div
            *ngIf="guest.get('password') && guest.get('password').invalid && (guest.get('password').dirty || guest.get('password').touched)">
            <p class="error-message-field"
              *ngIf="guest.get('password').errors?.required">
              {{ 'form-input-error-required' | translate }}
            </p>
            <p class="error-message-field"
              *ngIf="guest.get('password').errors?.minlength">
              {{ 'form-input-error-password-minlength' |
              translate }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 form-row">
          <label
            class="label-form-control label-form-control-inline">{{
            'confirm-password' | translate }}</label>
          <input class="form-control" type="password"
            formControlName="confirmPassword" />
          <p class="error-message-field" *ngIf="
              guest.get('confirmPassword').errors?.MatchFields &&
              (guest.get('confirmPassword').dirty || guest.get('confirmPassword').touched)
            ">
            {{ 'form-input-error-confirm-password-match' |
            translate }}
          </p>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-lg-12 col-xs-12 form-row">
        <!-- <button class="btn btn-primary" [disabled]="!guest.valid" (click)="onCreateAccountClick()">{{'continue' | translate}} -->
        <button class="btn btn-primary"
          [ppSubmitIfValid]="guest"
          (valid)="onCreateAccountClick()">{{ 'continue' |
          translate }}</button>
      </div>
    </div>
  </div>
</div>
<div
  class="content-inner-deprecated pp-container not-extended-container"
  fxLayout="row" fxLayoutAlign="center center"
  *ngIf="queryParams.type !== 'extended'">
  <div class="box select-box register-container">
    <form [formGroup]="confirmPasswordForm"
      (ngSubmit)="submitSetPasswordForm()">
      <pp-booking-steps *ngIf="showBookingStepts"
        step="step-1"></pp-booking-steps>
      <div fxLayout="row" fxLayoutAlign="center center"
        class="top-title">{{ 'register' | translate }}
      </div>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <label class="label-form-control">{{ 'email' |
            translate }}</label>
          <input class="form-control inp" type="email"
            formControlName="email" readonly />
        </div>
        <div class="col-lg-12 col-xs-12 form-row">
          <label
            class="label-form-control label-form-control-inline">{{
            'password' | translate }}</label>
          <input class="form-control" type="password"
            formControlName="password" />
          <div *ngIf="
                confirmPasswordForm.get('password') &&
                confirmPasswordForm.get('password').invalid &&
                (confirmPasswordForm.get('password').dirty || confirmPasswordForm.get('password').touched)
              ">
            <p class="error-message-field"
              *ngIf="confirmPasswordForm.get('password').errors?.required">
              {{ 'form-input-error-required' | translate }}
            </p>
            <p class="error-message-field"
              *ngIf="confirmPasswordForm.get('password').errors?.minlength">
              {{ 'form-input-error-password-minlength' |
              translate }}
            </p>
          </div>
        </div>
        <div class="col-lg-12 col-xs-12 form-row">
          <label
            class="label-form-control label-form-control-inline">{{
            'confirm-password' | translate }}</label>
          <input class="form-control" type="password"
            formControlName="confirmPassword" />
          <p class="error-message-field" *ngIf="
                confirmPasswordForm.get('confirmPassword').errors?.MatchFields &&
                (confirmPasswordForm.get('confirmPassword').dirty || confirmPasswordForm.get('confirmPassword').touched)
              ">
            {{ 'form-input-error-confirm-password-match' |
            translate }}
          </p>
        </div>
        <div class="col-lg-12 col-xs-12 form-row">
          <button class="btn btn-primary btn-login"
            type="submit"
            [disabled]="!confirmPasswordForm.valid">
            {{ 'register' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
