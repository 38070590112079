<div class="box select-box select-clinic-box">
  <h3 class="subtitle d-none d-sm-block">{{ 'signature-form' | translate }}</h3>
  <ng-container *ngIf="allConsentFormsAreSigned$ | async; else signTemplate">
    <div>
      {{ 'all-consent-forms-signed' | translate }}
    </div>
  </ng-container>
  <ng-template #signTemplate>
    <form class="form-full-width" [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-12 form-row wrap-switch-row">
          <div class="col-11">
            <div class="switch-text-label">
              {{ parentGuardianText | translate }}
            </div>
          </div>
          <div class="col-1">
            <div class="md-switch">
              <input
                type="checkbox"
                id="switch-input-1"
                class="switch-input"
                formControlName="isParentGuardian"
                name="isParentGuardian"
                (change)="onToggle()"
              />
              <label for="switch-input-1" class="switch-label">
                <span class="toggle-on"></span>
                <span class="toggle-off"></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="form.get('isParentGuardian').value">
        <div class="signature-form">
          <div class="row" *ngFor="let field of consentForm.controls">
            <pp-form-render
              *ngIf="field.type === 'CustomField'"
              class="col-12"
              [customFields]="[field.data]"
              [form]="form"
            ></pp-form-render>
            <pp-form-render *ngIf="field.type === 'FormField'" class="col-12" [formFields]="[field.data]" [form]="form"> </pp-form-render>
          </div>
        </div>
      </div>

      <div>
        <span>{{ 'signature-form-accept' | translate }}</span>
      </div>

      <pp-signature-input formControlName="Signature"> </pp-signature-input>
    </form>
  </ng-template>

  <div class="row">
    <div class="col-12">
      <button type="submit " class="btn btn-primary" (click)="onSubmit()" [disabled]="!form.valid">
        <i class="fa fa-spin fa-circle-o-notch" *ngIf="saving"></i>
        {{ 'save-and-continue' | translate }}
      </button>
    </div>
  </div>
</div>
