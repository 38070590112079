import { NoAuthGuard } from './no-auth.guard';
import { AuthGuard } from './auth.guard';
import { NotificationPreferencesResolver } from './notification-preferences.resolver';

export const ACCOUNT_GUARDS: any[] = [
    AuthGuard,
    NoAuthGuard,
    NotificationPreferencesResolver
];

export * from './auth.guard';
export * from './no-auth.guard';
export * from './notification-preferences.resolver';

