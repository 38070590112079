import { AutoInsuranceStepPageComponent } from './auto-insurance-step-page/auto-insurance-step-page.component';
import { WorkInsuranceStepPageComponent } from './work-insurance-step-page/work-insurance-step-page.component';
import { LoginStepPageComponent } from './login-step-page/login-step-page.component';
import { GuestStepPageComponent } from './guest-step-page/guest-step-page.component';
import { LocationStepPageComponent } from './location-step-page/location-step-page.component';
import { BookingSummaryBoxComponent } from './booking-summary-box/booking-summary-box.component';
import { ReviewStepPageComponent } from './review-step-page/review-step-page.component';
import { SuccessfullyConfirmationStepPageComponent } from './successfully-confirmation-step-page/successfully-confirmation-step-page.component';
import { InsuranceTypeStepPageComponent } from './insurance-type-step-page/insurance-type-step-page.component';
import { TimeslotStepPageComponent } from './timeslot-step-page/timeslot-step-page.component';
import { PractitionerStepPageComponent } from './practitioner-step-page/practitioner-step-page.component';
import { BookingContainerPageComponent } from './booking-container-page/booking-container-page.component';

import { PreviusVisitStepPageComponent } from './previous-visit-step-page/previus-visit-step-page.component';
import { ServiceStepPageComponent } from './service-step-page/service-step-page.component';
import { PrimaryInsuranceStepPageComponent } from './primary-insurance-step-page/primary-insurance-step-page.component';

export const BOOKING_COMPONENTS: any[] = [
  BookingContainerPageComponent
  , PreviusVisitStepPageComponent
  , LocationStepPageComponent
  , ServiceStepPageComponent
  , PractitionerStepPageComponent
  , TimeslotStepPageComponent
  , InsuranceTypeStepPageComponent
  , PrimaryInsuranceStepPageComponent
  , SuccessfullyConfirmationStepPageComponent
  , ReviewStepPageComponent
  , BookingSummaryBoxComponent
  , GuestStepPageComponent
  , LoginStepPageComponent
  , WorkInsuranceStepPageComponent
  , AutoInsuranceStepPageComponent
];

export * from './booking-container-page/booking-container-page.component';
export * from './previous-visit-step-page/previus-visit-step-page.component';
export * from './location-step-page/location-step-page.component';
export * from './service-step-page/service-step-page.component';
export * from './practitioner-step-page/practitioner-step-page.component';
export * from './timeslot-step-page/timeslot-step-page.component';
export * from './insurance-type-step-page/insurance-type-step-page.component';
export * from './primary-insurance-step-page/primary-insurance-step-page.component';
export * from './successfully-confirmation-step-page/successfully-confirmation-step-page.component';
export * from './review-step-page/review-step-page.component';
export * from './booking-summary-box/booking-summary-box.component';
export * from './guest-step-page/guest-step-page.component';
export * from './login-step-page/login-step-page.component';
export * from './work-insurance-step-page/work-insurance-step-page.component';
export * from './auto-insurance-step-page/auto-insurance-step-page.component';

