<!-- <h2 mat-dialog-title>Delete all</h2> -->

<mat-dialog-content>
  <button type="button" class="close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
  <google-map height="300px" width="100%" [zoom]="15"
    [center]="center">
    <map-marker [position]="markerPosition"
      [options]="markerOptions">
    </map-marker>
  </google-map>
</mat-dialog-content>
