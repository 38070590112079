import { MENU_COMPONENTS } from './components/index';
import { MENU_DIRECTIVES } from './directives/index';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChatModule } from '../chat/chat.module';

@NgModule({
  imports: [SharedModule, FlexLayoutModule, ChatModule],
  declarations: [...MENU_COMPONENTS, ...MENU_DIRECTIVES, SideNavbarComponent],
  exports: [...MENU_COMPONENTS, ...MENU_DIRECTIVES, SideNavbarComponent],
  providers: [
    // ...MENU_SERVICES
  ],
})
export class PpMenuModule {}
