<div fxLayout="column">
  <div class="box box-appointment-account" *ngIf="!appointment; else withData">
    <div class="row">
      <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-12 app-left">
        <div class="line-wrapper width-60">
          <div class="line width-100"></div>
          <div class="line width-90"></div>
        </div>
      </div>
      <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8 col-xs-12 app-right">
        <div class="row">
          <div class="col-10">
            <div class="line-wrapper width-60">
              <div class="line width-100"></div>
            </div>
          </div>
        </div>
        <div class="row row-border-bottom">
          <div class="col-12">
            <div class="line-wrapper width-60">
              <div class="line width-100"></div>
              <div class="line width-90"></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="wrap-actions wrap-actions-left col-lg-4 col-md-3">
            <div class="line-wrapper width-60">
              <div class="line width-100"></div>
            </div>
          </div>
          <div class="wrap-actions wrap-actions-right col-lg-8 col-md-9"></div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #withData>
    <div fxLayout="row" fxLayout.lt-sm="column" class="appointment mt-3 mb-3" fxFlex="100%">
      <div
        class="appointment__header"
        fxLayout="column"
        ngClass.lt-sm="p-2"
        fxFlex.lt-sm="100%"
        fxFlex="250px"
        ngClass.gt-xs="p-4 appointment__header--has-full-border-radius"
      >
        <div fxLayout="row wrap">
          <div class="nowrap mr-1" fxLayoutAlign="start center">
            <mat-icon class="appointment-icon appointment-icon__white mr-2" svgIcon="date"></mat-icon>
            {{ appointment?.localDateTimeDateObj | date: 'mediumDate' }}
          </div>
          <div class="nowrap" fxFlex fxLayoutAlign="end center" fxLayoutAlign.lt-sm="center center">
            <span class="mr-1" fxHide.gt-xs>{{ 'time' | translate }}:</span>
            {{ appointment?.localDateTimeDateObj | date: 'shortTime' }}
            {{ appointment?.location?.timeZoneAbbreviation }}
          </div>
          <div class="nowrap" fxHide.gt-xs fxLayoutAlign="end center">
            {{ status | translate }}
          </div>
        </div>
        <div fxHide.lt-sm class="mt-4" fxLayout="row">
          <mat-icon class="appointment-icon__white appointment-icon__location-icon" svgIcon="location"></mat-icon
          >{{ appointment?.location.name }}
        </div>
        <div fxHide.lt-sm class="mt-4" fxLayout="row">
          <mat-icon
            *ngIf="status.length > 0"
            class="appointment-icon appointment-icon__white appointment-icon__status"
            [svgIcon]="getStatusIcon(status)"
          ></mat-icon>
          {{ status | translate }}
        </div>
      </div>
      <div class="appointment__body pl-4 pr-4" fxLayout="column" fxFlex="100%" fxLayoutAlign.lt-sm="center center">
        <div fxLayout.lt-sm="column" fxLayout="row" fxFlex="100%" class="service-row mt-3">
          <div
            [fxFlex]="appointment?.service.description ? '33%' : '66%'"
            fxFlex.lt-sm="100%"
            fxLayoutAlign="start center"
            ngClass.lt-sm="pt-3"
            fxLayoutAlign.lt-sm="center center"
            class="font-dark"
          >
            <div>{{ appointment?.service.name }}</div>
            <div *ngIf="appointment?.service?.description?.length > 0" fxHide.lt-sm fxFlex class="divider"></div>
          </div>
          <div
            *ngIf="appointment?.service.description"
            fxFlex="33%"
            fxFlex.lt-sm="100%"
            class="text-center"
            ngClass.lt-sm="pt-3"
            fxLayoutAlign="center center"
          >
            <div class="w-100 word-break-all" ngClass.gt-xs="ellipsis card-description" [matTooltip]="appointment?.service.description">
              {{ appointment?.service.description }}
            </div>
          </div>
          <div fxFlex="33%" fxLayoutAlign.lt-sm="center center" ngClass.lt-sm="pt-3" fxFlex.lt-sm="100%">
            <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxHide.lt-sm>
              <div *ngIf="appointment?.service?.description?.length > 0" fxFlex class="divider"></div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-icon>access_time</mat-icon>
              <div>{{ appointment?.duration }} min</div>
            </div>
          </div>
        </div>
        <div fxFlex="100%" fxLayout="row" ngClass.lt-sm="mt-3" *ngIf="!isUniversity()">
          {{ 'practitioner' | translate }}: {{ appointment?.practitioner.firstName }}
          {{ appointment?.practitioner.lastName }}
        </div>
        <div class="flex flex-col gap-2" *ngIf="isUniversity()">
          <div *ngIf="appointment?.practitioner && !appointment?.supervisor" class="flex flex-row gap-4 items-center">
            <div class="w-[70px] font-semibold">
              {{ 'practitioner' | translate }}
            </div>
            <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: appointment?.practitioner }"></ng-container>
          </div>
          <div *ngIf="appointment?.supervisor" class="flex flex-row gap-4 items-center">
            <div class="w-[70px] font-semibold">
              {{ 'Supervisor' | translate }}
            </div>
            <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: appointment?.supervisor }"></ng-container>
          </div>
          <div class="flex flex-row gap-4" *ngIf="appointment?.intern || appointment?.assistingIntern">
            <div class="w-[70px] font-semibold mt-1">
              {{ 'Interns' | translate }}
            </div>
            <div class="flex flex-row gap-2 flex-wrap">
              <div *ngIf="appointment?.intern">
                <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: appointment?.intern }"></ng-container>
              </div>
              <div *ngIf="appointment?.assistingIntern">
                <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: appointment?.assistingIntern }"></ng-container>
              </div>
            </div>
          </div>
        </div>
        <div
          class="cancelation-policy-text mt-1"
          *ngIf="hasOrganizationCCPE && appointment.cancellationFee && !appointment.doNotChargeCancellationFee"
          fxFlex="100%"
          fxLayout="row"
          ngClass.lt-sm="my-2 text-center"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <div><mat-icon class="cancelation-policy-icon" svgIcon="warning-yellow"></mat-icon></div>
            {{
              'BookingStep.Service.CancelationPolicy'
                | translate: { cancellationFee: appointment?.cancellationFee, unitOfTime: organization?.cancellationPeriod }
            }}
          </div>
        </div>
        <div fxFlex="100%" fxLayout="row" ngClass.lt-sm="mt-3" *ngIf="appointment?.telehealthMeetingUrl">
          <a href="{{ appointment?.telehealthMeetingUrl }}" target="_blank">{{ 'telehealth-link' | translate }}</a>
        </div>
        <div fxFlex="100%" fxLayout="row" fxHide.gt-xs>
          <span class="mr-1">{{ 'Location' | translate }}:</span>
          {{ appointment?.location.name }}
        </div>
        <div fxFlex="100%" fxLayout="row" class="mt-3 mb-3" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
          <div fxFlex="33%" fxLayoutAlign="start center">
            <button color="primary" mat-button *ngIf="canBookSimilar" (click)="onBookSimilarClick()">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="mr-1">control_point</mat-icon>
                <div>{{ 'book-similar' | translate }}</div>
              </div>
            </button>
          </div>
          <div fxFlex="33%" fxLayoutAlign="start center">
            <button color="primary" mat-button *ngIf="canReschedule" (click)="onRescheduleClick()">
              <mat-icon class="mr-1">refresh</mat-icon>{{ 'reschedule' | translate }}
            </button>
          </div>
          <div fxFlex="33%" fxLayoutAlign="start center">
            <button color="primary" fxLayout="row" mat-button *ngIf="canCancel" (click)="onOpenCancelModalClick()">
              <mat-icon fxFlex fxLayoutAlign="start center" class="mt-1 cancel-icon" svgIcon="appointment_delete"></mat-icon>
              <div fxFlex fxLayoutAlign="start center">{{ 'cancel' | translate }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #practitionerItem let-practitioner>
  <div class="flex gap-2 items-center cursor-pointer hover:bg-neutral-50 rounded-md py-1 pl-1">
    <pp-practitioner-avatar class="flex" [avatarUrl]="practitioner?.avatarUrl!" [size]="24"></pp-practitioner-avatar>

    <div class="flex flex-col">
      <div>
        {{ practitioner?.firstName }}
        {{ practitioner?.lastName }}
      </div>
    </div>
  </div>
</ng-template>
