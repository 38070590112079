<div>
  <form class="form-full-width">
    <div class="row edit-row form-row" style="padding: 0px" *ngFor="let form of forms; let i = index">
      <div class="col-1 edit-col">
        <i class="material-icons" style="color: #42a5f5">assignment</i>
      </div>
      <div class="col-10 edit-col">
        <div class="generic-form-name" (click)="navigateToForm(form.uid)">
          {{ form.name }}
        </div>
      </div>
      <div class="col-1 edit-col" *ngIf="!form.isLegacy && form.isCompleted">
        <i class="material-icons pull-right" style="color: #42a5f5">check_circle</i>
      </div>
      <div class="col-1 edit-col" *ngIf="form.isLegacy">
        <i matTooltip="{{ 'legacy-form' | translate }}" matTooltipPosition="before" class="material-icons pull-right" style="color: #90a4ae"
          >info</i
        >
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-12 form-row">
      <button type="submit" class="btn btn-primary" (click)="onSubmit()">
        {{ 'complete-forms' | translate }}
      </button>
    </div>
  </div>
</div>
