import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
    transform(value: any, args: any[] = null): any {
        return Object.keys(value); // .map(key => value[key]);
        // const keys = [];
        // for (const enumMember in value) {
        //     if (!isNaN(parseInt(enumMember, 10))) {
        //         keys.push({ key: enumMember, value: value[enumMember] });
        //         // Uncomment if you want log
        //         // console.log("enum member: ", value[enumMember]);
        //     }
        // }
        // return keys;
    }
}
