import { CustomValidators } from './../../../shared/validators/custom-validators';
import { FormField } from './../../../shared/components/form-render/form-field.model';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';

@Component({
    selector: 'pp-auto-insurance-step-page',
    templateUrl: 'auto-insurance-step-page.component.html'
})

export class AutoInsuranceStepPageComponent implements OnInit {

    fields: FormField[] = [];
    insuredInfoField: FormField[] = [];
    insuredInfo: FormGroup;
    autoAccidentInsurance: FormGroup;

    constructor(
        private cdr: ChangeDetectorRef,
        private store: Store<fromRoot.State>,
        private formBuilder: FormBuilder,
        public navigateService: NavigateWrapperService
    ) { }

    ngOnInit() {

        this.insuredInfo = this.formBuilder.group({});
        this.autoAccidentInsurance = this.formBuilder.group({
            insuranceIsCoveredByOther: new FormControl(false),
            insuredInfo: this.insuredInfo
        });

        this.fields = [
            {
                key: 'accidentDate',
                label: 'accidentDate',
                type: FormFieldsEnum.DatePicker,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'caseClaimNo',
                label: 'caseClaimNo',
                planceholder: 'caseClaimNo-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'caseManagerName',
                label: 'caseManagerName',
                planceholder: 'caseManagerName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'caseManagerPhone',
                label: 'caseManagerPhone',
                planceholder: 'caseManagerPhone-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300), CustomValidators.phone],
            }
            , {
                key: 'caseManagerAddress',
                label: 'caseManagerAddress',
                planceholder: 'caseManagerAddress-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            }
        ];

        this.insuredInfoField = [
            {
                key: 'patientRelationtionshipToInsuredType',
                label: 'patientRelationtionshipToInsuredType',
                type: FormFieldsEnum.DropdownSingleSelect,
                itemsList: Common.InsuranceRelationships
            }
            , {
                key: 'firstName',
                label: 'insuredInfoFirstName',
                planceholder: 'insuredInfoFirstName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300), Validators.required],
            }
            , {
                key: 'middleName',
                label: 'insuredInfoMiddleName',
                planceholder: 'insuredInfoMiddleName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'lastName',
                label: 'insuredInfoLastName',
                planceholder: 'insuredInfoLastName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300), Validators.required],
            }
            , {
                key: 'dateOfBirth',
                label: 'insuredInfoDateOfBirth',
                type: FormFieldsEnum.DatePicker,
            }
        ];

        this.updateCoveredInsurance();

        // scroll top
        window.scrollTo(0, 0);
    }

    updateCoveredInsurance() {

        if (!this.autoAccidentInsurance.value.insuranceIsCoveredByOther) {
            this.autoAccidentInsurance.get('insuredInfo').disable();
        } else {
            this.autoAccidentInsurance.get('insuredInfo').enable();
        }

        this.cdr.detectChanges();
    }

    onContinueClick() {
        this.store.dispatch(new BookingActions.SetAutoInsurance(this.autoAccidentInsurance.value));
        this.navigateService.navigate(['booking', 'review']);
    }
}
