import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  load(): Promise<any> {
    return Promise.resolve(this.init());
  }

  private init(): void {
    this.matIconRegistry.addSvgIcon('date', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/date.svg`));
    this.matIconRegistry.addSvgIcon(
      'appointment_accepted',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointment_accepted.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'appointment_cancelled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointment_cancelled.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'appointment_pending',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointment_pending.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'appointment_rejected',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointment_rejected.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'appointment_refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointment_refresh.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'appointment_delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointment_delete.svg`)
    );
    this.matIconRegistry.addSvgIcon('location', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/location.svg`));
    this.matIconRegistry.addSvgIcon('appointments', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointments.svg`));
    this.matIconRegistry.addSvgIcon('appointments', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/appointments.svg`));
    this.matIconRegistry.addSvgIcon('forms', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/forms.svg`));
    this.matIconRegistry.addSvgIcon('forms-warn', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/forms-warn.svg`));
    this.matIconRegistry.addSvgIcon('my-profile', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-profile.svg`));
    this.matIconRegistry.addSvgIcon('calendar-icon', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/calendar_icon.svg`));
    this.matIconRegistry.addSvgIcon(
      'book-appointment',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/book-appointment.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'my-profile-active-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-profile-active-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon('my-profile-v2', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-profile-v2.svg`));
    this.matIconRegistry.addSvgIcon(
      'book-appointment-active-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/book-appointment-active-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'book-appointment-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/book-appointment-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'my-forms-warn-active-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-forms-warn-active-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'my-forms-warn-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-forms-warn-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'my-forms-active-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-forms-active-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon('my-forms-v2', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-forms-v2.svg`));
    this.matIconRegistry.addSvgIcon(
      'my-appointments-active-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-appointments-active-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'my-appointments-v2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/my-appointments-v2.svg`)
    );
    this.matIconRegistry.addSvgIcon('approved', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/approved.svg`));
    this.matIconRegistry.addSvgIcon('warning-red', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/warning-red.svg`));
    this.matIconRegistry.addSvgIcon('warning-yellow', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/warning-yellow.svg`));
    this.matIconRegistry.addSvgIcon('person-remove', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/person-remove.svg`));
    this.matIconRegistry.addSvgIcon(
      'supervisor-account',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/supervisor-account.svg`)
    );
    this.matIconRegistry.addSvgIcon('manage-accounts', this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/manage-accounts.svg`));
  }
}
