import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pp-onboarding-summary-dialog',
  templateUrl: 'onboarding-summary-dialog.component.html',
})
export class OnboardingSummaryDialogComponent {
  constructor(public dialogRef: MatDialogRef<OnboardingSummaryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }
}
