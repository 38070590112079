<div class="content-inner-deprecated pp-container">
  <div class="row wrap-section">
    <div
      class="col-xl-4 offset-xl-4 col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 col-xs-12">
      <div class="box select-box">
        <h3 class="subtitle">
          {{ 'reset-password' | translate }}</h3>
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            *ngIf="error.length > 0">
            <div class="box message message-danger"
              role="alert">
              <span class="wrap-icon-alert">
                <i
                  class="material-icons info-icon">error</i>
              </span>
              {{ error | translate }}
            </div>
          </div>
        </div>
        <form [formGroup]="resetForm"
          (ngSubmit)="onSubmit()" novalidate>
          <div class="row">

            <div class="col-lg-12 col-xs-12 form-row">
              <label
                class="label-form-control label-form-control-inline">{{ 'password' | translate }}</label>
              <input class="form-control" type="password"
                formControlName="password" tabindex="1"
                name="password" />

              <div
                *ngIf="resetForm.get('password') && resetForm.get('password').invalid && (resetForm.get('password').dirty || resetForm.get('password').touched)">
                <p class="error-message-field"
                  *ngIf="resetForm.get('password').errors?.required">
                  {{ 'form-input-error-required' | translate}}
                </p>
                <p class="error-message-field"
                  *ngIf="resetForm.get('password').errors?.minlength">
                  {{ 'form-input-error-password-minlength' | translate}}
                </p>
              </div>
            </div>
            <div class="col-lg-12 col-xs-12 form-row">
              <label
                class="label-form-control label-form-control-inline">{{ 'confirm-password' | translate }}
              </label>
              <input class="form-control" type="password"
                formControlName="confirmPassword"
                tabindex="2" name="confirm-password" />
              <p class="error-message-field"
                *ngIf="resetForm.get('confirmPassword').errors?.MatchFields && (resetForm.get('confirmPassword').dirty || resetForm.get('confirmPassword').touched) ">
                {{ 'form-input-error-confirm-password-match' | translate}}
              </p>
            </div>
            <div class="col-lg-12 col-xs-12 form-row ">
              <button class="btn btn-primary btn-login "
                type="submit" [disabled]="!resetForm.valid "
                tabindex="3 ">{{ 'reset' | translate }}</button>
            </div>
          </div>
        </form>

      </div>

    </div>
  </div>
</div>
