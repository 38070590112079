import { FormField } from './../../../shared/components/form-render/form-field.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { OnboardingService } from '../../services';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { parseISO } from 'date-fns';

@Component({
  selector: 'pp-patient-info-page',
  templateUrl: 'patient-info-page.component.html',
})
export class PatientInfoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  loaderRows = [0, 1, 2, 3, 4];

  fields: FormField[] = [];
  form: FormGroup;
  loggedIn = false;
  showLoader = true;
  saving = false;

  constructor(
    private onboardingService: OnboardingService,
    private fb: FormBuilder,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group([]);
    this.fields = this.initFields({});

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.onboardingService
      .getPatientInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.fields = this.initFields(res);
        this.showLoader = false;

        const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
        if (contentContainer) {
          contentContainer.scrollIntoView();
        }
      });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onboardingService.setFormSkip(this.form.pristine);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initFields(data) {
    const fields: FormField[] = [
      {
        key: 'firstName',
        label: 'first-name',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.required, Validators.maxLength(300)],
        value: data.firstName,
      },
      {
        key: 'middleName',
        label: 'middle-name',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300)],
        value: data.middleName,
      },
      {
        key: 'lastName',
        label: 'last-name',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.required, Validators.maxLength(300)],
        value: data.lastName,
      },
      {
        key: 'birthDate',
        label: 'date-of-birth',
        type: FormFieldsEnum.DatePicker,
        validators: [Validators.required],
        value: data.birthDate ? parseISO(data.birthDate) : null,
      },

      {
        key: 'gender',
        label: 'gender',
        type: FormFieldsEnum.Gender,
        validators: [Validators.required],
        value: {
          gender: data.gender,
          otherGender: data.otherGender,
        },
      },
      {
        key: 'occupation',
        label: 'occupation',
        type: FormFieldsEnum.TextBox,
        value: data.occupation,
      },
      {
        key: 'employed',
        label: 'employment-status',
        type: FormFieldsEnum.DropdownSingleSelect,
        planceholder: 'select',
        itemsList: Common.EmploymentStatus,
        value: data.employed,
      },
      {
        key: 'maritalStatus',
        label: 'marital-status',
        type: FormFieldsEnum.DropdownSingleSelect,
        planceholder: 'select',
        itemsList: Common.MaritalStatus,
        value: data.maritalStatus,
      },
    ];
    return fields;
  }

  onSubmit(redirect: boolean = true) {
    this.saving = true;
    this.form.value.otherGender = this.form.value.gender.otherGender;
    this.form.value.gender = this.form.value.gender.gender;

    this.onboardingService.savePatientInfo(this.form.value).subscribe(() => {
      this.saving = false;
      this.onboardingService.updateSummary(Common.OnboardingSummaryForms.Personal);
      this.onboardingService.setFormSkip(true);
      if (redirect) {
        const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
        if (uncompleted) {
          this.navigateService.navigate([uncompleted.route], null, true);
        } else {
          this.navigateService.navigate(['onboarding/success'], null, true);
        }
      }
    });
  }
}
