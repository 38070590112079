import { BookingService } from './../../services/booking.service';
import { StartupService } from './../../../core/services/startup.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { Store } from '@ngrx/store';
import { Practitioner } from '../../../clinic/models/index';
import { ClinicService } from '../../../clinic/services/index';
import { environment } from './../../../../environments/environment';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { OrganizationTypeEnum } from '../../../shared/models/organization.enum';

import { zip } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';

type PractitionerSelectionType = 'FeelingLucky' | 'Practitioner' | 'Intern';

@Component({
  selector: 'pp-practitioner-step-page',
  templateUrl: 'practitioner-step-page.component.html',
  styleUrls: ['practitioner-step-page.component.scss'],
})
export class PractitionerStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  practitionerSelectionTypes: PractitionerSelectionType[] = [];
  practitionerSelectedType: PractitionerSelectionType;
  mediaBaseUrl = environment.resourceUrl;
  practitioners: Practitioner[] = [];
  interns: Practitioner[] = [];
  filteredInterns: Practitioner[] = [];
  showLoader = false;
  allowPractitionerNoPreferenceBooking = false;
  noPreference: Practitioner = {
    id: null,
    firstName: this.translateService.instant('no-practitioner-prefrence'), // translation key
    lastName: '',
    avatarUrl: null,
    isActiveForOnlineBooking: false,
    isActive: true,
    isIntern: false,
    name: '',
    description: '',
  };
  message = undefined;
  isUniversity = false;

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private clinicService: ClinicService,
    private startupService: StartupService,
    private bookingService: BookingService,
    private navigateService: NavigateWrapperService,
    private translateService: TranslateService
  ) {
    super();
    this.practitioners = this.route.snapshot.data.practitioners;
  }

  ngOnInit() {
    this.allowPractitionerNoPreferenceBooking = this.startupService.startupData.allowPractitionerNoPreferenceBooking;
    this.showLoader = true;
    this.isUniversity = this.startupService.startupData.organizationType === OrganizationTypeEnum.univeristy;
    if (this.isUniversity && this.allowPractitionerNoPreferenceBooking) {
      this.practitionerSelectionTypes.push('FeelingLucky');
    }

    // this.store.select(fromRoot.getBookingService)
    zip(this.store.select(fromRoot.getBookingService), this.store.select(fromRoot.getBookingLocation))
      .pipe(
        switchMap((res) => {
          const service = res[0];
          const location = res[1];
          if (service && location) {
            return this.clinicService.getPractitioners(service.id, location.guid);
          }
          return [];
        }),
        tap(() => {
          this.showLoader = false;
          const contentContainer = document.querySelector('pp-booking-summary-box > div > form > div.summary-service');
          if (contentContainer) {
            contentContainer.scrollIntoView();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((practitoners) => {
        this.practitioners = practitoners.practitioners;
        if (!this.isUniversity && this.practitioners.length > 1 && this.allowPractitionerNoPreferenceBooking) {
          this.practitioners.push(this.noPreference);
        }
        this.store.dispatch(new BookingActions.SetPractitionersAction(practitoners.practitioners));
        // Skip this step
        // IF it is a single practitioner clinic
        if (this.practitioners.length === 1) {
          this.store.dispatch(new BookingActions.CanChangePractitionerAction(false));
          this.setPractitioner(this.practitioners[0], false);
        } else {
          this.store.dispatch(new BookingActions.CanChangePractitionerAction(true));
          if (this.isUniversity) {
            this.interns = this.practitioners.filter((p) => p.isIntern);
            this.filteredInterns = [...this.interns];
            this.practitioners = this.practitioners.filter((p) => !p.isIntern);
            if (this.practitioners.length) {
              this.practitionerSelectionTypes.push('Practitioner');
            }
            if (this.interns.length) {
              this.practitionerSelectionTypes.push('Intern');
            }
            if (!this.allowPractitionerNoPreferenceBooking && this.practitioners?.length === 0) {
              this.selectPractitionerPreference('Intern');
            }
            if (!this.allowPractitionerNoPreferenceBooking && this.interns?.length === 0) {
              this.selectPractitionerPreference('Practitioner');
            }
          } else {
            if (this.practitioners.length) {
              this.practitionerSelectionTypes.push('Practitioner');
            }
          }
        }

        this.navigateService.pushRoute('booking/practitioner', this.practitioners.length === 1);
      });

    this.store
      .select(fromRoot.getBookingPractitionerStepMessage)
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg) => (this.message = msg));
  }

  setPractitioner(practitioner: Practitioner, userAction: boolean): void {
    this.resetScheduling();

    this.store.dispatch(new BookingActions.SetPractitionerAction(practitioner));
    this.store.dispatch(new BookingActions.SetPreferredPractitionerOption(this.practitionerSelectedType));

    if (userAction) {
      this.navigateService.nextRoute = undefined;
    }

    let route = 'booking/timeslot';
    if (this.navigateService.nextRoute) {
      route = this.navigateService.nextRoute;
      this.navigateService.nextRoute = undefined;
    }
    this.navigateService.navigate([route]);
  }

  private resetScheduling() {
    this.store.dispatch(new BookingActions.SetMessageOnPractitionerStep(null));

    // reset scheduling pratitioner when the service is changed
    this.store.dispatch(new BookingActions.SetPractitionerAction(null));

    // reset scheduling time when the service is changed
    this.store.dispatch(new BookingActions.SetTimeslotAction(null));
  }

  filterInterns(searchTerm: string) {
    this.filteredInterns = this.interns.filter((i: Practitioner) => {
      return i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  selectPractitionerPreference(selectionType: PractitionerSelectionType) {
    this.practitionerSelectedType = selectionType;
    if (selectionType === 'FeelingLucky') {
      this.setPractitioner(this.noPreference, true);
    }
    if (selectionType === 'Practitioner' && this.practitioners.length === 1) {
      this.store.dispatch(new BookingActions.CanChangePractitionerAction(false));
      this.setPractitioner(this.practitioners[0], false);
    }

    if (selectionType === 'Intern' && this.interns.length === 1) {
      this.store.dispatch(new BookingActions.CanChangePractitionerAction(false));
      this.setPractitioner(this.interns[0], false);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
