import { NavigateWrapperService } from './../../core/services/navigateWrapper.service';
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthHelperService, StartupService } from '../../core/services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class NoAuthGuard implements CanActivate, OnDestroy {
  private loggedIn = false;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private authHelperService: AuthHelperService,
    private navigateService: NavigateWrapperService,
    private startupService: StartupService
  ) {
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((val) => (this.loggedIn = val));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate() {
    if (!this.loggedIn && this.startupService.startupData.usePatientPortal) {
      return true;
    }

    if (!this.startupService.startupData.usePatientPortal) {
      this.navigateService.navigate(['booking', 'previous-visit']);
    } else {
      this.navigateService.navigate([new URLSearchParams(window.location.search).get('returnUrl') || 'appointments']);
    }

    return false;
  }
}
