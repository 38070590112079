import { Action } from '@ngrx/store';
import { type } from '../../ngrx/type';

/**
 * Each action should be namespaced
 * this allows the interior to have similar typed names as other actions
 * however still allow index exports
 */
export namespace NameList {
    // Category to uniquely identify the actions
    export const CATEGORY: string = 'NameList';

    export const INIT = type(`${CATEGORY} Init`);
    export const INITIALIZED = type(`${CATEGORY} Initialized`);
    export const INIT_FAILED = type(`${CATEGORY} Init Failed`);
    export const ADD = type(`${CATEGORY} Add`);
    export const NAME_ADDED = type(`${CATEGORY} Name Added`);


    /**
     * Every action is comprised of at least a type and an optional
     * payload. Expressing actions as classes enables powerful
     * type checking in reducer functions.
     *
     * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
     */
    export class InitAction implements Action {
        type = INIT;
        payload: string = null;
    }

    export class InitializedAction implements Action {
        type = INITIALIZED;

        constructor(public payload: Array<string>) { }
    }

    export class InitFailedAction implements Action {
        type = INIT_FAILED;
        payload: string = null;
    }

    export class AddAction implements Action {
        type = ADD;

        constructor(public payload: string) { }
    }

    export class NameAddedAction implements Action {
        type = NAME_ADDED;

        constructor(public payload: string) { }
    }

    /**
     * Export a type alias of all actions in this action group
     * so that reducers can easily compose action types
     */
    export type Actions
        = InitAction
        | InitializedAction
        | InitFailedAction
        | AddAction
        | NameAddedAction;
}
