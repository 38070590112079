import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../ngrx';
import { BookingActions } from '../../../booking/actions';
import { ActivatedRoute } from '@angular/router';
import { ClinicService } from '../../../clinic/services';
import { find as _find } from 'lodash-es';
import { ClinicLocation, Service } from '../../../clinic/models';
import { NavigateWrapperService } from '../../../core/services';
import { BookingService } from '../../../booking/services';
import { BookingDay } from '../../../booking/models';
import { RegistrationParams } from '../register-onboarding-step/register-onboarging-step.model';
import { BaseComponent } from '../base/base.component';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

@Component({
  selector: 'pp-appointment-pickup',
  templateUrl: './appointment-pickup.component.html',
  styleUrls: ['./appointment-pickup.component.scss'],
})
export class AppointmentPickupComponent extends BaseComponent implements OnInit, OnDestroy {
  queryParams: RegistrationParams;
  constructor(
    private store: Store<fromRoot.State>,
    private activatedRoute: ActivatedRoute,
    private clinicService: ClinicService,
    private navigateService: NavigateWrapperService,
    private bookingService: BookingService
  ) {
    super();
  }

  ngOnInit() {
    this.queryParams = {
      email: this.activatedRoute.snapshot.queryParams.email,
      date: this.activatedRoute.snapshot.queryParams.Date || this.activatedRoute.snapshot.queryParams.date,
      index: Number(this.activatedRoute.snapshot.queryParams.Index || this.activatedRoute.snapshot.queryParams.index),
      locationGuid: this.activatedRoute.snapshot.queryParams.LocationGuid || this.activatedRoute.snapshot.queryParams.locationGuid,
      practitionerId: Number(
        this.activatedRoute.snapshot.queryParams.PractitionerId || this.activatedRoute.snapshot.queryParams.practitionerId
      ),
      serviceId: Number(this.activatedRoute.snapshot.queryParams.ServiceId || this.activatedRoute.snapshot.queryParams.serviceId),
      token: this.activatedRoute.snapshot.queryParams.token,
      type: this.activatedRoute.snapshot.queryParams.type,
    };
    if (!this.queryParams.locationGuid) {
      this.navigateService.navigate([this.activatedRoute.snapshot.queryParams.returnUrl || 'appointments']);
      return;
    }

    this.clinicService
      .getLocations()
      .pipe(takeUntil(this.destroy$))
      .subscribe((locations: ClinicLocation[]) => {
        const selectedLocation = _find(locations, (location) => location.guid === this.queryParams.locationGuid);
        if (!this.queryParams.serviceId) {
          this.store.dispatch(new BookingActions.SetLocationAction(selectedLocation));
          this.navigateService.navigate(['booking', 'location']);
          return;
        }
        combineLatest([
          this.clinicService.getServices(selectedLocation.guid, true),
          this.clinicService.getServices(selectedLocation.guid, false),
        ])
          .pipe(
            map(([servicesForNew, servicesForExisting]) => [...servicesForNew, ...servicesForExisting]),
            takeUntil(this.destroy$)
          )
          .subscribe((services: Service[]) => {
            const selectedService = _find(services, (service) => service.id === this.queryParams.serviceId);

            this.clinicService
              .getPractitioners(selectedService.id, selectedLocation.guid)
              .pipe(takeUntil(this.destroy$))
              .subscribe((practitioners: any) => {
                const selectedPractitioner = _find(
                  practitioners.practitioners,
                  (practitioner) => practitioner.id === this.queryParams.practitionerId
                );
                if (!this.queryParams.date) {
                  this.store.dispatch(new BookingActions.SetLocationAction(selectedLocation));
                  this.store.dispatch(new BookingActions.SetServiceAction(selectedService));
                  this.store.dispatch(new BookingActions.SetPractitionerAction(selectedPractitioner));
                  this.navigateService.navigate(['booking', 'timeslot']);
                  return;
                }
                combineLatest([
                  this.bookingService
                    .getAvailableDates(
                      selectedLocation.guid,
                      selectedService.id,
                      selectedPractitioner ? selectedPractitioner.id : undefined,
                      true,
                      new Date(this.queryParams.date),
                      new Date(this.queryParams.date)
                    )
                    .pipe(catchError(() => of([]))),
                  this.bookingService
                    .getAvailableDates(
                      selectedLocation.guid,
                      selectedService.id,
                      selectedPractitioner ? selectedPractitioner.id : undefined,
                      false,
                      new Date(this.queryParams.date),
                      new Date(this.queryParams.date)
                    )
                    .pipe(catchError(() => of([]))),
                ])
                  .pipe(map(([availabilitiesForNew, availabilitiesForExisting]) => [...availabilitiesForNew, ...availabilitiesForExisting]))
                  .subscribe((availabilities: BookingDay[]) => {
                    this.store.dispatch(new BookingActions.SetLocationAction(selectedLocation));
                    this.store.dispatch(new BookingActions.SetServiceAction(selectedService));
                    this.store.dispatch(new BookingActions.SetPractitionerAction(selectedPractitioner));
                    const selectedTimeslot = _find(
                      availabilities[0].availabilities,
                      (availability) => availability.slotIndex === this.queryParams.index
                    );

                    if (!selectedTimeslot) {
                      this.navigateService.navigate(['booking', 'timeslot'], { error: 'timeslot-not-available' });
                      return;
                    }
                    this.store.dispatch(new BookingActions.SetTimeslotAction(selectedTimeslot));
                    this.navigateService.navigate(['booking', 'review']);
                  });
              });
          });
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
