import { Component, OnInit } from '@angular/core';
import { OnboardingFormsEnum } from '../../models';

@Component({
    selector: 'pp-consent-list-page',
    templateUrl: 'consent-forms-list-page.component.html'
})

export class ConsentFormsListPageComponent implements OnInit {

  public get formTypes() {
    return OnboardingFormsEnum;
  }
    ngOnInit() {

    }

}
