<button class="close" mat-dialog-close type="button">
  <span aria-hidden="true">×</span>
</button>
<h1 class="col-lg-7 col-xs-12 title modal-title cancel-modal-title inline-title" mat-dialog-title>
  {{ 'cancel-appointment-title-question' | translate }}
</h1>
<mat-dialog-content>
  <div class="box box-appointment-account box-appointment-modal">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-xs-12 app-left">
        <span class="app-important">{{ data.localDateTimeDateObj | date: 'mediumDate' }}</span>
        <span>{{ data.localDateTimeDateObj | date: 'shortTime' }}</span>
      </div>
      <div class="col-lg-10 col-md-9 col-xs-12 app-right">
        <div class="row row-border-bottom">
          <div class="col-12">
            <p>
              <span class="app-important">{{ data.service.name }}</span>
            </p>
            <p>{{ data.practitioner.firstName }} {{ data.practitioner.lastName }}</p>
            <p>{{ 'location' | translate }}: {{ data.location.name }}</p>
          </div>
        </div>
        <div class="row">
          <div class="wrap-actions wrap-actions-left col-lg-4 col-md-3">
            <div class="action">
              <i class="status status-{{ data.status }}"></i>
              <span>{{ data.status | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="row">
      <div class="col-12">
        <h2 class="subtitle textarea-label">
          {{ 'reason-label' | translate }}
        </h2>
        <textarea class="textarea-modal" [(ngModel)]="reason" placeholder=" {{ 'reason-placeholder' | translate }}" maxlength="200">
        </textarea>
      </div>
    </div>
  </div>

  <div class="box" *ngIf="showCcpeSection()">
    <div class="row">
      <div class="col-12 action-buttons-container">
        <h2 class="subtitle textarea-label">
          {{ cancellationPolicyTitle }}
        </h2>
        <textarea class="textarea-modal" [ngModel]="cancellationPolicyWarningText" maxlength="1000" readonly="true" disabled> </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button [disabled]="cancelInProgress" (click)="onCancelClick()" class="btn btn-delete cancel-btn-charge">
          {{ 'cancel-appointment-with-charge' | translate }}
          ${{ this.data.cancellationFee }}
        </button>
        <button cdkFocusInitial (click)="dialogRef.close()" class="btn mx-0">
          {{ 'keep-appointment' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!showCcpeSection()" class="action-buttons-container">
    <button mat-button mat-dialog-close class="btn btn-cancel1">
      {{ 'close' | translate }}
    </button>
    <button mat-button cdkFocusInitial class="btn btn-delete" [disabled]="cancelInProgress" (click)="onCancelClick()">
      {{ 'cancel-appointment' | translate }}
    </button>
  </div>
</mat-dialog-content>
