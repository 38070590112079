import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { UpChatApiIntegrationService, UpChatLibModule } from '@unifiedpractice/up-chat-lib';
import { ChatApiIntegrationService } from './chat-api-integration.service';
import { ChatComponent } from './chat.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    UpChatLibModule.forRoot({
      apiKey: environment.streamApiKey,
      service: {
        provide: UpChatApiIntegrationService,
        useClass: ChatApiIntegrationService,
      },
    }),
  ],
  declarations: [ChatComponent],
  exports: [UpChatLibModule],
})
export class ChatModule {}
