import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OnboardingService } from '../../services';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pp-address-contact-info-page',
  templateUrl: 'address-contact-info-page.component.html',
})
export class AddressContactInfoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  loaderRows = [0, 1, 2, 3, 4];

  fields1: FormField[] = [];
  fields2: FormField[] = [];
  fields3: FormField[] = [];

  form: FormGroup;
  phoneTypes: any;
  loggedIn = false;
  showLoader = true;
  saving = false;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    const countryState = new FormControl(null);

    this.fields1 = this.initFields1({});
    this.fields2 = this.initFields2({});
    this.fields3 = this.initFields3({});

    this.form = this.fb.group({
      countryState: countryState,
    });

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
    this.onboardingService
      .getContactInfo()
      .pipe(takeUntil(this.destroy$))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.fields1 = this.initFields1(res);
        this.fields2 = this.initFields2(res);
        this.fields3 = this.initFields3(res);
        this.form.controls['countryState'].setValue({ country: res.countryCode, state: res.stateCode, otherState: res.state });
        this.showLoader = false;
        const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
        if (contentContainer) {
          contentContainer.scrollIntoView();
        }
      });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onboardingService.setFormSkip(this.form.pristine);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initFields1(data) {
    const fields: FormField[] = [
      {
        key: 'streetAddress',
        label: 'street-address',
        type: FormFieldsEnum.TextBox,
        value: data.addressLine1 || '',
      },
      {
        key: 'streetAddress2',
        label: 'street-address2',
        type: FormFieldsEnum.TextBox,
        value: data.addressLine2 || '',
      },
      {
        key: 'phone',
        label: 'phone',
        type: FormFieldsEnum.Phone,
        value: {
          number: data.phoneNumber1,
          phoneType: data.phoneNumber1TypeId,
          required: true,
        },
      },
      {
        key: 'alternatePhone',
        label: 'alternate-phone',
        type: FormFieldsEnum.Phone,
        value: {
          number: data.phoneNumber2,
          phoneType: data.phoneNumber2TypeId,
        },
      },
    ];
    return fields;
  }

  initFields2(data) {
    const fields: FormField[] = [
      {
        key: 'city',
        label: 'city',
        type: FormFieldsEnum.TextBox,
        value: data.city || '',
      },
      {
        key: 'zipCode',
        label: 'zipCode',
        type: FormFieldsEnum.TextBox,
        value: data.zipCode || '',
      },
    ];
    return fields;
  }

  initFields3(data) {
    const fields: FormField[] = [
      {
        key: 'fax',
        label: 'fax-number',
        type: FormFieldsEnum.TextBox,
        value: data.faxNumber || '',
      },
      {
        key: 'email',
        label: 'primary-email',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.required, Validators.email],
        value: data.emailAddress || '',
        readonly: true,
      },
    ];
    return fields;
  }

  onSubmit(redirect: boolean = true) {
    this.saving = true;

    const data = {
      addressLine1: this.form.value.streetAddress,
      addressLine2: this.form.value.streetAddress2,
      city: this.form.value.city,
      zipCode: this.form.value.zipCode,
      countryCode: this.form.value.countryState ? this.form.value.countryState.country : undefined,
      stateCode: this.form.value.countryState ? this.form.value.countryState.state : undefined,
      phoneNumber1: this.form.value.phone.number,
      phoneNumber1TypeId: this.form.value.phone.phoneType,
      phoneNumber2: this.form.value.alternatePhone ? this.form.value.alternatePhone.number : undefined,
      phoneNumber2TypeId: this.form.value.alternatePhone ? this.form.value.alternatePhone.phoneType : undefined,
      faxNumber: this.form.value.fax,
      emailAddress: this.form.value.email,
    };

    this.onboardingService.saveContactInfo(data).subscribe(
      (res) => {
        this.onboardingService.updateSummary(Common.OnboardingSummaryForms.Contact);
        this.saving = false;
        this.onboardingService.setFormSkip(true);
        if (redirect) {
          const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
          if (uncompleted) {
            this.navigateService.navigate([uncompleted.route], null, true);
          } else {
            this.navigateService.navigate(['onboarding', 'success']);
          }
        }
      },
      (error) => {
        const jsonData: any = JSON.parse(error.errorDescription);
        const errorMessages = Object.values(jsonData).reduce((acc: any, field) => acc.concat((field as any[]).map((item) => item.errorMessage)), []) as string [];
        this.toastr.error(this.translateService.instant(errorMessages.join('\r\n')));
        this.saving = false;
      }
    );
  }
}
