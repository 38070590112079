import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({ selector: '[ppSubmitIfValid]' })
export class SubmitIfValidDirective {
    // tslint:disable-next-line:no-input-rename
    @Input('ppSubmitIfValid') formRef: FormGroup;
    @Output() valid = new EventEmitter<void>();
    constructor() { }

    @HostListener('click')
    handleClick() {
        this.makeFieldsAsDirty(this.formRef);
        this.emitIfValid();

    }

    private makeFieldsAsDirty(formGroup) {
        const controls = Object.keys(formGroup.controls);

        for (let i = 0; i < controls.length; i++) {
            const obj = formGroup.controls[controls[i]];
            if (obj.controls) {
                // put the function call in the event queue
                // instead of recursive call
                // to prevent stack overflow
                setTimeout(() => { this.makeFieldsAsDirty(obj); }, 0);
            } else {
                obj.markAsDirty();
            }
        }

        return;
    }

    private emitIfValid() {
        if (this.formRef.valid) {
            this.valid.emit();
        } else {
            setTimeout(() => {
                this.scrollToFirstError();
            }, 0);
        }
    }

    private scrollToFirstError(): any {
        const contentContainer: HTMLElement = document.querySelector('.form-control.ng-invalid.ng-dirty');
        if (contentContainer) {
            contentContainer.parentElement.scrollIntoView();
        }
    }


}
