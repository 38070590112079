import { OnboardingModule } from './onboarding/onboarding.module';
import { AppComponent } from './app.component';
import { ClinicModule } from './clinic/clinic.module';
import { BookingModule } from './booking/booking.module';
import { HttpClient, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StartupService } from './core/services/startup.service';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { PpMenuModule } from './menu/menu.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { SampleModule } from './sample/sample.module';
import { EmailInUseValidator } from './shared/validators/index';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { reducers, metaReducers } from './ngrx';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppointmentsModule } from './appointments/appointments.module';
import { AccountModule } from './account/account.module';
import { TokenInterceptor } from './core/services/token.interceptor';
import { AuthService } from './core/services/auth.service';
import { AuthHelperService } from './core/services/auth-helper.service';
import { MultilingualModule } from './shared/multilingual/index';
import { ErrorsInterceptor } from './core/services/errors.interceptor';
import { MainContainerComponent } from './main-container.component';
import { GlobalErrorsService } from './core/services/global-errors.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { STATIC_COMPONENTS } from './static/components';
import { FullSteamIntegrationService } from './core/services/full-steam-integration.service';
import { UpNgFullsteamModule, UpFullSteamIntegrationService } from '@unifiedpractice/up-ng-fullsteam';
import { FullSteamIntegrationClient } from './core/services/full-steam-integration.client';
import { ChatModule } from './chat/chat.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `assets/i18n/`, '.json');
}

// load data before app start
export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.load();
}

@NgModule({
  declarations: [AppComponent, MainContainerComponent, ...STATIC_COMPONENTS],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    AppRoutingModule,
    HttpClientJsonpModule,

    MultilingualModule,
    PpMenuModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    ToastrModule.forRoot({
      preventDuplicates: true,
      closeButton: true,
    }),

    // ngrx-store modules
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
    StoreDevtoolsModule.instrument(),

    FlexLayoutModule,
    SampleModule,
    ClinicModule,
    BookingModule,
    AccountModule,
    AppointmentsModule,
    OnboardingModule,
    UpNgFullsteamModule.forRoot({
      service: {
        provide: UpFullSteamIntegrationService,
        useClass: FullSteamIntegrationService,
        deps: [FullSteamIntegrationClient, TranslateService],
      },
    }),
    ChatModule,
  ],
  providers: [
    GlobalErrorsService,
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      multi: true,
      deps: [StartupService],
    },
    AuthService,
    AuthHelperService,

    TokenInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    ErrorsInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    EmailInUseValidator,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
