<div class="content-inner-deprecated pp-container">
  <div class="w-100">
    <h1 class="top-title">{{ 'my-account' |
      translate }} </h1>
  </div>
  <div class="w-100">

    <div class="box select-box select-clinic-box">
      <h3 class="subtitle subtitle-create-account">Profile
      </h3>

      <div class="row row-edit summary-location">
        <div class="col-lg-12 col-xs-12 edit-col">
          <label>{{'first-name' |
            translate}}:&nbsp;</label>
          <span>{{userInfo.firstName}}</span>
        </div>
      </div>
      <div class="row row-edit summary-location">
        <div class="col-lg-12 col-xs-12 edit-col">
          <label>{{'last-name' |
            translate}}:&nbsp;</label>
          <span>{{userInfo.lastName}}</span>
        </div>
      </div>
      <div class="row row-edit summary-location">
        <div *ngIf="!editEmail && !userInfo.pendingEmail" class="col-lg-12 col-xs-12 edit-col">
          <label>{{'email'| translate}}:&nbsp;</label>
          <span>{{userInfo.email}}</span>
          <a class="float-right cursor-pointer text-primary" (click)="toggleEmailChange()">{{'change-email'|translate}}</a>
        </div>
        <div *ngIf="userInfo.pendingEmail" class="col-lg-12 col-xs-12 edit-col">
          <label>{{'email'| translate}}:&nbsp;</label>
          <span>{{userInfo.email}}</span>
        </div>
        <div *ngIf="userInfo.pendingEmail" class="col-lg-12 col-xs-12 edit-col alert-info">
          <label>{{'NewEmail'| translate}}:&nbsp;</label>
          <span>{{userInfo.pendingEmail}}</span>
          <span class="ml-1 font-bold">{{'PendingEmailValidation' | translate}}</span>
          <a class="float-right cursor-pointer text-primary" (click)="saveEmailChange()">{{'ResendVerificationMail'|translate}}</a>
        </div>
        <div *ngIf="editEmail && !userInfo.pendingEmail" class="col-lg-12 col-xs-12 edit-col">
          <label>{{'new-email'| translate}}<span><small class="ml-1 text-danger">*</small></span></label>
          <input class="ml-5" placeholder="{{'email-placeholder'|translate }}" [(ngModel)]="newEmail" #pEmail="ngModel" name="pEmail" required email="true">
          <button href="javascript:void(0)" class="btn btn-primary btn-small float-right ml-2" (click)="saveEmailChange()" [disabled]="pEmail.errors">
            {{'save' | translate }}
          </button>
          <button href="javascript:void(0)" class="btn btn-secondary btn-small float-right" (click)="toggleEmailChange()">
            {{'cancel' | translate }}
          </button>
          <div *ngIf="pEmail.errors?.['email'] && pEmail.touched" class="text-danger">
            {{"wrong-email-format"|translate}}</div>
          <div *ngIf="pEmail.errors?.['required'] && pEmail.touched" class=" text-danger">
            {{"email-required"|translate}}</div>
        </div>
        <!-- <form class="form-full-width" [formGroup]="profileForm"
          novalidate>
          <div class="row">

            <pp-form-render [formFields]="fields" [form]="profileForm"></pp-form-render>

          </div>
        </form> -->
      </div>
      <div class="row row-edit summary-location">
        <div class="col-lg-12 col-xs-12 edit-col">
          <label>{{'phone-number' |
            translate}}:&nbsp;</label>
          <span>{{userInfo.phone }}&nbsp;</span>
          <label>{{'phone-type' |
            translate}}:&nbsp;</label>
          <span>{{userInfo.phoneType }}</span>
        </div>
      </div>
      <div class="row row-edit summary-location">
        <div class="col-lg-12 col-xs-12 edit-col">
          <label>{{'date-of-birth' |
            translate}}:&nbsp;</label>
          <span>{{userInfo.birthDate | date:
            'mediumDate'}}</span>
        </div>
      </div>
      <div class="row row-edit summary-location mb-5">
        <div class="col-lg-12 col-xs-12 edit-col">
          <label>{{'gender' | translate}}:&nbsp;</label>
          <span>
            {{userInfo.gender| translate}}
          </span>
          <span>{{userInfo.otherGender}}</span>
        </div>
      </div>

      <!-- EDITABLE SECTION -->
      <!-- <div class="box select-box select-clinic-box">
                <h3 class="subtitle subtitle-create-account">Profile</h3>
                <form class="form-full-width" [formGroup]="profileForm" novalidate>
                    <div class="row">
                        <div class="col-lg-12 col-xs-12 form-row">
                            <label class="label-form-control required-field-label">First name</label>
                            <input class="form-control" placeholder="first name" type="text"
                                formControlName="firstName" />
                        </div>
                        <div class="col-lg-12 col-xs-12 form-row">
                            <label class="label-form-control required-field-label">Last name</label>
                            <input class="form-control" placeholder="last name" type="text"
                                formControlName="lastName" />
                        </div>
                        <div class="col-lg-12 col-xs-12 form-row">
                            <label class="label-form-control required-field-label">Phone 1</label>
                            <input class="form-control" placeholder="(000) 000-0000" type="text"
                                formControlName="homeNumber" />
                        </div>
                        <div class="col-lg-12 col-xs-12 form-row">
                            <label class="label-form-control required-field-label">Phone 2</label>
                            <input class="form-control" placeholder="(000) 000-0000" type="text"
                                formControlName="cellNumber" />
                        </div>
                        <div class="col-lg-12 col-xs-12 form-row">
                            <a href="javascript:void(0)" class="link link-account" *ngIf="profileReadonlyMode;else editMode"
                                (click)="onEnableProfileEditClick()">{{ 'edit-profile' | translate }}
                            </a>
                            <ng-template #editMode>
                                <a href="javascript:void(0)" class="link link-account" (click)="onApplyProfileChangesClick()">
                                    {{ 'apply' | translate }}
                                </a>
                                <a href="javascript:void(0)" class="link link-account" (click)="onCancelProfileEditClick()">{{ 'cancel' | translate }}
                                </a>
                            </ng-template>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-row"
                            *ngIf="accountUpdatedSuccessfully">
                            <div class="box alert alert-info" role="alert">
                                <span class="wrap-icon-alert">
                                    <i class="material-icons info-icon">info</i>
                                </span>
                                {{ 'account-updated-successfully' | translate }}
                            </div>
                        </div>
                    </div>
                </form>
            </div> -->
      <div class="box select-box select-clinic-box">
        <h3 class="subtitle subtitle-create-account">
          {{ 'security' | translate }}</h3>
        <form class="form-full-width" [formGroup]="changePasswordForm" novalidate>
          <div class="row">
            <div class="col-lg-12 col-xs-12 form-row" [ngClass]="{'d-none': resetPasswordReadonlyMode}">
              <label class="label-form-control required-field-label">
                {{ 'old-password' |
                translate }}</label>
              <input class="form-control" type="password" name="oldPassword" formControlName="oldPassword" />
              <div *ngIf="changePasswordForm.get('oldPassword') && changePasswordForm.get('oldPassword').invalid && (changePasswordForm.get('oldPassword').dirty || changePasswordForm.get('oldPassword').touched)">
                <p class="error-message-field" *ngIf="changePasswordForm.get('oldPassword').errors.required">
                  {{
                  'form-input-error-required'
                  | translate}}
                </p>
              </div>
            </div>
            <div class="col-lg-12 col-xs-12 form-row" [ngClass]="{'d-none': resetPasswordReadonlyMode}">
              <label class="label-form-control required-field-label">{{
                'new-password' | translate
                }}
              </label>
              <input class="form-control" type="password" name="password" formControlName="password" />
              <div *ngIf="changePasswordForm.get('password') && changePasswordForm.get('password').invalid && (changePasswordForm.get('password').dirty || changePasswordForm.get('password').touched)">
                <p class="error-message-field" *ngIf="changePasswordForm.get('password').errors.required">
                  {{
                  'form-input-error-required'
                  | translate}}
                </p>
                <p class="error-message-field" *ngIf="changePasswordForm.get('password').errors.minlength">
                  {{
                  'form-input-error-password-minlength'
                  | translate}}
                  {{changePasswordForm.get('password').errors.minlength.requiredLength}}
                </p>
              </div>
            </div>
            <div class="col-lg-12 col-xs-12 form-row" [ngClass]="{'d-none': resetPasswordReadonlyMode}">
              <label class="label-form-control required-field-label">{{
                'confirm-new-password' |
                translate
                }}
              </label>
              <input class="form-control" type="password" name="confirmPassword" formControlName="confirmPassword" />

              <div *ngIf="changePasswordForm.get('confirmPassword') && changePasswordForm.get('confirmPassword').invalid && (changePasswordForm.get('confirmPassword').dirty || changePasswordForm.get('confirmPassword').touched)">
                <p class="error-message-field" *ngIf="changePasswordForm.get('confirmPassword').errors.required">
                  {{
                  'form-input-error-required'
                  | translate}}
                </p>
                <p class="error-message-field" *ngIf="changePasswordForm.get('confirmPassword').errors.MatchFields">
                  {{
                  'form-input-error-confirm-password-match'
                  | translate}}
                </p>

              </div>
            </div>
            <div class="col-lg-12 col-xs-12 form-row">
              <a href="javascript:void(0)" class="btn btn-primary btn-small btn-left" *ngIf="resetPasswordReadonlyMode;else passEditMode" (click)="enableResetPasswordFormInputs()">
                {{ 'change-password' |
                translate }}
              </a>

              <ng-template #passEditMode>
                <a href="javascript:void(0)" class="btn btn-primary btn-small btn-left" (click)="onChangePasswordClick()" [ngClass]="{'disabled': !changePasswordForm.valid}">
                  {{ 'save' | translate
                  }}
                </a>
                <a href="javascript:void(0)" class="btn btn-secondary btn-small btn-left" (click)="disableResetPasswordFormInputs()">{{
                  'cancel' | translate }}
                </a>
              </ng-template>
            </div>
          </div>

        </form>
      </div>

      <div class="box select-box select-clinic-box">
        <h3 class="subtitle subtitle-create-account">
          {{ 'notifications' | translate }}</h3>
        <form class="form-full-width" [formGroup]="reminderForm" novalidate>

          <div class="col-lg-12 col-xs-12 form-row wrap-switch-row form-row-border-bottom account-bottom-row">
            <div class="col-lg-1 col-2">
              <div class="md-switch">
                <input type="checkbox" id="switch-input" name="set-name" class="switch-input" formControlName="email">
                <label for="switch-input" class="switch-label">
                  <span class="toggle-on"></span>
                  <span class="toggle-off"></span>
                </label>
              </div>
            </div>
            <div class="col-lg-11 col-10">
              <div class="switch-text-label">
                {{ 'marketing-emails' |
                translate }}
              </div>
            </div>
          </div>
          <div *ngIf="clinicHasSmsModuleEnabled" class="col-lg-12 col-xs-12 form-row wrap-switch-row account-bottom-row">
            <div class="col-lg-1 col-2">
              <div class="md-switch">
                <input type="checkbox" id="switch-input1" name="set-name" class="switch-input" formControlName="sms">
                <label for="switch-input1" class="switch-label">
                  <span class="toggle-on"></span>
                  <span class="toggle-off"></span>
                </label>
              </div>
            </div>
            <div class="col-lg-11 col-10">
              <div class="switch-text-label">
                {{ 'sms-reminder' |
                translate }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <ng-template #sendEmailDialog>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-icon-button (click)="closeChangeEmailDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="check-email">
      <div class="title-email" fxLayout="row" fxLayoutAlign="center center">{{ 'check-email-title' | translate }}</div>
      <div class="sub-title" fxLayout="row" fxLayoutAlign="center center">
        We sent you a confirmation email on your new address
      </div>
      <div class="sub-title" fxLayout="row" fxLayoutAlign="center center">
        Click the link in your mail to confirm your change
      </div>
      <div class="email-img" fxLayout="row" fxLayoutAlign="center center">
        <img src="assets/img/email-message.png" />
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        {{ 'do-not-see-email' | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        {{ 'check-spam-folder' | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <a class="click-resend-email" (click)="resendEmailChange()">Click here &nbsp;</a>
        <div>to send the email again</div>
      </div>
    </div>
  </ng-template>
