<div class="consent-form" style="padding-right: 0">
  <form class="form-full-width" [formGroup]="form" novalidate>
    <pp-form-render *ngIf="consentForm" [customFields]="consentForm.controls" [form]="form"></pp-form-render>
  </form>

  <div class="col-12">
    <button style="margin-left: -15px" type="submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="saving">
      <i class="fa fa-spin fa-circle-o-notch" *ngIf="saving"></i>
      {{ 'continue' | translate }}
    </button>
  </div>
</div>
