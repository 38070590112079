<div ngClass.lt-lg="pl-3" ngClass.lg="pl-3" class="pr-3 pb-3">
  <div
    class="info-no-booking-available"
    ngClass.lt-sm="p-3"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayout.lt-sm="column"
    *ngIf="!useOnlineBooking"
  >
    <div style="text-align: center">
      <span class="wrap-icon-alert" style="margin: -4px">
        <i class="material-icons info-icon">info</i>
      </span>
      {{ onlineBookingDisabledMessage || ('no-online-booking-banner' | translate) }}
    </div>
  </div>

  <div
    class="appointment-card mt-4"
    ngClass.lt-sm="p-3"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayout.lt-sm="column"
    *ngIf="showOnboardingAlert"
  >
    <div fxFlex class="forms-human-illustration p-3" fxHide.lt-sm fxLayoutAlign="center center">
      <img class="w-100" src="/assets/icon/forms-human.svg" />
    </div>
    <div fxLayout="row" fxFlex="100%" class="forms-stepper-illustration text-center" fxHide.gt-xs fxLayoutAlign="center center">
      <img class="w-100" src="/assets/icon/forms-stepper.svg" />
    </div>
    <div fxFlex class="text-center" ngClass.lt-xs="pt-4" fxLayout="column">
      <div>{{ 'complete-onboarding-forms' | translate }}</div>
      <div class="text-center mt-3">
        <button mat-button color="primary" (click)="completeForms()">
          {{ 'complete-forms' | translate }}
        </button>
      </div>
    </div>
    <div fxFlex fxHide.lt-sm class="forms-stepper-illustration p-3" fxLayoutAlign="center center">
      <img class="w-100" src="/assets/icon/forms-stepper.svg" />
    </div>
  </div>

  <div class="row wrap-section wrap-section-first mt-4">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-left">
      <div *ngIf="useOnlineBooking" class="mb-4 text-center" fxHide.gt-xs>
        <h1 class="top-title mb-3 text-center">
          <span fxHide.lt-sm>{{ 'my-appointments' | translate }}</span>
          <span fxHide.gt-xs>{{ 'my-appointments-upcoming' | translate }}</span>
        </h1>
        <button mat-flat-button color="primary" (click)="navigateTo('booking/location')">
          {{ 'book-appointment' | translate }}
        </button>
      </div>

      <h1 class="top-title" ngClass.lt-sm="text-center">
        <span fxHide.lt-sm>{{ 'my-appointments-upcoming' | translate }}</span>
      </h1>

      <div *ngIf="loadingUpcoming">
        <pp-appointment-item
          [minHoursCancelOrReschedule]="minHoursToCancelReschedule"
          [canRescheduleAppointments]="canRescheduleAppointments"
          [appointment]="null"
        ></pp-appointment-item>
        <pp-appointment-item
          [minHoursCancelOrReschedule]="minHoursToCancelReschedule"
          [canRescheduleAppointments]="canRescheduleAppointments"
          [appointment]="null"
        ></pp-appointment-item>
      </div>

      <pp-appointment-item
        *ngFor="let ap of upcomingList"
        [minHoursCancelOrReschedule]="minHoursToCancelReschedule"
        [canRescheduleAppointments]="canRescheduleAppointments"
        [appointment]="ap"
      ></pp-appointment-item>

      <div class="text-center mt-4">
        <button
          mat-flat-button
          color="primary"
          (click)="onShowMoreUpcomingClick()"
          *ngIf="showMoreUpcomingBtn && upcomingList.length > 0"
          [disabled]="loading"
        >
          {{ 'show-more-upcoming' | translate }}
        </button>
        <ng-template *ngIf="!showMoreUpcomingBtn && upcomingList.length === 0">
          <button mat-flat-button color="primary" (click)="navigateTo('booking/location')">
            {{ 'book-appointment' | translate }}
          </button>
        </ng-template>
      </div>

      <div *ngIf="!loadingUpcoming && upcomingList.length === 0">
        <div fxFlex="100%" fxLayout="row" class="alert-info p-3 mt-3" role="alert">
          <div fxFlex fxLayoutAlign="start center">{{ 'no-upcoming-appointments' | translate }}</div>
          <a
            fxFlex
            fxLayoutAlign="end center"
            href="javascript:void(0)"
            (click)="navigateTo('booking/location')"
            *ngIf="useOnlineBooking"
            >{{ 'book-now' | translate }}</a
          >
        </div>
      </div>

      <div class="mt-4">
        <h1 *ngIf="previousList.length > 0" class="top-title" ngClass.lt-sm="text-center">
          <span fxHide.lt-sm>{{ 'my-appointments-past' | translate }}</span>
          <span fxHide.gt-xs>{{ 'past' | translate }}</span>
        </h1>
      </div>

      <div *ngIf="loadingPast">
        <pp-appointment-item [appointment]="null"> </pp-appointment-item>
        <pp-appointment-item [appointment]="null"> </pp-appointment-item>
      </div>

      <pp-appointment-item
        *ngFor="let ap of previousList"
        [minHoursCancelOrReschedule]="minHoursToCancelReschedule"
        [canRescheduleAppointments]="canRescheduleAppointments"
        [appointment]="ap"
      ></pp-appointment-item>

      <div class="text-center mt-4" *ngIf="showMorePreviousBtn && previousList.length > 0">
        <button mat-flat-button color="primary" (click)="onShowMorePreviousClick()" [disabled]="loading">
          {{ 'show-more-past' | translate }}
        </button>
      </div>
    </div>
    <div ngClass.lt-sm="mt-4" class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-right">
      <div *ngIf="useOnlineBooking" fxHide.lt-sm class="mb-4 text-right">
        <button mat-flat-button color="primary" (click)="navigateTo('booking/location')">
          {{ 'book-appointment' | translate }}
        </button>
      </div>
      <div class="box select-box box-appointment-summary mat-subtitle appointment-card p-3" *ngIf="selectedLocation">
        <mat-select *ngIf="locations.length > 1" (selectionChange)="onLocationChange($event.value)" [value]="selectedLocation">
          <mat-option *ngFor="let location of locations" [value]="location" class="subtitle">
            {{ location.name }}
          </mat-option>
        </mat-select>
        <h3 class="subtitle" *ngIf="locations.length === 1">
          {{ locations[0].name }}
        </h3>
        <hr />
        <div class="row row-app-right">
          <div class="col-12">
            <label>{{ 'email' | translate }}</label>
            <p>{{ selectedLocation.email }}</p>
          </div>
        </div>
        <div class="row row-app-right">
          <div class="col-12">
            <label>{{ 'phone' | translate }}</label>
            <p>{{ selectedLocation.phoneNumber }}</p>
          </div>
        </div>
        <div class="row row-app-right">
          <div class="col-12">
            <span *ngIf="selectedLocation.addressLine1 || selectedLocation.addressLine2">
              {{ 'street-address' | translate }}:
              {{ selectedLocation.addressLine1 + (selectedLocation.addressLine2 ? ', ' : '') }}
              {{ selectedLocation.addressLine2 }}
            </span>
          </div>
        </div>

        <div class="row row-app-right" *ngIf="selectedLocation.city">
          <div class="col-12">
            <span>
              {{ 'city' | translate }}:
              {{ selectedLocation.city }}
            </span>
          </div>
        </div>

        <div class="row row-app-right" *ngIf="selectedLocation.state">
          <div class="col-12">
            <span>
              {{ 'state' | translate }}:
              {{ selectedLocation.state }}
            </span>
          </div>
        </div>

        <div class="row row-app-right" *ngIf="selectedLocation.zipCode">
          <div class="col-12">
            <span>
              {{ 'zipCode' | translate }}:
              {{ selectedLocation.zipCode }}
            </span>
          </div>
        </div>

        <div class="row row-app-right mb-20" *ngIf="selectedLocation.country">
          <div class="col-12">
            <p class="mb-1">
              {{ 'country' | translate }}:
              {{ selectedLocation.country }}
            </p>
          </div>
        </div>
        <div
          class="row row-app-right cursor-pointer"
          *ngIf="selectedLocation?.latitude && selectedLocation?.longitude && !showLocationMap"
          (click)="showLocationMap = true"
        >
          <div class="col-12">
            <i class="material-icons icon-map">place</i>
            <span class="small-text">{{ 'see-map' | translate }}</span>
          </div>
        </div>

        <div class="row row-app-right payment-md" *ngIf="acceptedPayments.length > 0">
          <div class="col-12">
            <label>{{ 'payment' | translate }}</label>
          </div>
        </div>
        <div class="row row-app-right payment-md" *ngFor="let payment of acceptedPayments">
          <div class="col-2 wrap-payment-icon">
            <i class="payment-icon">
              <img src="{{ payment.src }}" alt="{{ payment.name | translate }}" />
            </i>
          </div>
          <div class="col-10 wrap-payment-name">
            <span class="mr-1">
              {{ payment.name | translate }}
            </span>
          </div>
          <hr />
        </div>

        <div>
          <google-map
            *ngIf="showLocationMap"
            height="300px"
            width="100%"
            [zoom]="15"
            [center]="{ lat: selectedLocation?.latitude, lng: selectedLocation?.longitude }"
          >
            <map-marker [position]="{ lat: selectedLocation?.latitude, lng: selectedLocation?.longitude }"> </map-marker>
          </google-map>
        </div>
      </div>
    </div>
  </div>
</div>
