<div class="content-inner-deprecated container pp-container" *ngIf="!(authHelperService.loggedIn$ | async)">
  <div class="row wrap-section">
    <div class="wrap-title">
      <h1 class="title">{{ 'book-an-appointment' | translate }}</h1>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-xs-12 col-left">
      <div class="box" *ngIf="useOnlineBooking; else noOnlineBooking">
        <div class="create-acc">{{ 'you-must-create-acc' | translate }}</div>
        <h3 class="subtitle question-title">{{ 'step-1-question-title' | translate }}</h3>
        <div class="md-radio" (click)="setPreviousVisit(true)">
          <input id="1" type="radio" name="r" [checked]="isExistingPatient" />
          <label for="1">{{ 'yes-existing-patient' | translate }}</label>
        </div>
        <div class="md-radio" (click)="setPreviousVisit(false)">
          <input id="2" type="radio" name="r" [checked]="isExistingPatient === false" />
          <label for="2">{{ 'no-firsttime-patient' | translate }}</label>
        </div>
      </div>

      <ng-template #noOnlineBooking>
        <div class="box alert alert-info" role="alert">
          <span class="wrap-icon-alert">
            <i class="material-icons info-icon">info</i>
          </span>
          {{ [onlineBookingDisabledMessage] || ('OnlineBookingDisabledDefaultMessage' | translate) }}
        </div>
      </ng-template>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-xs-12 col-right" *ngIf="loginEnabled">
      <pp-login-box [returnUrl]="redirectLoginUrl" infoMessage="login-info-message"> </pp-login-box>
    </div>
  </div>
</div>
