import { OnboardingContainerPageComponent } from './onboarding-container-page/onboarding-container-page.component';
import { OnboardingSummaryBoxComponent } from './onboarding-summary-box/onboarding-summary-box.component';
import { OnboardingSummaryDialogComponent } from './onboarding-summary-box/onboarding-summary-dialog.component';
import { MedicalFormsListPageComponent } from './medical-forms-list-page/medical-forms-list-page.component';
import { MedicalFormPageComponent } from './medical-form-page/medical-form-page.component';
import { ScreeningFormsListPageComponent } from './screening-forms-list-page/screening-forms-list-page.component';
import { ScreeningFormPageComponent } from './screening-form-page/screening-form-page.component';
import { ConsentFormsListPageComponent } from './consent-forms-list-page/consent-forms-list-page.component';
import { ConsentFormPageComponent } from './consent-form-page/consent-form-page.component';
import { InsurancePageComponent } from './insurance-page/insurance-page.component';
import { MedicalInfoPageComponent } from './medical-info-page/medical-info-page.component';
import { PrimaryPhysicianInfoPageComponent } from './primary-physician-info-page/primary-physician-info-page.component';
import { EmergencyContactInfoPageComponent } from './emergency-contact-info-page/emergency-contact-info-page.component';
import { AddressContactInfoPageComponent } from './address-contact-info-page/address-contact-info-page.component';
import { AdditionalInfoPageComponent } from './additional-info-page/additional-info-page.component';
import { PatientInfoPageComponent } from './patient-info-page/patient-info-page.component';
import { SignaturePageComponent } from './signature-page/signature-page.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { InsuranceFormComponent } from './insurance-page/insurance-form.component';
import {GenericFormsListPageComponent} from './generic-forms-list-page/generic-forms-list-page.component';
import {GenericFormPageComponent} from './generic-form-page/generic-form-page.component';

export const ONBOARDING_COMPONENTS: any[] = [
    OnboardingContainerPageComponent
    , OnboardingSummaryBoxComponent
    , OnboardingSummaryDialogComponent
    , MedicalFormsListPageComponent
    , MedicalFormPageComponent
    , ScreeningFormsListPageComponent
    , ScreeningFormPageComponent
    , ConsentFormsListPageComponent
    , ConsentFormPageComponent
    , GenericFormsListPageComponent
    , GenericFormPageComponent
    , InsurancePageComponent
    , MedicalInfoPageComponent
    , PrimaryPhysicianInfoPageComponent
    , EmergencyContactInfoPageComponent
    , AddressContactInfoPageComponent
    , AdditionalInfoPageComponent
    , PatientInfoPageComponent
    , SignaturePageComponent
    , SuccessPageComponent
    , InsuranceFormComponent
];

export * from './onboarding-container-page/onboarding-container-page.component';
export * from './onboarding-summary-box/onboarding-summary-box.component';
export * from './onboarding-summary-box/onboarding-summary-dialog.component';
export * from './medical-forms-list-page/medical-forms-list-page.component';
export * from './medical-form-page/medical-form-page.component';
export * from './screening-forms-list-page/screening-forms-list-page.component';
export * from './screening-form-page/screening-form-page.component';
export * from './consent-forms-list-page/consent-forms-list-page.component';
export * from './consent-form-page/consent-form-page.component';
export * from './generic-forms-list-page/generic-forms-list-page.component';
export * from './generic-form-page/generic-form-page.component';
export * from './insurance-page/insurance-page.component';
export * from './medical-info-page/medical-info-page.component';
export * from './primary-physician-info-page/primary-physician-info-page.component';
export * from './emergency-contact-info-page/emergency-contact-info-page.component';
export * from './address-contact-info-page/address-contact-info-page.component';
export * from './additional-info-page/additional-info-page.component';
export * from './patient-info-page/patient-info-page.component';
export * from './signature-page/signature-page.component';
export * from './success-page/success-page.component';

