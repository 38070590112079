import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'pp-register-page',
    templateUrl: 'register-page.component.html'
})

export class RegisterPageComponent implements OnInit {
    constructor(

    ) { }

    ngOnInit() { }


}
