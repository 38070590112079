<div class="box">
  <div class="text-center wrap-success-icon flex place-content-center">
    <img src="assets/img/succes-appointment.png" alt="" />
  </div>

  <h1 *ngIf="loggedIn" class="title title-top-success">{{ 'title-onboardig-complete' | translate }}</h1>
  <h1 *ngIf="!loggedIn" class="title title-top-success">{{ 'title-onboardig-complete-loggedout' | translate }}</h1>

  <p *ngIf="loggedIn" class="text-center col-lg-8 offset-lg-2 col-xs-12 offset-xs-0">
    {{ 'onboardig-complete-description' | translate }}
  </p>
  <p *ngIf="!loggedIn" class="text-center col-lg-8 offset-lg-2 col-xs-12 offset-xs-0">
    {{ 'onboardig-complete-description-loggedout' | translate }}
  </p>

  <div class="wrap-buttons-appointment">
    <div class="wrap-btn-appointment">
      <button *ngIf="loggedIn" class="btn btn-primary btn-large-mobile" (click)="navigateTo('appointments')">
        {{ 'dashboard' | translate }}
      </button>

      <button *ngIf="!loggedIn" class="btn btn-primary btn-large-mobile" (click)="navigateTo('booking/previous-visit')">
        {{ 'book-another-appointment' | translate }}
      </button>

      <button
        *ngIf="!loggedIn && appointmentUid"
        class="btn btn-secondary btn-large-mobile"
        (click)="navigateTo('appointments/' + appointmentUid)"
      >
        {{ 'back-to-appointment' | translate }}
      </button>
    </div>
  </div>
</div>
