import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AccountService } from '../services';


@Injectable()
export class NotificationPreferencesResolver implements Resolve<Observable<any>> {
    constructor(
        private accountService: AccountService,
    ) { }

    resolve() {
        return this.accountService.getNotifications();
    }
}
