import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { AppointmentsListPageComponent } from './appointments-list-page/appointments-list-page.component';
import { AppointmentItemComponent } from './appointment-item/appointment-item.component';
import { AppointmentPageComponent } from './appointment-page/appointment-page.component';
import { AppointmentVerifyPageComponent } from './appointment-verify-page/appointment-verify-page.component';

export const APPOINTMENTS_COMPONENTS: any[] = [
  AppointmentsListPageComponent,
  AppointmentItemComponent,
  AppointmentPageComponent,
  AppointmentVerifyPageComponent,
  CancelModalComponent
];

export * from './appointments-list-page/appointments-list-page.component';
export * from './cancel-modal/cancel-modal.component';
export * from './appointment-item/appointment-item.component';
export * from './appointment-page/appointment-page.component';
export * from './appointment-verify-page/appointment-verify-page.component';
