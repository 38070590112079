import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

    show() {
        document.getElementById('full-screen-loader').style.display = 'block';

    }

    hide() {
        document.getElementById('full-screen-loader').style.display = 'none';

    }

}
