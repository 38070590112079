<div class="content-inner-deprecated pp-container">
    <div>
      <div class="top-title">
        {{ 'book-appointment' | translate }}
      </div>
      <div class="box alert alert-info" role="alert">

        <span class="wrap-icon-alert">
          <i class="material-icons info-icon">info</i>
        </span>

        <p *ngIf="isExistingPatient" style="margin:0">
          {{ insuranceMessageExistingPatient }}
        </p>

        <p *ngIf="!isExistingPatient" style="margin:0">
          {{ insuranceMessageNewPatient }}
        </p>

      </div>
    </div>
    <div class="mt-4">
      <div class="box">
        <h3 class="subtitle">{{'fill-insurance-question'
          | translate }} </h3>
        <div class="md-radio" (click)="navigateTo('booking/review', insuranceTypeEnum.NoInsurance)">
          <input id="1" type="radio" name="r">
          <label for="1">{{'no-insurance-radiobox'
            | translate }}
          </label>
        </div>
        <div class="md-radio" (click)="navigateTo('booking/primary-insurance', insuranceTypeEnum.Primary)"
          *ngIf="primaryInsurance">
          <input id="2" type="radio" name="r">
          <label for="2">{{'primary-insurance-radiobox'
            | translate
            }}
          </label>
        </div>

        <div class="md-radio" *ngIf="autoInsurance" (click)="navigateTo('booking/auto-insurance', insuranceTypeEnum.AutoAccident)">
          <input id="3" type="radio" name="r">
          <label for="3">
            {{'auto-accident-radiobox' |
            translate }}
          </label>
        </div>
        <div class="md-radio" *ngIf="workInsurance" (click)="navigateTo('booking/work-insurance', insuranceTypeEnum.WorkAccident)">
          <input id="4" type="radio" name="r">
          <label for="4">
            {{'work-insurance-radiobox' |
            translate }}
          </label>
        </div>
      </div>
    </div>
</div>
