<div class="content-inner-deprecated pp-container">
  <div class="top-title">
    {{ errorMessage || 'book-appointment' | translate }}
  </div>
  <div class="row wrap-section">
    <div class="col-xl-8 col-12 col-left order-2 md:order-1">
      <div class="box select-box">
        <h3 class="subtitle subtitle-create-account">{{ 'select-a-start-date' | translate }}</h3>
        <p>
          <i class="text-note">{{ 'available-timeslots' | translate }}</i>
        </p>
        <!-- <form> -->
        <div class="row">
          <div class="col-lg-12 col-xs-12 form-row head-calendar">
            <div class="head-selected-week">
              {{ startDate | date: 'MMMM d' }}
              - {{ endDate | date: 'MMMM d' }}
            </div>
            <div class="wrap-navigation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                [ngClass]="{ disabled: isPreviousDisabled() || showLoader }"
                (click)="onPreviousWeekClick()"
              >
                <i class="material-icons">arrow_back</i>
                <span>{{ 'previous-week' | translate }}</span>
              </a>
              <a href="javascript:void(0)" class="nav-link next" [ngClass]="{ disabled: showLoader }" (click)="onNextWeekClick()">
                <span>{{ 'next-week' | translate }}</span>
                <i class="material-icons">arrow_forward</i>
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-xs-12 form-row calendar-row" *ngFor="let bookingDay of bookingDays; let dayIndex = index">
            <h4 class="cal-row-date">{{ bookingDay.date | date: 'EEEE, MMMM d' }}</h4>
            <div
              class="md-radio-tab"
              *ngFor="let availability of bookingDay.availabilities"
              (click)="setTimeslot(availability, availability.slotIndex, dayIndex)"
            >
              <ng-container *ngIf="!(loadingSlotId === availability.slotIndex && loadingDayIndex === dayIndex); else loadingSlot">
                <input
                  id="{{ availability.localDateTime }}"
                  type="radio"
                  name="r"
                  [checked]="availability.localDateTime === selectedTimeSlot?.localDateTime"
                />
                <label for="{{ availability.localDateTime }}" [class.disabled]="loadingSlotId">
                  <span> {{ availability.timeString }} </span>
                </label>
              </ng-container>
            </div>

            <div class="radio-tab-more" *ngIf="bookingDay.dropdownAvailabilities.length > 0">
              <mat-select
                class="form-control form-control-select calendar-select"
                placeholder="{{ 'more' | translate }}"
                [(value)]="selectedTimeSlot"
                (selectionChange)="setTimeslot($event.value, $event.value.slotIndex, dayIndex)"
              >
                <mat-option disabled>{{ 'more' | translate }}</mat-option>
                <mat-option *ngFor="let availability of bookingDay.dropdownAvailabilities" [value]="availability">
                  {{ availability.timeString }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-xs-12 form-row calendar-row" *ngIf="bookingDays.length === 0 && !showLoader">
            <div class="box alert alert-info" role="alert">
              <span class="wrap-icon-alert">
                <i class="material-icons info-icon">info</i>
              </span>
              {{ 'no-timeslots-available' | translate }}
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Loader -->
          <div class="col-lg-12 col-xs-12 form-row calendar-row" *ngIf="showLoader">
            <div class="col-lg-12 col-no-padding">
              <i class="top-line"></i>
            </div>
            <div class="md-radio-tab" *ngFor="let timeslot of [0, 0, 0, 0, 0, 0, 0, 0, 0]">
              <ng-container *ngTemplateOutlet="loadingSlot"></ng-container>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-xs-12 form-row calendar-row" *ngIf="showLoader">
            <div class="col-lg-12 col-no-padding">
              <i class="top-line"></i>
            </div>
            <div class="md-radio-tab" *ngFor="let timeslot of [0, 0, 0, 0, 0, 0, 0, 0, 0]">
              <ng-container *ngTemplateOutlet="loadingSlot"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-12 col-right order-1 md:order-2">
      <pp-booking-summary-box title="{{ 'appointment-summary' | translate }}"></pp-booking-summary-box>
    </div>
  </div>
</div>

<ng-template #loadingSlot>
  <input id="1b" type="radio" name="r2" />
  <label for="1b">
    <span class="line width-100"> </span>
  </label>
</ng-template>
