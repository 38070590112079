
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CatalogsService } from '../../core/services';

@Injectable()
export class StatesResolver implements Resolve<any> {
    constructor(private catalogsService: CatalogsService) { }

    resolve() {
        return this.catalogsService.getStates();
    }
}
