<div class="onboarding-container-page" >

  <div class="content-inner-deprecated pp-container">
    <div class="top-title">
      {{'forms' | translate}}
    </div>
    <div class="row wrap-section">

      <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-xs-12 col-left order-2 order-md-1">

        <router-outlet></router-outlet>

      </div>

      <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-xs-12 col-right order-1 order-md-2">
        <pp-onboarding-summary-box [progress]="progress"></pp-onboarding-summary-box>
      </div>
    </div>
  </div>


</div>
