<div class="content-inner-deprecated pp-container" fxLayout="column" fxFlex="100%">
  <div fxFlex="100%" *ngIf="telehealthLocations.length > 0">
    <div id="telehealth-bar">
      <span id="telehealth-text">
        Telemedicine appointments are now available at
        <a *ngFor="let loc of telehealthLocations" class="telehealth-link" href="javascript:void(0)" (click)="setLocation(loc, false)">
          <u> {{ loc.name }}</u></a
        >
      </span>
    </div>
  </div>
  <div class="row wrap-section mt-3" *ngIf="message">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="box alert alert-info">
        <span class="wrap-icon-alert">
          <i class="material-icons info-icon">info</i>
        </span>
        {{ message | translate }}
      </div>
    </div>
  </div>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start center">
    <div class="w-100">
      <div class="top-title">
        {{ 'book-appointment' | translate }}
      </div>
      <div class="box select-box select-clinic-box">
        <h3 class="subtitle">{{ 'title-select-location' | translate }}</h3>

        <div class="row row-md-radio" *ngFor="let location of locations">
          <div class="col-xl-10 md-radio col-12" (click)="setLocation(location, true)">
            <input id="{{ location.id }}" type="radio" name="r" [checked]="location.id === selectedLocation?.id" />
            <label for="{{ location.id }}">
              <span>
                <strong> {{ location.name }}</strong>
              </span>
              <span class="small-text word-break-all">
                {{ location.addressLine1 }}
                {{ location.addressLine2 }},
                {{ location.city }}
                {{ location.zipCode }}
              </span>
            </label>
          </div>
          <div
            *ngIf="location.latitude && location.longitude"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutAlign.lt-lg="start start"
            class="col-xl-2 col-12 details-link view-map-link"
            (click)="openMap(location)"
          >
            <div class="cursor-pointer">
              <i class="material-icons icon-map">place</i>
              <span class="small-text">{{ 'see-map' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
