import { ClinicService } from './../../clinic/services/clinic.service';
import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import * as fromRoot from './../../ngrx';
import { Store } from '@ngrx/store';
import { NavigateWrapperService } from '../../core/services';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CanActivateServiceStepGuard implements CanActivate, OnDestroy {
  private location: boolean;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromRoot.State>,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService
  ) {
    this.store
      .select(fromRoot.getBookingLocation)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => (this.location = val));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.location) {
      return true;
    }

    // go to location step
    this.navigateService.navigate(['booking', 'location']);

    return false;
  }
}
