import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StartupService } from '../../../core/services';

@Component({
  selector: 'pp-register-inactive-user-error-modal',
  templateUrl: './register-inactive-user-error-modal.component.html',
  styleUrls: ['./register-inactive-user-error-modal.component.scss'],
})
export class RegisterInactiveUserErrorModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, public startupService: StartupService) {}

  ngOnInit(): void {}
}
