import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UploadService } from '../../../core/services/upload.service';

@Component({
    selector: 'pp-file-upload',
    templateUrl: 'file-upload.component.html'
})
export class FileUploadComponent {

    @Input() name: string;
    @Input() url: string;
    @Output() onFileSelect = new EventEmitter<any>();

    formatsAllowed = '.jpg, .png, .pdf, .jpeg, .hiec';

    @ViewChild('fileInput', { static: true }) fileInput;
    // public files: Set<File> = new Set();
    public file: File;

    localPath: any;

    constructor(
        public uploadService: UploadService) {
    }

    addFiles() {
        this.fileInput.nativeElement.click();
    }

    onFilesAdded(event) {
        const reader = new FileReader();

        // const files: { [key: string]: File } = this.file.nativeElement.files;
        // for (const key in files) {
        //     // tslint:disable-next-line:radix
        //     if (!isNaN(parseInt(key))) {
        //         this.files.add(files[key]);

        //         this.onFileSelect.emit({ name: this.name, file: files[key] });
        //     }
        // }

        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            this.file = file;

            reader.readAsDataURL(file);
            reader.onload = () => {
                this.localPath = reader.result;
                this.onFileSelect.emit({ name: this.name, file: file });
            };
        }

    }

    removeFile(file) {
        // this.files.delete(file);
        this.file = undefined;
        this.fileInput.nativeElement.value = '';
        this.onFileSelect.emit({ name: this.name, file: null });
    }

    isPdf(url) {
        return url.indexOf('.pdf') > -1;
    }
}
