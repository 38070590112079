<div mat-dialog-content class="onboarding-dialog">
  <p *ngIf="data.formValid">{{'onboarding-skip-msg' |
    translate}}</p>
  <p *ngIf="!data.formValid">{{'onboarding-invalid-form-msg'
    |
    translate}}</p>
</div>

<div class="row onboarding-dialog">

  <div class="col-6 text-center pr-0" *ngIf="data.formValid">
    <button class="btn btn-primary" style="width: 70%"
      mat-button [mat-dialog-close]="true">{{'yes'
      |
      translate}}</button>
  </div>

  <div class="col-6 text-center pl-0" *ngIf="data.formValid">
    <button mat-button class="btn btn-default" style="width: 70%"
      [mat-dialog-close]="false">{{'no'
      |
      translate}}</button>
  </div>

  <div class="col-12 text-center" *ngIf="!data.formValid">
    <button mat-button class="btn btn-primary" style="width: 40%"
      [mat-dialog-close]="false">{{'ok'
      |
      translate}}</button>
  </div>

</div>
