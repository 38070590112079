import { Lang } from './../models/lang';

// libs
import { Component, Inject, OnInit } from '@angular/core';


// app
import { MultilingualService } from '../services/index';

@Component({
  selector: 'pp-lang-switcher',
  templateUrl: 'lang-switcher.component.html',
  styleUrls: ['lang-switcher.component.scss'],
})
export class LangSwitcherComponent implements OnInit {

  public lang: string;
  public supportedLanguages: Array<Lang>;

  constructor(
    private multilangService: MultilingualService
  ) { }

  changeLang(e: any) {
    let lang = this.supportedLanguages[0].code; // fallback to default 'en'

    if (e && e.target) {
      lang = e.target.value;
    }

    this.multilangService.changeLang(lang)
  }

  ngOnInit() {
    this.supportedLanguages = [
      { code: 'en', title: 'English' },
      { code: 'es', title: 'Spanish' },
      { code: 'zh', title: 'Chinese' },
      { code: 'ja', title: 'Japanese' },

    ];
  }
}
