<div class="content-inner-deprecated">
  <div class="row wrap-section" *ngIf="showOnboardingAlert">
    <div class="col-12">
      <div class="box alert alert-warning message-warning" role="alert">
        <div class="row">
          <div class="col-lg-10 col-md-9 col-sm-8 col col-xs-12">
            <span>
              {{ 'complete-onboarding-forms' | translate }}
            </span>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
            <button class="btn btn-small btn-primary pull-right" (click)="completeForms()">
              {{ 'complete-forms' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <div class="box select-box box-appointment-summary" *ngIf="appointment">
      <h3 class="subtitle" *ngIf="!pastAppointment">
        {{ 'upcoming-appointment-title' | translate }}
      </h3>
      <h3 class="subtitle" *ngIf="pastAppointment">
        {{ 'appointment-details' | translate }}
      </h3>
      <form>
        <div class="row row-edit">
          <div class="col-xs-12 edit-col">
            <label>{{ 'location' | translate }}:&nbsp;</label>

            <span type="text" disabled>{{ appointment.location.name }} </span>
          </div>
        </div>
        <div class="row row-edit" *ngIf="appointment.telehealthMeetingUrl">
          <div class="col-xs-12 edit-col">
            <label>{{ 'join-telehealth' | translate }}:&nbsp;</label>
            <a href="{{ appointment.telehealthMeetingUrl }}" target="_blank">{{ appointment.telehealthMeetingUrl }}</a>
          </div>
        </div>
        <div class="row row-edit">
          <div class="col-xs-12 edit-col">
            <label>{{ 'service' | translate }}:&nbsp;</label>

            <span type="text" disabled>{{ appointment.service.name }} </span>
          </div>
        </div>
        <div class="row row-edit">
          <div class="col-xs-12 edit-col">
            <label>{{ 'practitioner' | translate }}:&nbsp;</label>
            <span *ngIf="appointment.practitioner.id; else noPreference" type="text" disabled
              >{{ appointment.practitioner.firstName }} {{ appointment.practitioner.lastName }}</span
            >
            <ng-template #noPreference>
              <input type="text" value="{{ 'no-practitioner-prefrence' | translate }}" disabled />
            </ng-template>
          </div>
        </div>
        <div class="row row-edit">
          <div class="col-xs-12 edit-col">
            <label>{{ 'date' | translate }}:&nbsp;</label>
            <span type="text" value="" disabled
              >{{ appointment.localDateTime | date: 'fullDate' }} at {{ appointment.localDateTime | date: 'shortTime' }}
            </span>
          </div>
        </div>
        <div class="row row-edit" *ngIf="cancellationPeriod && appointment.cancellationFee > 0">
          <div class="col-lg-12 col-xs-12 edit-col">
            <label>{{ 'cancellation-policy' | translate }}:&nbsp;</label>
            <span>
              If you cancel this appointment within {{ cancellationPeriod }} hours before its scheduled start time, you will be charged
              ${{ appointment.cancellationFee }} cancellation fee.
            </span>
          </div>
        </div>
        <div class="row row-edit">
          <div class="wrap-actions wrap-actions-left wrap-actions-apt-page col-lg-4 col-md-3">
            <div class="action">
              <i class="status status-{{ status }}"></i>
              <span>{{ status | translate }}</span>
            </div>
          </div>
          <div class="wrap-actions wrap-actions-right wrap-actions-apt-page col-lg-8 col-md-9">
            <div class="action" (click)="onRescheduleClick()" *ngIf="canReschedule">
              <i class="material-icons">refresh</i>
              <span>{{ 'reschedule' | translate }}</span>
            </div>
            <div class="action" (click)="onOpenCancelModalClick()" *ngIf="canCancel">
              <i class="material-icons">delete</i>
              <span>{{ 'cancel' | translate }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
