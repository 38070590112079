import { ClinicLocation, Service, Practitioner } from '../../clinic/models/index';

export interface Appointment {
  calendarAppointmentId: number;
  appointmentUid: string;
  locationGuid: string;
  location: ClinicLocation;
  serviceId: number;
  service: Service;
  patientId: number;
  practitionerId: number;
  practitioner: Practitioner;
  index: number;
  duration: number;
  localDateTime: string;
  localDateTimeDateObj: Date;
  utcDateTime: string;
  isForNewPatients: true;
  reasonForVisit: string;
  cancellationFee: number;
  cancellationPeriod: number;
  telehealthMeetingUrl: string;
  doNotChargeCancellationFee: boolean;

  billingState: BillingState;
  appointmentStatus: AppointmentStatus;
  appointmentState: AppointmentState;
  acceptedState: AppointmentAcceptedState;
  cancelledState: AppointmentCanceledState;
  arrivedState: AppointmentArrivedState;
  appointmentType: AppointmentType;
  isMatchingError: boolean;
  hasPatientPortalAccount: boolean;

  status: string;
  participants: [
    {
      personId: number;
      participantName: {
        firstName: string;
        middleName: string;
        lastName: string;
      };
      personTypeIds: [number];
    }
  ];
  supervisor: Practitioner;
  intern: Practitioner;
  assistingIntern: Practitioner;
}

export enum AppointmentStatus {
  Accepted,
  Confirmed,
  NotConfirmed,
  NoShow,
  ArrivedOnTime,
  ArrivedLate,
  InSession,
  Completed,
}

export enum AppointmentState {
  Created,
  Accepted,
  Rejected,
  Confirmed,
  NotConfirmed,
  Completed,
}

export enum AppointmentAcceptedState {
  Created,
  Rescheduled,
  Cancelled,
  Arrived,
  NotArrived,
  InSession,
}

export enum AppointmentCanceledState {
  CancelledByPatient,
  CancelledByPractitioner,
}

export enum AppointmentArrivedState {
  ArrivedOnTime,
  ArrivedLate,
}

export enum AppointmentType {
  SelfPay,
  Insurance,
  Promotion,
  ProBono,
  Other,
}

export enum BillingState {
  PaidInFull,
  BalanceDue,
  Settled,
  InvoiceCreated,
  ClaimCreated,
  ClaimSubmitted,
  ClaimPartialPay,
  ClaimComplete,
  ClaimSubmittedToOA,
  ClaimSubmittedExternally,
}
