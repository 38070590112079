import { Appointment } from './../../../appointments/models/appointment';
import { PhoneTypeEnum } from './../../../shared/models/phoneType.enum';
import { StartupService } from './../../../core/services/startup.service';
import { AppointmentData, BookingConfirmationStatus, Timeslot } from './../../models/booking-day';
import { BookingService } from './../../services/booking.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { select, Store } from '@ngrx/store';
import { ClinicService } from '../../../clinic/services/index';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthHelperService } from '../../../core/services/index';
import { AppointmentState } from '../../../appointments/models/appointment';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { OnboardingService } from '../../../onboarding/services';

import { Observable, zip } from 'rxjs';
import { first, finalize, takeUntil, filter, catchError } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { AccountService } from '../../../account/services';
import { UpFullSteamService, CardProfile, UpFullsteamControlComponent } from '@unifiedpractice/up-ng-fullsteam';
import { State as OrganizationState } from '../../../organization/reducers';
import { Service } from '../../../clinic/models';
import { OrganizationTypeEnum } from '../../../shared/models/organization.enum';

@Component({
  selector: 'pp-review-step-page',
  templateUrl: 'review-step-page.component.html',
})
export class ReviewStepPageComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('fullSteamControl') fullSteamControl: UpFullsteamControlComponent;
  additionalInfoForm: FormGroup;
  showLoader = false;
  showError = false;
  errorMessage = '';
  loggedIn = false;
  showSmsCheckbox = false;
  paymentSuccess = false;
  organization: OrganizationState;
  service: Service;

  bookingFlowState: 'unauth' | 'auth' | 'reschedule' = 'unauth';

  private appointment: AppointmentData;
  private savedInfo: any = {};
  patientData = {
    fullName: '',
    id: 0,
    patientCode: '',
    zipCode: '',
  };
  timeslot: Timeslot;

  constructor(
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private authHelperService: AuthHelperService,
    private startupService: StartupService,
    private navigateService: NavigateWrapperService,
    private onbardingService: OnboardingService,
    private clinicService: ClinicService,
    private accountService: AccountService,
    private upFullSteamService: UpFullSteamService
  ) {
    super();
  }

  ngOnInit() {
    this.upFullSteamService.initGlobalFSFunctions();
    this.accountService.user
      .pipe(
        filter((user) => user),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.patientData.patientCode = user.patientCode;
        this.patientData.id = user.patientId;
        this.patientData.fullName = `${user.personalInfo.firstName} ${user.personalInfo.lastName}`;
      });
    this.store.pipe(select(fromRoot.getOrganizationState), takeUntil(this.destroy$)).subscribe((organization) => {
      this.organization = organization;
    });
    zip(this.authHelperService.loggedIn$, this.store.select(fromRoot.getBookingService), this.store.select(fromRoot.getBookingDateTime))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.loggedIn = res[0];
        this.service = res[1];
        this.timeslot = res[2];
        if (this.loggedIn && this.clinicService.serviceNotAvailableForAuthenticated(res[1])) {
          this.store.dispatch(new BookingActions.SetMessageOnServiceStep('service-not-available'));

          this.store.dispatch(new BookingActions.SetServiceAction(null));

          // reset scheduling pratitioner when the location is changed
          this.store.dispatch(new BookingActions.SetPractitionerAction(null));

          // reset scheduling time when the location is changed
          this.store.dispatch(new BookingActions.SetTimeslotAction(null));

          this.navigateService.navigate(['booking', 'service']);
        }
      });

    zip(
      this.store.select(fromRoot.getBookingAppointmentObject).pipe(first()),
      this.store.select(fromRoot.getBookingAdditionalInformation).pipe(first())
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.appointment = <AppointmentData>res[0];
        this.savedInfo = res[1] || {};

        this.additionalInfoForm = this.formBuilder.group({
          reasonForVisit: this.savedInfo.reasonForVisit || '',
          referal: this.savedInfo.referal || '',
          receiveSms: false,
        });

        this.showSmsCheckbox =
          this.startupService.startupData.hasSms && this.appointment.account && this.appointment.account.phoneType === PhoneTypeEnum.Mobile;
      });
  }

  ngAfterViewInit() {
    const contentContainer = document.querySelector('pp-booking-summary-box > div > form > div.summary-insuranceType');
    if (contentContainer) {
      contentContainer.scrollIntoView();
    }
  }

  onSave(ev) {
    this.paymentSuccess = true;
    this.appointment.cardData = JSON.parse(ev);
  }

  cardSelectionChanged(event: any): void {
    const cardProfile = event as CardProfile;
    if (cardProfile.id === -1) {
      this.paymentSuccess = false;
      return;
    }
    this.appointment.cardId = cardProfile.id;
    this.appointment.cardData = null;
    this.paymentSuccess = true;
  }

  saveAditionalData() {
    this.appointment.additionalInformation = this.additionalInfoForm.value;
    this.store.dispatch(new BookingActions.SetAdditionalInfo(this.additionalInfoForm.value));
  }

  confirmAppointment(): void {
    this.showLoader = true;
    this.saveAditionalData();

    this.appointment.practitionerId = this.timeslot.practitionerId;

    if (this.startupService.startupData.organizationType === OrganizationTypeEnum.univeristy) {
      this.appointment.supervisorId = this.timeslot.supervisorId;
      this.appointment.mainInternId = this.timeslot.mainInternId;
      this.appointment.assistingInternId = this.timeslot.assistingInternId;
    }

    // Unauthenticated flow
    let create$: Observable<Object> = this.bookingService.createGuestBooking(this.appointment);

    if (this.loggedIn) {
      {
        // Authenticated flow
        this.bookingFlowState = 'auth';
        create$ = this.bookingService.createAuthBooking(this.appointment);
      }
    }

    if (this.appointment.appointmentUid) {
      // Reschedule flow
      this.bookingFlowState = 'reschedule';
      create$ = this.bookingService.reschedule(this.appointment);
    }

    create$
      .pipe(
        finalize(() => {
          this.showLoader = false;
          this.onbardingService.clearCache();
        }),
        catchError((err: any) => {
          if (err.error) {
            this.bookingService.redirectToStepWithError(err.error);
          }
          this.errorMessage = err.errorDescription;
          this.showError = true;
          throw err;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res: Appointment) => {
        this.setSuccessMessage(res);
        this.navigateService.navigate(['booking', 'success']);
      });
  }

  onConfirmClick() {
    if (this.showPayment() && !this.paymentSuccess) {
      this.showLoader = true;
      this.fullSteamControl.submitSaveCard();
      return;
    }
    this.confirmAppointment();
  }

  setSuccessMessage(res: Appointment) {
    if (+AppointmentState[res.appointmentState] === +AppointmentState.Accepted) {
      switch (this.bookingFlowState) {
        case 'reschedule':
          this.store.dispatch(
            new BookingActions.SetMessageOnSuccessStep({
              title: 'booking-success-title-reschedule-auto-accept',
              text: '',
            })
          );
          break;
        case 'auth':
          this.store.dispatch(
            new BookingActions.SetMessageOnSuccessStep({
              title: 'booking-success-title-logged-in',
              text: 'booking-success-info-logged-in',
            })
          );
          break;
        default:
          if (res.isMatchingError) {
            this.store.dispatch(new BookingActions.SetBookingConfirmationStatus(BookingConfirmationStatus.CreatedWithMatchingError));
          }
          if (res.hasPatientPortalAccount && !res.isMatchingError) {
            this.store.dispatch(new BookingActions.SetBookingConfirmationStatus(BookingConfirmationStatus.AutoAcceptedWithExistingUser));
          }
          if (!res.hasPatientPortalAccount && !res.isMatchingError) {
            this.store.dispatch(new BookingActions.SetBookingConfirmationStatus(BookingConfirmationStatus.AutoAcceptedWithNewUser));
          }
          this.store.dispatch(
            new BookingActions.SetMessageOnSuccessStep({
              title: 'booking-success-title-not-logged-in',
              text: 'booking-success-info-not-logged-in',
            })
          );
          break;
      }
    }

    if (+AppointmentState[res.appointmentState] === +AppointmentState.Created) {
      switch (this.bookingFlowState) {
        case 'reschedule':
          this.store.dispatch(
            new BookingActions.SetMessageOnSuccessStep({
              title: 'booking-success-title-reschedule-no-auto-accept',
              text: '',
            })
          );
          break;
        case 'auth':
          this.store.dispatch(
            new BookingActions.SetMessageOnSuccessStep({
              title: 'booking-success-title-logged-in',
              text: 'booking-success-info-logged-in-auto-accept-off',
            })
          );
          break;
        default:
          if (res.isMatchingError) {
            this.store.dispatch(new BookingActions.SetBookingConfirmationStatus(BookingConfirmationStatus.CreatedWithMatchingError));
          }
          if (res.hasPatientPortalAccount && !res.isMatchingError) {
            this.store.dispatch(new BookingActions.SetBookingConfirmationStatus(BookingConfirmationStatus.CreatedWithExistingUser));
          }
          if (!res.hasPatientPortalAccount && !res.isMatchingError) {
            this.store.dispatch(new BookingActions.SetBookingConfirmationStatus(BookingConfirmationStatus.CreatedWithNewUser));
          }
          this.store.dispatch(
            new BookingActions.SetMessageOnSuccessStep({
              title: 'booking-success-title-matching-error',
              text: 'booking-success-info-matching-error',
            })
          );
          break;
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  showPayment(): boolean {
    return this.service.enforceCancellation && this.organization.useCancellationPolicy;
  }

  fsError(event): void {
    this.showLoader = false;
    this.paymentSuccess = false;
    this.appointment.cardData = null;
    this.appointment.cardId = null;
  }

  fsSucces(event): void {
    this.paymentSuccess = true;
    this.appointment.cardId = event;
    this.confirmAppointment();
  }

  validationError(event): void {
    this.showLoader = false;
  }
}
