<div class="content-inner-deprecated pp-container">
  <div class="row wrap-section" *ngIf="message">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="box alert alert-info">
        <span class="wrap-icon-alert">
          <i class="material-icons info-icon">info</i>
        </span>
        {{ message | translate }}
      </div>
    </div>
  </div>
  <div class="top-title">
    {{ 'book-appointment' | translate }}
  </div>
  <div class="row wrap-section">
    <div class="col-xl-8 col-12 col-left order-2 md:order-1">
      <div class="box select-box select-clinic-box" *ngIf="!showLoader">
        <h3 class="subtitle">{{ 'title-select-service' | translate }}</h3>
        <div class="row row-md-radio service-row" *ngFor="let service of services" (click)="setService(service, true)">
          <div class="col-10 md-radio word-break-all">
            <div fxFlex="100%" fxLayout="row" fxLayoutAlign="start center">
              <input id="{{ service.id }}" type="radio" name="r" />
              <label for="{{ service.id }}">
                <span>
                  <strong>{{ service.name }}</strong>
                </span>
                <span class="small-text" *ngIf="service.description">
                  {{ service.description }}
                </span>
                <span
                  *ngIf="hasOrganizationCCPE && service.enforceCancellation"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  class="small-text cancelation-policy-text"
                >
                  <mat-icon class="cancelation-policy-icon" svgIcon="appointment_cancelled"> </mat-icon>
                  <span>{{
                    'BookingStep.Service.CancelationPolicy'
                      | translate: { cancellationFee: service?.cancellationFee, unitOfTime: organization?.cancellationPeriod }
                  }}</span>
                </span>
              </label>
            </div>
          </div>
          <div
            class="col-2 details-link details-time"
            fxLayout="row"
            fxLayout.lt-lg="column"
            fxLayoutAlign="center center"
            fxLayoutAlign.lt-lg="start start"
          >
            <i class="material-icons time-icon mr-1 py-2">access_time</i>
            <span class="small-text">{{ service.displayedDuration }} min</span>
          </div>
        </div>
      </div>

      <div class="box select-box select-clinic-box" *ngIf="showLoader">
        <h3 class="subtitle">{{ 'title-select-service' | translate }}</h3>
        <div class="row row-md-radio" *ngFor="let x of [0, 1, 2]">
          <div class="col-lg-10 col-sm-9 md-radio">
            <input id="{{ x }}" type="radio" name="r" />
            <label for="{{ x }}">
              <span class="line width-50"></span>
              <span class="line width-50"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-12 col-right order-1 md:order-2">
      <pp-booking-summary-box title="{{ 'appointment-summary' | translate }}"> </pp-booking-summary-box>
    </div>
  </div>
</div>
