import { ToastrService } from 'ngx-toastr';
import { Appointment } from './../../models/appointment';
import { AppointmentsFilterDirection, AppointmentsService } from './../../services/appointments.service';
import { Component, OnInit, Input } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../../booking/actions/index';
import { Store } from '@ngrx/store';
import { StartupService, NavigateWrapperService } from '../../../core/services';
import { OnboardingService } from '../../../onboarding/services';
import { ClinicService } from '../../../clinic/services';
import { ClinicLocation } from '../../../clinic/models';
import { tap, flatMap } from 'rxjs/operators';

@Component({
  selector: 'pp-appointments-list-page',
  templateUrl: 'appointments-list-page.component.html',
  styleUrls: ['appointments-list-page.component.scss'],
})
export class AppointmentsListPageComponent implements OnInit {
  upcomingList: Appointment[] = [];
  previousList: Appointment[] = [];
  currentList: Appointment[] = [];
  locations: ClinicLocation[] = [];
  acceptedPayments = [];
  selectedLocation: ClinicLocation;
  showMoreUpcomingBtn = true;
  showMorePreviousBtn = true;
  useOnlineBooking = true;
  showOnboardingAlert = false;
  loading = false;
  loadingUpcoming = true;
  loadingPast = true;
  canRescheduleAppointments = false;
  minHoursToCancelReschedule: number;

  skipFutureApptsCounter: number = 0;
  skipPastApptsCouner: number = 0;
  showLocationMap: boolean = false;

  private take = 6;

  onlineBookingDisabledMessage;

  constructor(
    private appointmentsService: AppointmentsService,
    private store: Store<fromRoot.State>,
    private toastr: ToastrService,
    private clinicService: ClinicService,
    private startupService: StartupService,
    private onboardingService: OnboardingService,
    public navigateService: NavigateWrapperService
  ) {
    this.useOnlineBooking = startupService.startupData.useOnlineBooking;

    startupService.startupData.acceptedPayments.forEach((payment) => {
      this.acceptedPayments.push({
        name: payment,
        src: 'assets/img/' + payment.toString().toLowerCase() + '@2x.png',
      });
    });

    this.onboardingService
      .getOnboardingForms()
      .pipe(flatMap((res) => this.onboardingService.progress()))
      .subscribe((progress: any) => {
        this.showOnboardingAlert = progress.completion < 100;
      });

    this.onlineBookingDisabledMessage = startupService.startupData.onlineBookingDisabledMessage;
  }

  ngOnInit(): void {
    this.onShowMoreUpcomingClick();
    this.onShowMorePreviousClick();
    this.store.dispatch(new BookingActions.ResetAllAction({}));

    this.clinicService.getLocations().subscribe(
      (res: ClinicLocation[]) => {
        this.locations = res;
        this.selectedLocation = this.locations[0];
      },
      (err) => {}
    );
  }

  onShowMoreUpcomingClick(): void {
    this.loading = true;

    const query = {
      Direction: AppointmentsFilterDirection.Future,
      Skip: this.skipFutureApptsCounter * this.take - 1,
      Take: this.take,
    };

    this.appointmentsService
      .getFutureAndPastAppointments(query.Direction, query.Take, query.Skip)
      .pipe(tap(() => (this.loading = this.loadingUpcoming = false)))
      .subscribe((list) => {
        this.skipFutureApptsCounter++;
        this.currentList = list.appointments || [];
        this.canRescheduleAppointments = list.canRescheduleAppointments;
        this.minHoursToCancelReschedule = list.minimumHoursToCancelReschedule;
        if (this.currentList.length < this.take) {
          this.showMoreUpcomingBtn = false;
        } else {
          this.currentList.pop();
        }
        this.upcomingList = [...this.upcomingList, ...this.currentList];
      });
  }

  onShowMorePreviousClick(): void {
    this.loading = true;

    const query = {
      Direction: AppointmentsFilterDirection.Past,
      Skip: this.skipPastApptsCouner * this.take - 1,
      Take: this.take,
    };

    this.appointmentsService
      .getFutureAndPastAppointments(query.Direction, query.Take, query.Skip)
      .pipe(tap(() => (this.loading = this.loadingPast = false)))
      .subscribe((list) => {
        this.skipPastApptsCouner++;
        list = list.appointments || [];
        if (list.length < this.take) {
          this.showMorePreviousBtn = false;
        } else {
          list.pop();
        }

        this.previousList = [...this.previousList, ...list];
      });
  }

  completeForms(): void {
    this.navigateService.navigate(['onboarding']);
  }

  onLocationChange(location): void {
    this.showLocationMap = false;
    this.selectedLocation = location;
  }

  navigateTo(route): void {
    this.navigateService.navigate([route]);
  }
}
