import { AppointmentsService } from './../../services/appointments.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthHelperService, NavigateWrapperService } from '../../../core/services';
import { AccountService } from '../../../account/services';
import { Common } from '../../../shared/models/Common';
import { first, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-appointment-verify-page',
  templateUrl: 'appointment-verify-page.component.html',
})
export class AppointmentVerifyPageComponent extends BaseComponent implements OnInit, OnDestroy {
  appointmetUid: string;
  token: string;
  form: FormGroup;
  error = '';
  loggedIn = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService,
    private appointmentsService: AppointmentsService,
    private authHelperService: AuthHelperService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
    this.route.params.pipe(first(), takeUntil(this.destroy$)).subscribe((params) => {
      this.appointmetUid = params['uid'];
    });
  }

  ngOnInit() {
    this.accountService.check(null, this.appointmetUid, null).subscribe(
      (res: any) => {
        if (res.hasAccount) {
          return this.navigateService.navigate(['login'], {
            returnUrl: this.route.snapshot.queryParams['returnUrl'],
            email: res.email,
          });
        } else {
          return this.navigateService.navigate(['register', this.appointmetUid, Common.RegisterType.Appointment], {
            returnUrl: this.route.snapshot.queryParams['returnUrl'],
            email: res.email,
          });
        }
      },
      (err) => {
        this.error = err.error;
        this.toastr.warning(this.translateService.instant(err.error));
      }
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
