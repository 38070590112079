import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Injectable()
export class NavigateWrapperService {

    private clinicSlug: string;
    private _skipRoutes = [];
    private _routes = [];
    public nextRoute: string = undefined;
    public backRoute: string = undefined;
    public forwardRoute: string = undefined;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.clinicSlug = this.location.path().split('/')[1];
        // console.log('NavigateWrapperService', this.clinicSlug);
    }

    /** Booking steps only.
     * Adds all booking routes, in their order, into an array. Helps to identify if back or forward button was pressed.
     * Adds all routes that are skipped, in their order, into an array.
     * Returns void.
     * **/
    public pushRoute(route: string, skip: boolean) {
        route = `/${this.clinicSlug}/` + route;
        if (route && this._routes.indexOf(route) < 0) {
            this._routes.push(route);
            if (skip) {
                this._skipRoutes.push(route);
            }
        }
    }

    /** Booking steps only.
     * Gets the next route to navigate to when Back is pressed.
     * @param currentRoute: the route from wich the navigation started.
     * @param nextRoute: the rpute to navigate to.
     * Returns void. Sets the next route for Back button to navigate to.
     */
    public redirectGoBack(currentRoute: string, nextRoute: string) {
        // console.log('redirectGoBack', currentRoute, nextRoute, this._skipRoutes);
        if (currentRoute && nextRoute) {
            if (this._skipRoutes.indexOf(nextRoute) !== -1) {
                const currentIndex = this._routes.indexOf(currentRoute);
                const nextIndex = this._routes.indexOf(nextRoute);
                if (currentIndex !== -1 && nextIndex !== -1) {
                    if (currentIndex > nextIndex) {
                        this.backRoute = this._routes[nextIndex - 2];
                        return this.redirectGoBack(this._routes[nextIndex], this._routes[nextIndex - 1]);
                    } else {
                        this.forwardRoute = this._routes[nextIndex + 2];
                        return this.redirectGoBack(this._routes[nextIndex], this._routes[nextIndex + 1]);
                    }
                }
            }
        }

        this.nextRoute = nextRoute ? nextRoute : currentRoute;
    }

    /**
     * Navigates to provided route.
     * @param route: The Route to navigate to.
     * Returns void.
     */
    public navigate(route: any[], queryParams: any = null, preserveQueryParams: boolean = false) {
        if (route) {

            // append clinic slug logic
            if (route.length === 1) {
                // fix when redirect from login
                if (route[0].indexOf(this.clinicSlug) === -1) {
                    route = route[0].split('/');
                    route.unshift(this.clinicSlug);
                }
            } else { route.unshift(this.clinicSlug); }

            const navigationExtras: NavigationExtras = {};

            if (queryParams) {
                navigationExtras.queryParams = queryParams;
                if (preserveQueryParams) {
                    navigationExtras.queryParamsHandling = 'preserve';
                }
            }

            this.router.navigate(route, navigationExtras);

        }
    }
}
