import { FormField } from './../../../shared/components/form-render/form-field.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { OnboardingService } from '../../services';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-additional-info-page',
  templateUrl: 'additional-info-page.component.html',
})
export class AdditionalInfoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  loaderRows = [0, 1, 2, 3, 4];

  fields1: FormField[] = [];
  fields2: FormField[] = [];
  fields3: FormField[] = [];
  form: FormGroup;

  private customFields = [];

  loggedIn = false;
  showLoader = true;
  saving = false;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      currentlySeeingMD: new FormControl(false),
    });

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.onboardingService
      .getAdditionalInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.customFields = res.customFields;

        this.fields1 = this.initFields1(res);
        this.fields2 = this.initFields2(res);
        this.fields3 = this.initFields3(res);

        this.form.get('currentlySeeingMD').setValue(res.currentlySeeingMD);

        const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
        if (contentContainer) {
          contentContainer.scrollIntoView();
        }

        this.showLoader = false;
      });

    this.form
      .get('currentlySeeingMD')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res) {
          if (this.form.get('conditionTreated')) {
            this.form.removeControl('conditionTreated');
          }
        }
      });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const isFormChangedAndFormNotCompleted = this.form.pristine;
      this.onboardingService.setFormSkip(isFormChangedAndFormNotCompleted);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initFields1(data) {
    const fields: FormField[] = [
      {
        key: 'hadAccupunctureBefore',
        label: 'additional-hadacupunctureBefore-label',
        type: FormFieldsEnum.CheckBox,
        value: data.hadAccupunctureBefore || false,
      },
      {
        key: 'hadChineseHerbalMedicineBefore',
        label: 'additional-hadChineseHerbalMedicineBefore-label',
        type: FormFieldsEnum.CheckBox,
        value: data.hadChineseHerbalMedicineBefore || false,
      },

      {
        key: 'notes',
        label: 'additional-notes-label',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(1000)],
        value: data.notes || '',
      },
    ];

    return fields;
  }

  initFields2(data) {
    const fields: FormField[] = [
      {
        key: 'conditionTreated',
        label: 'additional-conditionTreated-label',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(1000)],
        value: data.conditionTreated || '',
      },
    ];

    return fields;
  }

  initFields3(data) {
    const fields: FormField[] = [
      {
        key: 'therapyPrescribed',
        label: 'additional-therapyPrescribed-label',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(1000)],
        value: data.therapyPrescribed || '',
      },
    ];

    if (data.customFields) {
      for (const cf of data.customFields) {
        fields.push(this.mapFromCustomField(cf));
      }
    }

    return fields;
  }

  mapFromCustomField(cf) {
    const validators = [];
    if (cf.template.isRequired) {
      validators.push(Validators.required);
    }

    const field: FormField = {
      key: cf.template.displayName,
      label: cf.template.displayName,
      type: cf.template.type,
      value: cf.value || null,
      validators: validators,
    };

    if (field.type === FormFieldsEnum.DropdownSingleSelect || field.type === FormFieldsEnum.DropdownMultipleSelect) {
      const options = cf.template.customAttributes['options'];
      field.itemsList = [];
      for (const o of options) {
        field.itemsList.push({ value: o, label: o });
      }
    }

    return field;
  }

  mapToCustomField(customFieldId, formValue) {
    const cf: any = this.customFields.filter((f) => f.template.displayName === customFieldId)[0];

    if (cf) {
      return {
        id: cf.id,
        name: cf.template.displayName,
        templateId: cf.template.id,
        type: cf.template.type,
        value: !formValue || formValue.length === 0 ? (cf.template.type === FormFieldsEnum.CheckBox ? false : null) : formValue,
      };
    }
    return null;
  }

  onSubmit(redirect: boolean = true) {
    this.saving = true;
    const formValue = this.form.value;
    formValue.customFields = [];

    // tslint:disable-next-line:forin
    for (const key in formValue) {
      const customField = this.mapToCustomField(key, formValue[key]);
      if (customField) {
        formValue.customFields.push(customField);
      }
    }

    this.onboardingService.saveAdditionalInfo(formValue).subscribe((res) => {
      this.saving = false;
      this.onboardingService.updateSummary(Common.OnboardingSummaryForms.Additional);
      this.onboardingService.setFormSkip(true);
      if (redirect) {
        const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
        if (uncompleted) {
          this.navigateService.navigate([uncompleted.route], null, true);
        } else {
          this.navigateService.navigate(['onboarding/success'], null, true);
        }
      }
    });
  }
}
