<div class="box select-box select-clinic-box" *ngIf="!showLoader">
  <h3 class="subtitle d-none d-sm-block">{{ 'medical-information' | translate }}</h3>

  <form class="form-full-width" [formGroup]="form" novalidate>
    <!-- Medications -->
    <div class="row">
      <div class="col-lg-12 col-xs-12 form-row wrap-switch-row">
        <div class="col-lg-11 col-xs-11">
          <div class="switch-text-label">
            {{ 'medications' | translate }}
          </div>
        </div>
        <div class="col-lg-1 col-xs-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-showMedication"
              name="set-name"
              class="switch-input"
              formControlName="showMedication"
              name="showMedication"
            />
            <label for="switch-showMedication" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formArrayName="medications" [ngClass]="{ 'd-none': !form.get('showMedication').value }">
      <div *ngFor="let medication of getControls(form, 'medications'); let i = index">
        <div class="row" [formGroupName]="i">
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control"
              >{{ 'medication-name' | translate }}
              <i
                class="material-icons edit-btn pull-right"
                (click)="removeItem(i, 'medications')"
                *ngIf="getControls(form, 'medications').length > 1"
                >delete</i
              >
            </label>
            <input class="form-control" placeholder="{{ 'medication-name-placeholder' | translate }}" type="text" formControlName="name" />
          </div>
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control">{{ 'dosage' | translate }} </label>
            <input
              class="form-control"
              placeholder="{{ 'dosage-placeholder' | translate }}"
              type="text"
              formControlName="dosage"
              name="dosage"
            />
          </div>

          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control">{{ 'frequency-duration' | translate }} </label>
            <input
              class="form-control"
              placeholder="{{ 'frequency-duration-placeholder' | translate }}"
              type="text"
              formControlName="duration"
              name="duration"
            />
          </div>

          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'start-date' | translate }} </label>
            <input [matDatepicker]="startDate" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </div>

          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'discontinued-date' | translate }} </label>
            <input [matDatepicker]="discontinuedDate" formControlName="discontinuedDate" />
            <mat-datepicker-toggle matSuffix [for]="discontinuedDate"></mat-datepicker-toggle>
            <mat-datepicker #discontinuedDate></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <a href="javascript:void(0)" (click)="addMedication({})" class="onboarding-add-link">
            <i class="material-icons edit-btn">add</i>
            <span>{{ 'add-another' | translate }}</span>
          </a>
        </div>
      </div>
    </div>

    <!-- Vitamins -->
    <div class="row">
      <div class="col-lg-12 col-xs-12 form-row wrap-switch-row">
        <div class="col-lg-11 col-xs-11">
          <div class="switch-text-label">
            {{ 'Vitamins-Supplements' | translate }}
          </div>
        </div>
        <div class="col-lg-1 col-xs-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-showVitamins"
              name="set-name"
              class="switch-input"
              formControlName="showVitamins"
              name="showVitamins"
            />
            <label for="switch-showVitamins" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formArrayName="vitaminsSuplements" [ngClass]="{ 'd-none': !form.get('showVitamins').value }">
      <div *ngFor="let vitamin of getControls(form, 'vitaminsSuplements'); let i = index">
        <div class="row" [formGroupName]="i">
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control"
              >{{ 'vitamin-name' | translate }}
              <i
                class="material-icons edit-btn pull-right"
                (click)="removeItem(i, 'vitaminsSuplements')"
                *ngIf="getControls(form, 'vitaminsSuplements').length > 1"
                >delete</i
              >
            </label>
            <input class="form-control" placeholder="{{ 'vitamin-name-placeholder' | translate }}" type="text" formControlName="name" />
          </div>
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control">{{ 'dosage' | translate }}</label>
            <input class="form-control" placeholder="{{ 'dosage-placeholder' | translate }}" type="text" formControlName="dosage" />
          </div>
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control">{{ 'frequency-duration' | translate }} </label>
            <input
              class="form-control"
              placeholder="{{ 'frequency-duration-placeholder' | translate }}"
              type="text"
              formControlName="duration"
            />
          </div>

          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'start-date' | translate }} </label>
            <input [matDatepicker]="startDate" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </div>

          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'discontinued-date' | translate }} </label>
            <input [matDatepicker]="discontinuedDate" formControlName="discontinuedDate" />
            <mat-datepicker-toggle matSuffix [for]="discontinuedDate"></mat-datepicker-toggle>
            <mat-datepicker #discontinuedDate></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <a href="javascript:void(0)" (click)="addVitamins({})" class="onboarding-add-link">
            <i class="material-icons edit-btn">add</i>
            <span>{{ 'add-another' | translate }}</span>
          </a>
        </div>
      </div>
    </div>

    <!-- Allergies -->
    <div class="row">
      <div class="col-lg-12 col-xs-12 form-row wrap-switch-row">
        <div class="col-lg-11 col-xs-11">
          <div class="switch-text-label">
            {{ 'Allergies' | translate }}
          </div>
        </div>
        <div class="col-lg-1 col-xs-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-showAllergies"
              name="set-name"
              class="switch-input"
              formControlName="showAllergies"
              name="showAllergies"
            />
            <label for="switch-showAllergies" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formArrayName="allergies" [ngClass]="{ 'd-none': !form.get('showAllergies').value }">
      <div *ngFor="let a of getControls(form, 'allergies'); let i = index">
        <div class="row" [formGroupName]="i">
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control"
              >{{ 'Allergy' | translate }}
              <i
                class="material-icons edit-btn pull-right"
                (click)="removeItem(i, 'allergies')"
                *ngIf="getControls(form, 'allergies').length > 1"
                >delete</i
              >
            </label>
            <input class="form-control" placeholder="{{ 'Allergy-placeholder' | translate }}" type="text" formControlName="name" />
          </div>
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control">{{ 'Reaction' | translate }}</label>
            <input class="form-control" placeholder="{{ 'Reaction-placeholder' | translate }}" type="text" formControlName="reaction" />
          </div>
          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'start-date' | translate }} </label>
            <input [matDatepicker]="startDate" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </div>

          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'discontinued-date' | translate }} </label>
            <input [matDatepicker]="discontinuedDate" formControlName="discontinuedDate" />
            <mat-datepicker-toggle matSuffix [for]="discontinuedDate"></mat-datepicker-toggle>
            <mat-datepicker #discontinuedDate></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <a href="javascript:void(0)" (click)="addAllergies({})" class="onboarding-add-link">
            <i class="material-icons edit-btn">add</i>
            <span>{{ 'add-another' | translate }}</span>
          </a>
        </div>
      </div>
    </div>

    <!-- MedicalDevices -->
    <div class="row">
      <div class="col-lg-12 col-xs-12 form-row wrap-switch-row">
        <div class="col-lg-11 col-xs-11">
          <div class="switch-text-label">
            {{ 'Medical-Devices' | translate }}
          </div>
        </div>
        <div class="col-lg-1 col-xs-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-showMedicalDevices"
              name="set-name"
              class="switch-input"
              formControlName="showMedicalDevices"
              name="showMedicalDevices"
            />
            <label for="switch-showMedicalDevices" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formArrayName="medicalDevices" [ngClass]="{ 'd-none': !form.get('showMedicalDevices').value }">
      <div *ngFor="let medication of getControls(form, 'medicalDevices'); let i = index">
        <div class="row" [formGroupName]="i">
          <div class="col-lg-12 col-xs-12 form-row">
            <label class="label-form-control"
              >{{ 'Device' | translate }}
              <i
                class="material-icons edit-btn pull-right"
                (click)="removeItem(i, 'medicalDevices')"
                *ngIf="getControls(form, 'medicalDevices').length > 1"
                >delete</i
              >
            </label>
            <input class="form-control" placeholder="{{ 'Device-placeholder' | translate }}" type="text" formControlName="name" />
          </div>
          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'start-date' | translate }} </label>
            <input [matDatepicker]="startDate" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </div>

          <div class="col-lg-4 col-xs-12 form-row">
            <label class="label-form-control">{{ 'discontinued-date' | translate }} </label>
            <input [matDatepicker]="discontinuedDate" formControlName="discontinuedDate" />
            <mat-datepicker-toggle matSuffix [for]="discontinuedDate"></mat-datepicker-toggle>
            <mat-datepicker #discontinuedDate></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <i class="material-icons edit-btn">add</i>
          <a href="javascript:void(0)" (click)="addMedicalDevices({})">{{ 'add-another' | translate }} </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xs-12 form-row">
        <button type="submit" class="btn btn-primary" (valid)="onSubmit()" [disabled]="saving" [ppSubmitIfValid]="form">
          <i class="fa fa-spin fa-circle-o-notch" *ngIf="saving"></i>
          {{ 'save-and-continue' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<div class="box select-box select-clinic-box" *ngIf="showLoader">
  <h3 class="subtitle d-none d-sm-block">
    {{ 'medical-information' | translate }}
  </h3>
  <div class="row" *ngFor="let x of loaderRows" style="padding-bottom: 30px">
    <div class="col-lg-12 col-xs-12">
      <div class="line width-100"></div>
      <div class="line width-100"></div>
    </div>
  </div>
</div>
