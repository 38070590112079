import { Component, OnInit, HostListener, OnDestroy, Input, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OnboardingService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { flatMap, takeUntil, tap } from 'rxjs/operators';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { OnboardingFormsEnum } from '../../models';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-generic-form-page',
  templateUrl: 'generic-form-page.component.html',
})
export class GenericFormPageComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() formType: OnboardingFormsEnum;
  saving = false;
  loggedIn = false;
  form: FormGroup;
  formToComplete: any;
  uid: any;
  gridStackCssClass = '';
  classElement: HTMLElement = document.getElementById('onboardingForms');
  classElementHtml = '';
  mediaXL = `@media (min-width: 991px) { `;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (event.target.innerWidth < 991) {
      this.gridStackCssClass = 'grid-stack grid-stack-one-column-mode';
    } else {
      this.gridStackCssClass = 'grid-stack';
      if (this.formToComplete) {
        this.gridStackCssClass += ' grid-stack-' + this.formToComplete.totalColumns;
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
    if (contentContainer) {
      contentContainer.scrollIntoView();
    }

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.uid = params['uid'];

      const form = this.onboardingService.getForm(this.uid);

      this.parseData(form);

      this.form = this.fb.group({});
      if (window.innerWidth < 991) {
        this.gridStackCssClass = 'grid-stack grid-stack-one-column-mode';
      } else {
        this.gridStackCssClass = 'grid-stack';
        if (this.formToComplete) {
          this.gridStackCssClass += ' grid-stack-' + this.formToComplete.totalColumns;
        }
      }
    });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const isFormChangedAndFormNotCompleted = this.form.pristine;
      this.onboardingService.setFormSkip(isFormChangedAndFormNotCompleted);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  isEditableForm(): boolean {
    return (
      this.formType == OnboardingFormsEnum.Demographic ||
      this.formType == OnboardingFormsEnum.Additional ||
      this.formType == OnboardingFormsEnum.Contact ||
      this.formType == OnboardingFormsEnum.EmergencyContact ||
      this.formType == OnboardingFormsEnum.PrimaryPhysician ||
      this.formType == OnboardingFormsEnum.Medical ||
      this.formType == OnboardingFormsEnum.Insurance ||
      this.formType == OnboardingFormsEnum.MedicalForms ||
      this.formType == OnboardingFormsEnum.ScreeningForms ||
      this.formType == OnboardingFormsEnum.SignForms
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private parseData(form): void {
    if (form) {
      if (form.type === Common.FormType.MedicalForm || form.type === Common.FormType.ScreeningForm) {
        this.formToComplete = {
          uid: form.uid,
          name: form.name,
          type: form.type,
          width: form.width,
          height: form.sections ? this.getHeight(form.sections[0].controls, form.cellUnitHeight).toString() + 'px' : '0px',
          cellUnitHeight: form.cellUnitHeight,
          totalColumns: form.sections ? form.sections[0].columns : 1,
          controls: form.sections ? this.getControls(form.sections[0].controls, form.cellUnitHeight) : [],
          isCompleted: form.isCompleted,
          isSigned: form.isSigned,
        };
      }

      this.gridStackCssClass += ' grid-stack-' + this.formToComplete.totalColumns;
    }
  }

  private getControls(data, cellHeight) {
    const controls = [];

    if (data.length > 0) {
      let topXL = 0;
      const level = 0;
      let type: FormFieldsEnum;

      data.forEach((control) => {
        // get control type
        switch (control.type) {
          case Common.FormControlType.Heading:
            type = FormFieldsEnum.Heading;
            break;
          case Common.FormControlType.FreeText:
            type = FormFieldsEnum.FreeText;
            break;
          case Common.FormControlType.TextInput:
            type = FormFieldsEnum.TextInput;
            break;
          case Common.FormControlType.CustomCheckbox:
            type = FormFieldsEnum.CustomCheckbox;
            break;
          case Common.FormControlType.DateInput:
            type = FormFieldsEnum.DateInput;
            break;
          case Common.FormControlType.CheckboxList:
            type = FormFieldsEnum.CheckboxList;
            break;
          case Common.FormControlType.Slider:
            type = FormFieldsEnum.Slider;
            break;
          case Common.FormControlType.Signature:
            type = FormFieldsEnum.Signature;
            break;
          default:
            break;
        }

        // create the style with the heights for the current control
        topXL = control.y * cellHeight;
        this.mediaXL += ' #' + control.id + ' { top:' + topXL + 'px; } ';

        controls.push({
          key: control.id,
          type: type,
          id: control.id,
          value: control.value,
          width: control.width,
          height: control.height,
          widthPx: (control.width * cellHeight).toString() + 'px',
          heightPx: (control.height * cellHeight).toString() + 'px',
          coordX: control.x,
          coordY: control.y,
          settings: control.settings,
        });
      });

      this.mediaXL += ' } ';

      // add the style to the css stylesheet
      this.classElementHtml += this.mediaXL;
      this.classElement.innerHTML = this.classElementHtml;
    }

    return controls;
  }

  getHeight(data, cellHeight) {
    let value = 0;
    if (data.length > 0) {
      const lastCtrl = data[data.length - 1];
      // tslint:disable-next-line:radix
      value = (parseInt(lastCtrl.y) + parseInt(lastCtrl.height)) * cellHeight;
    }
    return value;
  }

  private parseFormValues(data) {
    const values = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const control = this.formToComplete.controls.find((c) => c.id === key);
        let type = '';

        switch (control.type) {
          case FormFieldsEnum.TextInput:
            type = Common.FormControlType.TextInput;
            break;
          case FormFieldsEnum.CustomCheckbox:
            type = Common.FormControlType.CustomCheckbox;
            break;
          case FormFieldsEnum.DateInput:
            type = Common.FormControlType.DateInput;
            break;
          case FormFieldsEnum.CheckboxList:
            type = Common.FormControlType.CheckboxList;
            break;
          case FormFieldsEnum.Slider:
            type = Common.FormControlType.Slider;
            break;
          case FormFieldsEnum.Signature:
            type = Common.FormControlType.Signature;
            break;
          default:
            break;
        }

        let objValue = value;
        if (value && value.hasOwnProperty('value')) {
          objValue = value.value;
        }

        if (control && type) {
          values.push({
            Id: key,
            Type: type,
            ValueWrapper: {
              Value: objValue,
            },
          });
        }
      }
    }

    return values;
  }

  private updateForm(uid, controlsValues): void {
    let form: any;
    if (this.formType === OnboardingFormsEnum.MedicalForms) {
      form = this.onboardingService.medicalForms.filter((mf) => mf.uid === this.uid)[0];
    } else {
      form = this.onboardingService.screeningForms.filter((mf) => mf.uid === this.uid)[0];
    }
    if (form && form.sections) {
      form.sections[0].controls.forEach((control) => {
        const controlValue = controlsValues.find((x) => x.Id === control.id);
        if (controlValue) {
          control.value = controlValue.ValueWrapper.Value;
        }
      });
    }
  }

  onSubmit(redirect: boolean = true): void {
    this.saving = true;
    if (this.uid && this.form.value) {
      const controlsValues = this.parseFormValues(this.form.value);
      if (this.form.valid) {
        this.onboardingService.saveMedicalForm(this.uid, { ControlsValues: controlsValues }).subscribe(
          () => {
            this.saving = false;
            if (this.formType === OnboardingFormsEnum.MedicalForms) {
              this.onboardingService.medicalForms.filter((mf) => mf.uid === this.uid)[0].isCompleted = true;
            } else {
              this.onboardingService.screeningForms.filter((mf) => mf.uid === this.uid)[0].isCompleted = true;
            }

            this.updateForm(this.uid, controlsValues);

            let nextForm = false;

            // get next incomplete form
            let form;
            if (this.formType === OnboardingFormsEnum.MedicalForms) {
              form = this.onboardingService.medicalForms.filter((mf) => !mf.isCompleted && !mf.isLegacy)[0];
            } else {
              form = this.onboardingService.screeningForms.filter((mf) => !mf.isCompleted && !mf.isLegacy)[0];
            }
            // get next completed form (authenticated flow)
            if (!form && this.loggedIn) {
              const forms =
                this.formType === OnboardingFormsEnum.MedicalForms
                  ? this.onboardingService.medicalForms.filter((mf) => !mf.isLegacy)
                  : this.onboardingService.screeningForms.filter((mf) => !mf.isLegacy);
              const index = forms.findIndex((f) => f.uid === this.uid);
              if (index < forms.length - 1) {
                form = forms[index + 1];
              }
            }

            // allow redirect to next form if it's not the last one
            if (
              form &&
              this.uid !==
                (this.formType === OnboardingFormsEnum.MedicalForms
                  ? this.onboardingService.medicalForms[this.onboardingService.medicalForms.length - 1].uid
                  : this.onboardingService.screeningForms[this.onboardingService.screeningForms.length - 1].uid)
            ) {
              nextForm = true;
            }

            // actual redirect

            this.onboardingService.setFormSkip(true);
            if (nextForm) {
              if (redirect) {
                this.navigateService.navigate(
                  ['onboarding', this.formType === OnboardingFormsEnum.MedicalForms ? 'medical-forms' : 'screening-forms', form.uid],
                  null,
                  true
                );
              }
              return;
            }
            this.onboardingService.updateSummary(
              this.formType === OnboardingFormsEnum.MedicalForms
                ? Common.OnboardingSummaryForms.MedicalForms
                : Common.OnboardingSummaryForms.ScreeningForms
            );
            // all forms are completed, move to next form
            const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
            if (redirect) {
              if (uncompleted) {
                this.navigateService.navigate([uncompleted.route], null, true);
              } else {
                this.navigateService.navigate(['onboarding', 'success']);
              }
            }
          },
          () => {
            this.saving = false;
          }
        );
      }
    }
  }
}
