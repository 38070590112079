import { Practitioner } from './../../clinic/models/practitioner';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ClinicService } from '../../clinic/services/index';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import * as fromRoot from './../../ngrx';
import { Observable } from 'rxjs';

@Injectable()
export class PractitionerStepResolver implements Resolve<Practitioner[]> {
  constructor(private store: Store<fromRoot.State>, private clinicService: ClinicService) {
    console.log('constructor PractitionerStepResolver');
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.clinicService.getPractitioners(null, null);
  }
}
