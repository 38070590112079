import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/booking.actions';
import { ActivatedRoute } from '@angular/router';
import { AuthHelperService, StartupService } from '../../../core/services';
import { Location } from '@angular/common';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';

@Component({
  selector: 'pp-previus-visit-step-page',
  templateUrl: 'previus-visit-step-page.component.html',
  styleUrls: ['previus-visit-step-page.component.scss'],
})
export class PreviusVisitStepPageComponent implements OnInit {
  isExistingPatient;
  useOnlineBooking = true;
  loginEnabled = false;

  onlineBookingDisabledMessage;
  redirectLoginUrl;

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private startupService: StartupService,
    private location: Location,
    private navigateService: NavigateWrapperService,
    public authHelperService: AuthHelperService
  ) {
    // save the location guid from query param, if exists
    this.store.dispatch(new BookingActions.SetQueryParamLocationAction(this.route.snapshot.params['locationGuid']));

    this.useOnlineBooking = startupService.startupData.useOnlineBooking;
    this.loginEnabled = this.startupService.startupData.usePatientPortal;

    this.onlineBookingDisabledMessage = startupService.startupData.onlineBookingDisabledMessage;

    this.navigateService.pushRoute('booking/previous-visit', false);
  }

  ngOnInit() {
    this.redirectLoginUrl = 'appointments';
  }

  setPreviousVisit(isExistingPatient) {
    if (this.useOnlineBooking) {
      this.store.dispatch(new BookingActions.SetExistingPatientAction(isExistingPatient));
      this.navigateService.navigate(['booking', 'location']);
    }
  }
}
