import {Component, OnDestroy,} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'pp-base',
  template: '',
})
export class BaseComponent implements OnDestroy {

  protected destroy$: Subject<void> = new Subject();

  constructor() { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
