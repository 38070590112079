import { CLINIC_COMPONENTS } from './components/index';
import { SharedModule } from './../shared/shared.module';
import { CLINIC_SERVICES } from './services/index';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { ClinicRoutingModule } from './clinic-routing.module';
import { AccountModule } from '../account/account.module';

@NgModule({
  imports: [
    SharedModule,
    AccountModule,
    ClinicRoutingModule
  ],
  declarations: [
    ...CLINIC_COMPONENTS
  ],
  providers: [
    ...CLINIC_SERVICES
  ],
  exports: [
    ...CLINIC_COMPONENTS
  ]
})

export class ClinicModule {
  constructor(@Optional() @SkipSelf() parentModule: ClinicModule) {
    if (parentModule) {
      throw new Error('ClinicModule already loaded; Import in root module only.');
    }
  }
}
