<pp-top-navbar></pp-top-navbar>

<div class="content-section-extraspace content-inner-deprecated">
  <div class="row wrap-section not-found-section">
    <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12 offset-sm-0 col-xs-12">
      <div class="box select-box">
        <h3 class="subtitle">
          {{ 'page-not-found-title' | translate }}
        </h3>
        <div class="row">
          <div class="col-12">
            <p class="description">{{ 'page-not-found-description' | translate }}</p>
          </div>
          <div class="col-12">
            <img src="assets/img/not-found.png" class="image-not-found" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
