import { CustomValidators } from './../../../shared/validators/custom-validators';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, NavigateWrapperService } from '../../../core/services';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../services';

import { tap, } from 'rxjs/operators';

@Component({
    selector: 'pp-reset-password-page',
    templateUrl: 'reset-password-page.component.html'
})

export class ResetPasswordPageComponent implements OnInit {

    resetForm: FormGroup;
    error = '';
    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private accountService: AccountService,
        private navigateService: NavigateWrapperService
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    onSubmit() {
        this.error = '';

        this.accountService.resetPassword(this.resetForm.value)
            .pipe(tap(() => this.initForm()))
            .subscribe(
                () => {
                    this.navigateService.navigate(['login']);
                }, error => this.error = error.error);
    }

    initForm() {
        this.resetForm = this.formBuilder.group({
            token: [this.route.snapshot.queryParams['t'], [Validators.required]],
            email: [this.route.snapshot.queryParams['e'], [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required]],
        }, { validator: CustomValidators.Match('password', 'confirmPassword') });
    }

}
