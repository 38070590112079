import { Routes } from '@angular/router';
// app
import { SampleComponent } from './components/sample/sample-page.component';

export const SampleRoutes: Routes = [
  {
    path: 'sample',
    component: SampleComponent
  },
];
