<div
  fxLayout.gt-sm="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-sm="center start"
  fxLayout="column"
  fxFlex="100%"
  fxLayoutGap="10px"
>
  <div>
    <mat-icon class="modal-icon" svgIcon="warning-red"></mat-icon>
  </div>
  <div fxFlex="100%" fxLayout="column">
    <div class="modal-title">
      {{ 'PatientArchivedByClinicTitle' | translate }}
    </div>
    <div class="mt-3">
      {{ 'PatientArchivedByClinicDescription' | translate }}
    </div>
    <div fxLayout="column" fxFlex="100%" fxLayoutGap.gt-sm="0" fxLayoutAlign="center start" class="mt-3 w-100">
      <div class="top-border contact-info">
        {{ 'Email' | translate }}: <a href="{{ 'mailto:' + startupService.startupData.email }}">{{ startupService.startupData.email }}</a>
      </div>
      <div class="top-border contact-info" [class.bottom-border]="!startupService.startupData.webAddresss">
        {{ 'Phone' | translate }}:
        <a href="{{ 'tel:' + startupService.startupData.phoneNumber }}">{{ startupService.startupData.phoneNumber }}</a>
      </div>
      <div class="top-border bottom-border contact-info" *ngIf="startupService.startupData.webAddresss">
        {{ 'Web' | translate }}:
        <a href="{{ startupService.startupData.webAddresss }}" target="_blank">{{ startupService.startupData.webAddresss }}</a>
      </div>
    </div>
  </div>
</div>
