import { NameListService } from './../../services/name-list.service';
import { NameList } from '../../actions';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';

import * as fromRoot from '../../../ngrx';

@Component({
  selector: 'pp-sample',
  templateUrl: './sample-page.component.html',
  styleUrls: ['./sample-page.component.scss']
})
export class SampleComponent implements OnInit {

  public env: any;

  inputName: string;
  names$: Observable<string[]>;

  constructor(
    private store: Store<fromRoot.State>,
    private nameListService: NameListService
  ) { }

  ngOnInit() {
    this.env = environment;

    // this.names$ = this.store.select(fromRoot.getNames);

    // this.nameListService.testEndpoint().subscribe((res) => console.log('test endpoint res', res));
  }

  addToCollection() {
    this.store.dispatch(new NameList.AddAction(this.inputName));
    this.inputName = '';
  }

}
