/**
 * Static data. Used for lists/dropdowns. Labels are translated.
 */
export class Common {

    static Relationships = [
        { value: 8, label: 'partner' },
        { value: 1, label: 'wife' },
        { value: 2, label: 'husband' },
        { value: 3, label: 'mother' },
        { value: 4, label: 'father' },
        { value: 5, label: 'brother' },
        { value: 6, label: 'sister' },
        { value: 9, label: 'friend' },
        { value: 10, label: 'other-family' },
        { value: 7, label: 'other' }
    ];

    static MaritalStatus = [
        { value: 1, label: 'single' },
        { value: 2, label: 'married' },
        { value: 3, label: 'other' }
    ];

    static EmploymentStatus =
        [
            { value: 1, label: 'employed' },
            { value: 2, label: 'full-time-student' },
            { value: 3, label: 'part-time-student' },
            { value: 4, label: 'unemployed' }
        ];

    static InsuranceRelationships = [
        { value: 1, label: 'spouse' },
        { value: 2, label: 'child' },
        { value: 3, label: 'other' }
    ];

    static FormControlType = {
        Heading: 'heading',
        FreeText: 'freeText',
        TextInput: 'input',
        DateInput: 'date',
        CustomCheckbox: 'customCheckbox',
        CheckboxList: 'checkboxList',
        Slider: 'numberSlider',
        File: 'file',
        Signature: 'signature'
    };

    static FormType = {
        MedicalForm: 0,
        ConsentForm: 1,
        ScreeningForm: 2
    };

    static OnboardingSummaryForms = {
        Personal: {
            id: 'Personal',
            name: 'patient-information',
            route: 'onboarding/patient-info',
        },
        Contact: {
            id: 'ContactInfo',
            name: 'contact-information',
            route: 'onboarding/contact-info',
        },
        EmergencyContact: {
            id: 'EmergencyContact',
            name: 'emergency-information',
            route: 'onboarding/emergency-contact-info',
        },
        PrimaryPhysician: {
            id: 'PrimaryPhysician',
            name: 'primary-physician-information',
            route: 'onboarding/primary-physician-info',
        },
        Medical: {
            id: 'MedicalInfo',
            name: 'medical-information',
            route: 'onboarding/medical-info',
        },
        Insurance: {
            id: 'Insurance',
            name: 'insurance-information',
            route: 'onboarding/insurance',
        },
        Additional: {
            id: 'Additional',
            name: 'additional-information',
            route: 'onboarding/additional-info',
        },
        MedicalForms: {
            id: 'MedicalForms',
            name: 'medical-forms',
            route: 'onboarding/medical-forms',
        },
        ConsentForms: {
            id: 'ConsentForms',
            name: 'consent-forms',
            route: 'onboarding/consent-forms',
        },
        ScreeningForms: {
          id: 'ScreeningForms',
          name: 'screening-forms',
          route: 'onboarding/screening-forms',
      },
        SignForms: {
            id: 'SignForms',
            name: 'signature-form',
            route: 'onboarding/signature',
        }
    };

    static RegisterType = {
        Appointment: 'a',
        Invite: 'i'
    };

}


