import { NoAuthGuard } from './../account/guards/no-auth.guard';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentsListPageComponent, AppointmentPageComponent, AppointmentVerifyPageComponent } from './components/index';
import { AuthGuard } from '../account/guards/index';
import { CanActivateAppointmentPageGuard } from './guards';
import { FormsResolver } from '../onboarding/services/forms.resolver';
import { AppointmentPickupComponent } from '../shared/components/appointment-pickup/appointment-pickup.component';

export const appointmentsRoutes: Routes = [
  {
    path: 'appointments',
    component: AppointmentsListPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'appointments/:uid',
    component: AppointmentPageComponent,
    canActivate: [CanActivateAppointmentPageGuard],
  },
  {
    path: 'appointments/pickup',
    component: AppointmentPageComponent,
    canActivate: [CanActivateAppointmentPageGuard],
  },
  {
    path: 'appointments/:uid/verify',
    component: AppointmentVerifyPageComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'pickup-appointment',
    component: AppointmentPickupComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(appointmentsRoutes)],
  exports: [RouterModule],
  providers: [FormsResolver],
})
export class AppointmentsRoutingModule {}
