// angular
import { Injectable, InjectionToken } from '@angular/core';

// libs
import { TranslateService } from '@ngx-translate/core';

// service
@Injectable()
export class MultilingualService {

    constructor(
        private translate: TranslateService
    ) {
        // // this language will be used as a fallback when a translation isn't found in the current language
        // this.translate.setDefaultLang('en');

        // // use browser/platform lang if available
        // const userLang = window.navigator.language.split('-')[0];

        // this.translate.use(userLang);
    }

    changeLang(lang) {
        this.translate.use(lang);
    }
}
