import { StartupService } from './../../../core/services/startup.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { Store } from '@ngrx/store';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { AccountService } from '../../../account/services';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { zip } from 'rxjs';
import { Guest } from '../../models';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { parseISO } from 'date-fns';

@Component({
  selector: 'pp-guest-step-page',
  templateUrl: 'guest-step-page.component.html',
})
export class GuestStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  guest: FormGroup;

  filledData: Guest;
  isExistingPatient: false;
  fields: FormField[] = [];

  constructor(
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private accountServie: AccountService,
    private navigateService: NavigateWrapperService,
    private startupService: StartupService
  ) {
    super();
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.guest = this.formBuilder.group({
      termsAgreed: new FormControl(false, [Validators.requiredTrue]),
      email: new FormControl('', {
        validators: [Validators.required, CustomValidators.email],
        updateOn: 'blur',
      }),
    });

    zip(this.store.select(fromRoot.getBookingAccount), this.store.select(fromRoot.getBookingExistingPatient))
      .pipe(takeUntil(this.destroy$))
      .subscribe((arr) => {
        this.filledData = arr[0] || {};
        this.isExistingPatient = arr[1];

        this.fields = this.initFields(this.filledData);
        this.guest.get('email').setValue(this.filledData.email || '');
      });
  }

  initFields(data) {
    const fields: FormField[] = [
      {
        key: 'firstName',
        label: 'first-name',
        planceholder: 'first-name-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.required, Validators.maxLength(300)],
        value: data.firstName || '',
      },
      {
        key: 'lastName',
        label: 'last-name',
        planceholder: 'last-name-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.required, Validators.maxLength(300)],
        value: data.lastName || '',
      },
      {
        key: 'phone',
        label: 'phone-number',
        type: FormFieldsEnum.Phone,
        validators: [Validators.required],
        value:
          data.phoneNumber && data.phoneType
            ? {
                number: data.phoneNumber,
                phoneType: data.phoneType,
              }
            : null,
      },
      {
        key: 'dateOfBirth',
        label: 'date-of-birth',
        type: FormFieldsEnum.DatePicker,
        validators: [Validators.required],
        value: data.dateOfBirth ? parseISO(data.dateOfBirth) : null,
      },
      {
        key: 'genderObj',
        label: 'gender',
        type: FormFieldsEnum.Gender,
        validators: [Validators.required],
        value: {
          gender: data.gender || '',
          otherGender: data.otherGender,
        },
      },
    ];

    return fields;
  }

  onCreateAccountClick() {
    if (!this.guest.valid) {
      return;
    }

    const account: Guest = this.guest.value;
    account.otherGender = this.guest.value.genderObj.otherGender;
    account.gender = this.guest.value.genderObj.gender;
    account.phoneNumber = this.guest.value.phone.number;
    account.phoneType = this.guest.value.phone.phoneType;
    delete this.guest.value.genderObj;
    delete this.guest.value.phone;

    this.store.dispatch(new BookingActions.SetAccount(account));

    if (this.isExistingPatient || !this.clinicAcceptsInsurance()) {
      return this.navigateService.navigate(['booking', 'review']);
    } else {
      return this.navigateService.navigate(['booking', 'insurance-type']);
    }
  }

  clinicAcceptsInsurance() {
    return (
      this.startupService.startupData.acceptsInsurance &&
      (this.startupService.startupData.acceptsMajorInsurance ||
        this.startupService.startupData.acceptsAutoAccident ||
        this.startupService.startupData.acceptsWorkerCompensation)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
