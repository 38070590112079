import { Component, OnInit } from '@angular/core';
import { OnboardingFormsEnum } from '../../models';

@Component({
    selector: 'pp-screening-form-page',
    templateUrl: 'screening-form-page.component.html'
})

export class ScreeningFormPageComponent implements OnInit {
  public get formTypes() {
    return OnboardingFormsEnum;
  }

  ngOnInit() {}

}
