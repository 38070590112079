<div
  *ngIf="avatarUrl; else noAvatar"
  class="bg-no-repeat bg-contain bg-center rounded-full"
  [style.background-image]="'url(' + mediaBaseUrl + '/' + avatarUrl + ')'"
  [style.width]="size + 'px'"
  [style.height]="size + 'px'"
  [style.min-height]="size + 'px'"
  [style.min-width]="size + 'px'"
></div>

<ng-template #noAvatar>
  <div [style.width]="size + 'px'" [style.min-width]="size + 'px'">
    <img class="rounded-full" src="assets/img/avatar-images/default.png" alt="" />
  </div>
</ng-template>
