import { BookingDay } from './../../models/booking-day';
import { BookingService } from './../../services/booking.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { Store } from '@ngrx/store';
import { ClinicService } from '../../../clinic/services/index';
import { startOfDay, addDays, isBefore, isEqual, isSameMonth, isSameYear, isSameDay } from 'date-fns';
import { AuthHelperService, StartupService } from '../../../core/services/index';
import { Location } from '@angular/common';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { of, zip } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pp-timeslot-step-page',
  templateUrl: 'timeslot-step-page.component.html',
  styleUrls: ['timeslot-step-page.component.scss'],
})
export class TimeslotStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  bookingDays: BookingDay[] = [];
  selectedTimeSlot;

  locationGuid;
  serviceId;
  practitionerId;
  isNewPatient;
  rescheduledAppointmentUid;
  startDate: Date;
  endDate: Date;
  today: Date;

  showLoader = true;
  loggedIn = false;
  loginEnabled = false;
  errorMessage: string;
  preferredPractitionerOption: 'FeelingLucky' | 'Practitioner' | 'Intern';
  loadingSlotId: number | null;
  loadingDayIndex: number | null;

  constructor(
    private store: Store<fromRoot.State>,
    private clinicService: ClinicService,
    private bookingService: BookingService,
    private authHelperService: AuthHelperService,
    private location: Location,
    private navigateService: NavigateWrapperService,
    private startupService: StartupService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.navigateService.pushRoute('booking/timeslot', false);
    this.loginEnabled = this.startupService.startupData.usePatientPortal;
  }

  ngOnInit() {
    this.checkErrors();
    this.today = new Date();
    this.startDate = this.today;
    // first week from today to Saturday
    const saturdayDiff = 6 - (this.today.getDay() % 7);
    this.endDate = startOfDay(addDays(this.startDate, saturdayDiff));

    zip(
      this.store.select(fromRoot.getBookingLocation),
      this.store.select(fromRoot.getBookingService),
      this.store.select(fromRoot.getBookingPractitioner),
      this.store.select(fromRoot.getBookingExistingPatient),
      this.store.select(fromRoot.getBookingRescheduledAppointmentUid),
      this.store.select(fromRoot.getPreferredPractitionerOptions)
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((apt) => {
        this.locationGuid = apt[0].guid;
        this.serviceId = apt[1].id;
        this.practitionerId = apt[2].id;
        this.isNewPatient = !apt[3];
        this.rescheduledAppointmentUid = apt[4];
        this.preferredPractitionerOption = apt[5] as 'FeelingLucky' | 'Practitioner' | 'Intern';
        this.getAvailabilities();
      });

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.store
      .select(fromRoot.getBookingDateTime)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.selectedTimeSlot = val;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  checkErrors(): void {
    if (this.activatedRoute.snapshot.queryParams.error) {
      this.errorMessage = this.activatedRoute.snapshot.queryParams.error;
    }
  }

  onNextWeekClick() {
    if (this.showLoader) {
      return;
    }
    this.startDate = startOfDay(addDays(this.endDate, 1));
    this.endDate = startOfDay(addDays(this.endDate, 7));
    this.getAvailabilities();
  }

  onPreviousWeekClick() {
    if (this.showLoader || isEqual(this.startDate, this.today)) {
      return;
    }
    this.startDate = startOfDay(addDays(this.startDate, -7));

    if (isBefore(this.startDate, this.today)) {
      this.startDate = this.today;
    }

    this.endDate = startOfDay(addDays(this.endDate, -7));

    this.getAvailabilities();
  }

  isPreviousDisabled() {
    return this.startDate === this.today;
  }

  getAvailabilities() {
    this.bookingDays = [];
    this.showLoader = true;
    this.store.dispatch(new BookingActions.SetTimeslotAction(null));
    return this.bookingService
      .getAvailableDates(
        this.locationGuid,
        this.serviceId,
        this.practitionerId,
        this.isNewPatient,
        this.startDate,
        this.endDate,
        this.rescheduledAppointmentUid,
        this.preferredPractitionerOption
      )
      .pipe(
        tap(() => {
          this.showLoader = false;
          const contentContainer = document.querySelector('pp-booking-summary-box > div > form > div.summary-practitioner');
          if (contentContainer) {
            contentContainer.scrollIntoView();
          }
        }),
        catchError((err: any) => {
          if (err.error) {
            this.bookingService.redirectToStepWithError(err.error);
          }
          throw err;
        })
      )
      .subscribe((days) => {
        this.bookingDays = days;
      });
  }

  setTimeslot(timeslot, index: number, dayIndex: number) {
    if (this.loadingSlotId) {
      return;
    }
    this.loadingSlotId = index;
    this.loadingDayIndex = dayIndex;
    this.store
      .select(fromRoot.getBookingPractitioner)
      .pipe(
        first(),
        switchMap((practitioner) => {
          if (!practitioner?.id && timeslot.practitionerId) {
            // I don't have a preference was selected
            return this.clinicService.getPractitionersByIds([timeslot.practitionerId]).pipe(map((practitioners) => practitioners[0]));
          }
          return of(practitioner);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((practitioner) => {
        this.loadingSlotId = null;
        this.loadingDayIndex = null;
        this.store.dispatch(new BookingActions.SetPractitionerAction(practitioner));
        this.store.dispatch(new BookingActions.SetTimeslotAction(timeslot));
        this.onContinueClick();
      });
  }

  onContinueClick() {
    if (this.loggedIn || this.rescheduledAppointmentUid) {
      this.navigateService.navigate(['booking', 'review']);
    } else {
      if (this.loginEnabled) {
        this.navigateService.navigate(['booking', 'auth']);
      } else {
        this.navigateService.navigate(['booking', 'register']);
      }
    }
  }

  isSameDate(date, compareDate) {
    return isSameMonth(date, compareDate) && isSameDay(date, compareDate) && isSameYear(date, compareDate);
  }
}
