import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { FullSteamAuthenticationModel, FullSteamAuthenticationResponse, CardProfile } from '@unifiedpractice/up-ng-fullsteam';
import { AuthHelperService } from './auth-helper.service';

@Injectable({
  providedIn: 'root',
})
export class FullSteamIntegrationClient {
  constructor(private http: HttpClient, private auth: AuthHelperService) {}

  getFullSteamAuthenticationKey(authenticationModel: FullSteamAuthenticationModel): Observable<FullSteamAuthenticationResponse> {
    let params: HttpParams = new HttpParams()
      .set('OperationType', authenticationModel.operationType.toString())
      .set('PerformCaptcha', authenticationModel.performCaptcha.toString());

    if (authenticationModel.amount) {
      params = params.set('Amount', authenticationModel.amount.toString());
    }

    return this.http.get(`${environment.apiUrl}/FullSteam/authenticationkey`, { params }) as Observable<FullSteamAuthenticationResponse>;
  }

  fullSteamSaveCard(request: string): Observable<{ data: number; messages: [] }> {
    if (!this.auth.token) {
      return of({ data: 0, messages: [] }) as Observable<{ data: number; messages: [] }>;
    }
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `${this.auth.token.token_type} ${this.auth.token.access_token}`,
      'Content-Type': 'application/json',
    });

    return this.http.post<{ data: number; messages: [] }>(`${environment.apiUrl}/FullSteam/savecard`, request, {
      headers,
      withCredentials: true,
    });
  }

  getCards(): Observable<CardProfile[]> {
    if (!this.auth.token) {
      return of([]);
    }
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `${this.auth.token.token_type} ${this.auth.token.access_token}`,
      'Content-Type': 'application/json',
    });

    return this.http.get(`${environment.apiUrl}/Account/cards`, { headers, withCredentials: true }) as Observable<CardProfile[]>;
  }
}
