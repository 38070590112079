import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, forwardRef, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { BaseComponent } from '../base/base.component';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-date-input',
  templateUrl: 'date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled = false;

  dateFormControl: FormControl = new FormControl();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.dateFormControl.valueChanges
      .pipe(
        filter((date) => !!date),
        takeUntil(this.destroy$)
      )
      .subscribe((date) => {
        this.onChange(date);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disabled?.currentValue !== undefined) {
      this.setDisabledState(this.disabled);
    }
  }

  writeValue(obj: any) {
    if (obj) {
      if (typeof obj === 'string') {
        this.dateFormControl.setValue(parseISO(obj), { emitEvent: false });
      } else {
        this.dateFormControl.setValue(obj, { emitEvent: false });
      }
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  onChange(date: Date): void {
    this.propagateChange(format(date, 'yyyy-MM-dd'));
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateFormControl.disable({ emitEvent: false });
      return;
    }
    this.dateFormControl.enable({ emitEvent: false });
  }

  private propagateChange = (_: any) => {};
}
