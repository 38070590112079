import { Injectable } from '@angular/core';
import { ClinicService } from '../../clinic/services/index';
import { Resolve } from '@angular/router';

@Injectable()
export class ClinicStepResolver implements Resolve<any> {
    constructor(private clinicService: ClinicService) { }

    resolve() {
        return this.clinicService.getLocations();
    }
}
