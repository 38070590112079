import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnboardingService } from '../../services';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-primary-physician-info-page',
  templateUrl: 'primary-physician-info-page.component.html',
})
export class PrimaryPhysicianInfoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  loaderRows = [0, 1, 2, 3, 4];

  fields: FormField[] = [];
  form: FormGroup;
  loggedIn = false;
  showLoader = true;
  saving = false;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({});
    this.fields = this.initFields({});

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.onboardingService
      .getPrimaryPhisicianInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.fields = this.initFields(res);
        this.showLoader = false;
        const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
        if (contentContainer) {
          contentContainer.scrollIntoView();
        }
      });
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onboardingService.setFormSkip(this.form.pristine);
      this.onboardingService.formValid.next(this.form.valid);
    });
    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initFields(data) {
    const fields: FormField[] = [
      {
        key: 'name',
        label: 'psysician-name',
        type: FormFieldsEnum.TextBox,
        value: data.name || '',
      },
      {
        key: 'phone',
        label: 'phone-number',
        planceholder: 'phone-eg',
        type: FormFieldsEnum.TextBox,
        value: data.phone || '',
        validators: [CustomValidators.phone, Validators.maxLength(20)],
      },
      {
        key: 'specialty',
        label: 'specialty',
        type: FormFieldsEnum.TextBox,
        value: data.specialty || '',
      },
      {
        key: 'currentTreatmentPlan',
        label: 'current-treatment-plan',
        type: FormFieldsEnum.TextBox,
        value: data.currentTreatmentPlan || '',
      },
    ];
    return fields;
  }

  onSubmit(redirect: boolean = true) {
    this.saving = true;
    this.onboardingService.savePrimaryPhisicianInfo(this.form.value).subscribe((res) => {
      this.saving = false;
      this.onboardingService.updateSummary(Common.OnboardingSummaryForms.PrimaryPhysician);
      this.onboardingService.setFormSkip(true);
      if (redirect) {
        const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
        if (uncompleted) {
          this.navigateService.navigate([uncompleted.route], null, true);
        } else {
          this.navigateService.navigate(['onboarding/success'], null, true);
        }
      }
    });
  }
}
