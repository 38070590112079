<div class="box select-box select-clinic-box"
  *ngIf="!showLoader">
  <h3 class="subtitle d-none d-sm-block">
    {{'patient-information'
    | translate}}</h3>

  <form class="form-full-width" [formGroup]="form"
    novalidate>
    <div class="row">
      <pp-form-render [formFields]="fields" [form]="form">
      </pp-form-render>
    </div>
  </form>

  <div class="row">
    <div class="col-lg-12 col-xs-12 form-row">
      <button class="btn btn-primary" type="submit"
        (valid)="onSubmit()" [disabled]="saving"
        [ppSubmitIfValid]="form">
        <i class="fa fa-spin fa-circle-o-notch"
          *ngIf="saving"></i>
        {{ 'save-and-continue' | translate }}</button>
    </div>
  </div>

</div>


<div class="box select-box select-clinic-box"
  *ngIf="showLoader">
  <h3 class="subtitle d-none d-sm-block">
    {{'patient-information'
    |
    translate}}</h3>
  <div class="row" *ngFor="let x of loaderRows"
    style="padding-bottom: 30px">
    <div class="col-lg-12 col-xs-12 ">
      <div class="line width-100"></div>
      <div class="line width-100"></div>
    </div>
  </div>
</div>
