<div class="content-inner-deprecated">
  <div class="row wrap-section">
    <div
      class="col-xl-4 offset-xl-4 col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 col-xs-12">

      <div class="box select-box" *ngIf="false">
        <h3 class="subtitle">Verify</h3>
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            *ngIf="error && error.length > 0">
            <div class="box message message-danger"
              role="alert">
              <span class="wrap-icon-alert">
                <i class="material-icons info-icon">info</i>
              </span>
              {{ error | translate }}
            </div>
          </div>
        </div>
        <form [formGroup]="form">
          <div class="row">
            <div class="col-lg-12 col-xs-12 form-row">
              <label
                class="label-form-control">Email</label>
              <input class="form-control inp" value=""
                placeholder="name@company.com" type="text"
                formControlName="email" tabindex="1" />
            </div>

            <div class="col-lg-12 col-xs-12 form-row">
              <button class="btn btn-primary btn-login"
                type="submit" [disabled]="!form.valid"
                tabindex="3">Verify</button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>
