import { ClinicService } from '../../clinic/services/clinic.service';
import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import * as fromRoot from '../../ngrx';
import { Store } from '@ngrx/store';
import { NavigateWrapperService } from '../../core/services';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CanActivateIfTimeslotGuard implements CanActivate, OnDestroy {
  private timeslot;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private clinicService: ClinicService,
    private navigateService: NavigateWrapperService
  ) {
    this.store
      .select(fromRoot.getBookingDateTime)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => (this.timeslot = val));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.timeslot) {
      return true;
    }

    // go to a previous step
    this.navigateService.navigate(['booking', 'timeslot']);

    return false;
  }
}
