<div class="form-group">

	<form>
    <span class="h5">
          Change Language:
    </span>
		<select [(ngModel)]="lang" class="form-control" (change)="changeLang($event)" name="lang">
			<option *ngFor="let l of supportedLanguages" [value]="l.code">{{l.title}}</option>
		</select>
	</form>

</div>
