import { Component, OnInit } from '@angular/core';
import { OnboardingFormsEnum } from '../../models';

@Component({
    selector: 'pp-screening-forms-list-page',
    templateUrl: 'screening-forms-list-page.component.html'
})

export class ScreeningFormsListPageComponent implements OnInit {
  public get formTypes() {
    return OnboardingFormsEnum;
  }

    ngOnInit() { }

}
