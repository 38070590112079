import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  OnboardingContainerPageComponent,
  MedicalFormsListPageComponent,
  MedicalFormPageComponent,
  ConsentFormsListPageComponent,
  ConsentFormPageComponent,
  InsurancePageComponent,
  MedicalInfoPageComponent,
  PrimaryPhysicianInfoPageComponent,
  EmergencyContactInfoPageComponent,
  AddressContactInfoPageComponent,
  AdditionalInfoPageComponent,
  PatientInfoPageComponent,
  SignaturePageComponent,
  SuccessPageComponent,
  ScreeningFormPageComponent,
  ScreeningFormsListPageComponent,
} from './components';

// Resolvers
import { StatesResolver } from './services/states.resolver';
import { FormsResolver } from './services/forms.resolver';
import { ProgressResolver } from './services/progress.resolver';

const children = [
  { path: 'patient-info', component: PatientInfoPageComponent },
  { path: 'additional-info', component: AdditionalInfoPageComponent },
  { path: 'contact-info', component: AddressContactInfoPageComponent },
  { path: 'emergency-contact-info', component: EmergencyContactInfoPageComponent },
  { path: 'primary-physician-info', component: PrimaryPhysicianInfoPageComponent },
  { path: 'medical-info', component: MedicalInfoPageComponent },
  { path: 'insurance', component: InsurancePageComponent, resolve: { states: StatesResolver } },
  { path: 'medical-forms', component: MedicalFormsListPageComponent, resolve: { forms: FormsResolver } },
  { path: 'medical-forms/:uid', component: MedicalFormPageComponent, resolve: { forms: FormsResolver } },
  { path: 'screening-forms', component: ScreeningFormsListPageComponent, resolve: { forms: FormsResolver } },
  { path: 'screening-forms/:uid', component: ScreeningFormPageComponent, resolve: { forms: FormsResolver } },
  { path: 'consent-forms', component: ConsentFormsListPageComponent, resolve: { forms: FormsResolver } },
  { path: 'consent-forms/:uid', component: ConsentFormPageComponent, resolve: { forms: FormsResolver } },
  { path: 'signature', component: SignaturePageComponent, resolve: { states: StatesResolver, forms: FormsResolver } },
  { path: 'success', component: SuccessPageComponent },
  { path: '', component: SuccessPageComponent },
];

export const onboardingRoutes: Routes = [
  {
    path: 'onboarding',
    component: OnboardingContainerPageComponent,
    resolve: { forms: FormsResolver, progress: ProgressResolver },
    children: children,
  },
];

@NgModule({
  imports: [
    // RouterModule.forChild(onboardingRoutes)
  ],
  exports: [RouterModule],
  providers: [StatesResolver, FormsResolver, ProgressResolver],
})
export class OnboardingRoutingModule {}
