// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  envName: 'staging:release',
  apiUrl: 'https://pp.api.release.staging.unifiedpractice.com/t',
  resourceUrl: 'https://pp.release.staging.unifiedpractice.com',
  googleMapsApiKey: 'AIzaSyD8ilfdA1t8O2gvq7ao_nseFS1bjuzytoQ',
  // defaultPingInterval: 60, // seconds
  defaultSessionTime: 30, // minutes
  defaultWarningTime: 1, // minutes - time to display keep-me-logged-in pop before autologout
  matomoAnalitycsId: 8,
  jqueryJSLibrary: 'https://code.jquery.com/jquery-3.5.1.min.js',
  fullSteamJSLibrary: 'https://hostedpayments-ext.fullsteampay.net/js/hostedcontrols/2.0.0/fullsteam.hostedcontrols.js',
  pendoApiKey: 'd1e5cf02-09bb-44f6-5315-8b7d5addca78',
  streamApiKey: 'w4ebbavd2z97',
};
