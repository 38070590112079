import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';

@Component({
    selector: 'pp-work-insurance-step-page',
    templateUrl: 'work-insurance-step-page.component.html'
})

export class WorkInsuranceStepPageComponent implements OnInit {

    fields: FormField[] = [];
    insuredInfoField: FormField[] = [];
    insuredInfo: FormGroup;
    workAccidentInsurance: FormGroup;

    constructor(
        private cdr: ChangeDetectorRef,
        private store: Store<fromRoot.State>,
        private formBuilder: FormBuilder,
        public navigateService: NavigateWrapperService
    ) { }

    ngOnInit() {
        this.insuredInfo = this.formBuilder.group({});
        this.workAccidentInsurance = this.formBuilder.group({
            insuranceIsCoveredByOther: new FormControl(false),
            insuredInfo: this.insuredInfo
        });

        this.fields = [
            {
                key: 'companyName',
                label: 'insName',
                planceholder: 'insName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            },
            {
                key: 'companyPhone',
                label: 'insPhone',
                planceholder: 'insPhone-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300), CustomValidators.phone],
            }
            , {
                key: 'companyAddress',
                label: 'insAddress',
                planceholder: 'insAddress-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'injuryDate',
                label: 'dateOfInjury',
                type: FormFieldsEnum.DatePicker,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'caseNo',
                label: 'caseClaimNo',
                planceholder: 'caseClaimNo-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'employerName',
                label: 'empName',
                planceholder: 'empName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(500)],
            }
            , {
                key: 'employerAddress',
                label: 'empAddress',
                planceholder: 'empAddress-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(500)],
            }
        ];

        this.insuredInfoField = [
            {
                key: 'patientRelationtionshipToInsuredType',
                label: 'patientRelationtionshipToInsuredType',
                type: FormFieldsEnum.DropdownSingleSelect,
                itemsList: Common.InsuranceRelationships
            }
            , {
                key: 'firstName',
                label: 'insuredInfoFirstName',
                planceholder: 'insuredInfoFirstName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300), Validators.required],
            }
            , {
                key: 'middleName',
                label: 'insuredInfoMiddleName',
                planceholder: 'insuredInfoMiddleName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300)],
            }
            , {
                key: 'lastName',
                label: 'insuredInfoLastName',
                planceholder: 'insuredInfoLastName-placeholder',
                type: FormFieldsEnum.TextBox,
                validators: [Validators.maxLength(300), Validators.required],
            }
            , {
                key: 'dateOfBirth',
                label: 'insuredInfoDateOfBirth',
                type: FormFieldsEnum.DatePicker,
            }
        ];

        this.updateCoveredInsurance();

        // scroll top
        window.scrollTo(0, 0);
    }

    updateCoveredInsurance() {

        if (!this.workAccidentInsurance.value.insuranceIsCoveredByOther) {
            this.workAccidentInsurance.get('insuredInfo').disable();
        } else {
            this.workAccidentInsurance.get('insuredInfo').enable();
        }

        this.cdr.detectChanges();
    }

    onContinueClick() {
        // console.log(this.workAccidentInsurance.value);
        this.store.dispatch(new BookingActions.SetWorkInsurance(this.workAccidentInsurance.value));
        this.navigateService.navigate(['booking', 'review']);

    }
}
