import { GenderEnum } from './../../models/gender.enum';
import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator } from '@angular/forms';
import { Component, OnInit, forwardRef } from '@angular/core';

@Component({
    selector: 'pp-gender-input',
    templateUrl: 'gender-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => GenderInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => GenderInputComponent),
            multi: true,
        }
    ]
})
export class GenderInputComponent implements ControlValueAccessor, Validator {

    disabled = false;

    gender = undefined;
    otherGender = undefined;

    // Function to call when the input is touched (when a star is clicked).
    onTouched = () => { };

    constructor() { }

    writeValue(obj: any): void {
        if (obj) {
            this.gender = obj.gender;
            this.otherGender = obj.otherGender;
        }
    }
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    // validates the form, returns null when valid else the validation object
    // in this case we're checking if the date parsing has passed or failed from the onChange method
    public validate(c: FormControl) {

        if (!this.gender) {
            return {
                gender: {
                    valid: false,
                }
            };
        }

        return null;
    }

    change() {
        // Angular does not know that the value has changed
        // from our component, so we need to update her with the new value.

        if (!this.gender) {
            this.propagateChange(null);
            return;
        }

        this.propagateChange({
            gender: this.gender,
            otherGender: this.gender === GenderEnum.Other ? this.otherGender : undefined
        });
    }


    // the method set in registerOnChange to emit changes back to the form
    // tslint:disable-next-line:no-empty
    private propagateChange = (_: any) => { };

}
