import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Common } from '../../../shared/models/Common';
import { Observable } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { parseISO } from 'date-fns';

@Component({
  selector: 'pp-insurance-form',
  templateUrl: 'insurance-form.component.html',
})
export class InsuranceFormComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() insuranceCompanies = [];
  @Input() states = [];
  @Input() filledData;
  @Input() form: FormGroup;
  @Input() fields: FormField[];
  @Input() showInsuranceAutocomplete = true;

  relationships: { value: number; label: string }[];
  coveredByOtherFG: FormGroup;
  coveredByOtherFields: FormField[];
  filteredCompanies: Observable<any>;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.filledData = this.filledData || {};
    this.relationships = Common.InsuranceRelationships;

    this.coveredByOtherFG = this.fb.group({});
    this.coveredByOtherFields = this.getCoveredByOtherFields(this.filledData);

    this.form.addControl('id', new FormControl(this.filledData.id));
    this.form.addControl('insuranceIsCoveredByOther', new FormControl(!!this.filledData.insuranceIsCoveredByOther));

    if (this.filledData.insuranceIsCoveredByOther) {
      this.form.addControl('insuredInfo', this.coveredByOtherFG);
    }

    this.form
      .get('insuranceIsCoveredByOther')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.form.addControl('insuredInfo', this.coveredByOtherFG);
        } else {
          this.form.removeControl('insuredInfo');
        }
      });

    if (this.showInsuranceAutocomplete) {
      this.form.addControl('companyId', new FormControl(this.filledData.companyId));
      this.form.addControl('companyName', new FormControl(this.filledData.companyName, [Validators.required]));
      this.filteredCompanies = this.form.get('companyName').valueChanges.pipe(
        startWith(''),
        map((name) => (name ? this._filterCompanies(name) : this.insuranceCompanies.slice()))
      );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  setCompanyId(name) {
    const company = this.insuranceCompanies.find((el) => el.name === name);
    this.form.get('companyId').setValue(company.id);
  }
  private _filterCompanies(val: string): any[] {
    if (!val) {
      return;
    }
    return this.insuranceCompanies.filter((option) => option.name.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  private getCoveredByOtherFields(info) {
    const data = info.insuredInfo ? info.insuredInfo : {};

    const fields: FormField[] = [
      {
        key: 'patientRelationtionshipToInsuredType',
        label: 'patientRelationtionshipToInsuredType',
        planceholder: 'select',
        type: FormFieldsEnum.DropdownSingleSelect,
        itemsList: this.relationships,
        value: data.patientRelationtionshipToInsuredType || undefined,
      },
      {
        key: 'firstName',
        label: 'insuredInfoFirstName',
        planceholder: 'insuredInfoFirstName-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.firstName || '',
        validators: [Validators.required],
      },
      {
        key: 'middleName',
        label: 'insuredInfoMiddleName',
        planceholder: 'insuredInfoMiddleName-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.middleName || '',
      },
      {
        key: 'lastName',
        label: 'insuredInfoLastName',
        planceholder: 'insuredInfoLastName-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.lastName || '',
        validators: [Validators.required],
      },
      {
        key: 'dateOfBirth',
        label: 'insuredInfoDateOfBirth',
        type: FormFieldsEnum.DatePicker,
        value: data.dateOfBirth ? parseISO(data.dateOfBirth) : null,
      },
    ];
    return fields;
  }
}
