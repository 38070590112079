import { Router } from '@angular/router';
import { LoaderService } from './../../../core/services/loader.service';
import { StartupService } from './../../../core/services/startup.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';
import { AuthService, AuthHelperService, NavigateWrapperService } from '../../../core/services/index';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { TopNavbarService } from './top-navbar.service';
import { FeaturePlanType } from '@unifiedpractice/up-chat-lib';

@Component({
  selector: 'pp-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent extends BaseComponent implements OnInit, OnDestroy {
  loggedIn = false;

  /** Xamarin. Used to hide the web navigation. */
  @Input() xamarin: boolean;

  user: any = {
    firstName: undefined,
    initials: undefined,
    avatarUrl: undefined,
  };
  clinicLogoUrl = '';
  loginEnabled = true;

  clinicName = '';
  webAddress = '';
  email = '';
  phone = '';
  FeaturePlanType: typeof FeaturePlanType = FeaturePlanType;

  constructor(
    public startupService: StartupService,
    private loader: LoaderService,
    private authHelperService: AuthHelperService,
    private authService: AuthService,
    private router: Router,
    private navigateService: NavigateWrapperService,
    public topNavbarService: TopNavbarService
  ) {
    super();
    this.xamarin = false;
  }

  /** Xamarin. Listen to "navigate" custom event raised from C# and navigate to provided path */
  @HostListener('window:navigate', ['$event'])
  navigate(event: CustomEvent) {
    if (event.detail) {
      if (event.detail === 'logout') {
        this.topNavbarService.logout();
      } else {
        this.navigateService.navigate([event.detail]);
        // TODO: add the Tenant to the navigation.
      }
    }
  }

  ngOnInit() {
    if (this.startupService.startupData.logoUrl) {
      this.clinicLogoUrl = `${environment.resourceUrl}/${this.startupService.startupData.logoUrl}`;
    } else {
      this.clinicLogoUrl = './../../../../assets/img/logo-symbol.png';
    }

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.loginEnabled = this.startupService.startupData.usePatientPortal;

    this.clinicName = this.startupService.startupData.name;
    this.webAddress = this.startupService.startupData.webAddress;
    this.email = this.startupService.startupData.email;
    this.phone = this.startupService.startupData.phoneNumber;

    if (this.webAddress && !this.webAddress.includes('http')) {
      this.webAddress = 'http://' + this.webAddress;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  login() {
    if (this.router.url.includes('login')) {
      this.navigateService.navigate(['booking', 'previous-visit']);
    } else if (
      this.router.url.includes('location') ||
      this.router.url.includes('service') ||
      this.router.url.includes('practitioner') ||
      this.router.url.includes('timeslot')
    ) {
      this.navigateService.navigate(['login'], { returnUrl: this.router.url });
    } else if (this.router.url.includes('previous-visit')) {
      this.navigateService.navigate(['login'], { returnUrl: 'appointments' });
    } else {
      this.navigateService.navigate(['login'], { returnUrl: 'booking/review' });
    }
  }

  isChatActivated(): boolean {
    return !!this.startupService.isStreamChatModuleEnabled();
  }
}
