import { ClinicService } from './../../../clinic/services/clinic.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { select, Store } from '@ngrx/store';
import { Service } from '../../../clinic/models/index';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { combineLatest } from 'rxjs';
import { tap, switchMap, takeUntil, filter } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-service-step-page',
  templateUrl: 'service-step-page.component.html',
  styleUrls: ['service-step-page.component.scss'],
})
export class ServiceStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  services: Service[] = [];
  organization$ = this.store.pipe(select(fromRoot.getOrganizationState));
  organization;
  showLoader = true;
  message = undefined;

  get hasOrganizationCCPE() {
    return this.organization.useCancellationPolicy;
  }

  constructor(private store: Store<fromRoot.State>, private clinicService: ClinicService, private navigateService: NavigateWrapperService) {
    super();
  }

  ngOnInit() {
    this.showLoader = true;
    this.organization$.pipe(takeUntil(this.destroy$)).subscribe((response) => (this.organization = response));

    combineLatest([
      this.store.select(fromRoot.getBookingLocation),
      this.store.select(fromRoot.getBookingExistingPatient).pipe(filter((res) => res !== null)),
    ])
      .pipe(
        switchMap((res) => {
          const location = res[0];
          const isExistingPatient = res[1];

          if (location && isExistingPatient !== null) {
            return this.clinicService.getServices(location.guid, !isExistingPatient);
          }

          return [];
        }),
        tap(() => {
          this.showLoader = false;
          const contentContainer = document.querySelector('pp-booking-summary-box > div > form > div.summary-location');
          if (contentContainer) {
            contentContainer.scrollIntoView();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        this.services = res;
        // Skip this step
        // IF it is a single practitioner clinic
        if (this.services.length === 1) {
          this.setService(this.services[0], false);
        }

        this.navigateService.pushRoute('booking/service', this.services.length === 1);
      });

    this.store
      .select(fromRoot.getBookingServiceStepMessage)
      .pipe(takeUntil(this.destroy$))
      .subscribe((msg) => {
        this.message = msg;
      });
  }

  public setService(service: Service, userAction: boolean): void {
    this.resetScheduling();

    this.store.dispatch(new BookingActions.SetServiceAction(service));

    if (userAction) {
      this.navigateService.nextRoute = undefined;
    }

    let route = 'booking/practitioner';
    if (this.navigateService.nextRoute) {
      route = this.navigateService.nextRoute;
      this.navigateService.nextRoute = undefined;
    }
    this.navigateService.navigate([route]);
  }

  private resetScheduling() {
    this.store.dispatch(new BookingActions.SetMessageOnServiceStep(null));

    // reset scheduling service when the service is changed
    this.store.dispatch(new BookingActions.SetServiceAction(null));

    // reset scheduling pratitioner when the service is changed
    this.store.dispatch(new BookingActions.SetPractitionerAction(null));

    // reset scheduling time when the service is changed
    this.store.dispatch(new BookingActions.SetTimeslotAction(null));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
