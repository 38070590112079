import { ClinicLocation } from './../../../clinic/models/location';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';

import { MatDialog } from '@angular/material/dialog';
import { MapModalComponent } from '../../../clinic/components/index';
import { BookingService } from '../../services';
import { AuthHelperService } from '../../../core/services';
import { PlatformLocation } from '@angular/common';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { first, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-location-step-page',
  templateUrl: 'location-step-page.component.html',
  styleUrls: ['location-step-page.component.scss'],
})
export class LocationStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  message = undefined;
  locations: ClinicLocation[];
  selectedLocation: ClinicLocation;
  telehealthLocations: ClinicLocation[] = [];

  constructor(
    private authHelperService: AuthHelperService,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private bookingService: BookingService,
    private location: PlatformLocation,
    private navigateService: NavigateWrapperService
  ) {
    super();
    this.locations = this.route.snapshot.data.locations;
    this.telehealthLocations = this.locations.filter((ser) => ser.hasTelehealthServices === true);
    this.navigateService.pushRoute('booking/location', this.locations.length === 1);
  }

  ngOnInit() {
    this.clearPreviousAppointment();
    this.store
      .select(fromRoot.getQueryLocationGuid)
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe((guid) => {
        // Skip this step
        // IF there is a location guid in url
        const location = this.getLocationByGuid(guid);
        if (guid && location) {
          this.setLocation(location, false);

          // IF it is a single location clinic
        } else if (this.locations.length === 1) {
          this.setLocation(this.locations[0], false);
          this.store.dispatch(new BookingActions.CanChangeLocationAction(false));
        }
      });

    this.store
      .select(fromRoot.getBookingLocationStepMessage)
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe((msg) => {
        this.message = msg;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setLocation(location: ClinicLocation, userAction: boolean): void {
    if (!this.selectedLocation || (this.selectedLocation && this.selectedLocation.id !== location.id)) {
      this.store.dispatch(new BookingActions.SetLocationAction(location));

      // reset the service
      this.store.dispatch(new BookingActions.SetServiceAction(null));

      // reset the practitiner
      this.store.dispatch(new BookingActions.SetPractitionerAction(null));

      this.store.dispatch(new BookingActions.SetMessageOnLocationStep(null));
    }

    if (userAction) {
      this.navigateService.nextRoute = undefined;
    }

    let route = 'booking/service';
    if (this.navigateService.nextRoute) {
      route = this.navigateService.nextRoute;
      this.navigateService.nextRoute = undefined;
    }
    this.navigateService.navigate([route]);
  }

  openMap(location) {
    const dialogRef = this.dialog.open(MapModalComponent, {
      data: location,
      panelClass: 'map-modal',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {});
  }

  private getLocationByGuid(guid) {
    return this.locations.find((loc) => loc.guid === guid);
  }

  private clearPreviousAppointment() {
    this.store.dispatch(new BookingActions.SetPractitionerAction(null));
    this.store.dispatch(new BookingActions.SetPreferredPractitionerOption(null));
    this.store.dispatch(new BookingActions.SetTimeslotAction(null));
    this.store.dispatch(new BookingActions.SetServiceAction(null));
    this.store.dispatch(new BookingActions.SetLocationAction(null));
  }
}
