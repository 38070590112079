<div class="row">
    <div class="col-6">
        <mat-select class="form-control form-control-select" placeholder="{{ 'select' | translate }}"
            [(value)]="gender" (selectionChange)="change()">
            <mat-option [value]="undefined">{{ 'select' | translate }}</mat-option>
            <mat-option value="M">{{ 'gender-male' | translate }}
            </mat-option>
            <mat-option value="F">{{ 'gender-female' | translate }}
            </mat-option>
            <mat-option value="O">{{ 'gender-other' | translate }}
            </mat-option>
        </mat-select>
    </div>


    <div class="col-6" *ngIf="gender === 'O'">
        <input class="form-control" type="text" [(ngModel)]="otherGender"
            placeholder="{{ 'other' | translate }}" (blur)="change()"
        />
    </div>


</div>
