// angular
import { Injectable } from '@angular/core';

// libs
import { Store, Action } from '@ngrx/store';
import { createEffect, Actions } from '@ngrx/effects';
import { Observable } from 'rxjs';



// module
import { NameListService } from '../services/index';
import { NameList } from '../actions/index';

@Injectable()
export class SampleEffects {

    /**
     * This effect makes use of the `startWith` operator to trigger
     * the effect immediately on startup.
     */
    // @Effect() init$: Observable<Action> = this.actions$
    //     .ofType(NameList.INIT)
    //     .startWith(new NameList.InitAction)
    //     .switchMap(() => this.nameListService.getNames())
    //     .map(payload => {
    //         const names = payload;
    //         return new NameList.InitializedAction(names);
    //     })
    //     // nothing reacting to failure at moment but you could if you want (here for example)
    //     .catch(() => Observable.of(new NameList.InitFailedAction()));

    // @Effect() add$: Observable<Action> = this.actions$
    //     .ofType(NameList.ADD)
    //     .map((action: NameList.AddAction) => {
    //         const name = action.payload;

    //         return new NameList.NameAddedAction(name);
    //     });

    // constructor(
    //     private store: Store<any>,
    //     private actions$: Actions,
    //     private nameListService: NameListService
    // ) { }
}
