import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as fromRoot from './../../ngrx';
import { Store } from '@ngrx/store';
import { NavigateWrapperService } from '../../core/services';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CanActivatePractitionerStepGuard implements CanActivate, OnDestroy {
  private service: any;
  private practitioner: any;
  private canChangePractitioner: any;
  private destroy$: Subject<void> = new Subject();

  constructor(private store: Store<fromRoot.State>, private route: ActivatedRoute, private navigateService: NavigateWrapperService) {
    this.store
      .select(fromRoot.getBookingService)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => (this.service = val));
    this.store
      .select(fromRoot.getBookingPractitioner)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => (this.practitioner = val));
    this.store
      .select(fromRoot.canChangeBookingPractitioner)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => (this.canChangePractitioner = val));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.service) {
      return true;
    }

    // go to location step
    this.navigateService.navigate(['booking', 'service']);

    return false;
  }
}
