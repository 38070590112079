import { Observable, of, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';
import { AuthHelperService, NavigateWrapperService, AuthService } from '../../core/services/index';
import { map, catchError, takeUntil } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {
  private loggedIn = false;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private authHelperService: AuthHelperService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private navigateService: NavigateWrapperService
  ) {
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((val) => (this.loggedIn = val));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.refreshTokens().pipe(
      map((res) => !!res), // user has token data so it's logged in
      catchError(() => {
        this.authService.logout();
        this.navigateService.navigate(['login'], { returnUrl: state.url });

        return of(false);
      })
    );
  }
}
