<div [ngSwitch]="view">
  <div class="box select-box" *ngSwitchCase="'reset'">
    <h3 class="subtitle">{{ 'forgot-password' | translate }}</h3>
    <div>{{ 'forgot-email' | translate }}</div>
    <form [formGroup]="forgotForm" (ngSubmit)="onSendResetLink()" novalidate>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <label class="label-form-control"> {{ 'email' | translate }}</label>
          <input
            class="form-control inp"
            placeholder="email@company.com"
            type="email"
            formControlName="email"
            tabindex="1"
            name="email-search-lastpass-disable"
          />
          <p class="error-message-field" *ngIf="forgotForm.get('email').invalid && forgotForm.get('email').touched">
            {{ 'form-input-error-email-format' | translate }}
          </p>
        </div>
        <div class="col-lg-12 col-xs-12 form-row">
          <button class="btn btn-primary btn-small" type="submit" [disabled]="!forgotForm.valid" tabindex="3">
            {{ 'send-me-instructions' | translate }}
          </button>
          <button class="btn btn-secondary btn-small ml-3" (click)="changeView('login')">
            {{ 'back' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="box select-box" *ngSwitchCase="'forgot-success'">
    <h3 class="subtitle">
      {{ 'reset-email-sent-success-title' | translate }}
    </h3>
    <p>
      {{ 'reset-email-sent-success-msg1' | translate }}
      <strong> {{ forgotForm.get('email').value }}</strong>
    </p>
    <p>
      {{ 'reset-email-sent-success-msg2' | translate }}
    </p>
    <div class="row">
      <div class="col-12">
        <a href="javascript:void(0)" class="btn btn-secondary btn-small btn-left" (click)="changeView('login')"
          >{{ 'back' | translate }}
        </a>
      </div>
    </div>
  </div>

  <div class="box select-box" *ngSwitchDefault>
    <h3 class="subtitle">{{ boxTitle | translate }}</h3>
    <p *ngIf="infoMessage && infoMessage.length > 0">
      {{ infoMessage | translate }}
    </p>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="error.length > 0">
        <div class="box message message-danger" role="alert">
          <span class="wrap-icon-alert">
            <i class="material-icons info-icon">info</i>
          </span>
          {{ error | translate }}
        </div>
      </div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row" *ngIf="isImpersonateLogin()">
          <label class="label-form-control">Impersonate Patient Portal User</label>
        </div>
        <div class="col-lg-12 col-xs-12 form-row" *ngIf="!isImpersonateLogin()">
          <label class="label-form-control">{{ 'email' | translate }}</label>
          <input class="form-control inp" value="" placeholder="email@company.com" type="email" formControlName="username" tabindex="1" />
          <p class="error-message-field" *ngIf="loginForm.get('username').invalid && loginForm.get('username').touched">
            {{ 'form-input-error-email-format' | translate }}
          </p>
        </div>
        <div class="col-lg-12 col-xs-12 form-row" *ngIf="!isImpersonateLogin()">
          <label class="label-form-control label-form-controfxLayoutl-inline"> {{ 'password' | translate }}</label>
          <input class="form-control" value="" type="password" formControlName="password" tabindex="2" />
          <a href="javascript:void(0)" tabindex="4" (click)="changeView('reset')">
            {{ 'forgot-password' | translate }}
          </a>
        </div>
        <div
          class="col-lg-12 col-xs-12 form-row"
          [ngClass]="{ 'login-form-actions': boxTitle === 'login', 'already-acc-form-actions': boxTitle != 'login' }"
        >
          <button
            mat-flat-button
            color="primary"
            class="login-btn"
            type="submit"
            [disabled]="(loginForm.touched && !loginForm.valid) || showLoader"
            tabindex="3"
          >
            <i class="fa fa-spin fa-circle-o-notch" *ngIf="showLoader"></i>
            {{ 'login' | translate }}
          </button>
        </div>
        <div class="col-lg-12 col-xs-12" fxLayout="row" *ngIf="showRegister" fxLayoutAlign="center center">or</div>
      </div>
    </form>
    <div class="create-acc-btn" fxLayout="row" *ngIf="showRegister" fxLayoutAlign="center center">
      <button mat-stroked-button color="primary" (click)="openCreateAccountDialog()" [disabled]="showLoader">
        <ng-container *ngIf="useOnlineBooking">{{ 'create-account-btn' | translate }}</ng-container>
        <ng-container *ngIf="!useOnlineBooking">{{ 'create-account-btn-no-online-booking' | translate }}</ng-container>
      </button>
    </div>
  </div>
</div>
