import { HttpClient } from '@angular/common/http';
// angular
import { Injectable } from '@angular/core';


// libs
import { Observable } from 'rxjs';


// module
import { NameList } from '../actions/index';

import { environment } from '../../../environments/environment';


@Injectable()
export class NameListService {
    public env: any = environment;
    constructor(
        private http: HttpClient
    ) {

    }

    getNames(): Observable<any> {
        return this.http.get('assets/data.json');

    }


}
