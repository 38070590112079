import { SharedModule } from './../shared/shared.module';
import { NameListService } from './services/name-list.service';

import { NgModule, Optional, SkipSelf } from '@angular/core';

// app
import { SampleComponent } from './components/sample/sample-page.component';

import { EffectsModule } from '@ngrx/effects';
import { SampleEffects } from './effects/sample.effect';
// app
import { SampleRoutingModule } from './sample-routing.module';

@NgModule({
  imports: [
    SharedModule,
    EffectsModule.forRoot([SampleEffects]),
    SampleRoutingModule,
  ],
  declarations: [SampleComponent],
  providers: [NameListService]
})
export class SampleModule {

  constructor(@Optional() @SkipSelf() parentModule: SampleModule) {
    if (parentModule) {
      throw new Error('SampleModule already loaded; Import in root module only.');
    }
  }
}
