import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OnboardingService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { environment } from '../../../../environments/environment';
import { Common } from '../../../shared/models/Common';
import { StartupService, NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { parseISO } from 'date-fns';
import { isEmpty as _isEmpty, keys as _keys } from 'lodash-es';

@Component({
  selector: 'pp-insurance-page',
  templateUrl: 'insurance-page.component.html',
})
export class InsurancePageComponent extends BaseComponent implements OnInit, OnDestroy {
  insuranceCompanies = [];
  loaderRows = [0, 1, 2, 3, 4];
  formData: FormData = new FormData();
  form: FormGroup;
  loggedIn = false;
  showLoader = true;
  saving = false;
  acceptsMajorInsurance: any;
  acceptsAutoAccident: any;
  acceptsWorkerCompensation: any;
  secondaryFG: FormGroup;
  primaryFG: FormGroup;
  insuranceFilledInfo: any;
  primaryInsuranceFields: FormField[];
  secondaryInsuranceFields: FormField[];
  autoFG: FormGroup;
  workFG: FormGroup;
  autoInsuranceFields: FormField[];
  workInsuranceFields: FormField[];

  private relationships: any;
  public states: any;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private startupService: StartupService,
    private navigateService: NavigateWrapperService,
    public authHelperService: AuthHelperService
  ) {
    super();
    this.states = this.route.snapshot.data.states;
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }

  ngOnInit() {
    this.acceptsMajorInsurance = this.startupService.startupData.acceptsMajorInsurance;
    this.acceptsAutoAccident = this.startupService.startupData.acceptsAutoAccident;
    this.acceptsWorkerCompensation = this.startupService.startupData.acceptsWorkerCompensation;
    this.insuranceCompanies = this.startupService.startupData.insuranceCompanies;
    this.relationships = Common.InsuranceRelationships;

    this.primaryFG = this.fb.group({});
    this.secondaryFG = this.fb.group({});
    this.autoFG = this.fb.group({});
    this.workFG = this.fb.group({});

    this.form = this.fb.group({
      hasPrimaryInsurance: new FormControl(false),
      hasSecondaryInsurance: new FormControl(false),
      hasAutoInsurance: new FormControl(false),
      hasWorkInsurance: new FormControl(false),
    });

    this.form
      .get('hasPrimaryInsurance')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newVal) => {
        if (newVal) {
          this.form.addControl('primary', this.primaryFG);
        } else {
          // save the data if the user toggle of the slider
          this.insuranceFilledInfo.primary = this.primaryFG.value;
          this.form.removeControl('primary');
        }
      });

    this.form
      .get('hasSecondaryInsurance')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newVal) => {
        if (newVal) {
          this.form.addControl('secondary', this.secondaryFG);
        } else {
          // save the data if the user toggle of the slider
          this.insuranceFilledInfo.secondary = this.secondaryFG.value;
          this.form.removeControl('secondary');
        }
      });

    this.form
      .get('hasAutoInsurance')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newVal) => {
        if (newVal) {
          this.form.addControl('autoAccident', this.autoFG);
        } else {
          // save the data if the user toggle of the slider
          this.insuranceFilledInfo.autoAccident = this.autoFG.value;
          this.form.removeControl('autoAccident');
        }
      });

    this.form
      .get('hasWorkInsurance')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newVal) => {
        if (newVal) {
          this.form.addControl('workerCompensation', this.workFG);
        } else {
          // save the data if the user toggle of the slider
          this.insuranceFilledInfo.workerCompensation = this.workFG.value;
          this.form.removeControl('workerCompensation');
        }
      });

    this.onboardingService
      .getInsuranceInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.primary) {
          this.primaryFG.setControl('cardFrontImagePath', new FormControl(res.primary.cardFrontImagePath));
          this.primaryFG.setControl('cardBackImagePath', new FormControl(res.primary.cardBackImagePath));
        }
        if (res.secondary) {
          this.secondaryFG.setControl('cardFrontImagePath', new FormControl(res.secondary.cardFrontImagePath));
          this.secondaryFG.setControl('cardBackImagePath', new FormControl(res.secondary.cardBackImagePath));
        }
        this.insuranceFilledInfo = res || {};

        this.primaryInsuranceFields = this.initMajorInsurance(res.primary);
        this.secondaryInsuranceFields = this.initMajorInsurance(res.secondary);
        this.autoInsuranceFields = this.initAutoInsurance(res.autoAccident);
        this.workInsuranceFields = this.initWorkInsurance(res.workerCompensation);

        if (res.primary) {
          this.primaryFG.setControl('cardFrontImagePath', new FormControl(res.primary.cardFrontImagePath));
          this.primaryFG.setControl('cardBackImagePath', new FormControl(res.primary.cardBackImagePath));
        }
        if (res.secondary) {
          this.secondaryFG.setControl('cardFrontImagePath', new FormControl(res.secondary.cardFrontImagePath));
          this.secondaryFG.setControl('cardBackImagePath', new FormControl(res.secondary.cardBackImagePath));
        }

        this.form.get('hasPrimaryInsurance').setValue(!!res.primary);
        this.form.get('hasSecondaryInsurance').setValue(!!res.secondary);
        this.form.get('hasAutoInsurance').setValue(!!res.autoAccident);
        this.form.get('hasWorkInsurance').setValue(!!res.workerCompensation);

        if (!_isEmpty(_keys(res.primary))) this.setSwitchDisable('hasPrimaryInsurance');
        if (!_isEmpty(_keys(res.secondary))) this.setSwitchDisable('hasSecondaryInsurance');
        if (!_isEmpty(_keys(res.autoAccident))) this.setSwitchDisable('hasAutoInsurance');
        if (!_isEmpty(_keys(res.workerCompensation))) this.setSwitchDisable('hasWorkInsurance');

        const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
        if (contentContainer) {
          contentContainer.scrollIntoView();
        }

        /*if (this.onboardingService.summaryForms.find((form) => form.id === Common.OnboardingSummaryForms.Insurance.id)) {
          this.formComplete = this.onboardingService.summaryForms.find(
            (form) => form.id === Common.OnboardingSummaryForms.Insurance.id
          ).completed;
        }*/

        this.showLoader = false;
      });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      /*if (this.formComplete) {
        this.onboardingService.setFormSkip(true);
      } else {
        this.onboardingService.setFormSkip(this.form.pristine);
      }*/
      this.onboardingService.setFormSkip(this.form.pristine);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getCardImageUrl(path: string) {
    if (path) {
      path = path.toLowerCase();
      const pos = path.indexOf('media');
      const relativePath = path.substring(pos, path.length);
      const url = new URL(`${environment.resourceUrl}/${relativePath}?nekot_ssecca=${this.authHelperService.token.access_token}`);
      return url.href;
    }
    return undefined;
  }

  cleanUp(form) {
    delete form.hasPrimaryInsurance;
    delete form.hasSecondaryInsurance;
    delete form.hasAutoInsurance;
    delete form.hasWorkInsurance;

    return form;
  }

  setSwitchDisable(selector) {
    let switchControl = this.form.controls[selector];
    if (switchControl && !switchControl.disabled) switchControl.disable();
  }

  private initMajorInsurance(data) {
    data = data || {};
    const fields: FormField[] = [
      {
        key: 'planType',
        label: 'planType',
        planceholder: 'planType-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.planType || '',
      },
      {
        key: 'cardID',
        label: 'insuranceCardID',
        planceholder: 'planType-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.cardID || '',
        validators: [Validators.required],
      },
      {
        key: 'groupNumber',
        label: 'groupNumber',
        planceholder: 'insuranceCardID-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.groupNumber || '',
      },
      {
        key: 'providerPhone',
        label: 'insuranceProviderPhone',
        planceholder: 'insuranceProviderPhone-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.providerPhone || '',
        validators: [CustomValidators.phone, Validators.maxLength(20)],
      },
      {
        key: 'claimAddress',
        label: 'claimAddress',
        planceholder: 'claimAddress-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.claimAddress || '',
      },
      {
        key: 'city',
        label: 'city',
        planceholder: 'city',
        type: FormFieldsEnum.TextBox,
        value: data.city || '',
      },
      {
        key: 'zipCode',
        label: 'zipCode',
        planceholder: 'zipCode',
        type: FormFieldsEnum.TextBox,
        value: data.zipCode || '',
      },
      {
        key: 'state',
        label: 'state',
        planceholder: 'select',
        type: FormFieldsEnum.DropdownSingleSelect,
        itemsList: this.states,
        value: data.state,
      },
    ];
    return fields;
  }

  initAutoInsurance(data) {
    data = data || {};
    const fields: FormField[] = [
      {
        key: 'accidentDate',
        label: 'accidentDate',
        type: FormFieldsEnum.DatePicker,
        value: data.accidentDate ? parseISO(data.accidentDate) : null,
      },
      {
        key: 'caseClaimNo',
        label: 'caseClaimNo',
        planceholder: 'caseClaimNo-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.caseClaimNo || undefined,
      },
      {
        key: 'caseManagerName',
        label: 'caseManagerName',
        planceholder: 'caseManagerName-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.caseManagerName || '',
      },
      {
        key: 'caseManagerPhone',
        label: 'caseManagerPhone',
        planceholder: 'caseManagerPhone-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.caseManagerPhone || '',
        validators: [CustomValidators.phone, Validators.maxLength(20)],
      },
      {
        key: 'caseManagerAddress',
        label: 'caseManagerAddress',
        planceholder: 'caseManagerAddress-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.caseManagerAddress || '',
      },
    ];
    return fields;
  }

  initWorkInsurance(data) {
    data = data || {};
    const fields: FormField[] = [
      {
        key: 'injuryDate',
        label: 'dateOfInjury',
        type: FormFieldsEnum.DatePicker,
        value: data.injuryDate ? parseISO(data.injuryDate) : null,
      },
      {
        key: 'caseNo',
        label: 'caseClaimNo',
        planceholder: 'caseClaimNo-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.caseNo || undefined,
      },
      {
        key: 'employerName',
        label: 'empName',
        planceholder: 'empName-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.employerName || '',
      },
      {
        key: 'employerAddress',
        label: 'empAddress',
        planceholder: 'empAddress-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.employerAddress || '',
      },
      {
        key: 'companyName',
        label: 'insName',
        type: FormFieldsEnum.TextBox,
        value: data.companyName || '',
      },
      {
        key: 'companyPhone',
        label: 'insPhone',
        planceholder: 'insPhone-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.companyPhone || '',
        validators: [CustomValidators.phone, Validators.maxLength(20)],
      },
      {
        key: 'companyAddress',
        label: 'insAddress',
        planceholder: 'insAddress-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.companyAddress || '',
      },
    ];
    return fields;
  }

  makeFormReadOnly(formGroup): any {
    const controls = Object.keys(formGroup.controls);

    for (let i = 0; i < controls.length; i++) {
      const obj = formGroup.controls[controls[i]];
      if (obj.controls) {
        // put the function call in the event queue
        // instead of recursive call
        // to prevent stack overflow
        setTimeout(() => {
          this.makeFormReadOnly(obj);
        }, 0);
      } else {
        obj.disable();
      }
    }

    return;
  }

  setFiles(fileObj: any) {
    if (!fileObj) {
      return;
    }
    if (fileObj.file) {
      this.formData.set(fileObj.name, fileObj.file);
    } else {
      this.formData.delete(fileObj.name);
    }
  }

  onSubmit(redirect: boolean = true) {
    const insuranceInfo = this.cleanUp(this.form.value);

    this.formData.append('request', JSON.stringify(insuranceInfo));
    this.saving = true;
    this.onboardingService.saveInsuranceInfo(this.formData).subscribe((res) => {
      this.saving = false;
      this.onboardingService.updateSummary(Common.OnboardingSummaryForms.Insurance);
      this.onboardingService.setFormSkip(true);
      if (redirect) {
        const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
        if (uncompleted) {
          this.navigateService.navigate([uncompleted.route], null, true);
        } else {
          this.navigateService.navigate(['onboarding', 'success']);
        }
      }
    });
  }
}
