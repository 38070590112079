import {
  FormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Component, forwardRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { PhoneTypeEnum } from '../../models/phoneType.enum';
import { CustomValidators } from '../../validators/custom-validators';
import { BaseComponent } from '../base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-phone-input',
  templateUrl: 'phone-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent extends BaseComponent implements ControlValueAccessor, Validator, OnDestroy {
  disabled = false;
  phoneTypes: any;
  form: FormGroup;
  private isRequired = false;

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    super();
    this.phoneTypes = this.getPhoneTypes();

    this.form = this.formBuilder.group({
      number: new FormControl(undefined, [CustomValidators.phone, Validators.maxLength(20)]),
      phoneType: new FormControl(undefined),
    });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.change();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.form.setControl('number', new FormControl(obj.number, [CustomValidators.phone, Validators.maxLength(20)]));
      this.form.setControl('phoneType', new FormControl(obj.phoneType));
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Angular does not know that the value has changed
  // from our component, so we need to update her with the new value.
  change() {
    let res = null;
    if (this.form.valid && this.form.value.number && this.form.value.phoneType) {
      res = this.form.value;
    }
    this.propagateChange(res);
  }

  validate(c: AbstractControl): ValidationErrors | any {
    if (this.form.get('number').invalid) {
      return {
        phone: true,
      };
    }

    if (this.form.value.number && this.form.value.number.length > 0 && !this.form.value.phoneType) {
      return {
        phoneType: true,
      };
    }

    return null;
  }

  // the method set in registerOnChange to emit changes back to the form
  // tslint:disable-next-line:no-empty
  private propagateChange = (_: any) => {};

  private getPhoneTypes() {
    const phoneTypes = [];

    for (const key in PhoneTypeEnum) {
      if (PhoneTypeEnum.hasOwnProperty(key)) {
        const element = PhoneTypeEnum[key];
        const isValueProperty = parseInt(element, 10) >= 0;
        if (isValueProperty) {
          phoneTypes.push({ label: PhoneTypeEnum[element], value: element });
        }
      }
    }

    return phoneTypes;
  }
}
