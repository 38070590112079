import { Observable, Subscription, interval } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pp-logout-warning-modal',
    templateUrl: 'logout-warning-modal.component.html'
})

export class LogoutWarningModalComponent implements OnInit, OnDestroy {

    counter: number;
    private intervalSub: Subscription;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<LogoutWarningModalComponent>,
        private toastr: ToastrService,
        private translateService: TranslateService,
    ) { this.counter = this.data; }

    ngOnInit() {
        this.intervalSub = interval(1000).subscribe(() => {
            this.counter--;
            if (this.counter <= 0) {
                this.counter = 0;
                this.intervalSub.unsubscribe();
            }
        });
    }

    ngOnDestroy() {
        this.intervalSub.unsubscribe();
    }
}
