import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SharedModule } from './../shared/shared.module';
import { CORE_SERVICES } from './services/index';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
    imports: [
        SharedModule,
        NgIdleKeepaliveModule.forRoot(),
    ],
    declarations: [],
    exports: [],
    providers: [
        ...CORE_SERVICES,

    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule already loaded; Import in root module only.');
        }
    }
}
