import { AccountService } from './../../account/services/account.service';
import { Service } from './../models/service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Practitioner } from '../models/index';
import { environment } from './../../../environments/environment';
import { ClinicLocation } from '../models/location';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromRoot from './../../ngrx';
import { Store } from '@ngrx/store';
import { join as _join, map as _map } from 'lodash';

@Injectable()
export class ClinicService {
  constructor(private http: HttpClient, private accountService: AccountService, private store: Store<fromRoot.State>) {}

  getPractitioners(serviceId: number, locationGuid): Observable<Practitioner[]> {
    const params = new HttpParams().set('ServiceId', serviceId.toString()).set('LocationGuid', locationGuid);

    return this.http.get(`${environment.apiUrl}/Practitioners`, { params }) as Observable<Practitioner[]>;
  }
  getPractitionersByIds(practitionerIds: number[]): Observable<Practitioner[]> {
    return this.http
      .get(
        `${environment.apiUrl}/Practitioners/clinicpractitioners?${_join(
          _map(practitionerIds, (id) => `PractitionerIds=${id}`),
          '&'
        )}`
      )
      .pipe(map((response: any) => response?.practitioners || [])) as Observable<Practitioner[]>;
  }

  getLocations(): Observable<ClinicLocation[]> {
    return this.http.get(`${environment.apiUrl}/Locations`) as Observable<ClinicLocation[]>;
  }

  getServices(locationGuid, isNewPatient): Observable<Service[]> {
    const params = new HttpParams().set('LocationGuid', locationGuid).set('IsForNewPatients', (isNewPatient || false).toString());

    return this.http.get(`${environment.apiUrl}/Services`, { params }).pipe(
      map((result) => {
        for (const s of <Service[]>result) {
          s.displayedDuration = isNewPatient ? s.durationForNewPatients : s.durationForExistingPatients;
        }

        return <Service[]>result;
      })
    );
  }

  serviceNotAvailableForAuthenticated(service) {
    const user = this.accountService.user.getValue();
    const isExistingUser = user && user.isExisting;
    return (
      !service.isActiveForOnlineBooking ||
      (isExistingUser && service.durationForExistingPatients === 0) ||
      (!isExistingUser && service.durationForNewPatients === 0)
    );
  }
}
