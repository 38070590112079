import { AccountService } from '../../account/services';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class EmailInUseValidator {
    static createValidator(accountService: AccountService) {
        return (control: AbstractControl) => {
            return accountService.check(control.value, null, null).
                pipe(map((res: any) => {
                    return res.hasAccount ? { emailIsInUse: true } : null;
                }));
        };
    }
}
