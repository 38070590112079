<form [formGroup]="form" novalidate>
    <div class="row">

        <div class="col-lg-4 col-6">
            <label class="label-form-control">{{'country' | translate}}</label>
            <mat-select class="form-control form-control-select" formControlName="country"
                placeholder="{{ 'select' | translate }}">
                <mat-option [value]="undefined">{{ 'select' | translate }}</mat-option>
                <mat-option *ngFor="let c of countries" [value]="c.code"> {{c.name}} </mat-option>
            </mat-select>
        </div>

        <div class="col-lg-4 col-6" *ngIf="this.form.get('country').value==='US'">
            <label class="label-form-control">{{'state' | translate}}</label>
            <mat-select class="form-control form-control-select" [ngClass]="form.get('countryState')?.errors.required ? 'is-required' : '' " formControlName="state"
                placeholder="{{ 'select-state' | translate }}">
                <mat-option [value]="undefined">{{ 'select' | translate }}</mat-option>
                <mat-option *ngFor="let s of states" [value]="s.value"> {{s.label}} </mat-option>
            </mat-select>
        </div>

        <div class="col-lg-4 col-6" *ngIf="this.form.get('country').value!=='US'">
            <label class="label-form-control">{{'state' | translate}}</label>
            <input formControlName="otherState" (blur)="change()" class="form-control"
                type="text" />
        </div>

    </div>
</form>
