import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { Store } from '@ngrx/store';
import { Common } from '../../../shared/models/Common';
import { OnboardingService } from '../../services';
import { StartupService, NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { MatDialog } from '@angular/material/dialog';
import { OnboardingSummaryDialogComponent } from './onboarding-summary-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-onboarding-summary-box',
  templateUrl: 'onboarding-summary-box.component.html',
})
export class OnboardingSummaryBoxComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() progress: any;
  currentRoute;
  onboardingForms = [];
  hideXS = false;
  loggedIn = false;
  formValid = true;
  navRoute;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private onboardingService: OnboardingService,
    private startupService: StartupService,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (!this.onboardingService.canSkipForm()) {
          this.openDialog();
          return;
        }
      }

      if (event instanceof NavigationEnd) {
        const urlParts = event.url.split('/');

        if (urlParts.length > 2) {
          this.currentRoute = `${urlParts[2] ? urlParts[2] : ''}/${urlParts[3] ? urlParts[3] : ''}`;

          if (this.currentRoute.indexOf('?') !== -1) {
            this.currentRoute = this.currentRoute.split('?')[0];
          }

          this.hideXS = this.currentRoute.indexOf('success') !== -1;
        }
      }
    });

    this.onboardingService.formValid.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => (this.formValid = res));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.progress || !changes.progress.currentValue) {
      return;
    }
    const progress = changes.progress.currentValue;

    if (!this.onboardingService.summaryForms) {
      // Get all sumary box forms
      for (const key in Common.OnboardingSummaryForms) {
        if (Common.OnboardingSummaryForms.hasOwnProperty(key)) {
          // No medical forms
          if (
            key === Common.OnboardingSummaryForms.MedicalForms.id &&
            (!this.onboardingService.medicalForms || this.onboardingService.medicalForms.length === 0)
          ) {
            continue;
          }

          // No consent forms
          if (
            (key === Common.OnboardingSummaryForms.ConsentForms.id || key === Common.OnboardingSummaryForms.SignForms.id) &&
            (!this.onboardingService.consentForms || this.onboardingService.consentForms.length === 0)
          ) {
            continue;
          }

          // No screening forms
          if (
            key === Common.OnboardingSummaryForms.ScreeningForms.id &&
            (!this.onboardingService.screeningForms || this.onboardingService.screeningForms.length === 0)
          ) {
            continue;
          }

          // No insurance forms
          if (key === Common.OnboardingSummaryForms.Insurance.id && this.startupService.startupData) {
            const acceptsInsurance =
              this.startupService.startupData.acceptsInsurance &&
              (this.startupService.startupData.acceptsMajorInsurance ||
                this.startupService.startupData.acceptsAutoAccident ||
                this.startupService.startupData.acceptsWorkerCompensation);

            if (!acceptsInsurance) {
              continue;
            }
          }

          const form = {
            id: Common.OnboardingSummaryForms[key].id,
            name: Common.OnboardingSummaryForms[key].name,
            route: Common.OnboardingSummaryForms[key].route,
            xsVisible: true,
            completed: undefined,
          };

          this.onboardingForms.push(form);
        }
      }

      // If any form was completed
      if (progress.completion > 0 && progress.completedSteps.length > 0) {
        this.onboardingForms.forEach((form) => {
          if (progress.completedSteps.filter((step) => step === form.id)[0]) {
            form.completed = true;
          } else {
            form.completed = false;
          }
        });
      } else {
        // All summary forms are uncompleted
        this.onboardingForms.forEach((form) => (form.completed = false));
      }

      // Copy to service (cache)
      this.onboardingService.summaryForms = this.onboardingForms;
    } else {
      // Summary forms already generated, copy to render
      this.onboardingForms = this.onboardingService.summaryForms;
    }

    const uncompleteForm = this.onboardingForms.find((form) => !form.completed);
    if (uncompleteForm) {
      this.navigateTo(uncompleteForm.route);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OnboardingSummaryDialogComponent, {
      width: '350px',
      data: { formValid: this.formValid },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: boolean) => {
        this.onboardingService.setFormSkip(true);

        if (result) {
          if (this.formValid) {
            this.onboardingService.skipAndSave.next(true);
            this.navigateTo(this.navRoute);
          }
        } else {
          this.onboardingService.skipAndSave.next(false);
          if (this.formValid) {
            this.navigateTo(this.navRoute);
          }
        }

        this.onboardingService.setFormSkip(false);
        this.onboardingService.skipAndSave.next(false);
      });
  }

  navigateTo(route) {
    if (this.onboardingService.canSkipForm()) {
      const queryParams = this.onboardingService.getQueryParams();
      if (queryParams) {
        this.navigateService.navigate([route], queryParams);
      } else {
        this.navigateService.navigate([route]);
      }
    } else {
      this.navRoute = route;
      this.openDialog();
    }
  }

  getFormColor(form: any) {
    return form.completed
      ? this.currentRoute === form.route
        ? '#81c884'
        : '#42a5f5'
      : this.currentRoute === form.route
        ? '#81c884'
        : '#90a4ae';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
