<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-6">
      <input class="form-control" type="tel" placeholder="{{ 'phone-eg' | translate }}"
        maxlength="20" formControlName="number" (blur)="change()" />
    </div>
    <div class="col-lg-4 col-6">
      <mat-select class="form-control form-control-select"
        placeholder="{{ 'select' | translate }}"
        formControlName="phoneType">
        <mat-option [value]="undefined">{{ 'select' |
          translate }}</mat-option>
        <mat-option *ngFor="let pt of phoneTypes" [value]="pt.value">
          {{pt.label}} </mat-option>
      </mat-select>
    </div>
  </div>
</form>
