<header class="header" *ngIf="!xamarin">
  <nav class="navbar">
    <div [class.container]="!loggedIn" class="container-fluid navbar-container">
      <div class="navbar-header" fxLayout="row" fxFlex="100%">
        <!-- Navbar Menu -->
        <ul fxFlex.lt-sm="10%" class="burger-menu" fxLayoutAlign="start center">
          <li class="wrap-collapse-btn">
            <a
              id="toggle-btn1"
              href="javascript:void(0)"
              class="navbar-toggler hidden-sm-up pull-xs-right menu-btn"
              mat-icon-button
              [matMenuTriggerFor]="menu"
            >
              <i class="material-icons">menu</i>
              <!-- <mat-icon>menu</mat-icon> -->
            </a>
            <mat-menu #menu="matMenu">
              <!-- Logged In -->
              <a href="javascript:void(0)" mat-menu-item (click)="login()" tabindex="-1" *ngIf="!loggedIn"> {{ 'login' | translate }}</a>

              <a href="javascript:void(0)" mat-menu-item (click)="topNavbarService.navigateTo('booking/previous-visit')" *ngIf="!loggedIn">
                {{ 'book-appointment' | translate }}
              </a>

              <!-- No Auth -->
              <a href="javascript:void(0)" (click)="topNavbarService.navigateTo('appointments')" mat-menu-item *ngIf="loggedIn">
                {{ 'my-appointments' | translate }}
              </a>
              <a href="javascript:void(0)" (click)="topNavbarService.navigateTo('onboarding/medical-forms')" mat-menu-item *ngIf="loggedIn">
                {{ 'forms' | translate }}
              </a>
              <a href="javascript:void(0)" (click)="topNavbarService.navigateTo('account')" mat-menu-item *ngIf="loggedIn">
                {{ 'my-account' | translate }}
              </a>
              <a href="javascript:void(0)" (click)="topNavbarService.navigateTo('chat')" mat-menu-item *ngIf="loggedIn && isChatActivated()">
                {{ (startupService.getChatFeaturePlan() === FeaturePlanType.Starter || !startupService.isLiveChatEnabled() ? 'FileShare' : 'Chat') | translate }}
                <up-chat-global-notifications-count class="inline-block mobile-chat-notification ml-1"></up-chat-global-notifications-count>
              </a>
              <a href="javascript:void(0)" (click)="topNavbarService.logout()" mat-menu-item *ngIf="loggedIn">
                {{ 'logout' | translate }}
              </a>
            </mat-menu>
          </li>
        </ul>
        <!-- Navbar Brand -->
        <div
          class="brand"
          fxFlex.lt-lg="100%"
          fxFlex="{{ loggedIn ? '20%' : '' }}"
          [class.pl-2]="loggedIn"
          fxLayoutAlign.lt-lg="center center"
          fxLayoutAlign="start center"
        >
          <a
            href="javascript:void(0)"
            (click)="loggedIn ? topNavbarService.navigateTo('appointments') : topNavbarService.navigateTo('booking/previous-visit')"
            class="logo"
            tabindex="-1"
            title="{{ clinicName }}"
          >
            <!-- Official logo -->
            <img class="logo-symbol" [src]="clinicLogoUrl" alt="logo" />
          </a>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign.lt-md="end center" fxLayoutAlign="{{ loggedIn ? 'start center' : 'center center' }}">
          <div class="clinic-details">
            <div class="clinic-phone" *ngIf="phone">
              <a href="{{ 'tel:' + phone }}" tabindex="-1">
                <i class="material-icons">phone</i>
                <span>Phone Number</span>
                {{ phone }}
              </a>
            </div>
          </div>

          <div class="clinic-details">
            <div class="clinic-email" *ngIf="email">
              <a href="{{ 'mailto:' + email }}" tabindex="-1">
                <i class="material-icons">email</i>
                <span>E-Mail</span>
                {{ email }}
              </a>
            </div>
          </div>

          <div class="clinic-details">
            <div class="clinic-web" *ngIf="webAddress">
              <a href="{{ webAddress }}" target="_blank" tabindex="-1">
                <i class="material-icons">launch</i>
                <span>Website</span>
                {{ webAddress }}
              </a>
            </div>
          </div>

          <div class="clinic-details-mobile ml-3">
            <div class="clinic-phone" *ngIf="phone">
              <a href="{{ 'tel:' + phone }}" tabindex="-1">
                <i class="material-icons">phone</i>
                {{ phone }}
              </a>
            </div>
            <div class="clinic-email" *ngIf="email">
              <a href="{{ 'mailto:' + email }}" tabindex="-1">
                <i class="material-icons">email</i>
                {{ email }}
              </a>
            </div>
            <div class="clinic-web" *ngIf="webAddress">
              <a href="{{ webAddress }}" target="_blank" tabindex="-1">
                <i class="material-icons">launch</i>
                {{ webAddress }}
              </a>
            </div>
          </div>
        </div>
        <div fxFlex fxHide.lt-lg fxLayout="row" fxLayoutAlign="end center" id="menu" class="menu desktop-only">
          <div class="wrap-navbar-right" *ngIf="!loggedIn; else loggedIn_menu">
            <a href="javascript:void(0)" class="nav-link" *ngIf="loginEnabled" tabindex="-1" (click)="login()"
              >{{ 'login' | translate }}
            </a>
          </div>

          <ng-template #loggedIn_menu>
            <div class="wrap-navbar-right">
              <a href="javascript:void(0)" class="nav-link" (click)="topNavbarService.logout()" tabindex="-1">
                {{ 'logout' | translate }}
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </nav>
</header>
