import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../ngrx';
import { BookingActions } from '../../booking/actions/index';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChangeEmailRequest, ConfirmAccountExtendedRequest, ConfirmAccountRequest, VerifyEmailRequest } from './account.model';
@Injectable()
export class AccountService {
  user = new BehaviorSubject(undefined);

  constructor(private store: Store<fromRoot.State>, private http: HttpClient) {}

  changePassword(body) {
    return this.http.put(`${environment.apiUrl}/Account/password`, body);
  }

  changeEmail(data: ChangeEmailRequest) {
    return this.http.put(`${environment.apiUrl}/Account/change-email`, data);
  }

  changeEmailVerify(data: ChangeEmailRequest) {
    return this.http.post(`${environment.apiUrl}/Account/change-email-verification`, data);
  }

  getNotifications() {
    return this.http.get(`${environment.apiUrl}/Account/notifications`);
  }

  updateNotifications(type, receive) {
    return this.http.put(`${environment.apiUrl}/Account/notifications`, {
      type,
      receive,
    });
  }

  confirmAccountExtended(data: ConfirmAccountExtendedRequest): Observable<any> {
    return this.http.post(`${environment.apiUrl}/Account/confirm-account-extended`, data);
  }

  confirmAccount(data: ConfirmAccountRequest): Observable<any> {
    return this.http.post(`${environment.apiUrl}/Account/confirm-account`, data);
  }

  verifyAccount(data: VerifyEmailRequest): Observable<any> {
    return this.http.post(`${environment.apiUrl}/Account/verify-account`, data);
  }

  check(email, appointmentUid, token) {
    const params = {
      email: email,
      uid: appointmentUid,
      token: token,
    };

    if (!email) {
      delete params.email;
    }

    if (!appointmentUid) {
      delete params.uid;
    }

    if (!token) {
      delete params.token;
    }

    return this.http.get(`${environment.apiUrl}/Account/check`, {
      params: params,
    });
  }

  forgotPassword(email) {
    return this.http.post(`${environment.apiUrl}/Account/forgotpassword`, {
      email,
    });
  }

  resetPassword(body) {
    return this.http.post(`${environment.apiUrl}/Account/resetpassword`, body);
  }

  me() {
    return this.http.get(`${environment.apiUrl}/Account/me`).pipe(
      tap((userInfo: any) => {
        this.user.next(userInfo);
        this.store.dispatch(new BookingActions.SetExistingPatientAction(userInfo.isExisting));
      })
    );
  }
}
