import { Injectable } from '@angular/core';

/** Xamarin. Service to raise custom events and send data to the mobile app. */
@Injectable()
export class EventService {

    constructor() { }

    /** Xamarin. Raise events with data for the mobile app. Event listeners are injected from C#.
     * @param {string} name - Required. The name of the event.
     * @param {object} data - Optional. Tha data to pass in the event detail. Data is passed as string: JSON.stringify(data).
     * @example this.eventService.raise('angular_loaded');
     */
    raise(name, data?) {
        const event = new CustomEvent(name, {
            detail: {
                EventType: name,
                EventData: JSON.stringify(data)
            }
        });
        window.dispatchEvent(event);
    }
}
