import { CanActivatePreviousVisitStepGuard } from './can-activate-previous-visit-step.guard';
import { CanActivateIfTimeslotGuard } from './can-activate-if-timeslot.guard';
import { CanActivateTimeslotStepGuard } from './can-activate-timeslot-step.guard';
import { CanActivatePractitionerStepGuard } from './can-activate-practitiner-step.guard';
import { CanActivateLocationStepGuard } from './can-activate-location-step.guard';
import { CanActivateServiceStepGuard } from './can-activate-service-step.guard';
import { PractitionerStepResolver } from './practitioner-step.resolver';

export const BOOKING_GUARDS: any[] = [
    CanActivateServiceStepGuard,
    CanActivateLocationStepGuard,
    CanActivatePractitionerStepGuard,
    CanActivateTimeslotStepGuard,
    CanActivateIfTimeslotGuard,
    CanActivatePreviousVisitStepGuard,

    PractitionerStepResolver
];

export * from './can-activate-service-step.guard';
export * from './can-activate-location-step.guard';
export * from './can-activate-practitiner-step.guard';
export * from './can-activate-timeslot-step.guard';
export * from './can-activate-if-timeslot.guard';
export * from './can-activate-previous-visit-step.guard';
