import { environment } from './../../../../environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OnboardingService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Common } from '../../../shared/models/Common';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-consent-form-page',
  templateUrl: 'consent-form-page.component.html',
})
export class ConsentFormPageComponent extends BaseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  consentForm: any;
  uid: any;
  saving = false;
  loggedIn = false;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService,
    private sanitizer: DomSanitizer,
    private authHelperService: AuthHelperService
  ) {
    super();
  }

  ngOnInit() {
    const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
    if (contentContainer) {
      contentContainer.scrollIntoView();
    }

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.form = this.fb.group({});

      this.uid = params['uid'];
      const form = this.onboardingService.getForm(this.uid);

      this.parseData(form);
    });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onboardingService.setFormSkip(true);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private parseData(form) {
    if (form) {
      if (form.type === Common.FormType.ConsentForm) {
        this.consentForm = {
          uid: form.uid,
          name: form.name,
          type: form.type,
          width: form.width,
          height: this.getHeight(form.sections[0].controls, form.cellUnitHeight).toString() + 'px',
          cellUnitHeight: form.cellUnitHeight,
          totalColumns: form.sections[0].columns,
          controls: this.getControls(form.sections[0].controls, form.cellUnitHeight, form.name),
          isCompleted: form.isCompleted,
          isSigned: form.isSigned,
        };
      }
    }
  }

  private getControls(data, cellHeight, name) {
    const controls = [];

    if (data.length > 0) {
      let type: FormFieldsEnum;

      data.forEach((control) => {
        switch (control.type) {
          case Common.FormControlType.Heading:
            type = FormFieldsEnum.Heading;
            break;
          case Common.FormControlType.FreeText:
            type = FormFieldsEnum.FreeText;
            break;
          case Common.FormControlType.TextInput:
            type = FormFieldsEnum.TextInput;
            break;
          case Common.FormControlType.CustomCheckbox:
            type = FormFieldsEnum.CustomCheckbox;
            break;
          case Common.FormControlType.DateInput:
            type = FormFieldsEnum.DateInput;
            break;
          case Common.FormControlType.CheckboxList:
            type = FormFieldsEnum.CheckboxList;
            break;
          case Common.FormControlType.Slider:
            type = FormFieldsEnum.Slider;
            break;
          case Common.FormControlType.File:
            type = FormFieldsEnum.File;
            break;
          default:
            break;
        }

        // add only the file controls
        // rest of controls will be rendered in the signature-page
        if (type === FormFieldsEnum.File) {
          // set the path to the file
          if (control.settings.path) {
            const path = `${environment.resourceUrl}/files/Forms/Consent/${control.settings.path}`;
            control.settings.trustPath = this.sanitizer.bypassSecurityTrustResourceUrl(path);
            control.settings.sPath = path;
          }

          controls.push({
            key: control.id,
            type: type,
            id: control.id,
            value: control.value,
            width: control.width,
            height: control.height,
            widthPx: (control.width * cellHeight).toString() + 'px',
            heightPx: (control.height * cellHeight).toString() + 'px',
            coordX: control.x,
            coordY: control.y,
            settings: control.settings,
            fileName: name,
          });
        }
      });
    }

    return controls;
  }

  getHeight(data, cellHeight) {
    let value = 0;
    if (data.length > 0) {
      const lastCtrl = data[data.length - 1];
      // tslint:disable-next-line:radix
      value = (parseInt(lastCtrl.y) + parseInt(lastCtrl.height)) * cellHeight;
    }
    return value;
  }

  onSubmit(redirect: boolean = true) {
    if (this.consentForm.isCompleted) {
      this.navigateToNextForm(redirect);
      return;
    }
    this.saving = true;

    const data = {
      isCompleted: this.form.valid,
    };

    this.onboardingService.saveConsentForm(this.uid, data).subscribe((res) => {
      this.saving = false;
      this.navigateToNextForm(redirect);
    });
  }

  navigateToNextForm(redirect: boolean): void {
    this.onboardingService.consentForms.filter((mf) => mf.uid === this.uid)[0].isCompleted = true;
    let nextForm = false;

    // get next incomplete form
    let form = this.onboardingService.consentForms.filter((mf) => !mf.isCompleted)[0];

    // get next completed form (authenticated flow)
    if (!form && this.loggedIn) {
      const index = this.onboardingService.consentForms.findIndex((f) => f.uid === this.uid);
      if (index < this.onboardingService.consentForms.length - 1) {
        form = this.onboardingService.consentForms[index + 1];
      }
    }

    // allow redirect to next form if it's not the last one
    if (form && this.uid !== this.onboardingService.consentForms[this.onboardingService.consentForms.length - 1].uid) {
      nextForm = true;
    }

    this.onboardingService.setFormSkip(true);
    // actual redirect

    if (nextForm) {
      if (redirect) {
        this.navigateService.navigate(['onboarding', 'consent-forms', form.uid], null, true);
      }
      return;
    }

    this.onboardingService.updateSummary(Common.OnboardingSummaryForms.ConsentForms);
    // all medical forms are completed, move to next forms
    const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
    if (redirect) {
      if (uncompleted) {
        this.navigateService.navigate([uncompleted.route], null, true);
      } else {
        this.navigateService.navigate(['onboarding', 'success']);
      }
    }
  }
}
