import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { OnboardingService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { Common } from '../../../shared/models/Common';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { NavigateWrapperService } from '../../../core/services';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';

@Component({
  selector: 'pp-signature-page',
  templateUrl: 'signature-page.component.html',
})
export class SignaturePageComponent extends BaseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  formFields: FormField[] = [];
  isSignFormComplete = false;
  customFields;
  consentForm;
  parentGuardianText: string;
  allConsentFormsAreSigned$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  saving = false;
  private states: any;
  private controls;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService
  ) {
    super();
    this.states = this.route.snapshot.data.states;
  }

  ngOnInit() {
    this.form = this.fb.group({
      isParentGuardian: new FormControl(false),
      Signature: ['', Validators.required],
    });

    const consentForm = this.onboardingService.consentForms[0];
    const signForm = this.onboardingService.summaryForms.find((form) => form.id === Common.OnboardingSummaryForms.SignForms.id);

    if (signForm) {
      this.isSignFormComplete = signForm.completed;
      this.allConsentFormsAreSigned$.next(this.isSignFormComplete);
    }

    if (consentForm && consentForm.sections[0]) {
      let formControls = consentForm.sections[0].controls;
      if (formControls.length > 0) {
        // get the heading text
        const heading = formControls.filter((ctrl) => ctrl.id === 'heading_legal' && ctrl.type === Common.FormControlType.Heading)[0];
        this.parentGuardianText = heading.settings.text;

        // remove file and heading controls
        formControls = formControls.filter((ctrl) => ctrl.id !== 'heading_legal' && ctrl.type !== Common.FormControlType.File);
        this.controls = this.getControls(formControls);

        this.consentForm = {
          uid: consentForm.uid,
          name: consentForm.name,
          type: consentForm.type,
          controls: this.controls,
          isCompleted: consentForm.isCompleted,
          isSigned: consentForm.isSigned,
        };
      }
    }

    const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
    if (contentContainer) {
      contentContainer.scrollIntoView();
    }

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const isFormChangedAndFormNotCompleted = this.isSignFormComplete ? this.isSignFormComplete : this.form.pristine;
      this.onboardingService.setFormSkip(isFormChangedAndFormNotCompleted);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    this.allConsentFormsAreSigned$.complete();
    super.ngOnDestroy();
  }

  private getControls(data) {
    const controls = [];

    let type: FormFieldsEnum;
    let name: string;

    data.forEach((control) => {
      const formField: FormField = {
        key: control.id,
        label: control.settings.label,
        type: FormFieldsEnum.TextBox,
        value: '',
        validators: [Validators.required],
      };

      const customField = {
        key: control.id,
        type: FormFieldsEnum.TextBox,
        id: control.id,
        settings: control.settings,
        width: control.width,
        height: control.height,
        widthPx: control.width.toString() + 'px',
        heightPx: control.height.toString() + 'px',
        coordX: control.x,
        coordY: control.y,
      };

      // get control type
      switch (control.type) {
        case Common.FormControlType.Heading:
          customField.type = FormFieldsEnum.Heading;
          controls.push({
            type: 'CustomField',
            data: customField,
          });
          break;
        case Common.FormControlType.TextInput:
          name = control.id;
          if (name.toLowerCase().includes('name')) {
            formField.planceholder = 'full-name-placeholder';
          } else if (name.toLowerCase().includes('phone')) {
            formField.validators = [Validators.required, CustomValidators.phone];
            formField.planceholder = 'phone-eg';
          } else if (name.toLowerCase().includes('email')) {
            formField.validators = [Validators.required, CustomValidators.email];
            formField.planceholder = 'email-placeholder';
          } else if (name.toLowerCase().includes('state')) {
            formField.type = FormFieldsEnum.DropdownSingleSelect;
            formField.itemsList = this.states;
            formField.planceholder = 'select';
          }
          controls.push({
            type: 'FormField',
            data: formField,
          });
          break;
        case Common.FormControlType.FreeText:
          type = FormFieldsEnum.FreeText;
          break;
        case Common.FormControlType.CustomCheckbox:
          type = FormFieldsEnum.CustomCheckbox;
          break;
        case Common.FormControlType.DateInput:
          type = FormFieldsEnum.DateInput;
          break;
        case Common.FormControlType.CheckboxList:
          type = FormFieldsEnum.CheckboxList;
          break;
        case Common.FormControlType.Slider:
          type = FormFieldsEnum.Slider;
          break;
        case Common.FormControlType.File:
          type = FormFieldsEnum.File;
          break;
        default:
          break;
      }
    });

    return controls;
  }

  onToggle() {
    if (!this.form.value.isParentGuardian) {
      this.controls.forEach((control) => this.form.removeControl(control.data.key));
      this.form.updateValueAndValidity();
    }
  }

  onSubmit(redirect: boolean = true) {
    this.saving = true;

    if (this.form.valid) {
      const data = {
        timestamp: +new Date(),
        signature: '',
        isParentOrGuardian: false,
        fullName: '',
        phone: '',
        email: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
      };

      const formData = this.form.value;
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          if (key.toLowerCase().includes('city')) {
            data.city = formData[key];
          } else if (key.toLowerCase().includes('signature')) {
            const signature = formData[key].split(',');
            if (signature.length > 1) {
              data.signature = signature[1];
            }
          } else if (key.toLowerCase().includes('isparent')) {
            data.isParentOrGuardian = formData[key];
          } else if (key.toLowerCase().includes('fullname')) {
            data.fullName = formData[key];
          } else if (key.toLowerCase().includes('phone')) {
            data.phone = formData[key];
          } else if (key.toLowerCase().includes('email')) {
            data.email = formData[key];
          } else if (key.toLowerCase().includes('address')) {
            data.streetAddress = formData[key];
          } else if (key.toLowerCase().includes('state')) {
            data.state = formData[key];
          } else if (key.toLowerCase().includes('zipcode')) {
            data.zipCode = formData[key];
          }
        }
      }

      // sometimes this request is canceled by the framework without any reason. setTimeout is a hack to prevent this.
      setTimeout(() => {
        this.onboardingService
          .saveSignatureInfo(data)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
              this.saving = false;
              this.onboardingService.setFormSkip(true);
              this.onboardingService.updateSummary(Common.OnboardingSummaryForms.SignForms);
              if (redirect) {
                this.navigateService.navigate(['onboarding/success'], null, true);
              }
            },
            () => {
              this.saving = false;
            }
          );
      }, 200);
    }
  }
}
