import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  CountryStateComponent,
  DateInputComponent,
  FileUploadComponent,
  GenderInputComponent,
  LogoutWarningModalComponent,
  PhoneInputComponent,
  SelectListComponent,
  SignatureInputComponent,
} from './components/index';
import { SHARED_DIRECTIVES } from './directives/index';
import { SHARED_PIPES } from './pipes/index';

import { MultilingualModule } from './multilingual/multilingual.module';

// primeng
import { HttpClientModule } from '@angular/common/http';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TranslateModule } from '@ngx-translate/core';

// angular material
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SignaturePadModule } from 'angular2-signaturepad';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppointmentPickupComponent } from './components/appointment-pickup/appointment-pickup.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { RegisterInactiveUserErrorModalComponent } from './components/register-inactive-user-error-modal/register-inactive-user-error-modal.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { FormRenderComponent } from './components/form-render/form-render.component';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';
import { BaseComponent } from './components/base/base.component';
import { BookingStepsComponent } from './components/booking-steps/booking-steps.component';
import { RegisterOnboardingStepComponent } from './components/register-onboarding-step/register-onboarding-step.component';
import { PractitionerAvatarComponent } from './components/practitioner-avatar/practitioner-avatar.component';

const SHARED_MODULES: any[] = [
  BrowserModule,
  CommonModule,
  HttpClientModule,
  FormsModule,
  GoogleMapsModule,
  ReactiveFormsModule,
  RouterModule,
  MultilingualModule,
  TranslateModule,
  PdfViewerModule,
  SignaturePadModule,
  MatButtonModule,
  MatDialogModule,
  MatSelectModule,
  MatSliderModule,
  MatCheckboxModule,
  MatIconModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatListModule,
  MatTooltipModule,
  FlexLayoutModule,
];
export const SHARED_COMPONENTS: any[] = [
  DateInputComponent,
  FormRenderComponent,
  CountryStateComponent,
  GenderInputComponent,
  PhoneInputComponent,
  SelectListComponent,
  SignatureInputComponent,
  FileUploadComponent,
  SendEmailDialogComponent,
  BaseComponent,
  BookingStepsComponent,
  RegisterOnboardingStepComponent,
  AppointmentPickupComponent,
  LogoutWarningModalComponent,
  SendEmailDialogComponent,
  UserAvatarComponent,
  PractitionerAvatarComponent,
];
/**
 * SharedModule
 * Only for shared components, directives and pipes
 * Do not specify providers here
 * https://angular.io/docs/ts/latest/cookbook/ngmodule-faq.html#!#what-kinds-of-modules-should-i-have-and-how-should-i-use-them-
 */

@NgModule({
  imports: [...SHARED_MODULES],
  declarations: [
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_PIPES,
    AppointmentPickupComponent,
    RegisterInactiveUserErrorModalComponent,
  ],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
})
export class SharedModule {
  constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
    if (parentModule) {
      throw new Error('SharedModule already loaded; Import in root module only.');
    }
  }
}
