import { RegisterModel } from './../../models/register-model';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AuthService, NavigateWrapperService, StartupService } from '../../../core/services/index';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../ngrx';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../services';
import { Common } from '../../../shared/models/Common';
import { catchError } from 'rxjs/operators';
import { RegisterInactiveUserErrorModalComponent } from '../../../shared/components/register-inactive-user-error-modal/register-inactive-user-error-modal.component';

@Component({
  selector: 'pp-register-box',
  templateUrl: 'register-box.component.html',
})
export class RegisterBoxComponent implements OnInit {
  registerForm: FormGroup;
  error: any;
  returnUrl = '';
  email: string;
  token: string;
  type: string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private store: Store<fromRoot.State>,
    private navigateService: NavigateWrapperService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private accountService: AccountService,
    public dialog: MatDialog,
    private startupService: StartupService
  ) {
    this.email = this.route.snapshot.queryParams['email'];
    this.token = this.route.snapshot.params['token'];
    this.type = this.route.snapshot.params['type'];
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'appointments';

    // Need ot get the patient email for invites
    if (this.token && !this.email) {
      let appoitmentUid = null;
      let inviteUid = null;

      if (this.type === Common.RegisterType.Appointment) {
        appoitmentUid = this.token;
      }

      if (this.type === Common.RegisterType.Invite) {
        inviteUid = this.token;
      }

      if (appoitmentUid || inviteUid) {
        this.accountService.check(null, appoitmentUid, inviteUid).subscribe(
          (res: any) => {
            if (res.hasAccount) {
              return this.navigateService.navigate(['login'], {
                returnUrl: route.queryParams['returnUrl'],
                email: res.email,
              });
            } else {
              this.registerForm.get('email').setValue(res.email);
              this.email = res.email;
            }
          },
          (err) => {
            this.error = err.error;
            this.toastr.warning(this.translateService.instant(err.error));
          }
        );
      }
    }
  }

  ngOnInit() {
    this.initForm();
  }

  onSubmit() {
    const user: RegisterModel = {
      token: this.token,
      type: this.type,
      email: this.registerForm.value['email'] || this.email,
      password: this.registerForm.value['password'],
      confirmPassword: this.registerForm.value['confirmPassword'],
    };

    this.authService
      .register(user)
      .pipe(
        catchError((err) => {
          if (err?.error === 'patient-is-inactive-error') {
            this.dialog.open(RegisterInactiveUserErrorModalComponent, {
              panelClass: 'modal-error',
              data: {
                email: this.startupService.startupData.email,
                phone: this.startupService.startupData.phoneNumber,
                web: this.startupService.startupData.webAddresss,
              },
            });
            throw err;
          }

          this.error = err.error;
          this.toastr.warning(this.translateService.instant(err.error));
          throw err;
        })
      )
      .subscribe(() => {
        this.ngOnInit();

        this.authService
          .login({
            username: user['email'],
            password: user['password'],
          })
          .subscribe(() => {
            this.navigateService.navigate([this.returnUrl]);
          });
      });
  }

  initForm() {
    this.registerForm = this.formBuilder.group(
      {
        email: [this.email || '', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: CustomValidators.Match('password', 'confirmPassword') }
    );
  }
}
