import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../services';
import { AuthService, NavigateWrapperService } from '../../../core/services';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'pp-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private authService: AuthService,
    private navigateWrapperService: NavigateWrapperService
  ) {}

  ngOnInit(): void {
    if (!this.activatedRoute?.snapshot?.queryParams?.token) {
      return;
    }
    this.accountService
      .changeEmailVerify({ tokenData: this.activatedRoute.snapshot.queryParams.token })
      .pipe(
        catchError((err) => {
          this.navigateWrapperService.navigate(['booking', 'previous-visit'], {
            loginMessage: 'ChangeEmailError',
          });
          throw err;
        })
      )
      .subscribe((data) => {
        this.authService.logout();
        this.navigateWrapperService.navigate(['booking', 'previous-visit'], {
          loginMessage: 'ChangeEmailSuccess',
          email: this.activatedRoute?.snapshot?.queryParams?.email || '',
        });
      });
  }
}
