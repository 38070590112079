import { InsuranceType } from './../../models/insurance-type';
import { Practitioner } from './../../../clinic/models/practitioner';
import { Component, OnInit, Input, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { select, Store } from '@ngrx/store';
import { Service, ClinicLocation } from '../../../clinic/models/index';
import { Timeslot } from '../../models/index';
import { environment } from './../../../../environments/environment';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';

import { zip } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { ClinicService } from '../../../clinic/services';
import { find as _find, isEmpty as _isEmpty } from 'lodash-es';
import { OrganizationTypeEnum } from '../../../shared/models/organization.enum';
import { BookingActions } from '../../actions';

@Component({
  selector: 'pp-booking-summary-box',
  templateUrl: 'booking-summary-box.component.html',
  styleUrls: ['booking-summary-box.component.scss'],
})
export class BookingSummaryBoxComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() showConfirmButton: boolean = false;
  @Input() confirmDisabled: boolean;
  @Input() showLoader: boolean;
  @Output() confirmClicked: EventEmitter<void> = new EventEmitter();

  mediaBaseUrl = environment.resourceUrl;
  location: ClinicLocation;
  service: Service;
  @Input() practitioner: Practitioner;
  @Input() timeslot: Timeslot;
  insuranceType: InsuranceType;
  canChangeBookingLocation = false;
  canChangeBookingPractitioner = false;
  intern: Practitioner;
  assistingIntern: Practitioner;
  supervisor: Practitioner;
  practitioners: Practitioner[];
  organization;
  isLoadingPractitioners: boolean;

  constructor(private store: Store<fromRoot.State>, public navigateService: NavigateWrapperService, private clinicService: ClinicService) {
    super();
  }

  ngOnInit() {
    this.store
      .pipe(select(fromRoot.getOrganizationState))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.organization = response;
      });
    zip(
      this.store.select(fromRoot.getBookingLocation).pipe(first()),
      this.store.select(fromRoot.getBookingService).pipe(first()),
      this.store.select(fromRoot.getBookingPractitioner).pipe(first()),
      this.store.select(fromRoot.canChangeBookingLocation).pipe(first()),
      this.store.select(fromRoot.canChangeBookingPractitioner).pipe(first()),
      this.store.select(fromRoot.getBookingDateTime).pipe(first()),
      this.store.select(fromRoot.getBookingInsuranceType).pipe(first()),
      this.store.select(fromRoot.getBookingPractitioners)
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.location = res[0];
        this.service = res[1];
        this.practitioner = res[2];
        this.canChangeBookingLocation = res[3];
        this.canChangeBookingPractitioner = res[4];
        this.timeslot = res[5];
        if (this.timeslot?.assistingInternId || this.timeslot?.mainInternId || this.timeslot?.supervisorId) {
          this.practitioner = null;
        }
        this.insuranceType = res[6];
        this.practitioners = res[7];
        const missingPractitionerIds: number[] = [];
        if (this.timeslot?.mainInternId) {
          this.intern = _find(this.practitioners, { id: this.timeslot?.mainInternId });
          if (!this.intern) {
            missingPractitionerIds.push(this.timeslot?.mainInternId);
          }
        }
        if (this.timeslot?.assistingInternId) {
          this.assistingIntern = _find(this.practitioners, { id: this.timeslot?.assistingInternId });
          if (!this.assistingIntern) {
            missingPractitionerIds.push(this.timeslot?.assistingInternId);
          }
        }
        if (this.timeslot?.supervisorId) {
          this.supervisor = _find(this.practitioners, { id: this.timeslot?.supervisorId });
          if (!this.supervisor) {
            missingPractitionerIds.push(this.timeslot?.supervisorId);
          }
        }
        if (!_isEmpty(missingPractitionerIds)) {
          this.isLoadingPractitioners = true;
          this.clinicService.getPractitionersByIds(missingPractitionerIds).subscribe((practitioners: Practitioner[]) => {
            if (this.timeslot?.mainInternId && !this.intern) {
              this.intern = _find(practitioners, { id: this.timeslot?.mainInternId });
            }
            if (this.timeslot?.assistingInternId && !this.assistingIntern) {
              this.assistingIntern = _find(practitioners, { id: this.timeslot?.assistingInternId });
            }
            if (this.timeslot?.supervisorId && !this.supervisor) {
              this.supervisor = _find(practitioners, { id: this.timeslot?.supervisorId });
            }
            this.isLoadingPractitioners = false;
          });
        }
      });
  }

  isUniversity(): boolean {
    return this.organization?.organizationType === OrganizationTypeEnum.univeristy;
  }

  navigateTo(route, enabled = true) {
    if (enabled) {
      this.navigateService.navigate([route]);
    }
  }

  editInsurance() {
    switch (this.insuranceType) {
      case InsuranceType.Primary:
        this.navigateService.navigate(['booking', 'primary-insurance']);
        break;
      case InsuranceType.AutoAccident:
        this.navigateService.navigate(['booking', 'auto-insurance']);
        break;
      case InsuranceType.WorkAccident:
        this.navigateService.navigate(['booking', 'work-insurance']);
        break;
      default:
        this.navigateService.navigate(['booking', 'insurance-type']);
        break;
    }
  }

  changePractitioner() {
    this.resetPractitioners();
    this.resetTimeslot();
    this.navigateTo('booking/practitioner', true);
  }

  changeService() {
    this.resetPractitioners();
    this.resetService();
    this.resetTimeslot();
    this.navigateTo('booking/service', true);
  }

  changeLocation() {
    this.store.dispatch(new BookingActions.SetLocationAction(null));
    this.resetPractitioners();
    this.resetService();
    this.resetTimeslot();
    this.navigateTo('booking/location', true);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private resetService() {
    this.store.dispatch(new BookingActions.SetServiceAction(null));
  }

  private resetTimeslot() {
    this.store.dispatch(new BookingActions.SetTimeslotAction(null));
  }

  private resetPractitioners() {
    this.store.dispatch(new BookingActions.SetPractitionerAction(null));
    this.store.dispatch(new BookingActions.SetPreferredPractitionerOption(null));
  }

  protected readonly encodeURI = encodeURI;
}
