<div fxLayout="row" fxLayoutAlign="end center">
  <button mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<ng-container *ngIf="showConfirmation; else sendEmailTemplate">
  <div class="check-email">
    <div class="title-email" fxLayout="row" fxLayoutAlign="center center">{{ 'check-email-title' | translate }}</div>
    <div class="sub-title" fxLayout="row" fxLayoutAlign="center center">{{ 'verify-email' | translate }}</div>
    <div class="email-img" fxLayout="row" fxLayoutAlign="center center">
      <img src="assets/img/email-message.png" />
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      {{ 'do-not-see-email' | translate }}
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      {{ 'check-spam-folder' | translate }}
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <a class="click-resend-email"(click)="resendEmail()">Click here &nbsp;</a>
      <div>to send the email again</div>
    </div>
  </div>
</ng-container>

<ng-template #sendEmailTemplate>
  <div class="title" fxLayout="row" fxLayoutAlign="center center">{{ 'get-started' | translate }}</div>

  <div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="email-form" fxLayout="column" fxLayoutAlign="center start">
        <div class="email-label">{{ 'email' | translate }}</div>
        <div>
          <input class="email-input" matInput [formControl]="emailFormControl" placeholder="email@company.com" type="email" />
        </div>

        <mat-error *ngIf="emailFormControl.touched && emailFormControl.hasError('required')">
          {{ 'form-input-error-required' | translate }}
        </mat-error>
        <mat-error *ngIf="emailFormControl.touched && emailFormControl.hasError('email')">
          {{ 'form-input-error-email-format' | translate }}
        </mat-error>
      </div>

      <div class="create-acc-btn">
        <button mat-flat-button color="primary" [disabled]="isLoading" (click)="createAccount()">
          {{ 'create-account-button' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
