import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'pp-practitioner-avatar',
  templateUrl: './practitioner-avatar.component.html',
  styleUrls: ['./practitioner-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PractitionerAvatarComponent {
  @Input() avatarUrl: string;
  @Input() size: number;

  mediaBaseUrl = environment.resourceUrl;
}
