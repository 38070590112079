import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { SendEmailDialogComponent } from '../../../shared/components/send-email-dialog/send-email-dialog.component';
import { BookingAppointment } from '../../models';
import * as fromRoot from './../../../ngrx';
import { VerifyEmailRequest } from '../../../account/services/account.model';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-login-step-page',
  templateUrl: 'login-step-page.component.html',
  styleUrls: ['login-step-page.component.scss'],
})
export class LoginStepPageComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  appointment: BookingAppointment;
  constructor(private navigateService: NavigateWrapperService, public dialog: MatDialog, private store: Store<fromRoot.State>) {
    super();
  }
  ngOnInit(): void {
    this.store
      .select(fromRoot.getBookingAppointmentObject)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.appointment = {
          locationGuid: data.locationGuid,
          serviceId: data.serviceId,
          practitionerId: data.practitionerId,
          isNewPatient: data.isNewPatient,
          index: data.index,
          date: data.date,
        };
      });
  }

  ngAfterViewInit() {
    const contentContainer = document.querySelector('pp-booking-summary-box > div > form > div.summary-timeslot');
    if (contentContainer) {
      contentContainer.scrollIntoView();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  openCreateAccountDialog(): void {
    const dialogRef = this.dialog.open(SendEmailDialogComponent, {
      width: '400px',
      height: '450px',
      autoFocus: false,
      data: {
        email: null,
        slotData: {
          date: this.appointment.date,
          index: this.appointment.index,
          locationGuid: this.appointment.locationGuid,
          practitionerId: this.appointment.practitionerId,
          serviceId: this.appointment.serviceId,
        },
      } as VerifyEmailRequest,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        console.log('The dialog was closed');
      });
  }

  onGuestClick() {
    console.log(this.appointment);
  }
}
