<div class="box select-box box-appointment-summary">
  <h3 class="subtitle">{{ title }}</h3>
  <form class="mt-6">
    <!--Location-->
    <div fxLayout="row" fxLayout.lt-lg="column" *ngIf="location">
      <div fxFlex="33%">
        {{ 'location' | translate }}
      </div>
      <div fxFlex="67%" fxLayout="row">
        <div fxFlex="70%">
          <span>{{ location.name }}</span>
        </div>
        <div fxFlex="30%" class="text-right">
          <a [routerLink]="[]" class="edit-btn" *ngIf="canChangeBookingLocation" (click)="changeLocation()">
            {{ 'change' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!--Service-->
    <div class="mt-4 pt-4 summary-section-divider" fxLayout="row" fxLayout.lt-lg="column" *ngIf="service">
      <div fxFlex="33%">
        {{ 'service' | translate }}
      </div>
      <div fxFlex="67%" fxLayout="row">
        <div fxFlex="70%">
          <span>{{ service.name }}</span>
        </div>
        <div fxFlex="30%" class="text-right">
          <a [routerLink]="[]" class="edit-btn" (click)="changeService()">
            {{ 'change' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!--Practitioner Normal Clinic-->
    <div
      class="flex !flex-col md:!flex-row gap-2 mt-4 pt-4 summary-section-divider md:items-center"
      *ngIf="practitioner && !isUniversity()"
    >
      <div class="flex md:w-[33%]">{{ 'practitioner' | translate }}</div>
      <div class="flex md:w-[67%] items-center md:!mt-0 !mt-2">
        <div class="flex w-full">
          <ng-container *ngIf="practitioner?.id; else noPreference">
            <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: practitioner }"></ng-container>
          </ng-container>
        </div>

        <ng-template #noPreference>
          <span>{{ 'no-practitioner-prefrence' | translate }}</span>
        </ng-template>
        <div class="text-right flex">
          <a [routerLink]="[]" class="edit-btn" *ngIf="canChangeBookingPractitioner" (click)="changePractitioner()">
            {{ 'change' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!--Practitioner University Clinic Loading-->
    <div class="summary-section-divider mt-4 pt-4 flex !flex-col md:!flex-row" *ngIf="isLoadingPractitioners && isUniversity()">
      <div class="flex md:w-[33%]">
        <span>{{ 'Practitioner' | translate }}:</span>
      </div>
      <div class="flex md:w-[67%] items-center md:!mt-0 !mt-2">
        <div class="flex w-full">
          <span class="line w-[100px] m-0"></span>
        </div>
      </div>
    </div>
    <!--Practitioner University Clinic-->
    <div
      class="summary-section-divider mt-4 pt-4 flex !flex-col md:!flex-row"
      *ngIf="!isLoadingPractitioners && isUniversity() && (supervisor?.id || practitioner?.id)"
    >
      <div class="flex md:w-[33%]">
        <span *ngIf="practitioner">{{ 'Practitioner' | translate }}:</span>
        <span *ngIf="supervisor">{{ 'Supervisor' | translate }}:</span>
      </div>
      <div class="flex md:w-[67%] items-center md:!mt-0 !mt-2">
        <div class="flex w-full">
          <ng-container>
            <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: supervisor || practitioner }"></ng-container>
          </ng-container>
        </div>
        <div class="text-right flex">
          <a [routerLink]="[]" class="edit-btn" *ngIf="canChangeBookingPractitioner" (click)="changePractitioner()">
            {{ 'change' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!--Interns University Clinic-->
    <div *ngIf="!isLoadingPractitioners && (intern?.id || assistingIntern?.id) && isUniversity()" class="mt-4 flex !flex-col md:!flex-row">
      <div class="flex w-[33%]">
        <span>{{ 'Interns' | translate }}:</span>
      </div>
      <div class="flex !flex-col md:!flex-row w-[67%] md:items-center gap-2 md:!mt-0 !mt-2">
        <ng-container *ngIf="intern?.id">
          <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: intern }"></ng-container>
        </ng-container>
        <div class="md:!mt-0 !mt-1">
          <ng-container *ngIf="assistingIntern?.id">
            <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: assistingIntern }"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <!--Date-->
    <div class="mt-4 pt-4 summary-section-divider" fxLayout="row" fxLayout.lt-lg="column" *ngIf="timeslot">
      <div fxFlex="33%">
        {{ 'date' | translate }}
      </div>

      <div fxFlex="67%" fxLayout="row">
        <div fxFlex="70%">
          <span>{{ timeslot.dateObj | date: 'MMM d, y, h:mm a' }}</span>
        </div>
        <div fxFlex="30%" class="text-right">
          <a [routerLink]="[]" class="edit-btn" (click)="navigateTo('booking/timeslot')"> {{ 'change' | translate }}</a>
        </div>
      </div>
    </div>
    <!--Insurance-->
    <div class="mt-4 pt-4 summary-section-divider" fxLayout="row" fxLayout.lt-lg="column" *ngIf="insuranceType">
      <div fxFlex="33%">
        {{ 'insurance' | translate }}
      </div>
      <div fxFlex="67%" fxLayout="row">
        <div fxFlex="70%">
          <span>{{ insuranceType | translate }}</span>
        </div>
        <div fxFlex="30%" class="text-right">
          <a [routerLink]="[]" class="edit-btn" (click)="editInsurance()">{{ 'change' | translate }}</a>
        </div>
      </div>
    </div>
  </form>
  <div class="mt-4" *ngIf="showConfirmButton">
    <button class="btn btn-primary" [disabled]="confirmDisabled" (click)="confirmClicked.emit()">
      <i class="fa fa-spin fa-circle-o-notch" *ngIf="showLoader"></i>
      {{ 'confirm-appointment' | translate }}
    </button>
  </div>
  <ng-template #practitionerItem let-practitioner>
    <div class="flex gap-2 items-center">
      <pp-practitioner-avatar class="flex" [avatarUrl]="practitioner?.avatarUrl!" [size]="24"></pp-practitioner-avatar>

      <div class="flex flex-col">
        <div>
          {{ practitioner?.firstName }}
          {{ practitioner?.lastName }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
