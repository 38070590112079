import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NavigateWrapperService, StartupService } from '../core/services';
import { UpChatLibService } from '@unifiedpractice/up-chat-lib';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../shared/components/base/base.component';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    private toastr: ToastrService,
    public startupService: StartupService,
    private upChatLibService: UpChatLibService,
    private navigateWrapperService: NavigateWrapperService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.upChatLibService.channels$
      .pipe(
        filter((channels) => !!channels),
        take(1),
        switchMap(() => this.activatedRoute.params),
        takeUntil(this.destroy$)
      )
      .subscribe((params) => {
        this.upChatLibService.activateChannel(params?.channelId || '0');
      });
  }

  chatError(message: string): void {
    this.toastr.error(message);
  }

  navigateToChannel(channelId: string): void {
    if (channelId) {
      this.navigateWrapperService.navigate(['chat', channelId]);
    }
  }
}
