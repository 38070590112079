import { NoAuthGuard } from './guards/no-auth.guard';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyAccountPageComponent, RegisterPageComponent, LoginPageComponent, ResetPasswordPageComponent } from './components/index';
import { NotificationPreferencesResolver } from './guards/notification-preferences.resolver';
import { RegisterOnboardingStepComponent } from '../shared/components/register-onboarding-step/register-onboarding-step.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

export const accountRoutes: Routes = [
  {
    path: 'account',
    component: MyAccountPageComponent,
    canActivate: [AuthGuard],
    resolve: { notificationPreferences: NotificationPreferencesResolver },
  },
  { path: 'login', component: LoginPageComponent, canActivate: [NoAuthGuard] },
  { path: 'register/:token/:type', component: RegisterPageComponent, canActivate: [NoAuthGuard] },
  { path: 'reset-password', component: ResetPasswordPageComponent, canActivate: [NoAuthGuard] },
  { path: 'complete-registration', component: RegisterOnboardingStepComponent, canActivate: [NoAuthGuard] },
  { path: 'account/verify-email', component: VerifyEmailComponent },
];

@NgModule({
  imports: [
    // RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
