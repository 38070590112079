<div class="content-inner-deprecated pp-container">
  <div class="row wrap-section" *ngIf="message">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="box alert alert-info">
        <span class="wrap-icon-alert">
          <i class="material-icons info-icon">info</i>
        </span>
        {{ message | translate }}
      </div>
    </div>
  </div>
  <div class="top-title">
    {{ 'book-appointment' | translate }}
  </div>
  <div class="row wrap-section">
    <div class="col-xl-8 col-12 col-left order-2 md:order-1">
      <!--Preferred practitioner selection Step-->
      <div
        class="box select-box flex flex-col gap-4"
        *ngIf="!practitionerSelectedType && !showLoader && isUniversity && practitioners.length + interns.length > 0"
      >
        <ng-container *ngFor="let selectionType of practitionerSelectionTypes; let last = last">
          <div class="flex gap-2 hover:bg-neutral-50 cursor-pointer rounded-md p-2" (click)="selectPractitionerPreference(selectionType)">
            <div>
              <mat-icon
                [svgIcon]="
                  selectionType === 'FeelingLucky'
                    ? 'manage-accounts'
                    : selectionType === 'Practitioner'
                    ? 'person-remove'
                    : 'manage-accounts'
                "
              ></mat-icon>
            </div>
            <div class="flex flex-col gap-1">
              <div class="font-semibold">
                {{ 'PractitionerSelectionType' + selectionType | translate }}
              </div>
              <div>
                {{ 'PractitionerSelectionType' + selectionType + 'Description' | translate }}
              </div>
            </div>
          </div>
          <div *ngIf="!last" class="h-[1px] w-full bg-neutral-100"></div>
        </ng-container>
      </div>

      <div class="box select-box select-clinic-box" *ngIf="!showLoader && isUniversity && practitioners.length + interns.length === 0">
        <h3 class="subtitle">
          {{ 'title-select-practitioner' | translate }}
        </h3>
        <div class="box alert alert-info mt-4">
          <span class="wrap-icon-alert">
            <i class="material-icons info-icon">info</i>
          </span>

          {{ 'UniversityNoPractitionersAvailable' | translate }}
        </div>
      </div>

      <!--Practitioner selection Step-->
      <div class="box select-box select-clinic-box" *ngIf="!showLoader && (practitionerSelectedType || !isUniversity)">
        <h3 class="subtitle" *ngIf="practitionerSelectedType === 'Practitioner' || !isUniversity">
          {{ 'title-select-practitioner' | translate }}
        </h3>
        <h3 class="subtitle" *ngIf="practitionerSelectedType === 'Intern'">
          {{ 'title-select-practitioner-or-intern' | translate }}
        </h3>

        <div *ngIf="practitionerSelectedType === 'Intern'">
          <h5 class="my-4">{{ 'interns' | translate }}</h5>
          <div *ngIf="interns.length > 9">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'search' | translate }}"
              [ngModel]="''"
              (ngModelChange)="filterInterns($event)"
            />
          </div>
          <div class="flex flex-col gap-2">
            <ng-container *ngFor="let intern of filteredInterns">
              <div (click)="setPractitioner(intern, true)">
                <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: intern }"></ng-container>
              </div>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="practitionerSelectedType === 'Practitioner' || !isUniversity">
          <h5 class="my-4">
            {{ 'practitioners' | translate }}
          </h5>
          <div class="flex flex-col gap-2">
            <div *ngFor="let practitioner of practitioners" (click)="setPractitioner(practitioner, true)">
              <ng-container *ngTemplateOutlet="practitionerItem; context: { $implicit: practitioner }"></ng-container>
            </div>
          </div>
        </ng-container>

        <div class="row" *ngIf="practitioners.length + interns.length === 0 && !showLoader">
          <div class="col-lg-12 col-xs-12">
            <div class="box alert alert-info">
              <span class="wrap-icon-alert">
                <i class="material-icons info-icon">info</i>
              </span>
              {{ 'no-practitioners-available' | translate }}
            </div>
          </div>
        </div>
      </div>

      <!--Loader-->
      <div class="box select-box select-clinic-box" *ngIf="showLoader">
        <h3 class="subtitle">
          {{ 'title-select-practitioner' | translate }}
        </h3>
        <div class="flex flex-col gap-2 mt-4">
          <div class="flex gap-2 items-center" *ngFor="let x of [0, 1, 2]">
            <div class="avatar">
              <div class="circle"></div>
            </div>
            <div class="flex flex-col gap-2">
              <span class="line w-[80px] m-0"></span>
              <span class="line w-[100px] m-0"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-12 col-right order-1 md:order-2">
      <pp-booking-summary-box title="{{ 'appointment-summary' | translate }}" [practitioners]="practitioners"> </pp-booking-summary-box>
    </div>
  </div>
</div>

<ng-template #practitionerItem let-practitioner>
  <div class="flex gap-2 items-center cursor-pointer hover:bg-neutral-50 rounded-md py-1 pl-1">
    <pp-practitioner-avatar class="flex" [avatarUrl]="practitioner?.avatarUrl!" [size]="56"></pp-practitioner-avatar>

    <div class="flex flex-col">
      <div>
        {{ practitioner.firstName }}
        {{ practitioner.lastName }}
      </div>
      <div class="text-xs">
        {{ practitioner.description }}
      </div>
    </div>
  </div>
</ng-template>
