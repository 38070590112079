import { AbstractControl, FormControl } from '@angular/forms';

export class CustomValidators {
  /**
   * Match two controls if they are the same
   * @param firstControlName
   * @param secondControlName
   * @returns {(AC: AbstractControl) => any}
   * @constructor
   */
  static Match(firstControlName, secondControlName) {
    return (AC: AbstractControl) => {
      const firstControlValue = AC.get(firstControlName).value; // to get value in input tag
      const secondControlValue = AC.get(secondControlName).value; // to get value in input tag
      if (firstControlValue !== secondControlValue) {
        AC.get(secondControlName).setErrors({ MatchFields: true });
        // console.log(false);
      } else {
        AC.get(secondControlName).setErrors(null);
        // console.log(true);
        return null;
      }
    };
  }

  /**
   * Validate control dependend on another. If baseControl has a value, dependendControl is required.
   * @param baseControl: control on wich the validation is done.
   * @param dependendControl: control to validate if the baseControl has a value.
   * @returns {(AC: AbstractControl) => any}
   * @example
   */
  static requiredDependent(baseControl, dependendControl) {
    return (AC: AbstractControl) => {
      if (AC.get(baseControl) && AC.get(dependendControl)) {
        const baseControlValue = AC.get(baseControl).value;
        const dependendControlValue = AC.get(dependendControl).value;
        if (baseControlValue && !dependendControlValue) {
          AC.get(dependendControl).setErrors({ requiredDependent: true });
        } else {
          AC.get(dependendControl).setErrors(null);
          return null;
        }
      } else {
        AC.get(dependendControl).setErrors(null);
        return null;
      }
    };
  }

  /**
   * Validator for phone numbers. Only validates controls with values.
   * @param control: FormControl
   * @returns validation object: 'phone { invalid: true }' if the value from param (control) does not match the regex, null otherwise.
   * @example usage: validators: [Validators.required, CustomValidators.phone]
   */
  static phone(control: FormControl) {
    const phoneRegExp = new RegExp('^(?:\\+?(\\d{1,3}))?([-. (]*(\\d{3})[-. )]*)?((\\d{3})[-. ]*(\\d{2,4})(?:[-.x ]*(\\d+))?)$');
    const phoneNo = control.value;
    if (phoneNo && !phoneRegExp.test(phoneNo)) {
      return {
        phone: {
          invalid: true,
        },
      };
    }
    return null;
  }

  /**
   * Validator for emails. Only validates controls with values.
   * @param control: FormControl
   * @returns validation object: 'email { invalid: true }' if the value from param (control) does not match the regex, null otherwise.
   * @example usage: validators: [Validators.required, CustomValidators.email]
   */
  static email(control: FormControl) {
    // tslint:disable-next-line:max-line-length
    const emailRegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const email = control.value;
    if (email && !emailRegExp.test(email)) {
      return {
        email: {
          invalid: true,
        },
      };
    }
    return null;
  }
}
