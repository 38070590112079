import { NotFoundPageComponent } from './static/components/not-found-page/not-found-page.component';
import { Routes } from '@angular/router';
import { bookingRoutes } from './booking/booking-routing.module';
import { MainContainerComponent } from './main-container.component';
import { accountRoutes } from './account/account-routing.module';
import { onboardingRoutes } from './onboarding/onboarding-routing.module';
import { appointmentsRoutes } from './appointments/appointments-routing.module';
import { ChatComponent } from './chat/chat.component';
/**
 * Define app module routes here, e.g., to lazily load a module
 * (do not place feature module routes here, use an own -routing.module.ts in the feature instead)
 */
export const AppRoutes: Routes = [
  {
    path: ':clinicId',
    component: MainContainerComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'booking/previous-visit' },
      ...accountRoutes,
      ...appointmentsRoutes,
      ...bookingRoutes,
      ...onboardingRoutes,
      {
        path: 'chat',
        redirectTo: 'chat/',
        pathMatch: 'full',
      },
      { path: 'chat/:channelId', pathMatch: 'full', component: ChatComponent },
    ],
  },
  { path: '**', component: NotFoundPageComponent },
];
