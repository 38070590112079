import {
  Appointment,
  AppointmentState,
  AppointmentAcceptedState
} from "./../models/appointment";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { isBefore, addHours } from "date-fns";
import { StartupService } from "../../core/services";
import { map } from "rxjs/operators";
import { zonedTimeToUtc } from "date-fns-tz";

export enum AppointmentsFilterDirection {
  Past = 1,
  Future = 2
}
@Injectable()
export class AppointmentsService {
  constructor(
    private http: HttpClient,
    private startupService: StartupService
  ) { }

  list(query) {
    let params = new HttpParams();
    Object.keys(query).forEach(
      key => (params = query[key] ? params.append(key, query[key]) : params)
    );
    return this.http.get<any>(`${environment.apiUrl}/Appointments`, {
      params
    });
    // .map((res: any) => res.data);
  }

  getFutureAndPastAppointments(direction: number, take: number, skip: number) {
    skip = skip < 0 ? 0 : skip;
    return this.http.get<any>(`${environment.apiUrl}/Appointments?Direction=${direction}&Take=${take}&Skip=${skip}`);
  }

  get(uid, token) {
    let url = `${environment.apiUrl}/Appointments/${uid}`;

    if (token) {
      url = `${url}?token=${token}`;
    }

    return this.http.get<Appointment>(url).pipe(
      map(apt => {
        return apt;
      })
    );
  }

  cancel(appointmentUid, reason?) {
    return this.http.put(
      `${environment.apiUrl}/Appointments/${appointmentUid}/cancel`,
      { reason }
    );
  }

  getStatus(appointment) {
    let status = "";

    if (
      appointment.appointmentState ===
      AppointmentState[AppointmentState.Created]
    ) {
      status = "pending";
    }

    if (
      appointment.appointmentState ===
      AppointmentState[AppointmentState.Completed]
    ) {
      status = "completed";
    }

    if (
      appointment.appointmentState ===
      AppointmentState[AppointmentState.Accepted] &&
      appointment.acceptedState ===
      AppointmentAcceptedState[AppointmentAcceptedState.NotArrived]
    ) {
      status = "missed";
    }

    if (
      appointment.appointmentState ===
      AppointmentState[AppointmentState.Accepted] ||
      appointment.appointmentState ===
      AppointmentState[AppointmentState.Confirmed] ||
      appointment.appointmentState ===
      AppointmentState[AppointmentState.NotConfirmed]
    ) {
      status = "accepted";
    }

    if (
      appointment.appointmentState ===
      AppointmentState[AppointmentState.Rejected] ||
      (appointment.appointmentState ===
        AppointmentState[AppointmentState.Accepted] &&
        appointment.acceptedState ===
        AppointmentAcceptedState[AppointmentAcceptedState.Cancelled]) ||
      (appointment.appointmentState ===
        AppointmentState[AppointmentState.Created] &&
        appointment.acceptedState ===
        AppointmentAcceptedState[AppointmentAcceptedState.Cancelled])
    ) {
      status = "cancelled";
    }

    return status;
  }

  canCancelOrReschedule(
    status,
    bookingTime,
    canReschedule,
    minHoursPatientAllowedCancelOrReschedule
  ) {
    let currentDateTimeUTC = zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone);
    let bookingDateTimeUTC = zonedTimeToUtc(bookingTime, "UTC");

    return (
      (status === "accepted" || status === "pending") &&
      isBefore(addHours(currentDateTimeUTC, minHoursPatientAllowedCancelOrReschedule), bookingDateTimeUTC) &&
      canReschedule &&
      this.startupService.startupData.useOnlineBooking
    );
  }

  canBookSimilar() {
    return this.startupService.startupData.useOnlineBooking;
  }
}
