import { OrganizationActions } from '../actions';

export interface State {
  guid: string;
  name: string;
  hasSameAddressAsOrganization: boolean;
  country: string;
  city: string;
  zipCode: string;
  addressLine1: string;
  addressLine2: string;
  email: string;
  phoneNumber: string;
  organizationType: number;
  /** Allow patients to book appointments online */
  useOnlineBooking: boolean;
  allowCancelOrReschedule: boolean;
  allowPractitionerNoPreferenceBooking: boolean;
  acceptsInsurance: boolean;
  acceptsMajorInsurance: boolean;
  acceptsAutoAccident: boolean;
  acceptsWorkerCompensation: boolean;
  acceptedPayments: any[];
  hasSms: boolean;
  hasCcpe: boolean;
  usePatientPortal: boolean;
  useCancellationPolicy: boolean;
  cancellationPeriod: number;
  cancellationPolicyTitle: string;
  cancellationPolicyDescription: string;
}

export const initialState: State = {
  guid: null,
  name: null,
  hasSameAddressAsOrganization: null,
  country: null,
  city: null,
  zipCode: null,
  addressLine1: null,
  addressLine2: null,
  email: null,
  phoneNumber: null,
  organizationType: null,
  useOnlineBooking: null,
  allowCancelOrReschedule: null,
  allowPractitionerNoPreferenceBooking: null,
  acceptsInsurance: null,
  acceptsMajorInsurance: null,
  acceptsAutoAccident: null,
  acceptsWorkerCompensation: null,
  acceptedPayments: null,
  hasSms: null,
  hasCcpe: null,
  usePatientPortal: null,
  useCancellationPolicy: null,
  cancellationPeriod: null,
  cancellationPolicyTitle: null,
  cancellationPolicyDescription: null,
};

export function reducer(state: State = initialState, action: OrganizationActions.Actions): State {
  switch (action.type) {
    case OrganizationActions.SET_ORGANIZATION: {
      const value = action.payload;
      return Object.assign({}, state, value);
    }

    default:
      return state;
  }
}
