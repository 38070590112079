<div class="content-inner-deprecated pp-container">
  <div class="row wrap-section">
    <div class="col-xl-12 col-xs-12">
      <h1 class="top-title mt-3 mb-0">
        {{ 'booking-auth-step-title' | translate }}
      </h1>
    </div>
  </div>
  <div class="row wrap-section">
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-xs-12 col-left order-2 order-md-1">
      <div class="box select-box select-clinic-box">
        <h3 class="subtitle">{{ 'fill-in-personal-info' | translate }}</h3>
        <div class="row">
          <div class="col-12 form-row">
            {{ 'create-account-question' | translate }}
          </div>
          <div class="col-12 form-row">
            <button class="btn btn-primary btn-login" (click)="openCreateAccountDialog()">
              {{ 'create-account' | translate }}
            </button>
          </div>
        </div>
      </div>
      <pp-login-box [returnUrl]="'booking/review'"
        [showRegister]="false" boxTitle="already-have-an-account">
      </pp-login-box>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-xs-12 col-right order-1 order-md-2">
      <pp-booking-summary-box title="{{ 'appointment-summary' | translate }}"></pp-booking-summary-box>
    </div>
  </div>
</div>
