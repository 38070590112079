
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { OnboardingService } from '.';
import { first } from 'rxjs/operators';

@Injectable()
export class FormsResolver implements Resolve<any> {
    constructor(private onboardingService: OnboardingService) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        this.onboardingService.setAppointmentUid(route.queryParams['uid']);
        this.onboardingService.setToken(route.queryParams['token']);
        return this.onboardingService.getOnboardingForms();
    }
}
