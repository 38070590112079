import { catchError, map, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, NavigateWrapperService } from '.';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService,
    public location: Location
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
          if (event.body && event.body.success === false) {
            throw new HttpErrorResponse({ error: event.body });
          }
        }
      }),
      map((event) => {
        if (event instanceof HttpResponse && event.body) {
          const nevent = event.clone({
            body: event.body.data,
          });
          return nevent;
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
        } else {
          if (error.status === 401) {
            this.dialog.closeAll();
            this.authService.logout();
            this.navigateService.navigate(['login'], { returnUrl: this.location.path() });
            return throwError({ error: 'unauthorized' });
          }

          if (error.status === 404) {
            this.toastr.error(this.translateService.instant('resource-not-found'));
            return throwError({ error: 'resource-not-found' });
          }

          if (error.error) {
            // TODO
            // implement "page level errors vs general errors" logic
            // hint: set a custom header before the call is triggered
            console.error(`Backend returned code ${error.error.error}, ` + `body was: ${error.error.errorDescription}`);

            // if be raise exception it will return 200 with error object.
            if (error.status === 0) {
              this.toastr.error(error.error.errorDescription);
            }
          }

          return throwError(error.error);
        }
        // return an throwError with a user-facing error message
        return throwError('Something bad happened; please try again later.');
      })
    );
  }
}
