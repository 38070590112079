import { HelperService } from './../../../core/services/helper.service';
import { FormField } from './form-field.model';
import { FormFieldsEnum } from './fields.enum';
import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'pp-form-render',
  templateUrl: 'form-render.component.html',
})
export class FormRenderComponent implements OnInit, OnChanges {
  @Input() formFields: FormField[] = [];
  @Input() customFields: any[];
  @Input() form: FormGroup;

  @Output() onSubmit = new EventEmitter<any>();

  @ViewChild('textBoxTemplate', { static: true }) textBoxTemplate: TemplateRef<any>;
  @ViewChild('dropdownSingleChoiceTemplate', { static: true }) dropdownSingleChoiceTemplate: TemplateRef<any>;
  @ViewChild('dropdownMultipleChoiceTemplate', { static: true }) dropdownMultipleChoiceTemplate: TemplateRef<any>;
  @ViewChild('checkBoxTemplate', { static: true }) checkBoxTemplate: TemplateRef<any>;
  @ViewChild('datePickerTemplate', { static: true }) datePickerTemplate: TemplateRef<any>;
  @ViewChild('genderTemplate', { static: true }) genderTemplate: TemplateRef<any>;
  @ViewChild('phoneTemplate', { static: true }) phoneTemplate: TemplateRef<any>;

  @ViewChild('headingTemplate', { static: true }) headingTemplate: TemplateRef<any>;
  @ViewChild('freeTextTemplate', { static: true }) freeTextTemplate: TemplateRef<any>;
  @ViewChild('textInputTemplate', { static: true }) textInputTemplate: TemplateRef<any>;
  @ViewChild('dateInputTemplate', { static: true }) dateInputTemplate: TemplateRef<any>;
  @ViewChild('customCheckboxTemplate', { static: true }) customCheckboxTemplate: TemplateRef<any>;
  @ViewChild('checkboxListTemplate', { static: true }) checkboxListTemplate: TemplateRef<any>;
  @ViewChild('numberSelectTemplate', { static: true }) numberSelectTemplate: TemplateRef<any>;
  @ViewChild('filePDFTemplate', { static: true }) filePDFTemplate: TemplateRef<any>;
  @ViewChild('signatureTemplate', { static: true }) signatureTemplate: TemplateRef<any>;

  requiredValidator = Validators.required;
  pdfZoom = 1;
  pdfLoaded = false;
  os = false;
  ftp = false;

  constructor(public sanitizer: DomSanitizer, public helperService: HelperService) {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.formFields) {
      const formFields = <FormField[]>changes.formFields.currentValue;

      for (const field of formFields) {
        const control =
          field.validators && field.validators.length > 0
            ? new FormControl(field.value || this.defaultValue(field.type), field.validators)
            : new FormControl(field.value || this.defaultValue(field.type));

        if (this.form.controls[field.key]) {
          this.form.setControl(field.key, control);
        } else {
          this.form.addControl(field.key, control);
        }
      }
    }
    if (changes.customFields) {
      const customFormFields = changes.customFields.currentValue;
      for (const field of customFormFields) {
        if (field.type !== FormFieldsEnum.Heading && field.type !== FormFieldsEnum.FreeText) {
          const control = new FormControl(field.value);
          if (this.form.controls[field.key]) {
            this.form.setControl(field.key, control);
            if ((field.settings && field.settings.required) || field.type === FormFieldsEnum.Signature) {
              this.form.controls[field.key].setValidators(Validators.required);
              this.form.controls[field.key].updateValueAndValidity();
            }
          } else {
            this.form.addControl(field.key, control);

            if ((field.settings && field.settings.required) || field.type === FormFieldsEnum.Signature) {
              this.form.controls[field.key].setValidators(Validators.required);
              this.form.controls[field.key].updateValueAndValidity();
            }
          }
        }
      }
    }
  }

  getTemplate(item) {
    switch (item.type) {
      case FormFieldsEnum.TextBox:
        return this.textBoxTemplate;
      case FormFieldsEnum.DropdownSingleSelect:
        return this.dropdownSingleChoiceTemplate;
      case FormFieldsEnum.DropdownMultipleSelect:
        return this.dropdownMultipleChoiceTemplate;
      case FormFieldsEnum.CheckBox:
        return this.checkBoxTemplate;
      case FormFieldsEnum.DatePicker:
        return this.datePickerTemplate;
      case FormFieldsEnum.Gender:
        return this.genderTemplate;
      case FormFieldsEnum.Phone:
        return this.phoneTemplate;
      case FormFieldsEnum.Heading:
        return this.headingTemplate;
      case FormFieldsEnum.FreeText:
        return this.freeTextTemplate;
      case FormFieldsEnum.TextInput:
        return this.textInputTemplate;
      case FormFieldsEnum.DateInput:
        return this.dateInputTemplate;
      case FormFieldsEnum.CustomCheckbox:
        return this.customCheckboxTemplate;
      case FormFieldsEnum.CheckboxList:
        return this.checkboxListTemplate;
      case FormFieldsEnum.Slider:
        return this.numberSelectTemplate;
      case FormFieldsEnum.File:
        return this.filePDFTemplate;
      case FormFieldsEnum.Signature:
        return this.signatureTemplate;
      default:
        break;
    }
  }

  defaultValue(type: FormFieldsEnum) {
    switch (type) {
      case FormFieldsEnum.TextBox:
        return '';
      case FormFieldsEnum.DropdownSingleSelect:
        return undefined;
      case FormFieldsEnum.DropdownMultipleSelect:
        return undefined;
      case FormFieldsEnum.CheckBox:
        return false;
      case FormFieldsEnum.DatePicker:
        return undefined;
      case FormFieldsEnum.TextInput:
        return '';
      case FormFieldsEnum.CustomCheckbox:
        return undefined;
      case FormFieldsEnum.CheckboxList:
        return undefined;
      case FormFieldsEnum.Slider:
        return undefined;
      default:
        break;
    }
  }
  getNumberSelectValues(min: number, max: number, step: number): any[] {
    if (!step) {
      step = 1;
    }
    const values = [];
    const stepsTotal = (max - min) / step;
    values.push(min);
    for (let i = 0; i < stepsTotal; i++) {
      const newValue = (min += step);
      values.push(newValue > max ? max : newValue);
    }
    return values;
  }
}
