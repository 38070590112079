import { RegisterBoxComponent } from './register-box/register-box.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginBoxComponent } from './login-box/login-box.component';
import { MyAccountPageComponent } from './my-account-page/my-account-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';

export const ACCOUNT_COMPONENTS: any[] = [
    MyAccountPageComponent,
    LoginPageComponent,
    LoginBoxComponent,
    RegisterPageComponent,
    RegisterBoxComponent,
    ResetPasswordPageComponent
];

export * from './my-account-page/my-account-page.component';
export * from './login-page/login-page.component';
export * from './login-box/login-box.component';
export * from './register-box/register-box.component';
export * from './register-page/register-page.component';
export * from './reset-password-page/reset-password-page.component';
