import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pp-map-modal',
  templateUrl: 'map-modal.component.html'
})

export class MapModalComponent implements OnInit {

  markers = [];
  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  constructor(
    public dialogRef: MatDialogRef<MapModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.center = {
      lat: data.latitude,
      lng: data.longitude
    }
    this.markerPosition = {
      lat: data.latitude,
      lng: data.longitude
    }
  }

  ngOnInit() { }

  // onNoClick(): void {
  //     this.dialogRef.close();
  // }
}
