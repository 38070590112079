import { StartupService } from './../../../core/services/startup.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../services/account.service';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { ActivatedRoute } from '@angular/router';
import { NotificationsEnum } from '../../models/profile-model';
import { AuthService, NavigateWrapperService } from '../../../core/services';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { PhoneTypeEnum } from '../../../shared/models/phoneType.enum';
import { GenderEnum } from '../../../shared/models/gender.enum';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { parseISO } from 'date-fns';

@Component({
  selector: 'pp-my-account-page',
  templateUrl: 'my-account-page.component.html',
  styleUrls: ['my-account-page.component.scss'],
})
export class MyAccountPageComponent extends BaseComponent implements OnInit, OnDestroy {
  profileForm: FormGroup;
  reminderForm: FormGroup;
  changePasswordForm: FormGroup;
  fields: FormField[] = [];
  userInfo: any = {};

  profileReadonlyMode = true;
  accountUpdatedSuccessfully = false;
  resetPasswordReadonlyMode = true;
  clinicHasSmsModuleEnabled = false;
  messageDisplayTime = 10000; // ms
  editEmail: boolean = false;
  newEmail: string = '';

  private dialogRef: MatDialogRef<any>;
  @ViewChild('sendEmailDialog', { static: true }) sendEmailDialog: TemplateRef<any>;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private startupService: StartupService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private navigateService: NavigateWrapperService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.setUserInfo();
    window.scrollTo(0, 0);
    this.clinicHasSmsModuleEnabled = this.startupService.startupData.hasSms;
    this.initPasswordResetSection();
    this.initNotificationsSection();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private setUserInfo() {
    const data = this.accountService.user.getValue();

    this.userInfo.firstName = data.personalInfo.firstName;
    this.userInfo.lastName = data.personalInfo.lastName;
    this.userInfo.email = data.contactInfo.emailAddress1 || data.contactInfo.emailAddress2;
    this.userInfo.phone = data.contactInfo.phoneNumber1 || data.contactInfo.phoneNumber2;
    this.userInfo.phoneType = data.contactInfo.phoneNumber1TypeId
      ? PhoneTypeEnum[data.contactInfo.phoneNumber1TypeId]
      : PhoneTypeEnum[data.contactInfo.phoneNumber2TypeId];

    if (data.personalInfo.birthDate) {
      this.userInfo.birthDate = parseISO(data.personalInfo.birthDate);
    }

    if (data.personalInfo.gender) {
      this.userInfo.gender =
        data.personalInfo.gender.toUpperCase() === GenderEnum.Male
          ? 'gender-male'
          : data.personalInfo.gender.toUpperCase() === GenderEnum.Female.toUpperCase()
          ? 'gender-female'
          : 'gender-other';
    }
    this.userInfo.otherGender = data.personalInfo.otherGender;
    this.userInfo.pendingEmail = data.pendingEmail;
  }

  onEnableProfileEditClick() {
    this.enableProfileFormInputs();
    this.accountUpdatedSuccessfully = false;
  }

  onCancelProfileEditClick() {
    this.disableProfileFormInputs();
  }

  enableProfileFormInputs() {
    this.profileReadonlyMode = false;
    this.enableForm(this.profileForm.value);
  }

  disableProfileFormInputs() {
    this.profileReadonlyMode = true;
    this.disableForm(this.profileForm);
  }

  enableResetPasswordFormInputs() {
    this.resetPasswordReadonlyMode = false;

    this.enableForm(this.changePasswordForm);
  }

  disableResetPasswordFormInputs() {
    this.resetPasswordReadonlyMode = true;
    this.initPasswordResetSection();
  }

  onChangePasswordClick() {
    if (!this.changePasswordForm.valid) {
      return;
    }
    this.accountService.changePassword(this.changePasswordForm.value).subscribe(
      (res) => {
        this.auth.logout();
        this.disableResetPasswordFormInputs();
        this.toastr.success(this.translateService.instant('reset-password-successfully'));
        this.navigateService.navigate(['login'], { returnUrl: 'appointments' });
      },
      (err) => {
        this.toastr.error(this.translateService.instant('reset-password-error'));
      }
    );
  }

  onToogleNotificationClick(type, receive) {
    this.accountService.updateNotifications(type, receive).subscribe(
      (res) => {
        this.toastr.success(this.translateService.instant('account-updated-successfully'));
      },
      (err) => this.toastr.error(this.translateService.instant('account-updated-error'))
    );
  }

  private enableForm(form) {
    Object.keys(form.value).forEach((key) => form.get(key).enable());
  }

  private disableForm(form) {
    Object.keys(form.value).forEach((key) => form.get(key).disable());
  }

  private initPasswordResetSection() {
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: new FormControl({ value: '', disabled: true }, [Validators.required]),
        password: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl({ value: '', disabled: true }, [Validators.required]),
      },
      {
        validator: CustomValidators.Match('password', 'confirmPassword'),
      }
    );
  }

  private initNotificationsSection() {
    const preferences = this.route.snapshot.data.notificationPreferences;

    this.reminderForm = this.formBuilder.group({
      email: preferences.receiveMarketingEmailNotification,
      sms: preferences.receiveSmsNotification,
    });

    this.reminderForm
      .get('email')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.onToogleNotificationClick(NotificationsEnum.email, val);
      });

    this.reminderForm
      .get('sms')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (!this.userInfo.phone && val) {
          this.toastr.error(this.translateService.instant('no-phone-number-sms'));
          this.reminderForm.controls.sms.setValue(false, { emitEvent: false });
          return;
        }

        if (!this.userInfo.phone) {
          this.reminderForm.controls.sms.setValue(false, { emitEvent: false });
          this.onToogleNotificationClick(NotificationsEnum.sms, false);
          return;
        }

        if (this.userInfo.phoneType !== PhoneTypeEnum[PhoneTypeEnum.Mobile]) {
          this.toastr.error(this.translateService.instant('no-mobile-phone-number'));
          this.reminderForm.controls.sms.setValue(false, { emitEvent: false });
          return;
        }
        this.onToogleNotificationClick(NotificationsEnum.sms, val);
      });
  }

  public toggleEmailChange(): void {
    this.editEmail = !this.editEmail;

    if (!this.editEmail) this.newEmail = '';
  }

  public saveEmailChange(): void {
    this.accountService.changeEmail({ email: this.userInfo?.pendingEmail || this.newEmail }).subscribe((data) => {
      this.dialogRef = this.dialog.open(this.sendEmailDialog);
      this.accountService.me().subscribe(() => {
        this.setUserInfo();
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        this.toggleEmailChange();
      });
      this.toastr.success('Email sent!');
    });
  }

  public resendEmailChange(): void {
    this.accountService.changeEmail({ email: this.userInfo?.pendingEmail || this.newEmail }).subscribe((data) => {
      this.toastr.success('Email sent!');
      this.accountService.me().subscribe(() => {
        this.setUserInfo();
      });
      this.closeChangeEmailDialog();
    });
  }

  public closeChangeEmailDialog(): void {
    this.dialogRef.close();
  }
}
