<div class="box select-box select-clinic-box"
  *ngIf="!showLoader">
  <h3 class="subtitle d-none d-sm-block">
    {{'contact-information'
    |
    translate}}</h3>

  <form class="form-full-width" [formGroup]="form"
    novalidate>

    <div class="row">
      <pp-form-render [formFields]="fields1" [form]="form">
      </pp-form-render>

      <div [formGroup]="form" class="col-12 form-row">
        <pp-country-state formControlName="countryState"
          name="countryState">
        </pp-country-state>

        <div
          *ngIf="form.get('countryState') && form.get('countryState').invalid && (form.get('countryState').dirty || form.get('countryState').touched)"
          class="col-12 form-row">
          <p class="error-message-field"
            *ngIf="form.get('countryState').errors.stateError">
            {{ 'form-input-error-state-error' | translate}}
          </p>
          <p class="error-message-field"
            *ngIf="form.get('countryState').errors.required">
            {{ 'form-input-error-required' | translate}}
          </p>
        </div>
      </div>

      <pp-form-render [formFields]=" fields2"
        [form]="form "></pp-form-render>
      <pp-form-render [formFields]="fields3" [form]="form ">
      </pp-form-render>
    </div>

  </form>

  <div class="row">
    <div class="col-lg-12 col-xs-12 form-row">
      <button type="submit" class="btn btn-primary"
        (valid)="onSubmit()" [disabled]="saving"
        [ppSubmitIfValid]="form">
        <i class="fa fa-spin fa-circle-o-notch"
          *ngIf="saving"></i>
        {{'save-and-continue' | translate}}
      </button>
    </div>
  </div>

</div>


<div class="box select-box select-clinic-box "
  *ngIf="showLoader ">
  <h3 class="subtitle d-none d-sm-block">
    {{'contact-information'
    |
    translate}}</h3>
  <div class="row " *ngFor="let x of loaderRows"
    style="padding-bottom: 30px ">
    <div class="col-lg-12 col-xs-12 ">
      <div class="line width-100 "></div>
      <div class="line width-100 "></div>
    </div>
  </div>
</div>
