<ng-template
  *ngFor="let item of formFields; let i = index"
  [ngTemplateOutlet]="getTemplate(item)"
  [ngTemplateOutletContext]="{ $implicit: item, index: i, formgr: form }"
>
</ng-template>

<ng-template #textBoxTemplate let-item let-formg="formgr">
  <div [formGroup]="formg" class="col-12 form-row">
    <label
      class="label-form-control"
      [ngClass]="{ 'required-field-label': item.validators && item.validators.indexOf(requiredValidator) > -1 }"
      >{{ item.label | translate }}</label
    >
    <input
      class="form-control"
      placeholder="{{ item.planceholder | translate }}"
      type="text"
      [name]="item.key"
      [formControlName]="item.key"
      [readonly]="item.readonly"
    />

    <div *ngIf="form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)" class=" ">
      <p class="error-message-field" *ngIf="form.get(item.key).errors.required">
        {{ (item.errorMessages && item.errorMessages.required ? item.errorMessages.required : 'form-input-error-required') | translate }}
      </p>
      <p class="error-message-field" *ngIf="form.get(item.key).errors.maxlength">
        {{ (item.errorMessages && item.errorMessages.maxlength ? item.errorMessages.maxlength : 'form-input-error-maxlength') | translate }}
      </p>
      <p class="error-message-field" *ngIf="form.get(item.key).value && form.get(item.key).errors.email">
        {{ (item.errorMessages && item.errorMessages.email ? item.errorMessages.email : 'form-input-error-email-format') | translate }}
      </p>
      <p class="error-message-field" *ngIf="form.get(item.key).value && form.get(item.key).errors.phone">
        {{ (item.errorMessages && item.errorMessages.phone ? item.errorMessages.phone : 'form-input-error-phone-format') | translate }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #dropdownSingleChoiceTemplate let-item let-formg="formgr">
  <div class="col-12 form-row">
    <label
      class="label-form-control"
      [ngClass]="{ 'required-field-label': item.validators && item.validators.indexOf(requiredValidator) > -1 }"
      >{{ item.label | translate }}</label
    >
  </div>

  <div [formGroup]="formg" class="col-sm-6 col-12 form-row wrap-multiple-form-controls">
    <mat-select
      class="form-control form-control-select form-render"
      placeholder="{{ item.planceholder || 'select' | translate }}"
      [formControlName]="item.key"
    >
      <mat-option value="" *ngIf="!item.validators || (item.validators && item.validators.indexOf(requiredValidator) === -1)"
        >{{ 'select' | translate }}
      </mat-option>
      <mat-option *ngFor="let opt of item.itemsList" [value]="opt.value">{{ opt.label | translate }} </mat-option>
    </mat-select>
  </div>

  <div
    *ngIf="form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)"
    class="col-12 form-row"
  >
    <p class="error-message-field" *ngIf="form.get(item.key).errors.required">
      {{ (item.errorMessages && item.errorMessages.required ? item.errorMessages.required : 'form-input-error-required') | translate }}
    </p>
  </div>
</ng-template>

<ng-template #dropdownMultipleChoiceTemplate let-item let-formg="formgr">
  <div class="col-12 form-row">
    <label
      class="label-form-control"
      [ngClass]="{ 'required-field-label': item.validators && item.validators.indexOf(requiredValidator) > -1 }"
      >{{ item.label | translate }}</label
    >
  </div>

  <div [formGroup]="formg" class="col-sm-6 col-12 form-row wrap-multiple-form-controls">
    <mat-select
      class="form-control form-control-select form-render"
      placeholder="{{ item.planceholder || 'select' | translate }}"
      [formControlName]="item.key"
      multiple
    >
      <!--
                <mat-option value="" *ngIf="!item.validators || (item.validators && item.validators.indexOf(requiredValidator) === -1)">{{ 'select' | translate }}
                </mat-option> -->
      <mat-option *ngFor="let opt of item.itemsList" [value]="opt.value">{{ opt.label | translate }} </mat-option>
    </mat-select>
  </div>

  <div *ngIf="form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)" class=" ">
    <p class="error-message-field" *ngIf="form.get(item.key).errors.required">
      {{ (item.errorMessages && item.errorMessages.required ? item.errorMessages.required : 'form-input-error-required') | translate }}
    </p>
  </div>
</ng-template>

<ng-template #checkBoxTemplate let-item let-formg="formgr">
  <div [formGroup]="formg" class="col-12 form-row wrap-switch-row">
    <div class="col-10">
      <label
        class="label-form-control"
        [ngClass]="{ 'required-field-label': item.validators && item.validators.indexOf(requiredValidator) > -1 }"
        >{{ item.label | translate }}
      </label>
    </div>
    <div class="col-2">
      <div class="md-switch">
        <input type="checkbox" id="switch-{{ item.key }}" class="switch-input" [name]="item.key" [formControlName]="item.key" />
        <label for="switch-{{ item.key }}" class="switch-label">
          <span class="toggle-on"></span>
          <span class="toggle-off"></span>
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #datePickerTemplate let-item let-formg="formgr">
  <div [formGroup]="formg" class="col-12 form-row">
    <label
      class="label-form-control"
      [ngClass]="{ 'required-field-label': item.validators && item.validators.indexOf(requiredValidator) > -1 }"
      >{{ item.label | translate }}
    </label>
    <pp-date-input [formControlName]="item.key"> </pp-date-input>
  </div>
  <div
    *ngIf="form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)"
    class="col-12 form-row"
  >
    <p class="error-message-field" *ngIf="form.get(item.key).errors.required">
      {{ (item.errorMessages && item.errorMessages.required ? item.errorMessages.required : 'form-input-error-required') | translate }}
    </p>
    <p class="error-message-field" *ngIf="form.get(item.key).errors.invalidDate">
      {{ (item.errorMessages && item.errorMessages.invalidDate ? item.errorMessages.invalidDate : 'form-input-error-date') | translate }}
    </p>
  </div>
</ng-template>

<ng-template #genderTemplate let-item let-formg="formgr">
  <div [formGroup]="formg" class="col-12 form-row">
    <label
      class="label-form-control"
      [ngClass]="{ 'required-field-label': item.validators && item.validators.indexOf(requiredValidator) > -1 }"
      >{{ item.label | translate }}
    </label>

    <pp-gender-input [formControlName]="item.key"> </pp-gender-input>
  </div>
  <div *ngIf="form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)" class="col-12 form-row">
    <p class="error-message-field" *ngIf="form.get(item.key).errors">
      {{ (item.errorMessages && item.errorMessages.gender ? item.errorMessages.gender : 'form-input-error-required') | translate }}
    </p>
  </div>
</ng-template>

<ng-template #phoneTemplate let-item let-formg="formgr">
  <div [formGroup]="formg" class="col-12 form-row">
    <label
      class="label-form-control"
      [ngClass]="{ 'required-field-label': item.validators && item.validators.indexOf(requiredValidator) > -1 }"
      >{{ item.label | translate }}
    </label>
    <pp-phone-input [formControlName]="item.key"> </pp-phone-input>
    <div
      *ngIf="form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)"
      class="col-12 form-row"
    >
      <p class="error-message-field" *ngIf="form.get(item.key).errors.phone">
        {{ 'form-input-error-phone-format' | translate }}
      </p>

      <p class="error-message-field" *ngIf="form.get(item.key).errors.phoneType">
        {{ 'form-input-error-phoneType-format' | translate }}
      </p>

      <p
        class="error-message-field"
        *ngIf="form.get(item.key).errors.required && !form.get(item.key).errors.phone && !form.get(item.key).errors.phoneType"
      >
        {{ (item.errorMessages && item.errorMessages.required ? item.errorMessages.required : 'form-input-error-required') | translate }}
      </p>
    </div>
  </div>
</ng-template>

<!--
    Used for Onboarding Forms: Medical (form-list-page) & Consent (consent-list-page).
    item.key:                   control name
    item.type:                  template type
    item.id:                    item id
    item.value:                 item value
    item.width:                 width (used for styling. see onboarding-forms.scss)
    item.height:                height (used for styling. see onboarding-forms.scss)
    item.widthPx:               width in px (item.widthPx = item.width * cellUnitHeight) cellUnitHeight = 10px (default)
    item.heightPx:              height in px (item.heightPx = item.height * cellUnitHeight) cellUnitHeight = 10px (default)
    item.coordX:                x axis position in columns (see onboardinf-forms.scss - styling for widths) min:1 max:12
    item.coordY:                y axis position
    item.settings.style:        css style
    item.settings.text:         text (used in spans)
    item.settings.class:        css class
    item.settings.label:        label
    item.settings.html:         html (used on free text controls with styling)
    item.settings.rows:         number of rows for textbox
    item.settings.max:          slider: max value
    item.settings.min:          slider: min value
    item.settings.start:        slider: value
    item.settings.steps:        slider: steps
    item.settings.isSingle:     checkbox or radio list (isSingle == true => radio list, else checkbox list)
    item.settings.columns:      checkbox list / radio list number of columns for options (1 => col-**-12; 2 => col-**-6; 3 => col-**-4)
    item.settings.options:      checkbox list / radio list options (labels)
    item.settings.path:         file path
-->

<ng-template
  *ngFor="let item of customFields; let i = index"
  [ngTemplateOutlet]="getTemplate(item)"
  [ngTemplateOutletContext]="{ $implicit: item, index: i, formgr: form }"
>
</ng-template>

<ng-template #headingTemplate let-item let-formg="formgr">
  <div
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content">
      <div [ngStyle]="item.settings.style" style="margin-bottom: 15px">
        <span>{{ item.settings.text }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #freeTextTemplate let-item let-formg="formgr">
  <div
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content" style="margin-bottom: 15px" [innerHTML]="item.settings.html"></div>
  </div>
</ng-template>

<ng-template #textInputTemplate let-item let-formg="formgr">
  <div
    [formGroup]="formg"
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content">
      <label class="label-form-control"
        >{{ item.settings.label | translate }} <span style="color: red" *ngIf="item.settings.required">*</span>
      </label>
      <input
        [ngClass]="{
          'form-invalid-border':
            form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)
        }"
        class="form-control"
        type="text"
        [name]="item.key"
        [formControlName]="item.key"
        *ngIf="!item.settings.rows"
      />
      <textarea
        [rows]="item.settings.rows"
        class="form-control"
        [name]="item.key"
        [formControlName]="item.key"
        *ngIf="item.settings.rows"
      ></textarea>
    </div>
  </div>
</ng-template>

<ng-template #signatureTemplate let-item let-formg="formgr">
  <div
    [formGroup]="formg"
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content">
      <pp-signature-input formControlName="{{ item.key }}"> </pp-signature-input>
      <div *ngIf="form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)" class="col-12 form-row">
        <p class="error-message-field">
          {{ 'signature-required' | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dateInputTemplate let-item let-formg="formgr">
  <div
    [formGroup]="formg"
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content">
      <label class="label-form-control"
        >{{ item.settings.label | translate }} <span style="color: red" *ngIf="item.settings.required">*</span></label
      >
      <pp-date-input
        [ngClass]="{
          'form-invalid-border':
            form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)
        }"
        [formControlName]="item.key"
      >
      </pp-date-input>
    </div>
  </div>
</ng-template>

<ng-template #customCheckboxTemplate let-item let-formg="formgr">
  <div
    [formGroup]="formg"
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content">
      <label class="label-form-control"
        >{{ item.settings.label | translate }} <span style="color: red" *ngIf="item.settings.required">*</span></label
      >
      <div
        class="form-group form-inline"
        [ngClass]="{
          'form-invalid-border':
            form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)
        }"
      >
        <div class="md-radio yes-checkbox">
          <input class="form-control" type="radio" [formControlName]="item.key" [id]="item.id + 'yes'" [value]="true" />
          <label class="radio-inline" [for]="item.id + 'yes'">Yes</label>
        </div>
        <div class="md-radio">
          <input class="form-control" type="radio" [formControlName]="item.key" [id]="item.id + 'no'" [value]="false" />
          <label class="radio-inline" [for]="item.id + 'no'">No</label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #checkboxListTemplate let-item let-formg="formgr">
  <div
    [formGroup]="formg"
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content">
      <label class="label-form-control"
        >{{ item.settings.label | translate }} <span style="color: red" *ngIf="item.settings.required">*</span></label
      >
      <div
        style="padding: 5px"
        [ngClass]="{
          'form-invalid-border':
            form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)
        }"
      >
        <pp-select-list [item]="item" [formControlName]="item.key"> </pp-select-list>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #numberSelectTemplate let-item let-formg="formgr">
  <div
    [formGroup]="formg"
    [attr.data-gs-x]="item.coordX"
    [attr.data-gs-y]="item.coordY"
    [attr.data-gs-width]="item.width"
    [attr.data-gs-height]="item.height"
    class="grid-stack-item col-12"
    [ngStyle]="{ 'min-height': item.heightPx }"
    [id]="item.id"
  >
    <div class="grid-stack-item-content">
      <label class="label-form-control"
        >{{ item.settings.label | translate }} <span style="color: red" *ngIf="item.settings.required">* </span></label
      >
      <mat-select
        [ngClass]="{
          'form-invalid-border':
            form.get(item.key) && form.get(item.key).invalid && (form.get(item.key).dirty || form.get(item.key).touched)
        }"
        class="form-control form-control-select form-render"
        placeholder="{{ item.planceholder || 'select' | translate }}"
        [(ngModel)]="item.settings.start"
        style="padding: 5px"
        [formControlName]="item.key"
      >
        <mat-option value="" *ngIf="!item.validators || (item.validators && item.validators.indexOf(requiredValidator) === -1)">
          {{ 'select' | translate }}
        </mat-option>
        <mat-option *ngFor="let opt of getNumberSelectValues(item.settings.min, item.settings.max, item.settings.steps)" [value]="opt"
          >{{ opt }}
        </mat-option>
      </mat-select>
    </div>
  </div>
</ng-template>

<ng-template #filePDFTemplate let-item let-formg="formgr">
  <div [formGroup]="formg" class="row">
    <div class="pdf-viewer-file-name">
      <h3>{{ item.fileName }}</h3>
    </div>
    <div class="box alert alert-warning pdf-viewer-loading" role="alert" *ngIf="!pdfLoaded">
      {{ 'file-is-loading' | translate }}
    </div>
    <div class="col-12" *ngIf="!helperService.mobileAndTabletcheck()">
      <pdf-viewer
        [src]="item.settings.sPath"
        [original-size]="false"
        [show-all]="true"
        [render-text]="true"
        [zoom]="pdfZoom"
        style="width: 100%; height: 800px"
        (after-load-complete)="pdfLoaded = true"
      >
      </pdf-viewer>
    </div>
    <div class="col-12" *ngIf="helperService.mobileAndTabletcheck()">
      <pdf-viewer
        [src]="item.settings.sPath"
        [original-size]="false"
        [show-all]="true"
        [render-text]="true"
        [zoom]="pdfZoom"
        style="width: 100%; height: 300px"
        (after-load-complete)="pdfLoaded = true"
      >
      </pdf-viewer>
    </div>
    <div class="o-pdfcontrols">
      <button class="pdfcontrols__zoom pdfcontrols__zoom--zoomin" (click)="pdfZoom = pdfZoom + 0.3">
        <span class="pdfcontrols__icon pdfcontrols__icon--zoomin"></span>
        {{ 'zoom-in' | translate }}
      </button>
      <button class="pdfcontrols__zoom pdfcontrols__zoom--zoomout" (click)="pdfZoom = pdfZoom - 0.3">
        <span class="pdfcontrols__icon pdfcontrols__icon--zoomout"></span>
        {{ 'zoom-out' | translate }}
      </button>

      <a class="pdfcontrols__open" [href]="item.settings.sPath" target="_blank">
        {{ 'open-in-new-window' | translate }}
        <span class="pdfcontrols__icon pdfcontrols__icon--open"></span>
      </a>
    </div>
  </div>
</ng-template>
