import { AuthHelperService } from './../../core/services/auth-helper.service';
import { getBookingExistingPatient } from './../../ngrx/index';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as fromRoot from './../../ngrx';
import { BookingActions } from '../actions/index';
import { Store } from '@ngrx/store';
import { StartupService, NavigateWrapperService } from '../../core/services';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CanActivatePreviousVisitStepGuard implements CanActivate, OnDestroy {
  private loggedIn = false;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private authHelperService: AuthHelperService,
    private store: Store<fromRoot.State>,
    private startupService: StartupService,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService
  ) {
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((val) => (this.loggedIn = val));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.loggedIn) {
      return true;
    } else if (!this.startupService.startupData.useOnlineBooking) {
      this.navigateService.navigate(['appointments']);

      return false;
    }
    // go to location page
    this.navigateService.navigate(['booking', 'location']);

    return false;
  }
}
