import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthHelperService, NavigateWrapperService, StartupService } from '../../core/services/index';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CanActivateAppointmentPageGuard implements CanActivate, OnDestroy {
  private loggedIn = false;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private authHelperService: AuthHelperService,
    private navigateService: NavigateWrapperService,
    private startupService: StartupService
  ) {
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((val) => (this.loggedIn = val));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.loggedIn) {
      this.navigateService.navigate(['appointments'], { returnUrl: state.url });

      return false;
    }

    if (!this.startupService.startupData.usePatientPortal) {
      return true;
    }

    // go to verify page
    this.navigateService.navigate(['appointments/' + route.params.uid + '/verify'], { returnUrl: state.url });

    return false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
