import { Injectable } from '@angular/core';
import {
  FullSteamAuthenticationModel,
  FullSteamAuthenticationResponse,
  SaleSuccess,
  UpFullSteamIntegrationService,
  UpFullSteamScript,
  CardProfile,
} from '@unifiedpractice/up-ng-fullsteam';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FullSteamIntegrationClient } from './full-steam-integration.client';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FullSteamIntegrationService extends UpFullSteamIntegrationService {
  jqueryJSLibrary: UpFullSteamScript = {
    name: 'jquery',
    src: environment.jqueryJSLibrary,
  };
  fullSteamJSLibrary: UpFullSteamScript = {
    name: 'fullsteam',
    src: environment.fullSteamJSLibrary,
  };

  constructor(private fullSteamIntegrationClient: FullSteamIntegrationClient, private translateService: TranslateService) {
    super();
  }

  fullSteamSaleSuccess(response: string): Observable<SaleSuccess> {
    return of();
  }
  getFullSteamAuthenticationKey(authenticationModel: FullSteamAuthenticationModel): Observable<FullSteamAuthenticationResponse> {
    return this.fullSteamIntegrationClient.getFullSteamAuthenticationKey(authenticationModel);
  }
  translateString(str: string, params?: any): string {
    return this.translateService.instant(str, params);
  }

  fullSteamSaveCard(request: string): Observable<{ data: number; messages: [] }> {
    // @ts-ignore
    return this.fullSteamIntegrationClient.fullSteamSaveCard(request).pipe(map((d) => ({ data: d })));
  }

  getCards(userId: number): Observable<CardProfile[]> {
    return this.fullSteamIntegrationClient.getCards();
  }
}
