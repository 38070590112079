import { SharedModule } from './../shared/shared.module';
import { ONBOARDING_SERVICES } from './services/index';
import { ONBOARDING_COMPONENTS } from './components/index';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { OnboardingSummaryDialogComponent } from './components/onboarding-summary-box/onboarding-summary-dialog.component';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [SharedModule, OnboardingRoutingModule, FlexLayoutModule],
  declarations: [...ONBOARDING_COMPONENTS],
  providers: [...ONBOARDING_SERVICES]
})
export class OnboardingModule {
  constructor(@Optional() @SkipSelf() parentModule: OnboardingModule) {
    if (parentModule) {
      throw new Error('OnboardingModule already loaded; Import in root module only.');
    }
  }
}
