import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnboardingFormsEnum } from '../../models';

@Component({
    selector: 'pp-medical-form-page',
    templateUrl: 'medical-form-page.component.html'
})

export class MedicalFormPageComponent implements OnInit {
  public get formTypes() {
    return OnboardingFormsEnum;
  }
    ngOnInit() {}
}
