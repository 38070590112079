import { Common } from './../../../shared/models/Common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { StartupService } from '../../../core/services';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-primary-insurance-step-page',
  templateUrl: 'primary-insurance-step-page.component.html',
})
export class PrimaryInsuranceStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  primaryInsurance: FormGroup;
  insuredInfo: FormGroup;
  primaryInsFields: FormField[] = [];
  insuredInfoField: FormField[] = [];
  insuranceCompanies = [];
  filteredOptions: Observable<string[]>;

  private states: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private startupService: StartupService,
    private route: ActivatedRoute,
    public navigateService: NavigateWrapperService
  ) {
    super();
    this.states = this.route.snapshot.data.states;
  }

  ngOnInit() {
    this.startupService.startupData.insuranceCompanies.forEach((c) => {
      this.insuranceCompanies.push(c.name);
    });

    this.insuredInfo = this.formBuilder.group({});
    this.primaryInsurance = this.formBuilder.group({
      companyName: new FormControl('', [Validators.required]),
      insuranceIsCoveredByOther: new FormControl(false),
    });

    this.filteredOptions = this.primaryInsurance.get('companyName').valueChanges.pipe(map((val) => this.filter(val)));

    this.primaryInsFields = [
      {
        key: 'planType',
        label: 'planType',
        planceholder: 'planType-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300)],
      },
      {
        key: 'cardID',
        label: 'insuranceCardID',
        planceholder: 'insuranceCardID-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300), Validators.required],
      },
      {
        key: 'groupNumber',
        label: 'groupNumber',
        planceholder: 'groupNumber-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300)],
      },
      {
        key: 'providerPhone',
        label: 'insuranceProviderPhone',
        planceholder: 'insuranceProviderPhone-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300), CustomValidators.phone],
      },
    ];

    this.insuredInfoField = [
      {
        key: 'patientRelationtionshipToInsuredType',
        label: 'patientRelationtionshipToInsuredType',
        type: FormFieldsEnum.DropdownSingleSelect,
        itemsList: Common.InsuranceRelationships,
      },
      {
        key: 'firstName',
        label: 'insuredInfoFirstName',
        planceholder: 'insuredInfoFirstName-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300), Validators.required],
      },
      {
        key: 'middleName',
        label: 'insuredInfoMiddleName',
        planceholder: 'insuredInfoMiddleName-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300)],
      },
      {
        key: 'lastName',
        label: 'insuredInfoLastName',
        planceholder: 'insuredInfoLastName-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.maxLength(300), Validators.required],
      },
      {
        key: 'dateOfBirth',
        label: 'insuredInfoDateOfBirth',
        type: FormFieldsEnum.DatePicker,
      },
    ];

    this.primaryInsurance
      .get('insuranceIsCoveredByOther')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.primaryInsurance.addControl('insuredInfo', this.insuredInfo);
        } else {
          this.primaryInsurance.removeControl('insuredInfo');
        }
      });

    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  filter(val: string): string[] {
    return this.insuranceCompanies.filter((option) => option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  updateCoveredInsurance() {
    if (!this.primaryInsurance.value.insuranceIsCoveredByOther) {
      this.primaryInsurance.get('insuredInfo').disable();
    } else {
      this.primaryInsurance.get('insuredInfo').enable();
    }

    this.cdr.detectChanges();
  }

  onContinueClick() {
    const company = this.startupService.startupData.insuranceCompanies.find((cmp) => cmp.name === this.primaryInsurance.value.companyName);
    if (company) {
      this.primaryInsurance.value.companyId = company.id;
    }
    this.store.dispatch(new BookingActions.SetInsurance(this.primaryInsurance.value));
    this.navigateService.navigate(['booking', 'review']);
  }
}
