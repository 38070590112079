import { EventService } from './event.service';
// import { AuthHelperService } from './auth-helper.service';
// import { AuthService } from './auth.service';
import { StartupService } from './startup.service';
import { LoaderService } from './loader.service';
import { DomService } from './dom.service';
import { CatalogsService } from './catalogs.service';
import { PingService } from './ping.service';
// import { GlobalErrorsService } from './global-errors.service';
import { NavigateWrapperService } from './navigateWrapper.service';
import { UploadService } from './upload.service';
import { HelperService } from './helper.service';

// registered in app module
export const CORE_SERVICES: any[] = [
    DomService,
    LoaderService,
    StartupService,
    UploadService,
    EventService,
    CatalogsService,
    PingService,
    // GlobalErrorsService,
    NavigateWrapperService,
    HelperService
];

export * from './dom.service';
export * from './loader.service';
export * from './startup.service';
export * from './auth-helper.service';
export * from './auth.service';
export * from './event.service';
export * from './catalogs.service';
export * from './ping.service';
export * from './global-errors.service';
export * from './navigateWrapper.service';
export * from './upload.service';
export * from './helper.service';

