import { Store } from '@ngrx/store';
import { AuthHelperService } from './auth-helper.service';
import { environment } from './../../../environments/environment';
import { RefreshGrantModel } from '../../account/models/refresh-grant-model';
import { LoginModel } from '../../account/models/login-model';
import { RegisterModel } from '../../account/models/register-model';
import { ProfileModel } from '../../account/models/profile-model';
import { AuthTokenModel } from '../../account/models/auth-tokens-model';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, interval, throwError, Subject } from 'rxjs';
import { map, catchError, tap, flatMap, first, takeUntil } from 'rxjs/operators';
import * as jwtDecode from 'jwt-decode';
import * as fromRoot from './../../ngrx';
import { BookingActions } from '../../booking/actions/index';
import { OnboardingService } from '../../onboarding/services';
import { AccountService } from '../../account/services';
import { ChannelService, ChatClientService } from 'stream-chat-angular';

@Injectable()
export class AuthService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private http: HttpClient,
    private authHelper: AuthHelperService,
    private store: Store<fromRoot.State>,
    private onboardingService: OnboardingService,
    private accountService: AccountService,

    private chatService: ChatClientService,
    private channelService: ChannelService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  init(): Observable<AuthTokenModel> {
    return this.startupTokenRefresh().pipe(tap(() => this.scheduleRefresh()));
  }

  register(data: RegisterModel): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/register`, data).pipe(catchError((res) => throwError(res)));
  }

  login(user: LoginModel): Observable<any> {
    return this.getTokens(user, 'password').pipe(tap((res) => this.scheduleRefresh()));
  }

  logout(): void {
    this.channelService?.reset();
    this.chatService?.chatClient?.disconnectUser();
    this.authHelper.updateState({ profile: null, tokens: null });
    if (this.authHelper.refreshSubscription$) {
      this.authHelper.refreshSubscription$.unsubscribe();
    }
    this.authHelper.removeToken();
    this.accountService.user.next(null);
    this.store.dispatch(new BookingActions.ResetAllAction({}));
    this.store.dispatch(new BookingActions.SetExistingPatientAction(null));
    this.onboardingService.clearCache();
  }

  refreshTokens(): Observable<AuthTokenModel> {
    return this.authHelper.state$.pipe(
      first(),
      map((state) => state.tokens),
      flatMap((tokens) => this.getTokens({ refresh_token: tokens.refresh_token }, 'refresh_token')),
      catchError((error) => throwError('Session Expired'))
    );
  }

  private getTokens(data: RefreshGrantModel | LoginModel, grantType: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    Object.assign(data, { grant_type: grantType, scope: 'openid offline_access' });

    const params = new URLSearchParams();
    Object.keys(data).forEach((key) => params.append(key, data[key]));

    return this.http.post(`${environment.apiUrl}/connect/token`, params.toString(), { headers }).pipe(
      tap((res) => {
        const tokens: AuthTokenModel = <AuthTokenModel>res;
        const now = new Date();
        tokens.expiration_date = new Date(now.getTime() + tokens.expires_in * 1000).getTime().toString();

        const profile: ProfileModel = jwtDecode(tokens.id_token);

        this.authHelper.storeToken(tokens);
        this.authHelper.updateState({ authReady: true, tokens, profile });
        this.accountService.me().subscribe();
      })
    );
  }

  private startupTokenRefresh(): Observable<AuthTokenModel> {
    return of(this.authHelper.retrieveTokens()).pipe(
      flatMap((tokens: AuthTokenModel) => {
        if (!tokens) {
          this.authHelper.updateState({ authReady: true });
          return throwError('No token in Storage !');
        }
        const profile: ProfileModel = jwtDecode(tokens.id_token);
        this.authHelper.updateState({ tokens, profile });

        if (+tokens.expiration_date > new Date().getTime()) {
          this.authHelper.updateState({ authReady: true });
        }
        return this.refreshTokens();
      }),
      catchError((error) => {
        this.logout();
        this.authHelper.updateState({ authReady: true });
        return throwError(error);
      })
    );
  }

  private scheduleRefresh(): void {
    this.authHelper.refreshSubscription$ = this.authHelper.tokens$
      .pipe(
        first(),
        // refresh every 5 minutes before expiration
        flatMap((tokens) => interval((tokens.expires_in - 300) * 1000)),
        flatMap(() => this.refreshTokens()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
