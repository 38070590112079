import { CustomValidators } from './../../../shared/validators/custom-validators';
import { ActivatedRoute } from '@angular/router';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../ngrx';
import { AccountService } from '../../services';
import { NavigateWrapperService, StartupService } from '../../../core/services';
import { MatDialog } from '@angular/material/dialog';
import { SendEmailDialogComponent } from '../../../../../src/app/shared/components/send-email-dialog/send-email-dialog.component';
import { BookingAppointment } from '../../../booking/models';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { VerifyEmailRequest } from '../../services/account.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pp-login-box',
  templateUrl: 'login-box.component.html',
  styleUrls: ['login-box.component.scss'],
})
export class LoginBoxComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() returnUrl;
  @Input() infoMessage;
  @Input() showRegister = true;
  @Input() boxTitle = 'login';

  appointment: BookingAppointment;

  loginForm: FormGroup;
  forgotForm: FormGroup;
  response: any;
  error = '';
  view = 'login';

  showLoader = false;
  impersonateToken: string;

  useOnlineBooking = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private accountService: AccountService,
    private store: Store<fromRoot.State>,
    public dialog: MatDialog,
    private navigateService: NavigateWrapperService,
    private startupService: StartupService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.loginMessage) {
      this.infoMessage = this.translateService.instant(this.route.snapshot.queryParams.loginMessage);
    }
    this.store
      .select(fromRoot.getBookingAppointmentObject)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.appointment = {
          locationGuid: data.locationGuid,
          serviceId: data.serviceId,
          practitionerId: data.practitionerId,
          isNewPatient: data.isNewPatient,
          index: data.index,
          date: data.date,
        };
      });

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email]],
    });

    this.impersonateToken = this.route.snapshot.queryParams.impersonateToken;
    this.initLoginForm();
    this.returnUrl = this.returnUrl || this.route.snapshot.queryParams.returnUrl || 'appointments';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.cleanUpLastPassElemets();
  }

  onSubmit() {
    this.error = '';
    this.showLoader = true;
    this.authService
      .login(
        this.isImpersonateLogin()
          ? { username: 'impersonate', password: 'impersonate', impersonate_token: this.impersonateToken }
          : this.loginForm.value
      )
      .pipe(
        switchMap(() => {
          return this.accountService.me();
        })
      )
      .subscribe(
        () => {
          if (this.appointment.practitionerId) {
            this.navigateService.navigate(['pickup-appointment'], this.appointment);
            return;
          }

          if (
            this.route.snapshot.queryParams.Index &&
            this.route.snapshot.queryParams.Date &&
            this.route.snapshot.queryParams.ServiceId &&
            this.route.snapshot.queryParams.LocationGuid
          ) {
            this.navigateService.navigate(['pickup-appointment'], this.route.snapshot.queryParams);
            return;
          }

          this.navigateService.navigate([this.returnUrl]);
        },
        (error) => {
          this.error = error.error_description;
          this.showLoader = false;
        }
      );
  }

  initLoginForm() {
    this.useOnlineBooking = this.startupService.startupData.useOnlineBooking;
    const controlsConfig: any = {
      username: [this.route.snapshot.queryParams['email'] || '', [Validators.required, CustomValidators.email]],
      password: [undefined, [Validators.required]],
    };
    if (this.isImpersonateLogin()) {
      controlsConfig.username = [''];
      controlsConfig.password = [''];
    }
    this.loginForm = this.formBuilder.group(controlsConfig);
  }

  onSendResetLink() {
    this.accountService
      .forgotPassword(this.forgotForm.value.email)
      .pipe(tap(() => this.initLoginForm()))
      .subscribe(() => {
        this.changeView('forgot-success');
      });
  }

  changeView(view) {
    this.view = view;
    this.cleanUpLastPassElemets();
  }

  cleanUpLastPassElemets() {
    document.querySelectorAll("[id*='lpform']").forEach((el) => {
      el.parentNode.removeChild(el);
    });
  }

  openCreateAccountDialog(): void {
    const verifyEmailRequest: VerifyEmailRequest = {
      email: null,
    };
    if (
      this.appointment.date &&
      this.appointment.index &&
      this.appointment.locationGuid &&
      this.appointment.practitionerId &&
      this.appointment.serviceId
    ) {
      verifyEmailRequest.slotData = {
        date: this.appointment.date,
        index: this.appointment.index,
        locationGuid: this.appointment.locationGuid,
        practitionerId: this.appointment.practitionerId,
        serviceId: this.appointment.serviceId,
      };
    }

    const dialogRef = this.dialog.open(SendEmailDialogComponent, {
      width: '400px',
      height: '450px',
      autoFocus: false,
      data: verifyEmailRequest,
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe();
  }

  isImpersonateLogin(): boolean {
    return !!this.impersonateToken;
  }
}
