<form class="form-full-width" [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-12 form-row" *ngIf="showInsuranceAutocomplete">
      <label class="label-form-control required-field-label">{{ 'insuranceCompanyName' | translate }} </label>
      <input
        class="form-control"
        placeholder="{{ 'insuranceCompanyName-placeholder' | translate }}"
        type="text"
        value=""
        formControlName="companyName"
        name="companyName"
        [matAutocomplete]="auto"
      />
      <div *ngIf="form.get('companyName').invalid && (form.get('companyName').dirty || form.get('companyName').touched)" class=" ">
        <p class="error-message-field">
          {{ 'form-input-error-required' | translate }}
        </p>
      </div>

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setCompanyId($event.option.value)">
        <mat-option *ngFor="let option of filteredCompanies | async" [value]="option.name">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </div>

    <pp-form-render [formFields]="fields" [form]="form"></pp-form-render>

    <div class="col-12 form-row">
      <label class="label-form-control">{{ 'insuranceIsCoveredByOther' | translate }} </label>
    </div>

    <div class="col-12 form-row wrap-multiple-form-controls">
      <div class="col-lg-2 col-4">
        <mat-select
          class="form-control form-control-select"
          placeholder="{{ 'select' | translate }}"
          formControlName="insuranceIsCoveredByOther"
          name="insuranceIsCoveredByOther"
        >
          <mat-option [value]="true">{{ 'yes-existing-patient' | translate }} </mat-option>
          <mat-option [value]="false">{{ 'no-firsttime-patient' | translate }} </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="form.get('insuredInfo')">
    <pp-form-render [formFields]="coveredByOtherFields" [form]="coveredByOtherFG"></pp-form-render>
  </div>
</form>
