
// angular
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, ModuleWithProviders, Optional, SkipSelf, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// module
import { MULTILANG_COMPONENTS } from './components/index';
import { MULTILANG_PROVIDERS } from './services/index';

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule
  ],
  declarations: [
    ...MULTILANG_COMPONENTS
  ],
  providers: [
    ...MULTILANG_PROVIDERS
  ],
  exports: [
    ...MULTILANG_COMPONENTS,

  ]
})
export class MultilingualModule {

  constructor(@Optional() @SkipSelf() parentModule: MultilingualModule) {
    if (parentModule) {
      throw new Error('MultilingualModule already loaded; Import in root module only.');
    }
  }
}
