import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthHelperService } from './auth-helper.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private inj: Injector, private auth: AuthHelperService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // in case injector fails on constructor
    if (!this.auth) {
      this.auth = this.inj.get(AuthHelperService);
    }

    if (this.auth.token) {
      const _headers = new HttpHeaders();
      let headers = _headers.set('Authorization', `${this.auth.token.token_type} ${this.auth.token.access_token}`);

      // prevents the interceptor to remove the Authorization when setting the content type
      if (request.url.includes('FullSteam/savecard') || request.url.includes('Account/cards')) {
        return next.handle(request);
      }
      // if need a different content type;
      // eg. in token refresh call
      if (request.headers.getAll('Content-Type')) {
        headers = _headers.set('Content-Type', request.headers.getAll('Content-Type'));
      }

      request = request.clone({
        headers: headers,
      });
    }

    return next.handle(request);
  }
}
