import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class GlobalErrorsService implements OnDestroy {
  displayError = new BehaviorSubject(undefined);
  private destroy$: Subject<void> = new Subject();

  constructor(private router: Router) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  display404() {
    this.displayError.next('404');
  }
  display500() {
    this.displayError.next('500');
  }

  hideAll() {
    this.displayError.next(undefined);
  }

  subscribeToRouter() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val instanceof NavigationStart && this.displayError.getValue() !== undefined) {
        this.hideAll();
      }
    });
  }
}
