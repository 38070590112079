<div class="page-content">
  <div class="content-inner-deprecated">
    <div class="row wrap-section">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="box alert alert-info" role="alert">
          <span class="wrap-icon-alert">
            <i class="material-icons info-icon">info</i>
          </span>
          Lorem ipsum dolor sit amet, consectetur
          adipisicing elit, sed
          do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris
          nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in
          voluptate velit esse cillum dolore eu fugiat
          nulla pariatur.
        </div>
      </div>
    </div>
    <div class="row wrap-section">
      <div class="wrap-title">
        <h1 class="title">Book an appointment</h1>
      </div>
      <div
        class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-xs-12 col-left">
        <div class="box">
          <h3 class="subtitle">Have you been to any of our
            clinics before?
          </h3>
          <div class="md-radio">
            <input id="1" type="radio" name="r">
            <label for="1">Yes</label>
          </div>
          <div class="md-radio">
            <input id="2" type="radio" name="r" checked>
            <label for="2">No</label>
          </div>
        </div>
      </div>
      <div
        class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-xs-12 col-right">
        <div class="box">
          <h3 class="subtitle">Login</h3>
          <p>If you have an account, please login to
            simplify
            the booking process.</p>
          <form>
            <div class="row">
              <div class="col-lg-12 col-xs-12 form-row">
                <label
                  class="label-form-control">Email</label>
                <input class="form-control inp" value=""
                  placeholder="name@company.com"
                  type="text" />
              </div>
              <div class="col-lg-12 col-xs-12 form-row">
                <label
                  class="label-form-control label-form-control-inline">Password</label>
                <a href="#" class="link-forgot">Forgot?</a>
                <input class="form-control" value=""
                  type="password" />
              </div>
              <div class="col-lg-12 col-xs-12 form-row">
                <button
                  class="btn btn-primary">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<pp-lang-switcher></pp-lang-switcher>
