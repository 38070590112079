<form class="form-full-width" [formGroup]="form" [ngClass]="{ 'not-editable-form': !isEditableForm() }">
  <div class="row edit-row form-row wrap-switch-row" style="padding: 0">
    <div class="col-12 edit-col">
      <h5>{{ formToComplete.name }}</h5>
    </div>
  </div>

  <div [ngStyle]="{ 'min-height': formToComplete?.height }" class="row" [ngClass]="gridStackCssClass">
    <div class="col-12">
      <pp-form-render [customFields]="formToComplete.controls" [form]="form"></pp-form-render>
    </div>
  </div>
</form>

<div class="row" *ngIf="isEditableForm()">
  <div class="col-12">
    <button type="submit" class="btn btn-primary" (valid)="onSubmit()" [disabled]="saving" [ppSubmitIfValid]="form">
      <i class="fa fa-spin fa-circle-o-notch" *ngIf="saving"></i> {{ 'save-and-continue' | translate }}
    </button>
  </div>
</div>
