import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import * as fromRoot from './../../ngrx';
import { Store } from '@ngrx/store';
import { NavigateWrapperService, AuthHelperService } from '../../core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CanActivateLocationStepGuard implements CanActivate, OnDestroy {
  private isExistingPatient: boolean;
  private loggedIn: boolean;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService
  ) {
    this.store
      .select(fromRoot.getBookingExistingPatient)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.isExistingPatient = val;
      });
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((val) => (this.loggedIn = val));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ((this.isExistingPatient !== null && this.isExistingPatient !== undefined) || this.loggedIn) {
      return true;
    }
    // go to location step
    this.navigateService.navigate(['booking', 'previous-visit']);
    return false;
  }
}
