import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Location, PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';

@Component({
    selector: 'pp-booking-container-page',
    template: '<router-outlet></router-outlet>'
})

export class BookingContainerPageComponent implements OnDestroy {
    private subscriptions: ISubscription[] = [];
    constructor(
        private location: Location,
        private _location: PlatformLocation,
        private navigateService: NavigateWrapperService,
        private router: Router

    ) {
        this.subscriptions.push(
            this.location.subscribe(e => {
                if (e && e.pop && e.url) {
                    let nextRoute = e.url;

                    if (this.navigateService.backRoute) {
                        nextRoute = this.navigateService.backRoute;
                        this.navigateService.backRoute = undefined;
                    } else if (this.navigateService.forwardRoute) {
                        nextRoute = this.navigateService.forwardRoute;
                        this.navigateService.forwardRoute = undefined;
                    }

                    this.navigateService.redirectGoBack(this.router.url, nextRoute);
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
