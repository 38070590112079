<div class="menu-host">
  <div fxFlex="220px" fxLayout="column" class="menu-wrapper" *ngIf="loggedIn">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="menu-item px-3 pt-4"
      (click)="topNavbarService.navigateTo('appointments')"
      [class.is-active]="isMenuItemActive('/appointments')"
    >
      <mat-icon class="calendar-icon" [svgIcon]="'my-appointments' + (isMenuItemActive('/appointments') ? '-active' : '') + '-v2'">
      </mat-icon>
      <div class="mt-3 w-100 text-center menu-item__description pb-4">
        {{ 'my-appointments' | translate }}
      </div>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="menu-item px-3 pt-4"
      (click)="topNavbarService.navigateTo('onboarding')"
      [class.is-active]="isMenuItemActive('/onboarding')"
    >
      <mat-icon *ngIf="areOnboardingFormsCompleted" [svgIcon]="'my-forms' + (isMenuItemActive('/onboarding') ? '-active' : '') + '-v2'">
      </mat-icon>
      <mat-icon
        *ngIf="!areOnboardingFormsCompleted"
        [svgIcon]="'my-forms-warn' + (isMenuItemActive('/onboarding') ? '-active' : '') + '-v2'"
      >
      </mat-icon>
      <div class="mt-3 w-100 text-center menu-item__description pb-4">
        {{ 'my-forms' | translate }}
      </div>
    </div>
    <div
      *ngIf="this.useOnlineBooking"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="menu-item px-3 pt-4"
      (click)="topNavbarService.navigateTo('booking/location')"
      [class.is-active]="isBookingAppointmentMenuItemActive()"
    >
      <img class="png-icon" [src]="'assets/icon/book-appointment' + (isBookingAppointmentMenuItemActive() ? '-active' : '') + '-v2.png'" />
      <div class="w-100 text-center menu-item__description pb-4">
        {{ 'book-appointment' | translate }}
      </div>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="menu-item px-3 pt-4"
      (click)="topNavbarService.navigateTo('account')"
      [class.is-active]="isMenuItemActive('/account')"
    >
      <img class="png-icon" [src]="'assets/icon/my-profile' + (isMenuItemActive('/account') ? '-active' : '') + '-v2.png'" />
      <div class="mt-2 w-100 text-center menu-item__description pb-4">
        {{ 'my-profile' | translate }}
      </div>
    </div>

    <div
      *ngIf="isChatActivated()"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="menu-item px-3 pt-4"
      (click)="topNavbarService.navigateTo('chat')"
      [class.is-active]="isMenuItemActive('/chat')"
    >
      <img class="png-icon-chat" [src]="'assets/icon/file-upload-chat-menu.png'" />
      <div class="mt-2 w-100 text-center menu-item__description pb-4">
        {{
          (startupService.getChatFeaturePlan() === FeaturePlanType.Starter || !startupService.isLiveChatEnabled() ? 'FileShare' : 'Chat')
            | translate
        }}
        <up-chat-global-notifications-count class="inline-block ml-1"></up-chat-global-notifications-count>
      </div>
    </div>

    <div class="logout-action text-center" (click)="topNavbarService.logout()">
      <button mat-button color="primary">
        {{ 'logout' | translate }}
      </button>
    </div>
  </div>
</div>
