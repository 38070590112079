import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { GlobalErrorsService } from './global-errors.service';
import { map } from 'rxjs/operators';
import { CustomIconService } from './custom-icon.service';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../ngrx';
import { OrganizationActions } from '../../organization/actions';
import { FeaturePlanType } from '@unifiedpractice/up-chat-lib';

@Injectable()
export class StartupService {
  private _startupData: any;

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private authService: AuthService,
    private location: Location,
    private store: Store<fromRoot.State>,
    private customIconService: CustomIconService
  ) {
    // save the tenant slug to append it to api call
    const ar = location.path().split('/');
    environment.apiUrl = environment.apiUrl + '/' + ar[1];
    environment.resourceUrl = environment.apiUrl;
  }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  load(): Promise<any> {
    this.initAuthTokenRefresh();

    this._startupData = null;
    return Promise.all([this.getOrganizationData(), this.customIconService.load(), this.initializeGoogleMaps()]);
  }

  get startupData(): any {
    return this._startupData || {};
  }

  initAuthTokenRefresh(): void {
    // This starts up the token refresh preocess for the app
    this.authService.init().subscribe(
      () => {},
      (error) => console.error(error)
    );
  }

  getOrganizationData(): Promise<any> {
    const globalErrorsService = this.injector.get(GlobalErrorsService);
    return this.http
      .get(`${environment.apiUrl}/organization`)
      .pipe(
        map((res: any) => {
          this._startupData = res;
          this.store.dispatch(new OrganizationActions.SetOrganization(res));
          return res;
        })
      )
      .toPromise()
      .catch((err: any) => {
        globalErrorsService.display404();
        return Promise.resolve();
      });
  }

  isLiveChatEnabled(): boolean {
    return this._startupData.useStreamChat;
  }

  isStreamChatModuleEnabled(): boolean {
    return this._startupData.hasStreamChat;
  }

  allowCreateChatChannel(): boolean {
    return this._startupData.allowCreateChatChannel;
  }

  getChatFeaturePlan(): FeaturePlanType {
    /*
    Starter = 11,
    Enhanced = 12,
    Premium = 13
    */
    switch (this._startupData.pricingPlanId) {
      case 11:
        return FeaturePlanType.Starter;
      case 12:
        return FeaturePlanType.Enhanced;
      case 13:
        return FeaturePlanType.Premium;
      default:
        return null;
    }
  }

  private initializeGoogleMaps(): Promise<any> {
    return this.http
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`, 'callback')
      .toPromise()
      .catch((err: any) => {
        return Promise.resolve();
      });
  }
}
