import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormField } from '../../../shared/components/form-render/form-field.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnboardingService } from '../../services';
import { FormFieldsEnum } from '../../../shared/components/form-render/fields.enum';
import { Common } from '../../../shared/models/Common';
import { CustomValidators } from '../../../shared/validators/custom-validators';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-emergency-contact-info-page',
  templateUrl: 'emergency-contact-info-page.component.html',
})
export class EmergencyContactInfoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  loaderRows = [0, 1, 2, 3, 4];

  fields: FormField[] = [];
  form: FormGroup;
  relationships: any;

  loggedIn = false;
  showLoader = true;
  saving = false;

  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.relationships = Common.Relationships;
    this.form = this.fb.group({});
    this.fields = this.initFields({});

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });

    this.onboardingService
      .getEmergencyInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.fields = this.initFields(res);
        this.showLoader = false;
        const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
        if (contentContainer) {
          contentContainer.scrollIntoView();
        }
      });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onboardingService.setFormSkip(this.form.pristine);
      this.onboardingService.formValid.next(this.form.valid);
    });

    this.onboardingService.skipAndSave.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (res) {
        this.onSubmit(false);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initFields(data) {
    const fields: FormField[] = [
      {
        key: 'contactName',
        label: 'contact-name',
        planceholder: 'name',
        type: FormFieldsEnum.TextBox,
        value: data.name || '',
      },
      {
        key: 'email',
        label: 'email',
        planceholder: 'email-placeholder',
        type: FormFieldsEnum.TextBox,
        value: data.email || '',
        validators: [CustomValidators.email],
      },
      {
        key: 'contactPhone',
        label: 'contact-phone-number',
        planceholder: 'phone-eg',
        type: FormFieldsEnum.TextBox,
        value: data.phoneNumber1 || '',
        validators: [CustomValidators.phone, Validators.maxLength(20)],
      },
      {
        key: 'alternatePhone',
        label: 'alternate-phone',
        planceholder: 'phone-eg',
        type: FormFieldsEnum.TextBox,
        value: data.phoneNumber2 || '',
        validators: [CustomValidators.phone, Validators.maxLength(20)],
      },
      {
        key: 'relationship',
        label: 'relationship',
        planceholder: 'select',
        type: FormFieldsEnum.DropdownSingleSelect,
        itemsList: this.relationships,
        value: data.relationship || undefined,
      },
    ];
    return fields;
  }

  onSubmit(redirect: boolean = true) {
    this.saving = true;
    const data = {
      relationship: this.form.value.relationship,
      name: this.form.value.contactName,
      phoneNumber1: this.form.value.contactPhone,
      phoneNumber2: this.form.value.alternatePhone,
      email: this.form.value.email,
    };
    this.onboardingService.saveEmergencyInfo(data).subscribe((res) => {
      this.saving = false;
      this.onboardingService.updateSummary(Common.OnboardingSummaryForms.EmergencyContact);
      this.onboardingService.setFormSkip(true);
      if (redirect) {
        const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
        if (uncompleted) {
          this.navigateService.navigate([uncompleted.route], null, true);
        } else {
          this.navigateService.navigate(['onboarding/success'], null, true);
        }
      }
    });
  }
}
