<div class="box select-box">
  <h3 class="top-title text-center">{{ 'register' |
    translate }}</h3>
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-12 col-xs-12 form-row">
        <label class="label-form-control">{{ 'email' |
          translate }}</label>
        <input class="form-control inp" type="email"
          formControlName="email" readonly />
      </div>
      <div class="col-lg-12 col-xs-12 form-row">
        <label
          class="label-form-control label-form-control-inline">{{
          'password' | translate }}</label>
        <input class="form-control" type="password"
          formControlName="password" />
        <div *ngIf="
            registerForm.get('password') &&
            registerForm.get('password').invalid &&
            (registerForm.get('password').dirty || registerForm.get('password').touched)
          ">
          <p class="error-message-field"
            *ngIf="registerForm.get('password').errors?.required">
            {{ 'form-input-error-required' | translate }}
          </p>
          <p class="error-message-field"
            *ngIf="registerForm.get('password').errors?.minlength">
            {{ 'form-input-error-password-minlength' |
            translate }}
          </p>
        </div>
      </div>
      <div class="col-lg-12 col-xs-12 form-row">
        <label
          class="label-form-control label-form-control-inline">{{
          'confirm-password' | translate }}</label>
        <input class="form-control" type="password"
          formControlName="confirmPassword" />
        <p class="error-message-field" *ngIf="
            registerForm.get('confirmPassword').errors?.MatchFields &&
            (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)
          ">
          {{ 'form-input-error-confirm-password-match' |
          translate }}
        </p>
      </div>
      <div class="col-lg-12 col-xs-12 form-row">
        <button class="btn btn-primary btn-login"
          type="submit" [disabled]="!registerForm.valid">{{
          'register' | translate }}</button>
      </div>
    </div>
  </form>
</div>
