import { createSelector } from 'reselect';
import { ActionReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

/**
 * The compose function is one of our most handy tools. In basic terms, you give
 * it any number of functions and it returns a function. This new function
 * takes a value and chains it through every composed function, returning
 * the output.
 *
 * More: https://drboolean.gitbooks.io/mostly-adequate-guide/content/ch5.html
 */
import { compose } from '@ngrx/core/compose';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */

/**
 * combineReducers is another useful metareducer that takes a map of reducer
 * functions and creates a new reducer that gathers the values
 * of each reducer and stores them using the reducer's key. Think of it
 * almost like a database, where every reducer is a table in the db.
 *
 * More: https://egghead.io/lessons/javascript-redux-implementing-combinereducers-from-scratch
 */
import { combineReducers, ActionReducerMap, MetaReducer } from '@ngrx/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromSample from '../sample/reducers';
import * as fromBooking from '../booking/reducers';
import * as fromOrganization from '../organization/reducers';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  sample: fromSample.State;
  booking: fromBooking.State;
  organization: fromOrganization.State;
}

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
export const reducers: ActionReducerMap<State> = {
  sample: fromSample.reducer,
  booking: fromBooking.reducer,
  organization: fromOrganization.reducer,
};

// export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
//     return compose(storeFreeze, localStorageSync({ keys: ['patientSearchInput'], rehydrate: true }), combineReducers)(reducers);
// }

export const metaReducers: Array<MetaReducer<any, any>> = [
  // localStorageSyncReducer
];

const developmentReducer: ActionReducer<any> = compose(combineReducers)(reducers);
const productionReducer: ActionReducer<any> = compose(combineReducers)(reducers);

export function reducer(state: any, action: any) {
  if (environment.production) {
    return productionReducer(state, action);
  } else {
    return developmentReducer(state, action);
  }
}

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `books` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent {
 * 	constructor(state$: Observable<State>) {
 * 	  this.booksState$ = state$.select(getBooksState);
 * 	}
 * }
 * ```
 */
// export const getSampleState = (state: State) => state.sample;
export const getBookingState = (state: State) => state.booking;

// Organization Reducer
export const getOrganizationState = (state: State) => state.organization;
/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function from the reselect library creates
 * very efficient selectors that are memoized and only recompute when arguments change.
 * The created selectors can also be composed together to select different
 * pieces of state.
 */

// example
// export const getNames = createSelector(getSampleState, fromSample.getNames);

// Booking reducer
export const getBookingExistingPatient = createSelector(getBookingState, fromBooking.getExistingPatient);
export const getBookingLocation = createSelector(getBookingState, fromBooking.getLocation);
export const getQueryLocationGuid = createSelector(getBookingState, fromBooking.getQueryLocationGuid);
export const getBookingService = createSelector(getBookingState, fromBooking.getService);
export const getBookingPractitioner = createSelector(getBookingState, fromBooking.getPractitioner);
export const getBookingPractitioners = createSelector(getBookingState, fromBooking.getPractitioners);
export const getBookingDateTime = createSelector(getBookingState, fromBooking.getTimeslot);
export const getBookingClinicGuid = createSelector(getBookingState, fromBooking.getClinicGuid);
export const getBookingLocationQueryGuid = createSelector(getBookingState, fromBooking.getLocationQueryGuid);
export const getBookingCurrentStep = createSelector(getBookingState, fromBooking.getCurrentStep);
export const getBookingLocationAndExisting = createSelector(getBookingState, fromBooking.getLocationAndExisting);
export const getBookingAccount = createSelector(getBookingState, fromBooking.getAccount);
export const getBookingAdditionalInformation = createSelector(getBookingState, fromBooking.getAdditionalInformation);
export const getBookingAppointmentObject = createSelector(getBookingState, fromBooking.getAppointment);
export const getBookingInsuranceType = createSelector(getBookingState, fromBooking.getInsuranceType);
export const getBookingRescheduledAppointmentUid = createSelector(getBookingState, fromBooking.getRescheduledAppointmentUid);
export const getPreferredPractitionerOptions = createSelector(getBookingState, fromBooking.getPreferredPractitionerOption);
export const getBookingSuccessStepMessages = createSelector(getBookingState, fromBooking.getSuccessStepMessages);
export const getBookingConfirmationStatus = createSelector(getBookingState, fromBooking.getBookingConfirmationStatus);

export const getBookingLocationStepMessage = createSelector(getBookingState, fromBooking.getLocationStepMessage);
export const getBookingPractitionerStepMessage = createSelector(getBookingState, fromBooking.getPractitionerStepMessage);
export const getBookingServiceStepMessage = createSelector(getBookingState, fromBooking.getServiceStepMessage);

export const canChangeBookingLocation = createSelector(getBookingState, fromBooking.canChangeLocation);
export const canChangeBookingPractitioner = createSelector(getBookingState, fromBooking.canChangePractitioner);
