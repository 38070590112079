<div
  class="box select-box box-appointment-summary d-none d-sm-block">
  <h3 class="subtitle">
    {{'online-forms' | translate}} </h3>
  <form>

    <div *ngFor="let form of onboardingForms; index as i">

      <div class="row row-edit"
        [ngClass]="currentRoute === form.route ? 'selected' : (form.xsVisible ? '' : 'd-none d-sm-block')">

        <div class="col-12 edit-col"
          (click)="navigateTo(form.route)"
          style="cursor:pointer"
          *ngIf="onboardingForms[i].completed ||
                    (i > 0 && onboardingForms[i-1].completed)">
          <input type="text"
            value="{{ form.name | translate}}" disabled />
          <i class="material-icons pull-right"
            [ngStyle]="{'color': this.getFormColor(form)}">
            {{ (form.completed === true) ? 'check_circle' :
            (form.completed
            === false) ? 'mode_edit' : ''}}</i>
        </div>

        <div class="col-12 edit-col"
          *ngIf="(!onboardingForms[i].completed && i == 0) ||
                                              (i > 0 && !onboardingForms[i-1].completed && !onboardingForms[i].completed)">
          <input type="text"
            value="{{ form.name | translate}}" disabled
            style="cursor: default !important" />
          <i class="material-icons pull-right"
            [ngStyle]="{'color': this.getFormColor(form), 'cursor':'default'}">
            {{ (form.completed === true) ? 'check_circle' :
            (form.completed
            === false) ? 'mode_edit' : ''}}</i>
        </div>

      </div>

    </div>

  </form>
</div>

<div class="m-box d-sm-none" *ngIf="!hideXS">
  <form>

    <div class="row" *ngFor="let form of onboardingForms; index as i">

      <div class="col-1 pl-0 pr-0" *ngIf="currentRoute === form.route">
        <i class="material-icons pull-left m-icon-action"
          *ngIf="loggedIn && i > 0" (click)="navigateTo(onboardingForms[i-1].route)">
          chevron_left</i>
      </div>

      <div class="col-10 text-center">
        <span class="m-subtitle" *ngIf="currentRoute === form.route">
          {{ form.name | translate }}
        </span>

        <div class="col-12 m-form-nav text-center" *ngIf="currentRoute === form.route">
          <span *ngFor="let innerFrom of onboardingForms; index as j">

            <span class="fa-stack fa-1x" *ngIf="innerFrom.completed"
              [ngStyle]="{'color': currentRoute === innerFrom.route ? '#81c884' : '#42a5f5'}">
              <i class="fa fa-check-circle fa-stack-2x"></i>
            </span>

            <span class="fa-stack fa-1x" *ngIf="!innerFrom.completed"
              [ngStyle]="{'color': currentRoute === innerFrom.route ? '#81c884' : '#90a4ae'}">
              <i [ngClass]="currentRoute === innerFrom.route ? 'fa fa-circle fa-stack-2x' : 'fa fa-circle-o fa-stack-2x'"></i>
              <span [ngClass]="currentRoute === innerFrom.route ? 'fa-stack-1x text-white' : 'fa-stack-1x'">{{j+1}}</span>
            </span>

          </span>
        </div>
      </div>

      <div class="col-1 pl-0 pr-0" *ngIf="currentRoute === form.route">
        <i class="material-icons pull-right m-icon-action"
          *ngIf="loggedIn && onboardingForms.length > i + 1 && onboardingForms[i].completed"
          (click)="navigateTo(onboardingForms[i+1].route)">
          chevron_right
        </i>
      </div>

    </div>

  </form>
</div>
