import { InsuranceType } from './../models/insurance-type';
import { BookingConfirmationStatus, Guest } from './../models/booking-day';
import { ClinicLocation } from './../../clinic/models/location';
import { Practitioner } from './../../clinic/models/practitioner';
import { Service } from './../../clinic/models/service';
import { Action } from '@ngrx/store';
import { type } from '../../ngrx/type';
import { Timeslot } from '../models/index';

/**
 * Each action should be namespaced
 * this allows the interior to have similar typed names as other actions
 * however still allow index exports
 */
export namespace BookingActions {
  // Category to uniquely identify the actions
  export const CATEGORY = '[Booking]';

  export const SET_EXISTING_PATIENT = type(`${CATEGORY} Set Existing Patient`);
  export const SET_LOCATION = type(`${CATEGORY} Set Location`);
  export const SET_QUERY_LOCATION = type(`${CATEGORY} Set Query Param Location`);
  export const SET_LOCATION_SERVICE = type(`${CATEGORY} Set Location Service`);
  export const SET_PRACTITIONER = type(`${CATEGORY} Set Practitioner`);
  export const SET_PREFERRED_PRACTITIONER_OPTION = type(`${CATEGORY} SET_PREFERRED_PRACTITIONER_OPTION`);
  export const SET_PRACTITIONERS = type(`${CATEGORY} Set Practitioners`);
  export const SET_TIMESLOT = type(`${CATEGORY} SET_TIMESLOT`);
  export const SET_LOCATION_UID = type(`${CATEGORY} SET_LOCATION_UID`);
  export const SET_CLINIC_UID = type(`${CATEGORY} SET_CLINIC_UID`);
  export const SET_INSURANCE_TYPE = type(`${CATEGORY} SET_INSURANCE_TYPE`);
  export const SET_INSURANCE = type(`${CATEGORY} SET_INSURANCE`);
  export const SET_WORK_INSURANCE = type(`${CATEGORY} SET_WORK_INSURANCE`);
  export const SET_AUTO_INSURANCE = type(`${CATEGORY} SET_AUTO_INSURANCE`);
  export const SET_ACCOUNT = type(`${CATEGORY} SET_ACCOUNT`);
  export const SET_ADDITIONAL_DATA = type(`${CATEGORY} SET_ADDITIONAL_DATA`);
  export const CAN_CHANGE_LOCATION = type(`${CATEGORY} CAN_CHANGE_LOCATION`);
  export const CAN_CHANGE_PRACTITIONER = type(`${CATEGORY} CAN_CHANGE_PRACTITIONER`);
  export const RESET_ALL = type(`${CATEGORY} RESET_ALL`);

  export const SET_APPOINTMENT_UID = type(`${CATEGORY} SET_APPOINTMET_UID`);
  export const SET_MESSAGE_ON_SUCCEESS_STEP = type(`${CATEGORY} SET_MESSAGE_ON_SUCCEESS_STEP`);
  export const SET_MESSAGE_ON_Practitioner_STEP = type(`${CATEGORY} SET_MESSAGE_ON_Practitioner_STEP`);
  export const SET_MESSAGE_ON_Location_STEP = type(`${CATEGORY} SET_MESSAGE_ON_Location_STEP`);
  export const SET_MESSAGE_ON_Service_STEP = type(`${CATEGORY} SET_MESSAGE_ON_Service_STEP`);
  export const SET_BOOKING_CONFIRMATION_STATUS = type(`${CATEGORY} SET_BOOKING_CONFIRMATION_STATUS`);

  /**
   * Every action is comprised of at least a type and an optional
   * payload. Expressing actions as classes enables powerful
   * type checking in reducer functions.
   *
   * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
   */
  /**
   * General scheduling actions
   */

  export class ResetAllAction implements Action {
    type = RESET_ALL;

    constructor(public payload: any) {}
  }

  // export class ResetContentAction implements Action {
  //     type = RESET_CONTENT;

  //     constructor(public payload: any) { }
  // }

  /**
   * Step 1 - Previous location actions
   */
  export class SetExistingPatientAction implements Action {
    type = SET_EXISTING_PATIENT;

    constructor(public payload: boolean) {}
  }

  /**
   * Step 2 - Location and service actions
   */
  export class SetLocationAction implements Action {
    type = SET_LOCATION;

    constructor(public payload: ClinicLocation) {}
  }
  export class SetQueryParamLocationAction implements Action {
    type = SET_QUERY_LOCATION;

    constructor(public payload: string) {}
  }

  export class SetServiceAction implements Action {
    type = SET_LOCATION_SERVICE;

    constructor(public payload: Service) {}
  }

  export class CanChangeLocationAction implements Action {
    type = CAN_CHANGE_LOCATION;

    constructor(public payload: boolean) {}
  }

  /**
   * Step 3 - Practitioner and date actions
   */
  export class SetPractitionerAction implements Action {
    type = SET_PRACTITIONER;

    constructor(public payload: Practitioner) {}
  }

  export class SetPreferredPractitionerOption implements Action {
    type = SET_PREFERRED_PRACTITIONER_OPTION;

    constructor(public payload: string) {}
  }

  export class SetPractitionersAction implements Action {
    type = SET_PRACTITIONERS;

    constructor(public payload: Practitioner[]) {}
  }

  export class CanChangePractitionerAction implements Action {
    type = CAN_CHANGE_PRACTITIONER;

    constructor(public payload: boolean) {}
  }

  export class SetTimeslotAction implements Action {
    type = SET_TIMESLOT;

    constructor(public payload: Timeslot) {}
  }

  export class SetClinicGuid implements Action {
    type = SET_CLINIC_UID;

    constructor(public payload: string) {}
  }
  export class SetLocationGuid implements Action {
    type = SET_LOCATION_UID;

    constructor(public payload: string) {}
  }

  export class SetAccount implements Action {
    type = SET_ACCOUNT;

    constructor(public payload: Guest) {}
  }
  export class SetAdditionalInfo implements Action {
    type = SET_ADDITIONAL_DATA;

    constructor(public payload: Guest) {}
  }

  export class SetInsuranceType implements Action {
    type = SET_INSURANCE_TYPE;

    constructor(public payload: InsuranceType) {}
  }
  export class SetInsurance implements Action {
    type = SET_INSURANCE;

    constructor(public payload: any) {}
  }
  export class SetWorkInsurance implements Action {
    type = SET_WORK_INSURANCE;

    constructor(public payload: any) {}
  }
  export class SetAutoInsurance implements Action {
    type = SET_AUTO_INSURANCE;

    constructor(public payload: any) {}
  }
  export class SetAppointmentUid implements Action {
    type = SET_APPOINTMENT_UID;

    constructor(public payload: any) {}
  }
  export class SetMessageOnSuccessStep implements Action {
    type = SET_MESSAGE_ON_SUCCEESS_STEP;
    constructor(public payload: any) {}
  }
  export class SetMessageOnPractitionerStep implements Action {
    type = SET_MESSAGE_ON_Practitioner_STEP;
    constructor(public payload: string) {}
  }
  export class SetMessageOnLocationStep implements Action {
    type = SET_MESSAGE_ON_Location_STEP;
    constructor(public payload: string) {}
  }
  export class SetMessageOnServiceStep implements Action {
    type = SET_MESSAGE_ON_Service_STEP;
    constructor(public payload: string) {}
  }
  export class SetBookingConfirmationStatus implements Action {
    type = SET_BOOKING_CONFIRMATION_STATUS;
    constructor(public payload: BookingConfirmationStatus) {}
  }

  /**
   * Export a type alias of all actions in this action group
   * so that reducers can easily compose action types
   */
  export type Actions =
    | SetExistingPatientAction
    | SetLocationAction
    | SetServiceAction
    | SetPractitionerAction
    | SetPractitionersAction
    | SetTimeslotAction
    | CanChangeLocationAction
    | CanChangePractitionerAction
    | SetAdditionalInfo
    | SetAccount
    | SetInsuranceType
    | SetInsurance
    | SetWorkInsurance
    | SetAutoInsurance
    | SetAppointmentUid
    | ResetAllAction
    | SetMessageOnSuccessStep
    | SetMessageOnPractitionerStep
    | SetMessageOnLocationStep
    | SetMessageOnServiceStep
    | SetClinicGuid
    | SetQueryParamLocationAction
    | SetPreferredPractitionerOption
    | SetBookingConfirmationStatus;
}
