import { InsuranceType } from './../../models/insurance-type';
import { StartupService } from './../../../core/services/startup.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { Store } from '@ngrx/store';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-insurance-type-step-page',
  templateUrl: 'insurance-type-step-page.component.html',
})
export class InsuranceTypeStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  insuranceCompanies = [];
  primaryInsurance = false;
  workInsurance = false;
  autoInsurance = false;
  isExistingPatient = false;
  insuranceMessageNewPatient;
  insuranceMessageExistingPatient;
  insuranceTypeEnum = InsuranceType;

  constructor(
    private store: Store<fromRoot.State>,
    private startupService: StartupService,
    private navigateService: NavigateWrapperService
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(fromRoot.getBookingExistingPatient)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => (this.isExistingPatient = val));

    this.insuranceCompanies = this.startupService.startupData.insuranceCompanies;
    this.primaryInsurance = this.startupService.startupData.acceptsMajorInsurance;
    this.autoInsurance = this.startupService.startupData.acceptsAutoAccident;
    this.workInsurance = this.startupService.startupData.acceptsWorkerCompensation;
    this.insuranceMessageNewPatient = this.startupService.startupData.insuranceMessageNewPatient;
    this.insuranceMessageExistingPatient = this.startupService.startupData.insuranceMessageExistingPatient;

    // scroll top
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  navigateTo(route, insurnaceType: InsuranceType) {
    this.store.dispatch(new BookingActions.SetInsuranceType(insurnaceType));
    this.navigateService.navigate([route]);
  }
}
