import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Common } from '../../shared/models/Common';
import { BehaviorSubject, Subject, of, Observable } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

@Injectable()
export class OnboardingService {
  skipAndSave = new Subject();
  formValid = new BehaviorSubject(false);

  medicalForms;
  consentForms;
  screeningForms;
  summaryForms;

  private forms;
  private _progress;
  private appointmentUid: string;
  private token: string;
  private skipForm = true;

  constructor(private http: HttpClient) {}

  isFormValid(): boolean {
    return this.formValid.getValue();
  }

  setFormSkip(value: boolean) {
    this.skipForm = value;
  }

  canSkipForm(): boolean {
    return this.skipForm;
  }

  setAppointmentUid(uid: string) {
    if (uid) {
      this.appointmentUid = uid;
    }
  }
  getAppointmentUid() {
    return this.appointmentUid;
  }

  setToken(token: string) {
    if (token) {
      this.token = token;
    }
  }
  getToken() {
    return this.token;
  }

  getQueryParams() {
    const queryParams: any = {};
    if (this.appointmentUid) {
      queryParams.uid = this.appointmentUid;
    }
    if (this.token) {
      queryParams.token = this.token;
    }
    if (queryParams.uid || queryParams.token) {
      return queryParams;
    }
    return null;
  }

  getOnboardingForms() {
    if (!this.forms) {
      return this.http.get(this.getRequestUrl('onboardingforms')).pipe(
        tap((data: any[]) => {
          this.medicalForms = data.filter((form) => form.type === Common.FormType.MedicalForm);
          this.consentForms = data.filter((form) => form.type === Common.FormType.ConsentForm);
          this.screeningForms = data.filter((form) => form.type === Common.FormType.ScreeningForm);
          this.forms = data;
        }),
        shareReplay(1)
      );
    }
    return of(this.forms);
  }

  getForm(formuid) {
    let form;
    if (this.forms) {
      form = this.forms.filter((f) => f.uid === formuid)[0];
    }
    return form;
  }

  updateSummary(form) {
    if (this.summaryForms) {
      if (this.summaryForms.filter((sm) => sm.id === form.id)[0]) {
        this.summaryForms.filter((sm) => sm.id === form.id)[0].completed = true;
      }
      // update the progress
      const completedForms = this.summaryForms.filter((sm) => sm.completed).length;
      this._progress.completion = Math.ceil((completedForms / this.summaryForms.length) * 100);
    }
  }

  clearCache() {
    this.forms = null;
    this._progress = null;
    this.medicalForms = null;
    this.consentForms = null;
    this.screeningForms = null;
    this.summaryForms = null;
  }

  validate(token) {
    return this.http.get(this.getRequestUrl('validate'), { params: { token: token } }).pipe(map((res: boolean) => res));
  }

  progress(): Observable<number> {
    if (!this._progress) {
      return this.http.get(this.getRequestUrl('progress')).pipe(
        tap((data: any) => (this._progress = data)),
        shareReplay(1)
      );
    }

    return of(this._progress);
  }

  getAdditionalInfo() {
    return this.http.get(this.getRequestUrl('additional'));
  }
  saveAdditionalInfo(data) {
    return this.http.post(this.getRequestUrl('additional'), data);
  }

  getContactInfo() {
    return this.http.get(this.getRequestUrl('contact'));
  }
  saveContactInfo(data) {
    return this.http.post(this.getRequestUrl('contact'), data);
  }

  getEmergencyInfo() {
    return this.http.get(this.getRequestUrl('emergency'));
  }
  saveEmergencyInfo(data) {
    return this.http.post(this.getRequestUrl('emergency'), data);
  }

  getInsuranceInfo() {
    return this.http.get(this.getRequestUrl('insurance'));
  }
  saveInsuranceInfo(data) {
    return this.http.post(this.getRequestUrl('insurance'), data);
  }

  saveMedicalForm(formuid, data) {
    return this.http.post(this.getRequestUrl(`medicalforms/${formuid}`), data);
  }
  saveConsentForm(formuid, data) {
    return this.http.post(this.getRequestUrl(`consentforms/${formuid}`), data);
  }

  getMedicalInfo() {
    return this.http.get(this.getRequestUrl('medical'));
  }
  saveMedicalInfo(data) {
    return this.http.post(this.getRequestUrl('medical'), data);
  }

  getPatientInfo() {
    return this.http.get(this.getRequestUrl('personal'));
  }
  savePatientInfo(data) {
    return this.http.post(this.getRequestUrl('personal'), data);
  }

  getPrimaryPhisicianInfo() {
    return this.http.get(this.getRequestUrl('primaryphisician'));
  }
  savePrimaryPhisicianInfo(data) {
    return this.http.post(this.getRequestUrl('primaryphisician'), data);
  }

  saveSignatureInfo(data) {
    return this.http.post(this.getRequestUrl('sign'), data);
  }

  error() {
    return this.http.get(`${environment.apiUrl}/test/error500`);
  }

  private getRequestUrl(action: string) {
    let url = `${environment.apiUrl}/Onboarding/${action}`;
    if (this.appointmentUid) {
      url = `${url}?uid=${this.appointmentUid}`;
    }
    if (this.token) {
      url = `${url}&token=${this.token}`;
    }
    return url;
  }
}
