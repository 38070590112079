import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { OnboardingService } from '../../services';
import { Common } from '../../../shared/models/Common';
import { NavigateWrapperService, AuthHelperService } from '../../../core/services';
import { OnboardingFormsEnum } from '../../models';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-generic-forms-list-page',
  styleUrls: ['generic-forms-list-page.component.scss'],
  templateUrl: 'generic-forms-list-page.component.html',
})
export class GenericFormsListPageComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() formType: OnboardingFormsEnum;
  forms;
  navigationUrl: string;
  loggedIn = false;
  summaryFormsId: string;

  constructor(
    private onboardingService: OnboardingService,
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService
  ) {
    super();
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }

  ngOnInit() {
    switch (this.formType) {
      case OnboardingFormsEnum.MedicalForms: {
        this.forms = this.onboardingService.medicalForms;
        if (this.forms.length === 1) {
          this.navigateService.navigate(['onboarding', 'medical-forms', this.forms[0].uid], null, true);
        }
        this.navigationUrl = 'medical-forms';
        this.summaryFormsId = Common.OnboardingSummaryForms.MedicalForms.id;
        break;
      }
      case OnboardingFormsEnum.ConsentForms: {
        this.forms = this.onboardingService.consentForms;
        if (this.forms.length === 1) {
          this.navigateService.navigate(['onboarding', 'consent-forms', this.forms[0].uid], null, true);
        }
        this.navigationUrl = 'consent-forms';
        this.summaryFormsId = Common.OnboardingSummaryForms.ConsentForms.id;
        break;
      }
      default: {
        this.forms = this.onboardingService.screeningForms;
        if (this.forms.length === 1) {
          this.navigateService.navigate(['onboarding', 'screening-forms', this.forms[0].uid], null, true);
        }
        this.navigationUrl = 'screening-forms';
        this.summaryFormsId = Common.OnboardingSummaryForms.ScreeningForms.id;
        break;
      }
    }
    this.onboardingService.setFormSkip(true);
    const contentContainer = document.querySelector('pp-onboarding-summary-box > div > form > div:nth-child(1) > div.selected');
    if (contentContainer) {
      contentContainer.scrollIntoView();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  navigateToForm(uid: string): void {
    if (this.navigationUrl && uid) {
      this.navigateService.navigate(['onboarding', this.navigationUrl, uid], null, true);
    }
  }

  onSubmit() {
    let unncompletedForms = this.forms.filter((mf) => !mf.isCompleted);
    if (unncompletedForms.length > 0 && unncompletedForms[0] && unncompletedForms[0].uid) {
      this.navigateService.navigate(['onboarding', this.navigationUrl, unncompletedForms[0].uid], null, true);
      return;
    }

    let form = this.forms.filter((mf) => (!mf.isCompleted || (mf.isCompleted && this.loggedIn)) && !mf.isLegacy)[0];
    if (!form) {
      form = this.forms.filter((mf) => mf.isCompleted && this.loggedIn && !mf.isLegacy)[0];
    }
    if (form && form.uid) {
      this.navigateService.navigate(['onboarding', this.navigationUrl, form.uid], null, true);
    } else {
      this.onboardingService.summaryForms.filter((sm) => sm.id === this.summaryFormsId)[0].completed = true;
      const uncompleted = this.onboardingService.summaryForms.find((f) => !f.completed);
      if (uncompleted) {
        this.navigateService.navigate([uncompleted.route], null, true);
      } else {
        this.navigateService.navigate(['onboarding', 'success']);
      }
    }
  }
}
