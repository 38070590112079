<div class="box select-box select-clinic-box" *ngIf="!showLoader">
  <h3 class="subtitle d-none d-sm-block">{{ 'additional-information' | translate }}</h3>
  <form class="form-full-width" [formGroup]="form" novalidate>
    <div class="row">
      <pp-form-render [formFields]="fields1" [form]="form"></pp-form-render>
    </div>

    <div class="row">
      <div class="col-12 form-row wrap-switch-row">
        <div class="col-11">
          <div class="switch-text-label label-form-control">
            {{ 'additional-currentlySeeingMD-label' | translate }}
          </div>
        </div>

        <div class="col-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-currentlySeeingMD"
              name="set-name-other"
              class="switch-input"
              formControlName="currentlySeeingMD"
              name="currentlySeeingMD"
            />
            <label for="switch-currentlySeeingMD" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="form.get('currentlySeeingMD').value">
      <pp-form-render [formFields]="fields2" [form]="form"> </pp-form-render>
    </div>

    <div class="row">
      <pp-form-render [formFields]="fields3" [form]="form"> </pp-form-render>
    </div>
  </form>

  <div class="row">
    <div class="col-lg-12 col-xs-12 form-row">
      <button class="btn btn-primary" type="submit" (valid)="onSubmit()" [disabled]="saving" [ppSubmitIfValid]="form">
        <i class="fa fa-spin fa-circle-o-notch" *ngIf="saving"></i>
        {{ 'save-and-continue' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="box select-box select-clinic-box" *ngIf="showLoader">
  <h3 class="subtitle d-none d-sm-block">
    {{ 'additional-information' | translate }}
  </h3>
  <div class="row" *ngFor="let x of loaderRows" style="padding-bottom: 30px">
    <div class="col-lg-12 col-xs-12">
      <div class="line width-100"></div>
      <div class="line width-100"></div>
    </div>
  </div>
</div>
