import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormField } from '../form-render/form-field.model';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../ngrx';
import { AccountService } from '../../../account/services';
import { AuthService, NavigateWrapperService, StartupService } from '../../../core/services';
import { CustomValidators } from '../../validators/custom-validators';
import { catchError, switchMap } from 'rxjs/operators';
import { FormFieldsEnum } from '../form-render/fields.enum';
import { ActivatedRoute } from '@angular/router';
import { RegistrationParams } from './register-onboarging-step.model';
import { ConfirmAccountExtendedRequest } from '../../../account/services/account.model';
import { RegisterInactiveUserErrorModalComponent } from '../register-inactive-user-error-modal/register-inactive-user-error-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pp-register-onboarding-step',
  templateUrl: './register-onboarding-step.component.html',
  styleUrls: ['./register-onboarding-step.component.scss'],
})
export class RegisterOnboardingStepComponent extends BaseComponent implements OnInit, OnDestroy {
  guest: FormGroup;
  fields: FormField[] = [];
  queryParams: RegistrationParams;
  showBookingStepts: boolean = true;

  confirmPasswordForm: FormGroup;

  constructor(
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private navigateService: NavigateWrapperService,
    private startupService: StartupService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.queryParams = {
      email: this.activatedRoute.snapshot.queryParams.email,
      date: this.activatedRoute.snapshot.queryParams.Date,
      index: this.activatedRoute.snapshot.queryParams.Index,
      locationGuid: this.activatedRoute.snapshot.queryParams.LocationGuid,
      practitionerId: this.activatedRoute.snapshot.queryParams.PractitionerId,
      serviceId: this.activatedRoute.snapshot.queryParams.ServiceId,
      token: this.activatedRoute.snapshot.queryParams.token,
      type: this.activatedRoute.snapshot.queryParams.type,
    };

    if (this.activatedRoute.snapshot.queryParams.bookingSteps && this.activatedRoute.snapshot.queryParams.bookingSteps == 0) {
      this.showBookingStepts = false;
    }
    this.initConfirmPasswordForm();
    window.scrollTo(0, 0);

    this.guest = this.formBuilder.group(
      {
        termsAgreed: new FormControl(false, [Validators.requiredTrue]),
        email: new FormControl(
          { value: this.queryParams.email, disabled: true },
          {
            validators: [Validators.required, CustomValidators.email],
            updateOn: 'blur',
          }
        ),
        password: [null, [Validators.required, Validators.minLength(6)]],
        confirmPassword: [null, [Validators.required]],
      },
      { validator: CustomValidators.Match('password', 'confirmPassword') }
    );
    this.fields = this.initFields();
  }

  submitSetPasswordForm() {
    this.accountService
      .confirmAccount({
        email: this.queryParams.email,
        confirmPassword: this.confirmPasswordForm.get('confirmPassword').value,
        password: this.confirmPasswordForm.get('password').value,
        token: this.queryParams.token,
      })
      .pipe(
        catchError((err) => {
          if (err?.error === 'patient-is-inactive-error') {
            this.openArchivedPatientError();
          }
          throw err;
        })
      )
      .subscribe(() => {
        this.login();
      });
  }

  initConfirmPasswordForm() {
    this.confirmPasswordForm = this.formBuilder.group(
      {
        email: [{ value: this.queryParams.email, disabled: true }, [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: CustomValidators.Match('password', 'confirmPassword') }
    );
  }

  initFields() {
    const fields: FormField[] = [
      {
        key: 'firstName',
        label: 'first-name',
        planceholder: 'first-name-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.required, Validators.maxLength(300)],
        value: null,
      },
      {
        key: 'lastName',
        label: 'last-name',
        planceholder: 'last-name-placeholder',
        type: FormFieldsEnum.TextBox,
        validators: [Validators.required, Validators.maxLength(300)],
        value: null,
      },
      {
        key: 'phone',
        label: 'phone-number',
        type: FormFieldsEnum.Phone,
        validators: [Validators.required],
        value: null,
      },
      {
        key: 'dateOfBirth',
        label: 'date-of-birth',
        type: FormFieldsEnum.DatePicker,
        validators: [Validators.required],
        value: null,
      },
      {
        key: 'genderObj',
        label: 'gender',
        type: FormFieldsEnum.Gender,
        validators: [Validators.required],
        value: {
          gender: null,
          otherGender: null,
        },
      },
    ];

    return fields;
  }

  onCreateAccountClick() {
    if (!this.guest.valid) {
      return;
    }

    const requestData: ConfirmAccountExtendedRequest = {
      token: this.queryParams.token,
      email: this.queryParams.email,
      password: this.guest.get('password').value,
      confirmPassword: this.guest.get('confirmPassword').value,
      firstName: this.guest.get('firstName').value,
      lastName: this.guest.get('lastName').value,
      phone: {
        phoneNumber: this.guest.value.phone.number,
        type: this.guest.value.phone.phoneType,
      },
      dateOfBirth: this.guest.get('dateOfBirth').value,
      gender: this.guest.value.genderObj.gender,
      otherGender: this.guest.value.genderObj.otherGender,
    };
    this.accountService
      .confirmAccountExtended(requestData)
      .pipe(
        catchError((err) => {
          if (err?.error === 'patient-is-inactive-error') {
            this.openArchivedPatientError();
          }
          throw err;
        })
      )
      .subscribe((data) => {
        this.login();
      });
  }

  clinicAcceptsInsurance() {
    return (
      this.startupService.startupData.acceptsInsurance &&
      (this.startupService.startupData.acceptsMajorInsurance ||
        this.startupService.startupData.acceptsAutoAccident ||
        this.startupService.startupData.acceptsWorkerCompensation)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  login(): void {
    this.authService
      .login({
        username: this.queryParams.email,
        password: this.queryParams.type === 'extended' ? this.guest.get('password').value : this.confirmPasswordForm.get('password').value,
      })
      .pipe(
        switchMap(() => {
          return this.accountService.me();
        })
      )
      .subscribe(() => {
        this.navigateService.navigate(['pickup-appointment'], this.activatedRoute.snapshot.queryParams);
      });
  }
  private openArchivedPatientError(): void {
    this.dialog.open(RegisterInactiveUserErrorModalComponent, {
      panelClass: 'modal-error',
      data: {
        email: this.startupService.startupData.email,
        phone: this.startupService.startupData.phoneNumber,
        web: this.startupService.startupData.webAddresss,
      },
    });
  }
}
