import { SharedModule } from './../shared/shared.module';
import { ACCOUNT_SERVICES } from './services/index';
import { ACCOUNT_COMPONENTS } from './components/index';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AccountRoutingModule } from './account-routing.module';
import { ACCOUNT_GUARDS } from './guards/index';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

@NgModule({
    imports: [
        SharedModule,
        AccountRoutingModule,
        FlexLayoutModule
    ],
    declarations: [
        ...ACCOUNT_COMPONENTS,
        VerifyEmailComponent,

    ],
    providers: [
        ...ACCOUNT_SERVICES,
        ...ACCOUNT_GUARDS,

    ],
    exports: [
        ...ACCOUNT_COMPONENTS,

    ]
})
export class AccountModule {

    constructor(@Optional() @SkipSelf() parentModule: AccountModule) {
        if (parentModule) {
            throw new Error('AccountModule already loaded; Import in root module only.');
        }
    }
}
