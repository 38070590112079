import { Component, OnInit } from '@angular/core';
import { OnboardingFormsEnum } from '../../models';

@Component({
    selector: 'pp-medical-forms-list-page',
    templateUrl: 'medical-forms-list-page.component.html'
})

export class MedicalFormsListPageComponent implements OnInit {

    public get formTypes() {
      return OnboardingFormsEnum;
    }

    ngOnInit() {

    }
}
