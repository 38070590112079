import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthHelperService, StartupService } from '../../../core/services';
import { OnboardingService } from '../../../onboarding/services';
import { flatMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { TopNavbarService } from '../top-navbar/top-navbar.service';
import { FeaturePlanType } from '@unifiedpractice/up-chat-lib';

@Component({
  selector: 'pp-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent extends BaseComponent implements OnInit, OnDestroy {
  loggedIn = false;
  areOnboardingFormsCompleted = false;
  useOnlineBooking = true;
  FeaturePlanType: typeof FeaturePlanType = FeaturePlanType;

  constructor(
    private authHelperService: AuthHelperService,
    private onboardingService: OnboardingService,
    public topNavbarService: TopNavbarService,
    public startupService: StartupService
  ) {
    super();
    this.useOnlineBooking = startupService.startupData.useOnlineBooking;
  }

  ngOnInit(): void {
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
      if (this.loggedIn) {
        this.checkOnboardingFormsCompleted();
      }
    });
  }
  checkOnboardingFormsCompleted(): void {
    this.onboardingService
      .getOnboardingForms()
      .pipe(
        flatMap((res) => this.onboardingService.progress()),
        takeUntil(this.destroy$)
      )
      .subscribe((progress: any) => {
        this.areOnboardingFormsCompleted = progress.completion === 100;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  isMenuItemActive(path: string): boolean {
    if (window.location.pathname.indexOf(path) > -1) {
      return true;
    }

    if (window.location.pathname.indexOf('booking') > -1 && path === 'appointments' && !this.isBookingAppointmentRouting()) {
      return true;
    }

    if (window.location.pathname.indexOf('onboarding') > -1 && path === 'onboarding/medical-forms') {
      return true;
    }

    return false;
  }

  isBookingAppointmentMenuItemActive(): boolean {
    return this.isBookingAppointmentRouting();
  }

  isBookingAppointmentRouting(): boolean {
    return (
      window.location.pathname.indexOf('booking/location') > -1 ||
      window.location.pathname.indexOf('booking/service') > -1 ||
      window.location.pathname.indexOf('booking/practitioner') > -1 ||
      window.location.pathname.indexOf('booking/timeslot') > -1 ||
      window.location.pathname.indexOf('booking/review') > -1 ||
      window.location.pathname.indexOf('booking/success') > -1
    );
  }

  isChatActivated(): boolean {
    return !!this.startupService.isStreamChatModuleEnabled();
  }
}
