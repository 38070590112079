<h1 class="col-lg-7 col-xs-12 title modal-title cancel-modal-title"
    mat-dialog-title>
    {{'keep-me-logged-in-warning-title' | translate}}
</h1>
<mat-dialog-content>
    <div class="box box-appointment-account box-appointment-modal">
        <div class="row">
            <div class="col-lg-12">
                {{'keep-me-logged-message' | translate}}
                <strong> {{counter}} </strong>
                {{'seconds' | translate}}
            </div>
        </div>
    </div>

</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button cdkFocusInitial class="btn btn-primary" mat-dialog-close>
        {{'keep-me-logged-in' | translate}}</button>
</div>
