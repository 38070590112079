import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, ValidationErrors } from '@angular/forms';
import { Component, forwardRef, ViewChild, AfterViewInit, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'pp-signature-input',
  templateUrl: 'signature-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SignatureInputComponent),
      multi: true,
    },
  ],
})
export class SignatureInputComponent implements ControlValueAccessor, Validator, AfterViewInit {
  @ViewChild(SignaturePad, { static: true }) public signaturePad: SignaturePad;
  @ViewChildren('container') public container: QueryList<ElementRef>;

  options: Object = {};
  signature: any = null;
  propagateChange: Function = null;

  updateSignature(signature: any): void {
    this.signature = signature;
    this.propagateChange(this.signature);
  }

  writeValue(obj: any): void {
    if (!obj) {
      return;
    }
    this.signature = obj.signature;
    if (this.signature) {
      this.signaturePad.fromDataURL(this.signature);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  ngAfterViewInit(): void {
    this.setOptions();
    this.signaturePad.clear();
  }


  setOptions() {
    this.signaturePad.set('backgroundColor', 'rgb(255,255,255)');
    this.signaturePad.set('penColor', 'rgb(142, 156, 164)');
    this.signaturePad.clear();
  }

  drawBegin(): void {}

  drawComplete(): void {
    this.updateSignature(this.signaturePad.toDataURL('image/png', 0.5));
  }

  clear(): void {
    this.signaturePad.clear();
    this.updateSignature('');
  }

  validate(c: FormControl): ValidationErrors | null {
    if (!this.signature) {
      return { valid: false };
    }
    return null;
  }
}
