<div class="box select-box select-clinic-box" *ngIf="!showLoader">
  <h3 class="subtitle d-none d-sm-block">
    {{ 'insurance-information' | translate }}
  </h3>

  <form class="form-full-width" [formGroup]="form" novalidate>
    <!--  Primary Insurance switch-->
    <div class="row" *ngIf="acceptsMajorInsurance">
      <div class="col-12 form-row wrap-switch-row">
        <div class="col-11">
          <div class="switch-text-label">
            <h6>{{ 'primary-insurance' | translate }}</h6>
          </div>
        </div>

        <div class="col-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-hasPrimaryInsurance"
              name="set-name-other"
              class="switch-input"
              formControlName="hasPrimaryInsurance"
              name="hasPrimaryInsurance"
            />
            <label for="switch-hasPrimaryInsurance" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Primary Insurance form -->
    <pp-insurance-form
      *ngIf="form.get('primary')"
      [form]="primaryFG"
      [insuranceCompanies]="insuranceCompanies"
      [fields]="primaryInsuranceFields"
      [states]="states"
      [filledData]="insuranceFilledInfo.primary"
    >
    </pp-insurance-form>

    <!-- Primary Insurance upload -->
    <div class="row" *ngIf="form.get('hasPrimaryInsurance').value">
      <!-- first display uploader -->
      <div class="col-xs-12 col-md-6 form-row form-row--upload" *ngIf="loggedIn">
        <label class="label-form-control">{{ 'front-card-image' | translate }} </label>
        <pp-file-upload
          (onFileSelect)="setFiles($event)"
          [url]="getCardImageUrl(form.get('primary').get('cardFrontImagePath')?.value)"
          name="primary-front"
        ></pp-file-upload>
      </div>

      <div class="col-xs-12 col-md-6 form-row form-row--upload" *ngIf="loggedIn">
        <label class="label-form-control">{{ 'back-card-image' | translate }} </label>
        <pp-file-upload
          (onFileSelect)="setFiles($event)"
          [url]="getCardImageUrl(form.get('primary').get('cardBackImagePath')?.value)"
          name="primary-back"
        ></pp-file-upload>
      </div>
    </div>

    <!-- Secondary Insurance switch-->
    <div class="row" *ngIf="acceptsMajorInsurance">
      <div class="col-12 form-row wrap-switch-row">
        <div class="col-11">
          <div class="switch-text-label">
            <h6>{{ 'secondaryInsurance' | translate }}</h6>
          </div>
        </div>

        <div class="col-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-hasSecondaryInsurance"
              name="set-name-other"
              class="switch-input"
              formControlName="hasSecondaryInsurance"
              name="hasSecondaryInsurance"
            />
            <label for="switch-hasSecondaryInsurance" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Secondary Insurance form -->
    <pp-insurance-form
      *ngIf="form.get('secondary')"
      [form]="secondaryFG"
      [fields]="secondaryInsuranceFields"
      [insuranceCompanies]="insuranceCompanies"
      [states]="states"
      [filledData]="insuranceFilledInfo.secondary"
    >
    </pp-insurance-form>

    <!-- Secondary Insurance upload -->
    <div class="row" *ngIf="form.get('hasSecondaryInsurance').value">
      <div class="col-6 form-row form-row--upload" *ngIf="loggedIn">
        <label class="label-form-control">{{ 'front-card-image' | translate }} </label>
        <pp-file-upload
          (onFileSelect)="setFiles($event)"
          [url]="getCardImageUrl(form.get('secondary').get('cardFrontImagePath')?.value)"
          name="secondary-front"
        ></pp-file-upload>
      </div>
      <div class="col-6 form-row form-row--upload" *ngIf="loggedIn">
        <label class="label-form-control">{{ 'back-card-image' | translate }} </label>
        <pp-file-upload
          (onFileSelect)="setFiles($event)"
          [url]="getCardImageUrl(form.get('secondary').get('cardBackImagePath')?.value)"
          name="secondary-back"
        ></pp-file-upload>
      </div>
    </div>

    <!--  Auto Accident Insurance switch-->
    <div class="row" *ngIf="acceptsAutoAccident">
      <div class="col-12 form-row wrap-switch-row">
        <div class="col-11">
          <div class="switch-text-label">
            <h6>{{ 'auto-accident-radiobox' | translate }}</h6>
          </div>
        </div>

        <div class="col-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-hasAutoInsurance"
              name="set-name-auto"
              class="switch-input"
              formControlName="hasAutoInsurance"
              name="hasAutoInsurance"
            />
            <label for="switch-hasAutoInsurance" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Auto Accident Insurance form -->
    <pp-insurance-form
      *ngIf="form.get('autoAccident')"
      [form]="autoFG"
      [insuranceCompanies]="insuranceCompanies"
      [showInsuranceAutocomplete]="false"
      [fields]="autoInsuranceFields"
      [states]="states"
      [filledData]="insuranceFilledInfo.autoAccident"
    >
    </pp-insurance-form>

    <!-- Work Insurance switch-->
    <div class="row" *ngIf="acceptsWorkerCompensation">
      <div class="col-12 form-row wrap-switch-row">
        <div class="col-11">
          <div class="switch-text-label">
            <h6>{{ 'work-insurance-radiobox' | translate }}</h6>
          </div>
        </div>

        <div class="col-1">
          <div class="md-switch">
            <input
              type="checkbox"
              id="switch-hasWorkInsurance"
              name="set-name-employment"
              class="switch-input"
              formControlName="hasWorkInsurance"
              name="hasWorkInsurance"
            />
            <label for="switch-hasWorkInsurance" class="switch-label">
              <span class="toggle-on"></span>
              <span class="toggle-off"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Work Insurance form -->
    <pp-insurance-form
      *ngIf="form.get('workerCompensation')"
      [form]="workFG"
      [insuranceCompanies]="insuranceCompanies"
      [showInsuranceAutocomplete]="false"
      [fields]="workInsuranceFields"
      [states]="states"
      [filledData]="insuranceFilledInfo.workerCompensation"
    >
    </pp-insurance-form>
  </form>

  <div class="row">
    <div class="col-12 form-row">
      <button type="submit" class="btn btn-primary" (valid)="onSubmit()" [disabled]="saving" [ppSubmitIfValid]="form">
        <i class="fa fa-spin fa-circle-o-notch" *ngIf="saving"></i>
        {{ 'save-and-continue' | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Loader -->
<div class="box select-box select-clinic-box" *ngIf="showLoader">
  <h3 class="subtitle d-none d-sm-block">
    {{ 'insurance-information' | translate }}
  </h3>
  <div class="row" *ngFor="let x of loaderRows" style="padding-bottom: 30px">
    <div class="col-12">
      <div class="line width-100"></div>
      <div class="line width-100"></div>
    </div>
  </div>
</div>
