import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromRoot from './../../../ngrx';
import { BookingActions } from '../../actions/index';
import { Store } from '@ngrx/store';
import { AuthHelperService } from '../../../core/services';
import { NavigateWrapperService } from '../../../core/services/navigateWrapper.service';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { filter, takeUntil } from 'rxjs/operators';
import { BookingConfirmationStatus } from '../../models';

@Component({
  selector: 'pp-successfully-confirmation-step-page',
  templateUrl: 'successfully-confirmation-step-page.component.html',
  styleUrls: ['successfully-confirmation-step-page.component.scss'],
})
export class SuccessfullyConfirmationStepPageComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'booking-success-title-not-logged-in';
  text = 'booking-success-info-not-logged-in';
  bookingConfirmationTitle;
  bookingConfirmationText;
  buttonLabel: string = '';
  loggedIn = false;
  bookingConfirmationStatus: BookingConfirmationStatus = null;

  constructor(
    private store: Store<fromRoot.State>,
    private authHelperService: AuthHelperService,
    private navigateService: NavigateWrapperService
  ) {
    super();
    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }

  ngOnInit() {
    this.store
      .select(fromRoot.getBookingSuccessStepMessages)
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => {
        this.title = message.title;
        this.text = message.text;
      });
    this.store
      .select(fromRoot.getBookingConfirmationStatus)
      .pipe(
        filter((bookingConfirmationStatus) => !!bookingConfirmationStatus),
        takeUntil(this.destroy$)
      )
      .subscribe((bookingConfirmationStatus) => {
        this.bookingConfirmationStatus = bookingConfirmationStatus;
        this.updateBookingInfoMessages(bookingConfirmationStatus);
      });
    this.store.dispatch(new BookingActions.ResetAllAction(null));

    // scroll top
    window.scrollTo(0, 0);
  }

  navigateTo(route, enabled = true) {
    if (!this.loggedIn) {
      this.store.dispatch(new BookingActions.SetExistingPatientAction(null));
    }

    if (enabled) {
      this.navigateService.navigate([route]);
    }
  }

  updateBookingInfoMessages(bookingConfirmationStatus: BookingConfirmationStatus): void {
    switch (bookingConfirmationStatus) {
      case BookingConfirmationStatus.AutoAcceptedWithNewUser:
      case BookingConfirmationStatus.CreatedWithNewUser:
      case BookingConfirmationStatus.CreatedWithUserWithoutPP:
        this.bookingConfirmationTitle = 'BookingConfirmation.ActionRequiredUser.Title';
        this.bookingConfirmationText = 'BookingConfirmation.ActionRequiredUser.Description';
        this.buttonLabel = 'BookingConfirmation.ActionRequiredUser.Button';
        break;
      case BookingConfirmationStatus.CreatedWithMatchingError:
        this.bookingConfirmationTitle = 'BookingConfirmation.ActionRequiredClinic.Title';
        this.bookingConfirmationText = 'BookingConfirmation.ActionRequiredClinic.Description';
        this.buttonLabel = 'BookingConfirmation.GotItButton';
        break;
      case BookingConfirmationStatus.CreatedWithExistingUser:
        this.bookingConfirmationTitle = 'BookingConfirmation.PendingConfirmation.Title';
        this.bookingConfirmationText = 'BookingConfirmation.PendingConfirmation.Description';
        this.buttonLabel = 'BookingConfirmation.GotItButton';
        break;
      case BookingConfirmationStatus.AutoAcceptedWithExistingUser:
        this.bookingConfirmationTitle = 'BookingConfirmation.SuccessfullyScheduled.Title';
        this.bookingConfirmationText = 'BookingConfirmation.SuccessfullyScheduled.Description';
        this.buttonLabel = 'BookingConfirmation.GotItButton';
        break;
    }
  }

  canShowStepper(): boolean {
    return (
      this.bookingConfirmationStatus === BookingConfirmationStatus.AutoAcceptedWithNewUser ||
      this.bookingConfirmationStatus === BookingConfirmationStatus.CreatedWithNewUser ||
      this.bookingConfirmationStatus === BookingConfirmationStatus.CreatedWithUserWithoutPP
    );
  }

  getStatusIcon(): string {
    return this.bookingConfirmationStatus === BookingConfirmationStatus.CreatedWithMatchingError ? 'sand-clock.svg' : 'success2.svg';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
