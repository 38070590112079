import { StartupService } from './../../../core/services/startup.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { isBefore, parseISO } from 'date-fns';
import { first, takeUntil } from 'rxjs/operators';
import { BookingActions } from '../../../booking/actions/booking.actions';
import { GlobalErrorsService, NavigateWrapperService } from '../../../core/services';
import { OnboardingService } from '../../../onboarding/services';
import { Appointment } from '../../models/appointment';
import { ClinicService } from './../../../clinic/services';
import * as fromRoot from './../../../ngrx';
import { AppointmentsService } from './../../services/appointments.service';
import { CancelModalComponent } from './../cancel-modal/cancel-modal.component';
import { BaseComponent } from '../../../shared/components/base/base.component';

@Component({
  selector: 'pp-appointment-page',
  templateUrl: 'appointment-page.component.html',
})
export class AppointmentPageComponent extends BaseComponent implements OnInit, OnDestroy {
  private token: string;
  private uid: string;

  appointment: Appointment;
  showOnboardingAlert = false;
  showNotFound = false;
  canCancelRescheduleFromSettings = false;
  minHoursToCancelRescheduleFromSettings: number;
  canReschedule = false;
  canCancel = false;
  canBookSimilar = false;
  status = '';
  pastAppointment = false;

  cancellationPeriod: number;

  constructor(
    public appointmentsService: AppointmentsService,
    private dialog: MatDialog,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private navigateService: NavigateWrapperService,
    private onboardingService: OnboardingService,
    private globalErrorsService: GlobalErrorsService,
    private clinicService: ClinicService,
    private startupService: StartupService
  ) {
    super();
    this.route.params.pipe(first(), takeUntil(this.destroy$)).subscribe((params) => {
      this.uid = params['uid'];
      this.onboardingService.setAppointmentUid(params['uid']);
    });

    this.route.queryParams.pipe(first(), takeUntil(this.destroy$)).subscribe((params) => {
      this.token = params['token'];
      this.onboardingService.setToken(params['token']);
    });

    this.onboardingService
      .progress()
      .pipe(takeUntil(this.destroy$))
      .subscribe((progress: any) => {
        this.showOnboardingAlert = progress.completion < 100;
      });
  }

  ngOnInit() {
    this.appointmentsService.get(this.uid, this.token).subscribe(
      (res: any) => {
        this.appointment = res.appointment;
        this.appointment.localDateTimeDateObj = parseISO(res.appointment.localDateTime);
        this.canCancelRescheduleFromSettings = res.canCancelRescheduleAppointment;
        this.minHoursToCancelRescheduleFromSettings = res.minimumHoursToCancelReschedule;
        this.setAppointmentStatus();
      },
      (err) => {
        this.globalErrorsService.display404();
      }
    );
    this.cancellationPeriod = this.startupService.startupData.cancellationPeriod;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onOpenCancelModalClick() {
    const dialogRef = this.dialog.open(CancelModalComponent, {
      data: this.appointment,
      panelClass: 'cancel-app-modal-content',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.appointment.acceptedState = result.acceptedState;
          this.appointment.appointmentState = result.appointmentState;
          this.setAppointmentStatus();
        }
      });
  }

  onRescheduleClick() {
    this.store.dispatch(new BookingActions.SetAppointmentUid(this.appointment.appointmentUid));
    this.store.dispatch(new BookingActions.SetExistingPatientAction(true));
    this.setBookingStoreDataAndRedirect();
  }

  setAppointmentStatus() {
    this.status = this.appointmentsService.getStatus(this.appointment);
    this.canReschedule = this.appointmentsService.canCancelOrReschedule(
      this.status,
      this.appointment.localDateTimeDateObj,
      this.canCancelRescheduleFromSettings,
      this.minHoursToCancelRescheduleFromSettings
    );
    this.canCancel = this.appointmentsService.canCancelOrReschedule(
      this.status,
      this.appointment.localDateTimeDateObj,
      this.canCancelRescheduleFromSettings,
      this.minHoursToCancelRescheduleFromSettings
    );
    this.canBookSimilar = this.appointmentsService.canBookSimilar();
    this.pastAppointment = !isBefore(new Date(), this.appointment.localDateTimeDateObj);
  }

  private setBookingStoreDataAndRedirect() {
    if (!this.appointment.location.isActiveForOnlineBooking) {
      this.store.dispatch(new BookingActions.SetMessageOnLocationStep('location-not-available'));
      return this.navigateService.navigate(['booking', 'location']);
    } else {
      this.store.dispatch(new BookingActions.SetLocationAction(this.appointment.location));
    }

    if (this.clinicService.serviceNotAvailableForAuthenticated(this.appointment.service)) {
      this.store.dispatch(new BookingActions.SetMessageOnServiceStep('service-not-available'));
      return this.navigateService.navigate(['booking', 'service']);
    } else {
      this.store.dispatch(new BookingActions.SetServiceAction(this.appointment.service));
    }

    if (!this.appointment.practitioner.isActiveForOnlineBooking) {
      this.store.dispatch(new BookingActions.SetMessageOnPractitionerStep('practitioner-not-available'));
      return this.navigateService.navigate(['booking', 'practitioner']);
    } else {
      this.store.dispatch(new BookingActions.SetPractitionerAction(this.appointment.practitioner));
    }
    return this.navigateService.navigate(['booking', 'timeslot']);
  }

  completeForms() {
    const queryParams = this.onboardingService.getQueryParams();
    this.navigateService.navigate(['onboarding'], queryParams, true);
  }
}
