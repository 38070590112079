import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthHelperService, NavigateWrapperService } from '../../../core/services';
import { OnboardingService } from '../../services';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-success-page',
  templateUrl: 'success-page.component.html',
})
export class SuccessPageComponent extends BaseComponent implements OnInit, OnDestroy {
  loggedIn: boolean;
  appointmentUid: string;

  constructor(
    private navigateService: NavigateWrapperService,
    private authHelperService: AuthHelperService,
    private onboardingService: OnboardingService
  ) {
    super();
  }

  ngOnInit() {
    this.appointmentUid = this.onboardingService.getAppointmentUid();

    const contentContainer = document.querySelector('.wrap-buttons-appointment');
    if (contentContainer) {
      contentContainer.scrollIntoView();
    }

    this.authHelperService.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  navigateTo(route, enabled = true) {
    if (enabled) {
      this.navigateService.navigate([route]);
    }
  }
}
