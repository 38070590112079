import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from '../../validators/custom-validators';
import { BaseComponent } from '../base/base.component';
import { AccountService } from '../../../account/services';
import { VerifyEmailRequest } from '../../../account/services/account.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'pp-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendEmailDialogComponent extends BaseComponent implements OnInit, OnDestroy {
  emailFormControl: FormControl = new FormControl('', [Validators.required, CustomValidators.email]);

  isLoading: boolean = false;
  showConfirmation: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VerifyEmailRequest,
    public dialogRef: MatDialogRef<SendEmailDialogComponent>,
    private accountService: AccountService,
    private changeDetector: ChangeDetectorRef,
    private toastr: ToastrService) {
    super();
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  createAccount(): void {
    this.emailFormControl.markAsTouched();
    if (this.emailFormControl.invalid) {
      return;
    }

    this.accountService
      .verifyAccount({
        ...this.data,
        email: this.emailFormControl.value,
      })
      .subscribe((data) => {
        this.isLoading = true;
        this.showConfirmation = true;
        this.changeDetector.markForCheck();
      });
  }

  resendEmail(): void {
    this.accountService
      .verifyAccount({
        ...this.data,
        email: this.emailFormControl.value,
      })
      .subscribe((data) => {
        this.isLoading = true;
        this.showConfirmation = true;
        this.toastr.success("Email sent !");
        this.changeDetector.markForCheck();
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
