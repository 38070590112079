<div class="content-inner-deprecated pp-container">
  <div class="top-title">
    {{ 'book-appointment' | translate }}
  </div>
  <div class="box select-box select-clinic-box">
    <h3 class="subtitle subtitle-create-account">{{ 'primary-insurance' | translate }}</h3>
    <p>
      <i class="text-note">{{ 'required-fields-note' | translate }}</i>
    </p>
    <form class="form-full-width" [formGroup]="primaryInsurance" novalidate>
      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <label class="label-form-control required-field-label">{{ 'insuranceCompanyName' | translate }} </label>
          <input
            class="form-control"
            placeholder="{{ 'insuranceCompanyName-placeholder' | translate }}"
            type="text"
            value=""
            formControlName="companyName"
            name="companyName"
            [matAutocomplete]="auto"
          />
          <div
            *ngIf="
              primaryInsurance.get('companyName') &&
              primaryInsurance.get('companyName').invalid &&
              (primaryInsurance.get('companyName').dirty || primaryInsurance.get('companyName').touched)
            "
            class=" "
          >
            <p class="error-message-field" *ngIf="primaryInsurance.get('companyName').errors">
              {{ 'form-input-error-required' | translate }}
            </p>
          </div>

          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <div class="row">
        <pp-form-render [formFields]="primaryInsFields" [form]="primaryInsurance"></pp-form-render>
      </div>

      <div class="row">
        <div class="col-lg-12 col-xs-12 form-row">
          <label class="label-form-control">{{ 'insuranceIsCoveredByOther' | translate }} </label>
        </div>

        <div class="col-lg-12 col-xs-12 form-row wrap-multiple-form-controls">
          <div class="col-lg-6 col-sm-12">
            <mat-select
              class="form-control form-control-select"
              placeholder="{{ 'select' | translate }}"
              formControlName="insuranceIsCoveredByOther"
              name="insuranceIsCoveredByOther"
              (change)="updateCoveredInsurance()"
            >
              <mat-option [value]="true">{{ 'yes-existing-patient' | translate }} </mat-option>
              <mat-option [value]="false">{{ 'no-firsttime-patient' | translate }} </mat-option>
            </mat-select>
          </div>
        </div>
      </div>

      <div class="row" formGroupName="insuredInfo" *ngIf="primaryInsurance.get('insuranceIsCoveredByOther').value">
        <pp-form-render [formFields]="insuredInfoField" [form]="insuredInfo"></pp-form-render>
      </div>
    </form>

    <div class="row">
      <div class="form-row col-lg-12 col-xs-12" style="display: block">
        <button class="btn btn-primary" (valid)="onContinueClick()" [ppSubmitIfValid]="primaryInsurance">
          {{ 'continue' | translate }}
        </button>
        <button class="btn btn-secondary pull-right" (click)="navigateService.navigate(['booking', 'insurance-type'])">
          {{ 'cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
