import { CountryStateComponent } from './../../shared/components/country-state/country-state.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';

import { BehaviorSubject, Subject, Observable, Subscription, of, interval } from 'rxjs';
import { map, switchMap, catchError, mergeMap, filter, tap, flatMap, first, shareReplay } from 'rxjs/operators';


@Injectable()
export class CatalogsService {
    constructor(private http: HttpClient) { }

    private _countries;
    private _states;

    public getCountries() {
        if (!this._countries) {
            return this.http.get(`${environment.apiUrl}/Catalogs/countries`)
                .pipe(
                    tap((data) => {
                        this._countries = <any[]>data;
                        const us = { code: 'US', name: 'United States' };
                        this._countries.unshift(us);
                    }),
                    shareReplay(1)
                );
        }
        return of(this._countries);
    }

    public getStates() {
        if (!this._states) {
            const params = new HttpParams().set('countryCode', 'US');
            return this.http.get(`${environment.apiUrl}/Catalogs/states`, { params })
                .pipe(
                    map(data => {
                        this._states = Object.keys(data).map(prop => {
                            return { label: data[prop].name, value: data[prop].code };
                        });
                        return this._states;
                    }),
                    shareReplay(1)
                );
        }
        return of(this._states);
    }

}
