export enum OnboardingFormsEnum {
    Personal,
    Demographic,
    Additional,
    Contact,
    EmergencyContact,
    PrimaryPhysician,
    Medical,
    Insurance,
    MedicalForms,
    ConsentForms,
    ScreeningForms,
    SignForms
}

