import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pp-booking-steps',
  templateUrl: './booking-steps.component.html',
  styleUrls: ['./booking-steps.component.scss'],
})
export class BookingStepsComponent implements OnInit {
  @Input() step: 'step-1' | 'step-2';

  constructor() {}

  ngOnInit() {}
}
