import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnboardingService } from '../../services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pp-onboarding-container-page',
  templateUrl: 'onboarding-container-page.component.html',
})
export class OnboardingContainerPageComponent implements OnInit, OnDestroy {
  private subscriptions = [];
  progress: any;

  constructor(private location: Location, private route: ActivatedRoute, private onboardingService: OnboardingService) {}

  ngOnInit() {
    this.progress = this.route.snapshot.data.progress;

    this.subscriptions.push(
      this.location.subscribe((e) => {
        if (e && e.pop && e.url) {
          const nextRoute = e.url;
          if (nextRoute.endsWith('onboarding')) {
            window.history.go(-1);
          }
        }
      })
    );
  }

  fail() {
    this.onboardingService.error().subscribe((res) => {});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
