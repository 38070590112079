import { Component } from '@angular/core';
import { AuthHelperService } from './core/services';

@Component({
  selector: 'pp-main-container',
  template: `
    <pp-top-navbar></pp-top-navbar>
    <div class="page">
      <div class="page-content" fxFlex="100%">
        <pp-side-navbar></pp-side-navbar>
        <div fxFlex class="w-100"><router-outlet></router-outlet></div>
      </div>
    </div>
  `,
})
export class MainContainerComponent {
  // the only purpose is to have a main route that contains the clinic name

  // all the routes that require clinic-name param
  // must be children to this route and should be imported in the app.routes

  constructor(public authHelperService: AuthHelperService) {}
}
