import { AuthHelperService } from './core/services/auth-helper.service';
import { EventService } from './core/services/event.service';
import { Component, HostListener, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalErrorsService } from './core/services/global-errors.service';
import { BaseComponent } from './shared/components/base/base.component';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { AccountService } from './account/services';
import { select, Store } from '@ngrx/store';
import * as fromRoot from './ngrx';

@Component({
  selector: 'pp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Xamarin. Used to hide the web navigation. */
  isXamarinLoaded: boolean;

  error: any = undefined;

  constructor(
    private translateService: TranslateService,
    private eventService: EventService,
    private authHelperService: AuthHelperService,
    private globalErrorsService: GlobalErrorsService,
    private accountService: AccountService,
    private store: Store<fromRoot.State>
  ) {
    super();
    // i18n setup
    const languages = ['es', 'en', 'zh', 'ja'];
    this.translateService.addLangs(languages);
    this.translateService.setDefaultLang('en');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(languages.includes(browserLang) ? browserLang : 'en');

    // global erros
    globalErrorsService.displayError.pipe(takeUntil(this.destroy$)).subscribe((error) => (this.error = error));

    // xamarin
    this.isXamarinLoaded = false;
  }

  ngOnInit(): void {
    this.accountService.user
      .pipe(
        distinctUntilChanged((prev, curr) => (prev && curr ? prev.uniqueId === curr.uniqueId : false)),
        takeUntil(this.destroy$)
      )
      .subscribe((patientInfo) => {
        if (!patientInfo) {
          this.store
            .pipe(select(fromRoot.getOrganizationState))
            .pipe(
              filter((organization) => !!organization),
              take(1),
              takeUntil(this.destroy$)
            )
            .subscribe((organization: any) => {
              this.initializePendo('', organization.guid, organization.name);
            });
          return;
        }
        this.identityPendo(patientInfo.uniqueId, patientInfo.clinicInfo.clinicUniqueId, patientInfo.clinicInfo.clinicName);
      });
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /** Xamarin. Listen to "hideWebNavigation" event raised from C# in order to hide the navigation menu. */
  @HostListener('window:hideWebNavigation', ['$event'])
  hideWebNavigation(event: CustomEvent) {
    if (event.detail) {
      this.isXamarinLoaded = event.detail === 'true';
    }
  }

  ngAfterViewInit(): void {
    /** Xamarin. Raise event when the app has loaded. */
    this.eventService.raise('angular_loaded');

    /** Xamarin. Raise event with the authentication token. */
    this.authHelperService.tokens$.pipe(takeUntil(this.destroy$)).subscribe((token) => {
      this.eventService.raise('auth_token', token);
    });
  }

  private initializePendo(patientId: string, clinicId: string, clinicName: string): void {
    window['pendo'].initialize({
      visitor: {
        id: patientId,
        role: 'patient',
      },
      account: {
        id: clinicId,
        clinicId: clinicId,
        clinicName: clinicName,
      },
    });
  }

  private identityPendo(patientId: string, clinicId: string, clinicName: string): void {
    if ((window as any).pendo && (window as any).pendo.isReady()) {
      (window as any).pendo.identify(patientId, clinicId);
      return;
    }
    this.initializePendo(patientId, clinicId, clinicName);
  }
}
